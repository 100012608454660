import React from 'react'
import CurrencyFormat from 'react-currency-format'
import '../../css/components/input/input.css'

const InputCurrency = ({ field, validate, label, value, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <CurrencyFormat
        placeholder={field.placeholder || ''}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'R$'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={
          hasErrors
            ? 'form-control is-invalid input-text text-right'
            : 'form-control input-text text-right'
        }
        name={field.name}
        onInput={e => validate(e, field)}
        onBlur={e => validate(e, field)}
      />
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default InputCurrency