import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import Select from '../../components/select'
import DateTime from '../../components/date-time'
// import InputTime from '../../components/input-time'
import InputMask from '../../components/input-mask'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { stringToDate, floatToTime, timeToFloat } from '../../actions/util'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      id: '',
      date: new Date(),
      machine_id: '',
      crop_id: '',
      activity_id: '',
      amount_of_hours: '',
      amount_of_hours_format: '000:00',
      observation: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'machine_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'crop_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'activity_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //4
          name: 'amount_of_hours_format',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'observation',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getMachineTime(id)

    this.getMachines()
    this.getCrops()
    this.getActivities()
  }

  getMachines = async () => {
    api.get('machines').then(result => {
      const machines = result.data
      const options = [{ label: '', value: null }]

      machines.map(func => {
        const f = {
          label: func.name,
          value: func.id
        }
        options.push(f)
        return null
      })
      const { fields } = this.state

      fields[1].options = options
      this.setState({
        fields: fields
      })
    })
  }

  getCrops = async () => {
    api.get('crops').then(result => {
      const crops = result.data
      const options = [{ label: '', value: null }]

      crops.map(item => {
        const f = {
          label: item.name,
          value: item.id
        }
        options.push(f)
        return null
      })
      const { fields } = this.state

      fields[2].options = options
      this.setState({
        fields: fields
      })
    })
  }

  getActivities = async () => {
    api.get('activities').then(result => {
      const crops = result.data
      const options = [{ label: '', value: null }]

      crops.map(func => {
        const f = {
          label: func.name,
          value: func.id
        }
        options.push(f)
        return null
      })
      const { fields } = this.state

      fields[3].options = options
      this.setState({
        fields: fields
      })
    })
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''

    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const machineTime = this.state

    let fields = this.submitForm(e, machineTime.fields)
    this.setState({ fields: fields })

    if (machineTime.valid && machineTime.errors.length === 0) {
      machineTime.id ? this.update() : this.save()
    }
    return false
  }

  getMachineTime = async id => {
    await api.get(`machine-times/${id}`)
      .then(result => {
        const machineTime = result.data
        const fields = this.state.fields

        fields[0].value = machineTime.date
        fields[1].value = machineTime.machine_id
        fields[2].value = machineTime.crop_id
        fields[3].value = machineTime.activity_id
        fields[4].value = machineTime.amount_of_hours
        fields[5].value = machineTime.observation

        this.setState({
          id: machineTime.id,
          date: machineTime.date ? stringToDate(machineTime.date) : '',
          machine_id: machineTime.machine_id || '',
          crop_id: machineTime.crop_id || '',
          activity_id: machineTime.activity_id || '',
          amount_of_hours_format: floatToTime(machineTime.amount_of_hours) || '000:00',
          observation: machineTime.observation || '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Horas Máquina', url: '/machine-times' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/machine-times')
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    event.preventDefault()
    const name = event.target.name
    const machineTime = this.state
    const fields = this.state.fields

    fields[0].value = machineTime.date
    fields[1].value = machineTime.machine_id
    fields[2].value = machineTime.crop_id
    fields[3].value = machineTime.activity_id
    fields[4].value = machineTime.amount_of_hours_format
     fields[5].value = machineTime.observation

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const machineTime = this.state
    machineTime.amount_of_hours = timeToFloat(machineTime.amount_of_hours_format)

    await api.post('machine-times', machineTime)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push('/machine-times')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  update = async () => {
    const machineTime = this.state
    machineTime.amount_of_hours = timeToFloat(machineTime.amount_of_hours_format)

    await api.put(`machine-times/${machineTime.id}`, machineTime)
      .then(result => {
        toastSuccess(`Registro de ${machineTime.name} atualizado!`)
        this.props.history.push('/machine-times')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  render() {
    const machineTime = this.state

    return (
      <Widget
        title={machineTime.id ? `Editar Lançamento` : 'Novo Lançamento'}>

        <div className="row">
          <div className="col-sm col-md-7">
            <form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm col-md-4">
                  <DateTime
                    label="Data"
                    value={machineTime.date}
                    onChange={date => this.setState({ date: date })}
                    field={machineTime.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Select
                    label="* Máquina"
                    value={machineTime.machine_id}
                    onChange={this.handleChange}
                    options={machineTime.fields[1].options}
                    field={machineTime.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Safra"
                    value={machineTime.crop_id}
                    onChange={this.handleChange}
                    options={machineTime.fields[2].options}
                    field={machineTime.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[2])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Atividade"
                    value={machineTime.activity_id}
                    options={machineTime.fields[3].options}
                    onChange={this.handleChange}
                    field={machineTime.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[3])}
                  />
                </div>

                <div className="col-sm col-md-4">
                  <InputMask
                    label="* Horas Trabalhadas"
                    value={machineTime.amount_of_hours_format}
                    onChange={this.handleChange}
                    mask={'999:99'}
                    field={machineTime.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[4])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <InputText
                    label="Observações"
                    value={machineTime.observation}
                    onChange={this.handleChange}
                    field={machineTime.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, machineTime.fields[5])}
                  />
                </div>
              </div>


              <div className="row justify-content-end mb-3 ">
                <div className="col-sm col-md-4">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!machineTime.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col-sm col-md-3">
                  <Link to="/machine-times">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index