
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FiEdit } from 'react-icons/fi'
import { formatPrice, dateToString } from '../../../../actions/util'

const OutOfBudget = ({ data }) => {

  const rowTotalStyle = data => {
    const style = {
      backgroundColor: '#98c884',
      fontWeight: 'bold',
    }
    if (data.month === 'Total') return style

    return null
  }

  return (
    <Container>
      <h2 className="text-center">Lançamentos fora do período do Orçamento</h2>

      <div className="table-responsive">
        <table className="table table-sm table-striped table-hover">
          <thead className="thead-report">
            <tr>
              <th scope="col">DATA VENC.</th>
              <th scope="col">DATA PGTO</th>
              <th scope="col">DESCRIÇÃO</th>
              <th scope="col">TIPO</th>
              <th scope="col">VALOR</th>
              <th scope="col" className="text-center">#</th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.map((reg, i) => (
                <tr key={i} style={rowTotalStyle(reg)}>
                  <td>{reg.due_date ? dateToString(reg.due_date) : ''}</td>
                  <td>{reg.pay_date ? dateToString(reg.pay_date) : ''}</td>
                  <td>{reg.description}</td>
                  <td>{reg.payment_type}</td>
                  <td>{formatPrice(reg.value)}</td>
                  <td className="text-center">
                    <Link to="#">
                      <button className="btn btn-success btn-sm">
                        <FiEdit size={14} /> Ver
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            }
            <tr style={{ background: '#98c884', fontWeight: 'bold' }}>
              <td colSpan="5">Total</td>
              <td>R$ 1.000,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default OutOfBudget

export const Container = styled.div`

  h2 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`

export const Total = styled.div`
  background: '#98c884' !important;
  font-weight: 'bold';
`