import React, { useCallback, useState, useRef } from 'react';
import { GoSearch, GoX } from 'react-icons/go';
import InputLabel from '../../../components/unform/input-label';
import { Form } from '@unform/web';
import styled from 'styled-components'

const CargoPackingsFilter = ({ setParams }) => {
  const formRef = useRef(null);

  const[isFilter, setIsFilter] = useState(false)


  const clearFieldsSearch = useCallback(() => {
    formRef.current.reset()
    formRef.current.setFieldValue('document', '');

    setParams({
      page: 1,
      perPage: 20,
    })

  }, [setParams]);

  const submit = useCallback(async (data) => {
    let filter = {}

    if(data.document) filter.document = data.document

    if(Object.keys(filter).length > 0){
      filter.page = 1
      filter.perPage = 999999
      // filter.detail_level= 'analytic'
    }
    
    setParams(filter)
  },[setParams])

  return (
    <FilterContainer>
      <div className="row pt-2 ">
        <div className="col-sm text-right">
          <a
            className="text-success btn p-0"
            data-toggle="collapse"
            href="#collapseCArgo"
            role="button"
            aria-expanded="false"
            aria-controls="collapseCArgo"
            onClick={() => {
              setIsFilter(!isFilter)
              if (isFilter) clearFieldsSearch()
            }}
          >
            {!isFilter && <>Filtrar <GoSearch /></>}
            {isFilter && <>Fechar Filtro <GoX /></>}
          </a>
        </div>
      </div>

      <div className="col p-1 ">
        <div className="collapse filter" id="collapseCArgo">
          <div className="card card-body">

            
            <Form ref={formRef} onSubmit={submit} noValidate>
              <div className="row justify-content-center">
                <div className="col-sm col-md-6">
                  <span>Filtrar por romaneio</span>
                  
                  <div className="col-sm p-0">
                    <InputLabel
                      label="Documento"
                      name="document"
                    />
                  </div>

                  <div className="row justify-content-end mt-3">
                    <div className="col-sm col-md-3 mb-1">
                      <button className="btn btn-success btn-block" type='submit'>
                        Filtrar <GoSearch />
                      </button>
                    </div>

                    <div className="col-sm col-md-3 mb-1">
                      <button
                        href="#"
                        className="btn btn-success btn-block"
                        onClick={clearFieldsSearch}
                      >
                        Limpar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </FilterContainer>
  );
};

export default CargoPackingsFilter;


export const FilterContainer = styled.div`
  .card {
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 10px;
    border: none;
  }

  .btn-success{
    height: 35px !important;
  }

  .nav-link {
    :hover {
      color: #2d7330;
    }
  }
`
