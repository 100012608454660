import React from 'react'
import CurrencyFormat from 'react-currency-format'
import '../../css/components/input/input.css'

const InputTextQuantity = ({ field, validate, label, value, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <CurrencyFormat
        placeholder={field.placeholder || ''}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={3}
        fixedDecimalScale={true}
        value={value}
        onChange={onChange}
        onBlur={(e, field) => validate(e, field)}
        onInput={(e, field) => validate(e, field)}
        disabled={disabled}
        className={hasErrors ? 'form-control input-text is-invalid' : 'form-control input-text'}
        name={field.name || ''}
      />
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default InputTextQuantity