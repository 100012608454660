
import api from './api'
import { handleError } from './handle-error'

export const isAuthenticated = () => {
  if (localStorage.getItem('ns_access_token')) {
    return true;
  } else {
    return false;
  }
}

export const login = (token) => {
  localStorage.setItem('ns_access_token', token);
}

export const logoff = () => {
  localStorage.removeItem('ns_access_token');
  window.location.reload();
}

export const getToken = () => {
  return localStorage.getItem('ns_access_token');
}

export const authenticate = async (data) => {
  const result = new Promise((resolve) => {
    api.post('authenticate', data)
      .then(result => {
        resolve(result.data)
      })
      .catch(async error => {
        const response = await handleError(error);
        resolve(response);
      });
  })
  return result
}
