import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../actions/auth-context';

function Logout(props) {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    signOut();
    window.location.href = '/';
  }, [signOut]);

  return <div></div>;
}

export default Logout;
