export const dateToString = async (date) => {
  if (date) {
    const string_date = date
      .toISOString()
      .substr(0, 10)
      .split('/')
      .reverse()
      .join('-');

    return string_date;
  }

  return null;
};

export const dateToStringWithTime = async (date) => {
  if (date) {
    const string_date = date
      .toISOString()
      .substr(0, 10)
      .split('/')
      .reverse()
      .join('-');

    return `${string_date} 00:00:00`;
  }

  return null;
};

export const dateToStringDDMMYYY = (date) => {
  const new_date = new Date(date);

  let day = new_date.getDate();
  let month = new_date.getMonth() + 1;
  const year = new_date.getFullYear();

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${day}/${month}/${year}`;
};

export const dateToStringTime = (date) => {
  const new_date = new Date(date);

  let hour = new_date.getHours();
  let minuts = new_date.getMinutes() + 1;
  let seconds = new_date.getSeconds();

  hour = hour < 10 ? `0${hour}` : hour;
  minuts = minuts < 10 ? `0${minuts}` : minuts;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hour}:${minuts}:${seconds}`;
};

export const validateExpectedDelivaryDate = (value) => {
  const today = new Date();

  const expected_delivery_date = new Date(value);

  const diff = Math.abs(today.getTime() - expected_delivery_date.getTime());

  const days_difference = Math.ceil(diff / (1000 * 60 * 60 * 24));

  if (days_difference < 15) return false;

  return true;
};
