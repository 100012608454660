import React, { useCallback, useRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import InputLabel from '../../../components/unform/input-label';
import { resetPasswordApi } from '../../../actions/pages/user.service';

const ResetPassword = () => {
  const formRef = useRef(null);

  const params = useParams();

  const [verSenha, setVerSenha] = useState(false);

  const changeVerSenha = useCallback(() => {
    const input1 = document.getElementById('password');
    const input2 = document.getElementById('password_confirmation');

    if (verSenha) {
      input1.type = 'text';
      input2.type = 'text';
    } else {
      input1.type = 'password';
      input2.type = 'password';
    }
  }, [verSenha]);

  const submit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('Senha é obrigatório'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas digitadas são diferentes'
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const dados = {
          token: params.token,
          password: data.password,
          password_confirmation: data.password_confirmation,
        };

        const response = await resetPasswordApi(dados);

        if (response) window.location.href = '/';
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [params]
  );

  useEffect(() => {
    changeVerSenha();
  }, [changeVerSenha]);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm col-md-5 form shadow m-0 px-3">
            <div className="col-sm col-md-12 logo text-center mt-4">
              <Link to="/">
                <img
                  src="../../assets/images/logo.svg"
                  alt="logo nossa safra"
                />
              </Link>

              <h6>GESTÃO AGRÍCOLA DE QUALIDADE</h6>
            </div>

            <div className="px-3">
              <Form ref={formRef} onSubmit={submit} noValidate>
                <span className="text-center">Digite sua nova senha</span>

                <InputLabel
                  type="password"
                  name="password"
                  label="Nova Senha"
                />

                <InputLabel
                  type="password"
                  name="password_confirmation"
                  label="Confirmar Senha"
                />

                <ViewPassword className="form-check text-end">
                  <p className="form-check-label">
                    <input
                      id="defaultCheck1"
                      className="form-check-input mb-3"
                      type="checkbox"
                      checked={verSenha}
                      onChange={() => {
                        setVerSenha(!verSenha);
                        changeVerSenha();
                      }}
                    />
                    mostrar senha
                  </p>
                </ViewPassword>

                <div className="row justify-content-center">
                  <div className="col-sm col-md-8">
                    <button
                      type="submit"
                      className="btn btn-block btn-success mt-5 mb-4 btn-lg"
                    >
                      ENVIAR
                    </button>
                  </div>
                </div>
                <br />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;

export const Container = styled.div`
  background: url('../../assets/images/background2.svg') center/cover no-repeat;
  height: 100vh;
  width: 110%;
  padding-top: 10%;
  margin-left: -10px;

  .form {
    background-color: #fff;
    border-radius: 5px;

    span {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-top: 30px;
    }
  }
`;

export const ViewPassword = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;

  p {
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
    color: #4d4d4d;
  }

  input {
    cursor: pointer;
    border-radius: 0 !important;
    width: 15px;
    height: 15px;
    margin-top: 2px;
  }

  :hover {
    color: red;
  }

  .form-check-input:checked {
    background-color: red;
    border-color: red;
  }
`;
