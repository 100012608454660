import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Cropper from 'react-cropper'
import Widget from '../../../components/widget'
import api from '../../../actions/api'
import { toastError } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { update } from '../../../actions/pages/person.service'
import { fetchImage, saveImage } from '../../../actions/aws.service'

import InputFile from '../../../components/input-file'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)

    this.state = {
      person: '',
      loading: true,
      imageUrl: '',
      croppedImage: null,
    }
  }

  componentDidMount() {
    const { personId } = this.props.match.params;
    if (personId) this.getPerson(personId);
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await this.readFile(e.target.files[0])
      this.setState({
        imageUrl: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
      })
    }
  }

  readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  submit(e) {
    e.preventDefault()

    if (typeof this.cropper.getCroppedCanvas() === 'undefined') return;

    const data = this.cropper.getCroppedCanvas().toDataURL();

    this.setState({ croppedImage: data });

    if (data) this.save(data);

    return false
  }

  getPerson = async id => {
    await api.get(`people/${id}`)
      .then(result => {
        const person = result.data;

        const imageUrl = fetchImage(person.thumbnail, 'people') || '../../../assets/images/profile.jpeg';

        this.setState({
          person: person,
          imageUrl: imageUrl
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Pessoas', url: '/people' },
            { page: `${person.name}`, url: `people/${person.id}` },
            { page: 'Alterar Foto', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response.status === 500) this.props.history.push('/people')
        toastError(handleError(error))
      });
  }

  save = async (croppedImage) => {
    const { person } = this.state;

    person.thumbnail = await saveImage(person.name, person.id, croppedImage, 'people');

    const resolve = await update(person);
    if (resolve) {
      setTimeout(() => {
        this.props.history.push(`/people/${person.id}`);
      }, 500);
    }
  }

  render() {
    const photo = this.state;

    return (
      <Widget
        title="Alterar Foto">
        <div className="row">
          <div className="col">
            <div className="col">
              <Cropper
                style={{ height: 350, width: '100%' }}
                aspectRatio={3 / 3}
                guides={false}
                src={photo.imageUrl}
                ref={cropper => { this.cropper = cropper; }}
              />
            </div>

            <div className="row col justify-content-center mt-4 p-0">
              <div className="mr-2">
                <InputFile
                  label="Escolher Imagem"
                  icon={true}
                  onChange={this.onFileChange}
                />
              </div>

              {/* <Link to="#" className="mr-2">
                  <button className="btn btn-success btn-icon text-white">
                    <i className="material-icons">add_a_photo</i>
                    <span>Capturar Imagem</span>
                  </button>
                </Link> */}

              <Link to="#" className="mr-2">
                <button
                  className="btn btn-success text-white"
                  onClick={this.submit}>
                  {photo.person.thumbnail ? 'Atualizar' : 'Salvar'}
                </button>
              </Link>

              <Link to={`/people/${photo.person.id}`}>
                <button
                  className="btn btn-success btn-outline">
                  Voltar
                    </button>
              </Link>
            </div>

          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;