import api from './api';
import { handleError } from './handle-error';
import { toastError, toastSuccess } from './toast';

export const index = async (url, params) => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`${url}`, { params })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
  return result;
};

export const store = async (url, data) => {
  const result = new Promise(resolve => {
    api
      .post(`${url}`, data)
      .then(response => {
        toastSuccess('Novo registro incluido com sucesso!');
        resolve(response.data);
      })
      .catch(async error => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const remove = async (url, id) => {
  const result = new Promise((resolve, reject) => {
    api
      .delete(`${url}/${id}`)
      .then(response => {
        toastSuccess('Registro excluido com sucesso!');
        resolve(response);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
  return result;
};

export const update = async (url, data) => {
  const result = new Promise(resolve => {
    api
      .put(`${url}/${data.id}`, data)
      .then(response => {
        toastSuccess('Registro atualizado com sucesso!');

        resolve(response.data);
      })
      .catch(async error => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const show = async (url, id) => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`${url}/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
        toastError(handleError(error));
      });
  });
  return result;
};
