
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import Address from './Address'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {

      // this.props.dispatch({
      //   type: 'SET_BREADCRUMB',
      //   items: [
      //     { page: 'Home', url: '/' },
      //     { page: 'Pessoas', url: '/people' },
      //     { page: person.name, url: `people/${person.id}` },
      //     { page: 'Endereços', url: '#' },
      //   ]
      // })
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(Address),
    renderNothing
  )
)(Address)

export default Component