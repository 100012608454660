
import React, { useCallback, useEffect, useState } from 'react'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { dateToString, formatSimplePrice } from '../../actions/util'
import { index, remove } from '../../actions/base-rest'
import styled from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader';
import FinancialMovementsFilter from '../financial-movements/filter/FinancialMovementsFilter'
import ResumeRecords from '../../components/resume-records'
import FinancialMovementsTable from '../financial-movements/FinancialMovementsTable'

const URL = 'receivables'

const default_params = {
  page: 1,
  perPage: 20,
};

function Receivables () {

  const [params, setParams] = useState(default_params);

  const [scrolling, setScrolling] = useState(false);

  const [data, setData] = useState([])

  const [itemDelete, setItemDelete] = useState({});

  const [loading, setLoading] = useState(false) // mostra ao atualizar registros na tabela

  const [loadingFilter, setLoadingFilter] = useState(false) // mostra ao abrir a página 
  
  const [page, setPage] = useState({current_page: 0, has_next_page: false, total: 0, balance: 0})

  const [filterButtonClicked, setFilterButtonClicked] = useState(true)

  const actions = [
    {
      name: 'Nova Conta a Receber',
      class_btn: 'btn-success',
      class_collumn: 'col-sm col-md-3 m-1',
      route: 'receivables/new',
    }
  ]

  const getResumeRecords = useCallback(async() => {
    setLoadingFilter(true)

    const filter = JSON.parse(localStorage.getItem('filter_financial_movements')) || {}

    filter.page = 1
    filter.perPage = 9999999
    
    const response = await index(URL, filter);
    
    setPage((prevState) => ({
      ...prevState,
      total: response.total,
      balance: response.total_value_by_filters
    }));

    setLoadingFilter(false)

    setFilterButtonClicked(false)
  },[])
  
  const getReceivables = useCallback(
    async (filter) => {
      let financial_movements = [];

      setLoading(true);
      filter.perPage = default_params.perPage

      const response = await index(URL, filter);

      financial_movements = response.data?.map(item => ({
        ...item,
        due_date_format: dateToString(item.due_date),
        pay_date_format: item.pay_date ? dateToString(item.pay_date) : 'Em aberto',
        value_format: formatSimplePrice(item.value),
      }))

      if (scrolling) {
        const new_data = (data) => [...data, ...financial_movements];

        setData(new_data);

        setScrolling(false);
      } else {
        setData(financial_movements);
      }

      setPage((prevState) => ({
        ...prevState,
        current_page: response.page,
        has_next_page: response.lastPage >= response.page ? true : false,
      }));

      setLoading(false);
    },
    [scrolling]
  );
 
  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);
    
    if (response) {
      setItemDelete({id: null, description: null})

      const filter = JSON.parse(localStorage.getItem('filter_financial_movements'))

      if (filter) {
        getReceivables(filter)
      }else {
        getReceivables(params)
      }
    }
  }, [itemDelete, params, getReceivables, params]);


  // obter o total de registros e o valor total da base
  useEffect(() => {
    if(filterButtonClicked) getResumeRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterButtonClicked])

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_financial_movements'))

    if (filter) {
      getReceivables(filter)
    }else {
      getReceivables(params)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  
  return (
    <Widget title="Contas a Receber" actions={actions} line={false}>

      <FinancialMovementsFilter setParams={setParams} setFilterButtonClicked={setFilterButtonClicked}/>

      {!loadingFilter && (
        <>
          <ResumeRecords
            total_itens={page.total}
            total_value={page.balance}/>

          <div className="row d-flex">
            <div className="col table">
              <FinancialMovementsTable
                data={data}
                page={page}
                setItemDelete={setItemDelete}
                setParams={setParams}
                loading={loading}
                setScrolling={setScrolling}
              />
            </div>
          </div> 
        </>
      )}

      {loadingFilter && (
        <div className="row mt-5">
          <div className="col text-center">
            <PulseLoader color="#388e3c" size={15} margin={10} />
          </div>
        </div>
      )} 

      <Modal
        isOpen={itemDelete.id ? true : false}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader>Deletar Registro</ModalHeader>
        <ModalBody>
          Deseja excluir movimento financeiro {itemDelete.description}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success col-4" onClick={deleteItem}>
            Sim
          </button>
          <button className="btn btn-success" onClick={() => setItemDelete({id: null, description: null})}>
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    </Widget>
  )
}

export default Receivables


export const TableContainer = styled.section`
  height: 100vh;
  margin-top: -40px;

  h6 {
    font-size: 12px;
    margin-bottom: -20px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .bg-success {
    /* background-color: #E8E8E8 !important; */
    background-image: linear-gradient(white, #bfbfbf);
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;