import api from './api';
import { toastError } from './toast';
import { handleError } from './handle-error';
import { dateToString, formatWeight } from './util';

export const indexCrop = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`crops`, { params })
      .then(result => {
        const _crops = result.data.map(crop => ({
          ...crop,
          initial_date_format: dateToString(crop.initial_date),
          end_date_format: dateToString(crop.end_date),
          goal_format: formatWeight(crop.goal),
        }));

        resolve(_crops);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};

export const indexResumeCrops = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`dashboard/budget/resume-of-crops-by-budget`, { params })
      .then(result => {
        const _crops = result.data.map(crop => ({
          ...crop,
          initial_date_format: dateToString(crop.initial_date),
          end_date_format: dateToString(crop.end_date),
          goal_format: formatWeight(crop.goal),
        }));

        resolve(_crops);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};
