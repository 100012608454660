/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Widget from '../../components/widget';
import InputText from '../../components/input-text';
import Select from '../../components/select';
import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import { fetchImage } from '../../actions/aws.service';
import foto from '../../assets/images/profile.jpeg';
import './style.css';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEmployee = this.handleEmployee.bind(this);
    this.includeEmployee = this.includeEmployee.bind(this);

    this.state = {
      errors: [],
      id: '',
      tillage_id: '',
      cultivation_id: '',
      cultivation_name: '',
      name: '',
      number_of_plants: '',
      employees_list: [], // employees of base rest
      employees: [], // employees of team
      addEmployee: false,
      modal: false,
      itemDelete: {},
      isDeleteTeam: false,
      fields: [
        {
          value: '', // 0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório',
            },
          },
        },
        {
          value: '', // 1
          name: 'number_of_plants',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nº de plantas é obrigatório',
            },
          },
        },
        {
          value: '', // 2
          name: 'employee_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {},
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    const { cultivationId, id } = this.props.match.params;
    if (cultivationId) {
      this.setState({ cultivation_id: cultivationId });
      this.getCultivation(cultivationId);
    }

    if (id) this.getTeam(id);

    const { tillageId } = this.props.match.params || '';
    this.setState({ tillage_id: tillageId });

    this.getEmployees();
  }

  validateField(e, field, fields) {
    e.preventDefault();

    field.value = '';
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password;
    }
    const result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      }
      return item;
    });
    return fields;
  }

  validate(e, field) {
    const fields = this.validateField(e, field, this.state.fields);
    const errors = [];
    let valid = false;

    fields.map(field => {
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields,
      errors,
      valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const team = this.state;

    const fields = this.submitForm(e, team.fields);
    this.setState({ fields });

    if (team.valid && team.errors.length === 0) {
      team.id ? this.update() : this.save();
    }
    return false;
  }

  getCultivation = id => {
    api.get('cultivations').then(result => {
      this.setState({ cultivation_name: result.data.name });
    });
  };

  getTeam = async id => {
    await api
      .get(`teams/${id}`)
      .then(result => {
        const team = result.data;
        const { fields } = this.state;

        fields[0].value = team.name || '';
        fields[1].value = team.number_of_plants || '';

        this.setState({
          id: team.id || '',
          tillage_id: team.tillage_id || '',
          name: team.name || '',
          number_of_plants: team.number_of_plants || '',
          employees: team.employees || [],
          fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Safras', url: '/' },
            { page: `Cultivos`, url: `#` },
            { page: 'Lavouras', url: '#' },
            { page: 'Equipes', url: '#' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/teams');
        toastError(handleError(error));
      });
  };

  getEmployees = async () => {
    await api.get('employees?inTeam=no&page=1&perPage=1000').then(result => {
      const employees_list = result.data;
      const options = [{ label: '', value: null }];

      employees_list.data.map(employee => {
        const p = {
          label: employee.person.name,
          value: employee.id,
        };
        options.push(p);
        return null;
      });
      const { fields } = this.state;

      fields[2].options = options;
      this.setState({
        fields,
      });
    });
  };

  handleChange(event) {
    const { name } = event.target;
    const team = this.state;
    const { fields } = this.state;

    fields[0].value = team.name || '';
    fields[1].value = team.number_of_plants || '';

    this.setState({
      [name]: event.target.value,
      fields,
    });
  }

  save = async () => {
    const team = this.state;

    await api
      .post('teams', team)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push(
          `/cultivations/${this.state.cultivation_id}/tillages/${this.state.tillage_id}/teams/${result.data.id}`
        );
        this.getTeam(result.data.id);
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  update = async () => {
    const team = this.state;

    const employees_for_add = team.employees.map(item => {
      return item.id;
    });

    team.employees = employees_for_add;

    await api
      .put(`teams/${team.id}`, team)
      .then(result => {
        toastSuccess(`Registro de ${team.name} atualizado!`);
        this.getTeam(team.id);
        this.getEmployees();
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  updateEmployess = async () => {
    const team = this.state;

    await api
      .put(`teams/${team.id}`, team)
      .then(result => {
        toastSuccess(`Registro de ${team.name} atualizado!`);
        this.getTeam(team.id);
        this.getEmployees();
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  handleEmployee = () => {
    this.setState({ addEmployee: true });
  };

  includeEmployee = () => {
    const { employee_id, employees } = this.state;

    let employees_for_add = [];

    if (employees.length > 0) {
      employees_for_add = employees.map(employee => {
        return employee.id;
      });
    }

    employees_for_add.push(employee_id);

    this.setState({
      employee_id: '',
      addEmployee: false,
      employees: employees_for_add,
    });

    setTimeout(() => {
      this.updateEmployess();
    }, 100);
  };

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDelete: item });
  }

  onDeleteItem = async () => {
    const { itemDelete, employees } = this.state;

    const index = employees.indexOf(itemDelete);

    employees.splice(index, 1);

    const employees_for_add = employees.map(employee => {
      return employee.id;
    });

    this.setState({
      employees: employees_for_add,
      modal: false,
      itemDelete: '',
    });

    setTimeout(() => {
      this.updateEmployess();
    }, 100);
  };

  deleteTeam = async () => {
    await api
      .delete(`teams/${this.state.id}`)
      .then(result => {
        toastSuccess(`Equipe excluída com sucesso!`);
        this.props.history.push(
          `/cultivations/${this.state.cultivation_id}/tillages/${this.state.tillage_id}`
        );
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const team = this.state;

    return (
      <Widget title={team.id ? `Editar ${team.name}` : 'Nova Equipe'}>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-sm col-md-6">
              <InputText
                label="* Nome"
                value={team.name}
                onChange={this.handleChange}
                field={team.fields[0]}
                validate={(e, field) => this.validate(e, team.fields[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm col-md-6">
              <InputText
                label="* Nº de plantas"
                value={team.number_of_plants}
                onChange={this.handleChange}
                field={team.fields[1]}
                validate={(e, field) => this.validate(e, team.fields[1])}
              />
            </div>
          </div>

          {team.id && (
            <div className="d-flex mb-2 mt-2">
              <h6 className="form-control-h6 mr-2 mt-1">
                <b>Funcionários</b>
              </h6>
              <Link to="#" onClick={this.handleEmployee}>
                <i className="material-icons text-success btn-list-person">
                  add_box
                </i>
              </Link>
            </div>
          )}

          {team.addEmployee && (
            <div className="col-md-6 col-sm mb-2 pl-0">
              <div className="card">
                <div className="row p-2">
                  <div className="col-sm col-md-9">
                    <Select
                      label="Nome"
                      value={team.employee_id}
                      options={team.fields[2].options}
                      onChange={this.handleChange}
                      field={team.fields[2]}
                      validate={(e, field) => this.validate(e, team.fields[2])}
                    />
                  </div>

                  <div className="col-sm align-self-center">
                    <Link to="#">
                      <button
                        className="btn btn-success btn-block"
                        disabled={!team.valid}
                        onClick={this.includeEmployee}
                      >
                        Incluir
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {team.employees.length > 0 && (
            <div className="row">
              <div className="col-md-6">
                <ul className="list-group card">
                  {team.employees.map((employee, i) => (
                    <li key={i} className="list-group-item">
                      {employee.person && (
                        <div className="row">
                          <div className="col-md-2 col-4">
                            <img
                              className="img-employee img-thumbnail"
                              src={
                                fetchImage(
                                  employee.person.thumbnail,
                                  'people'
                                ) || foto
                              }
                              alt="foto"
                            />
                          </div>

                          <Link
                            className="col align-self-center employee-text"
                            to={`/people/${employee.person.id}`}
                          >
                            <p className="list-group-item-text ">
                              {employee.person ? employee.person.name : ''}
                            </p>
                            <span className="list-group-item-text ">
                              {employee.function ? employee.function.name : ''}
                            </span>
                          </Link>

                          <div className="col-1 flex-40 align-self-center">
                            <i
                              className="material-icons text-danger btn-list-person"
                              onClick={e => this.onConfirmDelete(employee)}
                            >
                              delete_forever
                            </i>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="row mt-4">
            <div className="col-md-6 col-sm d-flex p-0 justify-content-end">
              <Link className="col-sm col-md-4" to="#">
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => this.setState({ isDeleteTeam: true })}
                >
                  Excluir equipe
                </button>
              </Link>
              <Link to="#" className="col-sm col-md-4">
                <button
                  className="btn btn-success btn-block"
                  disabled={!team.valid}
                  onClick={this.submit}
                >
                  {team.id ? 'Atualizar' : 'Salvar'}
                </button>
              </Link>

              <Link
                className="col-sm col-md-3"
                to={`/cultivations/${team.cultivation_id}/tillages/${team.tillage_id}`}
              >
                <button className="btn btn-success btn-block">Voltar</button>
              </Link>
            </div>
          </div>
        </form>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default"
        >
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir{' '}
            {this.state.itemDelete.person
              ? this.state.itemDelete.person.name
              : ''}{' '}
            ?
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-success col-4"
              onClick={this.onDeleteItem}
            >
              Sim
            </button>
            <button
              className="btn btn-success"
              onClick={event =>
                this.setState({
                  modal: false,
                  itemDeleteId: null,
                  itemDeleteName: null,
                })
              }
            >
              Cancelar
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isDeleteTeam}
          wrapClassName="modal-danger"
          size="default"
        >
          <ModalHeader toggle={this.toggle}>Deletar Equipe</ModalHeader>
          <ModalBody>Deseja excluir esta equipe ?</ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.deleteTeam}>
              Sim
            </button>
            <button
              className="btn btn-success"
              onClick={event => this.setState({ isDeleteTeam: false })}
            >
              Cancelar
            </button>
          </ModalFooter>
        </Modal>
      </Widget>
    );
  }
}

export default Index;
