
// TODO // alterar para o arquivo crops.js

import api from '../api'
import { toastError, toastSuccess } from '../toast'
import { handleError } from '../handle-error'

export const update = async crop => {
  let response;

  await api.put(`crops/${crop.id}`, crop).then(result => {
    response = result.data;
    toastSuccess(`Registro de ${crop.name} atualizado!`);
  }).catch(error => {
    response = false;
    toastError(handleError(error));
  })
  return response
}

export const save = async crop => {
  let response;

  await api.post('crops', crop)
    .then(result => {
      response = result.data;
      toastSuccess('Novo registro incluído!');
    }).catch(error => {
      response = false;
      toastError(handleError(error));
    })

  return response
}

export const list = async () => {
  const result = new Promise((resolve, reject) => {
    api.get(`crops`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const listCropsByBudgetId = async budget_id => {
  const result = new Promise((resolve, reject) => {
    api.get(`crops?budget_id=${budget_id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const showCropById = async crop_id => {
  const result = new Promise((resolve, reject) => {
    api.get(`crops/${crop_id}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export default { update, save };