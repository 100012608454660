import React, { useCallback, useState, useRef } from 'react';
import { GoSearch, GoX } from 'react-icons/go';
import InputLabel from '../../components/unform/input-label';
import InputMaskLabel from '../../components/unform/input-mask-label';
import { Form } from '@unform/web';
import styled from 'styled-components'

const PeopleFilter = ({ setParams, default_params }) => {
  const formRef = useRef(null);

  const [isFilterName, setIsFilterName] = useState(true)

  const[isFilter, setIsFilter] = useState(false)

  const searchByName = useCallback(name => {

    if (name?.length >= 3) {
      setParams(prevState => ({
        ...prevState,
        name: name,
      }));
    } else if (name.length === 0) {
      setParams(default_params);
    }
  }, [default_params, setParams]);


  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('name', '');

    formRef.current.setFieldValue('cpf', '');

    formRef.current.setFieldValue('cnpj', '');

    setParams(default_params);
  }, [default_params, setParams]);

  const searchByCpfCnpj = useCallback(async data => {
    if (data.cpf || data.cnpj) {
      setParams((prevState) => ({
        ...prevState,
        cpfCnpj: data.cpf ? data.cpf : data.cnpj
      }));
    }
  }, [setParams]);

  return (
    <FilterContainer>
      <div className="row pt-2 ">
        <div className="col-sm text-right">
          <a
            className="text-success btn p-0"
            data-toggle="collapse"
            href="#collapsePeople"
            role="button"
            aria-expanded="false"
            aria-controls="collapsePeople"
            onClick={() => {
              setIsFilter(!isFilter)
              if (isFilter) clearFieldsSearch()
            }}
          >
            {!isFilter && <>Filtrar <GoSearch /></>}
            {isFilter && <>Fechar Filtro <GoX /></>}
          </a>
        </div>
      </div>

      <div className="col p-1 ">
        <div className="collapse filter" id="collapsePeople">
          <div className="card card-body">
            
            <Form ref={formRef} onSubmit={searchByCpfCnpj} noValidate>
              <div className="row justify-content-center">
                <div className="col-sm col-md-8">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#nome" role="tab" 
                      aria-controls="nome" aria-selected="true" onClick={() => setIsFilterName(true)}>Nome</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#cpf" role="tab" 
                      aria-controls="profile" aria-selected="false" onClick={() => setIsFilterName(false)}>CPF</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="contact-tab" data-toggle="tab" href="#cnpj" role="tab" 
                      aria-controls="contact" aria-selected="false" onClick={() => setIsFilterName(false)}>CNPJ</a>
                  </li>
                </ul>
                <div className="tab-content border py-4 bg-white" id="myTabContent">
                  <div className="tab-pane fade show active" id="nome" role="tabpanel" aria-labelledby="home-tab">
                    <div className="col-sm ">
                      <InputLabel
                        label="Busca rápida por nome"
                        name="name"
                        onChange={e => searchByName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="tab-pane fade" id="cpf" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="col-sm">
                      <InputMaskLabel
                        label="Insira o CPF completo"
                        mask="999.999.999-99"
                        name="cpf"
                      />
                    </div>
                  </div>

                  <div className="tab-pane fade" id="cnpj" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="col-sm">
                      <InputMaskLabel
                        label="Insira o CNPJ completo"
                        name="cnpj"
                        mask="99.999.999/9999-99"
                      />
                    </div>
                  </div>
                </div>

                  <div className="row justify-content-end mt-3">
                    {!isFilterName && (
                      <div className="col-sm col-md-2 mb-1">
                        <button
                          className="btn btn-success btn-block"
                          onClick={searchByCpfCnpj}
                        >
                          Filtrar <GoSearch />
                        </button>
                      </div>
                    )}

                    <div className="col-sm col-md-2 mb-1">
                      <button
                        href="#"
                        className="btn btn-success btn-block"
                        onClick={clearFieldsSearch}
                      >
                        Limpar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </FilterContainer>
  );
};

export default PeopleFilter;


export const FilterContainer = styled.div`
  .card {
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 10px;
    border: none;
  }

  .btn-success{
    height: 35px !important;
  }
`
