import React from 'react'
import '../../css/components/input/input.css'
import './style.css'

const InputText = ({ label, disabled, onChange, icon }) => {
  return (
    <div className="btn btn-success btn-icon">
      <div className="col d-flex">
        {icon && <i className="material-icons p-0 m-0">image</i>}
        <label htmlFor="file" className="label-input ">{label || ''}</label>
      </div>
      <input
        name="file" id="file"
        className="inputfile"
        type="file"
        disabled={disabled}
        onChange={onChange} />
    </div>
  )
}

export default InputText