import React, { Component } from 'react'
import './error-page.css'


class PageError extends Component {
  constructor() {
    super();

    this.toHome = this.toHome.bind(this)
  }

  toHome = () => {
    this.props.history.push('/')
    window.location.reload();
  }

  render() {
    return (
      <div className="row col error-page p-2">
        <h1 className="animated fadeIn delay-100 mt-5">404</h1>
        <h3 className="animated fadeIn delay-500 mt-5">Página não encontrada</h3>
        <div className="btn btn-primary btn-lg btn-block animated fadeIn delay-1000 error-link mt-5" onClick={this.toHome}>
          <p>Retorne a página inicial</p>
        </div>
      </div>
    )
  }
}

export default PageError