import api from '../api'
import { toastError, toastSuccess } from '../toast'
import { handleError } from '../handle-error'

export const update = async person => {
  let response;

  await api.put(`people/${person.id}`, person).then(result => {
    response = result.data;
    toastSuccess(`Registro de ${person.name} atualizado!`);
  }).catch(error => {
    response = false;
    toastError(handleError(error));
  })
  return response
}

export const save = async person => {
  let response;

  await api.post('people', person)
    .then(result => {
      response = result.data;
      toastSuccess('Novo registro incluído!');
    }).catch(error => {
      response = false;
      toastError(handleError(error));
    })
    
  return response
}

export default { update, save };