import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    common: {
      authorization: `Bearer ${localStorage.getItem('ns_access_token')}`,
    }
  }
});

export default api;