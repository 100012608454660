import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import InputText from '../../../components/input-text';
import DateTime from '../../../components/date-time';
import Select from '../../../components/select';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      date: new Date(),
      crop_id: '',
      document: '',
      quantity: '',
      quantity_diarist: '',
      tillages: [],
      teams: [],
      harvest_packings: [],
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo data é obrigatório',
            },
          },
        },
        {
          value: '', //1
          name: 'crop_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //2
          name: 'tillage_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //3
          name: 'team_name',
          type: 'text',
          options: [],
          errors: [],
          rules: {},
        },
        {
          value: '', //4
          name: 'quantity',
          type: 'text',
          options: [],
          errors: [],
          rules: {},
        },
        {
          value: '', //5
          name: 'quantity_diarist',
          type: 'text',
          options: [],
          errors: [],
          rules: {},
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    this.getCrops();
    this.getTillages();
    this.getTeams();
  }

  validateField(e, field, fields) {
    e.preventDefault();

    field.value = '';
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password;
    }
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const harvestPacking = this.state;

    let fields = this.submitForm(e, harvestPacking.fields);
    this.setState({ fields: fields });

    if (harvestPacking.valid && harvestPacking.errors.length === 0) {
      this.save();
    }
    return false;
  }

  getCrops = async () => {
    await api
      .get('crops')
      .then(result => {
        const crops = result.data;
        const options = [{ label: '', value: null }];

        crops.map(team => {
          const p = {
            label: team.name,
            value: team.id,
          };
          options.push(p);
          return null;
        });
        const { fields } = this.state;

        fields[1].options = options;
        this.setState({
          fields: fields,
        });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getTillages = async () => {
    await api
      .get('tillages')
      .then(result => {
        this.setState({ tillages: result.data });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getTeams = async () => {
    await api
      .get('teams')
      .then(result => {
        this.setState({ teams: result.data });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  handleChange(event) {
    const name = event.target.name;
    const harvestPacking = this.state;
    const { fields } = this.state;

    fields[0].value = harvestPacking.date;
    fields[1].value = harvestPacking.crop_id;
    fields[2].value = harvestPacking.tillage_id;

    if (name === 'crop_id') {
      const options = this.setOptionsTillages(event.target.value);
      fields[2].options = options;
    }

    if (name === 'tillage_id') {
      this.setTeams(event.target.value);
    }

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  setOptionsTillages = crop_id => {
    const { tillages } = this.state;

    const options = [{ label: '', value: null }];

    tillages.map(item => {
      if (item.crop_id === crop_id) {
        const p = {
          label: item.name,
          value: item.id,
        };
        options.push(p);
      }
      return null;
    });

    return options;
  };

  setTeams = tillage_id => {
    const { teams } = this.state;

    const harvest_packings = [];

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];

      if (team.tillage_id === tillage_id) {
        const item = {
          team_id: team.id,
          team_name: team.name,
          quantity: '',
          quantity_diarist: '',
        };

        harvest_packings.push(item);
      }
    }

    this.setState({ harvest_packings: harvest_packings });
  };

  prepareHarvestPackingToSave = () => {
    const { harvest_packings, date } = this.state;

    const harvestPackings = harvest_packings.map(item => {
      if (item.quantity || item.quantity_diarist) {
        const obj = {
          team_id: item.team_id,
          date: date,
          quantity: item.quantity || 0,
          quantity_diarist: item.quantity_diarist || 0,
        };
        return obj;
      }
      return null;
    });

    const harvestPackingsFilter = harvestPackings.filter(function(el) {
      return el != null;
    });

    const harvestPacking = {
      harvest_packings: harvestPackingsFilter,
    };

    return harvestPacking;
  };

  save = async () => {
    const harvestPacking = this.prepareHarvestPackingToSave();
    await api
      .post('harvest-packings', harvestPacking)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/harvest-packings');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  handleHarvestPackingTeams(event, team) {
    const { harvest_packings } = this.state;
    for (let i = 0; i < harvest_packings.length; i++) {
      const harvest = harvest_packings[i];
      if (harvest.team_id === team.team_id) {
        if (event.target.name === 'quantity') {
          harvest.quantity = event.target.value;
        }
        if (event.target.name === 'quantity_diarist') {
          harvest.quantity_diarist = event.target.value;
        }
      }
    }
    this.setState({ harvest_packings: harvest_packings });
  }

  render() {
    const harvestPacking = this.state;

    return (
      <Widget title="Novo Romaneio">
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col-sm col-md-3">
                  <DateTime
                    label="* Data"
                    value={harvestPacking.date}
                    onChange={date => this.setState({ date: date })}
                    field={harvestPacking.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[0])
                    }
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <Select
                    label="* Safra"
                    value={harvestPacking.crop_id}
                    options={harvestPacking.fields[1].options}
                    onChange={this.handleChange}
                    field={harvestPacking.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[1])
                    }
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Lavoura"
                    value={harvestPacking.tillage_id}
                    options={harvestPacking.fields[2].options}
                    onChange={this.handleChange}
                    field={harvestPacking.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[2])
                    }
                  />
                </div>
              </div>

              <div className="col">
                {harvestPacking.tillage_id && (
                  <div className="row col mb-3 mt-3 d-flex align-items-end">
                    <i className="material-icons text-warning mr-2">warning</i>
                    <span>
                      Equipes com campo quantidade em <b>BRANCO</b> não serão
                      incluídas!
                    </span>
                  </div>
                )}
                {harvestPacking.harvest_packings.map((team, i) => (
                  <div className="row" key={i}>
                    <div className="col">
                      <InputText
                        label="Nome"
                        value={team.team_name}
                        disabled={true}
                        onChange={e => this.handleHarvestPackingTeams(e, team)}
                        field={harvestPacking.fields[3]}
                        validate={(e, field) =>
                          this.validate(e, harvestPacking.fields[3])
                        }
                      />
                    </div>

                    <div className="col-3">
                      <InputText
                        label="Quantidade Equipe"
                        value={team.quantity}
                        onChange={e => this.handleHarvestPackingTeams(e, team)}
                        field={harvestPacking.fields[4]}
                        validate={(e, field) =>
                          this.validate(e, harvestPacking.fields[4])
                        }
                      />
                    </div>

                    <div className="col-3">
                      <InputText
                        label="Quantidade Diarista"
                        value={team.quantity_diarist}
                        onChange={e => this.handleHarvestPackingTeams(e, team)}
                        field={harvestPacking.fields[5]}
                        validate={(e, field) =>
                          this.validate(e, harvestPacking.fields[5])
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-4 mt-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!harvestPacking.valid}
                      onClick={this.submit}
                    >
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3  mt-3">
                  <Link to="/harvest-packings">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
