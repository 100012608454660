import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { config } from './config'
import { colors, backgroundColors } from './colors'
import { navigation } from './navigation'
import { login } from './pages/login'
import { breadcrumb } from './breadcrumb'
import { person } from './pages/person'
import { user } from './pages/user'
import { selectedBudget } from './selectedBudget'
import { selectedAccounting } from './selectedAccounting'

const rootReducer = combineReducers({
  config,
  colors,
  backgroundColors,
  navigation,
  breadcrumb,
  login,
  person,
  user,
  selectedBudget,
  selectedAccounting,
  router: routerReducer,
})
export default rootReducer
