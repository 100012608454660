import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    padding: 0;
  }

  body {
    /* font-family: Montserrat, Arial, Helvetica, sans-serif, Arial, Helvetica, sans-serif;
    overflow-x: hidden; */
  }

  /* .btn-primary {
    background: #FFBA00 !important;
    font-weight: 600;
    text-transform: uppercase;
    border-color: #ECB113 !important;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #FFCF4C !important;
      border-color: #FFBA00 !important;
    }

    :active {
      background: #ECB113 !important;
      border-color: #FFBA00;
      box-shadow: 0 0 0 0.25rem rgb(236 177 19 / 50%) !important;
    }
  } */

  /* .btn-outline-primary {
    font-weight: 600;
    text-transform: uppercase;
    border-color: #FFBA00 ;
    color: #FFBA00;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #FFBA00;
      border-color: #FFCF4C;
    }

    :active {
      background: #ECB113 !important;
      border-color: #FFBA00;
      box-shadow: 0 0 0 0.25rem rgb(236 177 19 / 50%) !important;
    }
  } */

  .btn-secondary {
    background: #464646 !important;
    font-weight: 600;
    text-transform: uppercase;
    border-color: #1F1F1F;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #6B6B6B !important;
      border-color: #464646 !important;
      color: #fff;
    }

    :active {
      background: #121212 !important;
      border-color: #464646;
      box-shadow: 0 0 0 0.25rem rgb(18 18 18 / 50%) !important;
    }
  }

  .btn-outline-secondary {
    font-weight: 600;
    text-transform: uppercase;
    border-color: #464646 !important;
    color: #464646;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #464646 !important;
      border-color: #6B6B6B !important;
      color: #fff !important;
    }

    :active {
      background: #121212 !important;
      border-color: #464646;
      box-shadow: 0 0 0 0.25rem rgb(18 18 18 / 50%) !important;
    }
  }

  .btn-success {
    background: #3F9D2F !important;
    font-weight: 600;
    text-transform: uppercase;
    border-color: #307824 !important;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #4FC43B !important;
      border-color: #3F9D2F !important;
    }

    :active {
      background: #307824 !important;
      border-color: #3F9D2F !important;
      box-shadow: 0 0 0 0.25rem rgb(48 120 35 / 50%) !important;
    }
  }

  .btn-danger {
    background: #D11515 !important;
    font-weight: 600;
    text-transform: uppercase;
    border-color: #D11515 !important;
    box-shadow: none !important;
    height: 40px;

    :hover {
      background: #EC4646 !important;
      border-color: #D11515 !important;
    }

    :active {
      background: #D11515 !important;
      border-color: #D11515 !important;
      box-shadow: 0 0 0 0.25rem rgb(209 21 21 / 50%) !important;
    }
  }
`;
