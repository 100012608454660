import React, { useCallback, useEffect, useState } from 'react'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {index, remove} from '../../actions/base-rest'
import PeopleTable from './PeopleTable'
import PeopleFilter from './PeopleFilter'


const URL = 'people';

const default_params = {
  page: 1,
  perPage: 10,
};

const People = () => {

  const [params, setParams] = useState(default_params);

  const [scrolling, setScrolling] = useState(false);

  const [data, setData] = useState([])

  const [itemDelete, setItemDelete] = useState({});

  const [loading, setLoading] = useState(false)
  
  const [page, setPage] = useState({current_page: 0, has_next_page: false, total: 0})

  const actions = [
    {
      name: 'Nova Pessoa',
      class_btn: 'btn-success',
      class_collumn: 'col col-md-3',
      route: 'people/new'
    }
  ]
  

  const getPeople = useCallback(
    async (filter) => {
      setLoading(true);

      const response = await index(URL, filter);

      let people = []

      people = response?.data?.map(item => ({
        ...item,
        person_type_format: item.person_type === 'FISICA' ? 'Física' : 'Jurídica',
        gender_type_format: item.gender_type === 'MASCULINO' ? 'M' : 'F'
      }))

      if (scrolling) {
        const new_data = (data) => [...data, ...people];

        setData(new_data);

        setScrolling(false);
      } else setData(people);

      setLoading(false);

      setPage({
        current_page: response.page,
        has_next_page: response.lastPage >= response.page ? true : false,
        total: response.total
      });
    },
    [scrolling]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setData([]);

      getPeople();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDelete,]);

  useEffect(() => {
    getPeople(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);


  return (
    <Widget title="Pessoas" actions={actions} line={false}>

      <PeopleFilter setParams={setParams} default_params={default_params}/>

      <div className="row d-flex">
        <div className="col table">
          <PeopleTable
            data={data}
            page={page}
            setItemDelete={setItemDelete}
            setParams={setParams}
            loading={loading}
            setScrolling={setScrolling}
          />
        </div>
      </div>

      <Modal
        isOpen={itemDelete.id ? true : false}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader>Deletar Registro</ModalHeader>
        <ModalBody>
          Deseja excluir o registro de {itemDelete.description}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success col-4" onClick={deleteItem}>
            Sim
          </button>
          <button className="btn btn-success" onClick={() => setItemDelete({id: null, description: null})}>
            Cancelar
          </button>
        </ModalFooter>
      </Modal>

    </Widget>
  );
};

export default People;
