
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import HarvestPackingReport from './HarvestPackingReport'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {

      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Relatórios', url: '#' },
          { page: 'Colheita', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(HarvestPackingReport),
    renderNothing
  )
)(HarvestPackingReport)

export default Component