import React from 'react'
import InputReactMask from 'react-input-mask';
import '../../css/components/input/input.css'

const InputText = ({ field, validate, label, value, mask, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <InputReactMask
        mask={mask}
        placeholder={field.placeholder || ''}
        type={field.type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={
          hasErrors
            ? 'form-control is-invalid input-text'
            : 'form-control input-text'
        }
        name={field.name}
        onInput={e => validate(e, field)}
        onBlur={e => validate(e, field)}
      />
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default InputText