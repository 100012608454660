import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GiTruck } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../../actions/util';

const CargoPackingDashboard = ({ data }) => {
  const [cargoPackings, setCargoPackings] = useState([]);

  useEffect(() => {
    let cargoPackings = [];

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const resumes = [
        { status: 'ABERTO', value: 0 },
        { status: 'PAGO', value: 0 },
      ];

      if (element.resumes.length === 1) {
        if (element.resumes[0].status === 'ABERTO')
          resumes[0] = element.resumes[0];
        if (element.resumes[0].status === 'PAGO')
          resumes[1] = element.resumes[0];
      } else {
        if (element.resumes[0].status === 'ABERTO') {
          resumes[0] = element.resumes[0];
          resumes[1] = element.resumes[1];
        } else {
          resumes[0] = element.resumes[1];
          resumes[1] = element.resumes[0];
        }
      }

      element.resumes = resumes;

      cargoPackings.push(element);
    }
    setCargoPackings(cargoPackings);
  }, [data]);

  return (
    <Container>
      <h1 className="text-center">
        <GiTruck size={25} className="mb-1" /> Romaneio de Cargas
      </h1>

      <div className="row justify-content-center">
        {cargoPackings.length === 0 && (
          <div className="col-sm col-md-3">
            <Link to="/cargo-packings">
              <div className="card shadow">
                <div className="card-body mb-4 text-center font-italic">
                  Romaneio de Cargas ainda não possui lançamentos!
                </div>
              </div>
            </Link>
          </div>
        )}

        {cargoPackings.length > 0 && (
          <>
            {cargoPackings.map((reg, i) => (
              <div key={i} className="col-sm col-md-4 no-gutters">
                <div className="card shadow">
                  <div className="card-body">
                    <h5 className="card-title text-center">{reg.name}</h5>

                    <div className="row">
                      <div className="col text-center">
                        <Link
                          to={
                            reg.resumes[0].value > 0
                              ? `dashboard/cargo-packings/${reg.id}/ABERTO`
                              : '#'
                          }
                        >
                          <table className="table tale-sm">
                            <thead>
                              <tr>
                                <th>A RECEBER</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{formatPrice(reg.resumes[0]?.value)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Link>
                      </div>

                      <div className="col text-center">
                        <Link
                          to={
                            reg.resumes[1]?.value > 0
                              ? `dashboard/cargo-packings/${reg.id}/PAGO`
                              : '#'
                          }
                        >
                          <table className="table tale-sm">
                            <thead>
                              <tr>
                                <th>PAGO</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {formatPrice(reg.resumes[1]?.value || 0)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export default CargoPackingDashboard;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  padding-bottom: 30px;

  h1 {
    color: #53883d;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }

  h5 {
    color: #53883d;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;
  }

  a {
    color: #53883d !important;
    th {
      color: #505050;
      font-size: 12px;
      padding-left: 0;
      padding-right: 0;
    }

    td {
      font-size: 18px;
      font-weight: bold;
      color: #53883d;
      border: 0;
    }

    :hover {
      color: #fff !important;
      .card {
        background-color: #53883d !important;
      }
      th {
        background-color: #53883d !important;
        color: #fff !important;
      }
      td {
        background-color: #53883d !important;
        color: #fff !important;
      }
    }
  }

  .table th {
    border: 0;
  }

  .card {
    height: 100%;
    border-radius: 0;
    padding: 0 !important;

    :hover {
      border-color: #53883d;
    }
  }

  .card-body {
    padding-bottom: 0;
  }

  @media screen and (max-width: 1290px) {
    h5 {
      font-size: 12px;
    }
  }
`;
