import React from 'react';
import { Link } from 'react-router-dom';
import { BsCardChecklist } from 'react-icons/bs';
import styled from 'styled-components';
import { formatPrice } from '../../../actions/util';

function StockDetail({ data, title, input_type, budget_id }) {
  return (
    <Container>
      <div className="card shadow">
        <div className="card-body">
          <h5 className="card-title ">{title.toUpperCase()}</h5>

          <div className="table-responsive">
            <table className="table table-sm table-hover">
              <thead>
                <tr className="">
                  <th scope="col">Lavoura</th>
                  <th scope="col">Valor p/ Ha</th>
                  <th scope="col">valor</th>
                </tr>
              </thead>
              <tbody>
                {data.map((reg, i) => (
                  <tr key={i}>
                    {reg.name !== 'Total' && (
                      <>
                        <CropName className="text-left">
                          <Link
                            to={`/dashboard/stock/input_type/${input_type}/budget_id/${budget_id}/crop_id/${reg.id}`}
                          >
                            {reg.name}
                          </Link>
                        </CropName>
                        <td>{formatPrice(reg.total_by_area)}</td>
                        <td>{formatPrice(reg.total)}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-footer table-responsive">
          <table className="table table-sm">
            <tbody>
              {data.map((reg, i) => (
                <tr key={i}>
                  {reg.name === 'Total' && (
                    <>
                      <CropName>
                        <p>{reg.name}</p>
                      </CropName>
                      <td className="total">{formatPrice(reg.total)}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <small className="text-muted details">
            <Link
              to={`/dashboard/stock/input_type/${input_type}/budget_id/${budget_id}`}
            >
              <BsCardChecklist size={15} className="mb-1" /> DESPESA DETALHADA
            </Link>
          </small>
        </div>
      </div>
    </Container>
  );
}

export default StockDetail;

export const Container = styled.div`
  height: 100%;

  h5 {
    color: #53883d;
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;
  }

  .total {
    font-size: 15px !important;
    font-weight: bold;
    color: #53883d;
  }

  .details {
    a {
      color: #505050;
      :hover {
        color: #53883d;
      }
    }
  }

  .table td {
    font-size: 11px;
    border: 0;
    text-transform: uppercase;
  }

  .table tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0;
  }

  .table th {
    border: 0;
    color: #505050;
    text-transform: uppercase;
  }

  .card {
    height: 100%;
    :hover {
      border-color: #53883d;
    }
  }

  .card-body {
    padding-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    background: none !important;
    border: none !important;
  }

  @media screen and (max-width: 1290px) {
    h5 {
      font-size: 12px;
    }
  }
`;

export const CropName = styled.td`
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px;
    font-weight: bold;
    color: #505050;
  }

  :hover {
    a {
      color: #53883d;
    }
  }
`;
