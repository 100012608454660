export function person(
  state = {
    valid: false,
    fields: [
      {
        value: '',
        name: 'person_type',
        type: 'text',
        errors: [],
        options: [
          { label: 'Física', value: 'FISICA' },
          { label: 'Jurídica', value: 'JURIDICA' },
        ],
        rules: {
          required: {
            message: 'Campo tipo pessoa é obrigatório'
          },
        }
      },
      {
        value: '',
        name: 'name',
        type: 'text',
        errors: [],
        rules: {
          required: {
            message: 'Campo nome é obrigatório'
          },
        }
      },
      {
        value: '',
        name: 'cpf_cnpj',
        type: 'text',
        errors: [],
        rules: {
          validCpfCnj: true
        }
      },
      {
        value: '',
        name: 'rg_ie',
        type: 'text',
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'birth_date',
        type: 'text',
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'rg_issue_date',
        type: 'text',
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'rg_emitter',
        type: 'text',
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'gender_type',
        type: 'text',
        options: [
          { label: 'Masculino', value: 'MASCULINO' },
          { label: 'Feminino', value: 'FEMININO' },
        ],
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'marital_status_type',
        type: 'text',
        options: [
          { label: '', value: '' },
          { label: 'Casado', value: 'CASADO' },
          { label: 'Solteiro', value: 'SOLTEIRO' },
          { label: 'Divorciado', value: 'DIVORCIADO' },
          { label: 'Desquitado', value: 'DESQUITADO' },
          { label: 'União Estável', value: 'UNIAO_ESTAVEL' },
        ],
        errors: [],
        rules: {}
      },
      {
        value: '',
        name: 'thumbnail',
        type: 'text',
        errors: [],
        rules: {}
      },
    ]
  },
  action) {
  switch (action.type) {
    case 'VALIDATE':
      let fields = state.fields.map(item => {
        if (item.name === action.field.name) {
          return action.field
        } else {
          return item
        }
      })
      let valid =
        fields.filter(field => field.errors.length > 0).length > 0
          ? false
          : true
      return Object.assign({}, state, {
        valid: valid,
        fields: Array.from(fields)
      })
    case 'SUBMIT':
      return Object.assign({}, state, {
        valid: false,
        fields: Array.from(state.fields)
      })
    default:
      return state
  }
}
