import {createStore, applyMiddleware} from 'redux'
import rootReducer from './reducers/index'

export default function configureStore() {
  const store = createStore(rootReducer, applyMiddleware())
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
