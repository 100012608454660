
import api from '../api'
import { toastError } from '../toast'
import { handleError } from '../handle-error'


export const listBudgets = async () => {
  const result = new Promise((resolve, reject) => {
    api.get(`budgets`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}
