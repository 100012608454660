import React, { useCallback } from 'react';
import { IoIosTrash } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import SyncLoader from 'react-spinners/SyncLoader';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const EmployeesTable = ({
  data,
  page,
  setItemDelete,
  setParams,
  loading,
  setScrolling,
}) => {
  const history = useHistory();

  const changePage = useCallback(() => {
    setScrolling(true);

    setParams(prevState => ({
      ...prevState,
      page: page.current_page + 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Container>
      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - {data?.length} de <b>{page?.total}</b>
        </h6>
      </div>
      <div className="row">
        <div className="col mt-3 scroll">
          <InfiniteScroll
            dataLength={data?.length}
            next={changePage}
            hasMore={page.has_next_page}
            loader={
              <div className="col text-center">
                {loading && (
                  <SyncLoader color="#F7931E" size={15} margin={10} />
                )}
              </div>
            }
            endMessage={
              <div className="col text-center">
                <h6>
                  TOTAL DE REGISTROS - {data?.length} de <b>{page?.total}</b>
                </h6>
              </div>
            }
          >
            <div className="table-responsive">
              <table className="table table-hover text-center">
                <thead className="bg-success">
                  <tr>
                    <th scope="col" className="text-start">
                      Nome
                    </th>
                    <th scope="col" className="text-start">
                      Função
                    </th>
                    <th scope="col">Admissão</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-end">
                      Ação
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, i) => (
                    <tr
                      key={i}
                      onClick={e => {
                        if (e.target.innerText !== undefined) {
                          history.push(`employees/${item.id}`);
                        }
                      }}
                    >
                      <td className="text-start">{item.name}</td>
                      <td className="text-start">{item.function}</td>
                      <td className="text-CENTER">{item.admission_date}</td>
                      <td className="text-CENTER">{item.status}</td>

                      <td className="text-end action">
                        <IoIosTrash
                          size={25}
                          className="mb-1"
                          data-tip="Excluir"
                          onClick={() =>
                            setItemDelete({
                              id: item.id,
                              description: item.name,
                            })
                          }
                        />
                        <ReactTooltip />
                      </td>
                    </tr>
                  ))}

                  {data.length === 0 && (
                    <tr>
                      <td colSpan={6}>Nenhum registro encontrado!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </Container>
  );
};

EmployeesTable.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setScrolling: PropTypes.func.isRequired,
};

export default EmployeesTable;

export const Container = styled.section`
  height: 100vh;
  margin-top: -40px;

  h6 {
    font-size: 12px;
    margin-bottom: -20px;
  }

  .scroll {
    margin-bottom: 100px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .bg-success {
    /* background-color: #E8E8E8 !important; */
    background-image: linear-gradient(white, #bfbfbf);
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;
