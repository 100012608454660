import React, { useCallback, useEffect, useRef, useState } from 'react';
import {GoSearch, GoX} from 'react-icons/go'
import { Form } from '@unform/web'
import InputLabel from '../../../components/unform/input-label'
import SelectLabel from '../../../components/unform/select-label'
import InputDateLabel from '../../../components/unform/date-label'
import styled from 'styled-components';
import api from '../../../actions/api'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom'

export default function FinancialMovementsFilter ({setParams, setFilterButtonClicked}) {

  const formRef = useRef(null)

  const location = useLocation()

  const [optionsAccount, setOptionsAccount] = useState([])

  const [optionsAccountings, setOptionsAccountings] = useState([])

  const[isFilter, setIsFilter] = useState(false)

  const [selectedMovimentType, setSelectedMovimentType] = useState(null)

  const [selectedAccount, setSelectedAccount] = useState(null)

  const [selectedAccounting, setSelectedAccounting] = useState(null)

  const [initialDueDate, setInitialDueDate] = useState(null)
  
  const [endDueDate, setEndDueDate] = useState(null)
  
  const [initialPayDate, setInitialPayDate] = useState(null)
  
  const [endPayDate, setEndPayDate] = useState(null)

  const optionsMovimentType = [
    { label: 'Crédito', value: 'CREDITO'},
    { label: 'Débito', value: 'DEBITO'},
    { label: 'Transferência', value: 'TRANSFERENCIA'},
  ]


  const cleanFilter = useCallback(async e => {
    formRef.current.reset()

    setSelectedMovimentType(null)
    setSelectedAccount(null)
    setSelectedAccounting(null)
    setInitialDueDate(null)
    setEndDueDate(null)
    setInitialPayDate(null)
    setEndPayDate(null)

    setParams({
      page: 1,
      perPage: 15
    })
    
    setFilterButtonClicked(true)
    
    localStorage.removeItem('filter_financial_movements')
  },[formRef, setParams, setFilterButtonClicked]);

  
  const getAccounts = useCallback(async () => {
    setSelectedAccount(null)

    await api.get(`accounts?status_type=ATIVO`)
    .then(response => {
      const options = response.data?.accounts.map(item => ({
        label:  item.name,
        value: item.id
      }))

      setOptionsAccount(options)
    })
    .catch(error => console.log(error))
  },[])
  
  const getAccountings = useCallback(async () => {
    let accountings = [];

    let url = '';
    if (location.pathname === '/receivables')
      url = 'accountings?status_type=ATIVO&accounting_type=RECEITA';
    if (location.pathname === '/payables')
      url = 'accountings?status_type=ATIVO&accounting_type=DESPESA';
    if (location.pathname === '/financial-movements')
      url = 'accountings?status_type=ATIVO';

    await api
      .get(url)
      .then(result => {
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          const item = {
            value: element.id,
            label: `${element.number} ${element.name}`,
            isdisabled: true,
          };
          accountings.push(item);

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x];
              const item = {
                value: elem.id,
                label: `${elem.number} ${elem.name}`,
              };
              accountings.push(item);
            }
          }
        }

        setOptionsAccountings(accountings)
      })
      .catch(error => console.log(error));
  },[location.pathname])


  const submit = useCallback(async (data) => {
    let filter = {}

    if(selectedMovimentType) filter.movement_type = selectedMovimentType.value
    if(selectedAccount) filter.account_id = selectedAccount.value
    if(selectedAccounting) filter.accounting_id = selectedAccounting.value
    if(data.document) filter.document = data.document
    if(initialDueDate) filter.init_due_date = initialDueDate
    if(endDueDate) filter.end_due_date = endDueDate
    if(initialPayDate) filter.init_pay_date = initialPayDate
    if(endPayDate) filter.end_pay_date = endPayDate

    if(Object.keys(filter).length > 0){
      filter.page = 1
      filter.perPage = 15
    }

    setParams(filter)

    setFilterButtonClicked(true)

    localStorage.setItem('filter_financial_movements', JSON.stringify(filter))
  },[selectedMovimentType, selectedAccount, selectedAccounting, 
    initialDueDate, endDueDate, initialPayDate, endPayDate, setParams, setFilterButtonClicked])
 
  const setFieldsFilter = useCallback((filter) => {
    setIsFilter(true)
    
    document.getElementById('collapseFilter').classList.add('show')

    
    if(filter.movement_type) {
      const option = optionsMovimentType.filter(item => item.value === filter.movement_type)
      
      setSelectedMovimentType(option[0]);
    }
    
    if(filter.account_id) {
      const option = optionsAccount.filter(item => item.value === filter.account_id)
      
      setSelectedAccount(option[0]);
    }
    
    if(filter.accounting_id) {
      const option = optionsAccountings.filter(item => item.value === filter.accounting_id)
      
      setSelectedAccounting(option[0]);
    }
    
    if(filter.document) formRef.current.setFieldValue('document', filter.document)

    if(filter.init_due_date) setInitialDueDate(new Date(filter.init_due_date))

    if(filter.end_due_date) setEndDueDate(new Date(filter.end_due_date))
    
    if(filter.init_pay_date) setInitialPayDate(new Date(filter.init_pay_date))

    if(filter.end_pay_date) setEndPayDate(new Date(filter.end_pay_date))

  },[optionsMovimentType, optionsAccount, optionsAccountings])

  useEffect(() => {
    getAccounts()
    getAccountings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
 

  // se contém filtro, seta os valores nos campos do formulário
  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_financial_movements'))
   
    if(filter) setFieldsFilter(filter) 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[optionsAccount])

  return (
    <Container>
      <div className="row pt-2">
        <div className="col-sm text-left">
          {location.pathname !== '/financial-movements' && (
              <Link
                className="text-success btn"
                role="button"
                to="/financial-movements"
              >
                Movimento Financeiro 
              </Link>
          )}

          {location.pathname === '/receivables'  && (
            <span>|</span>
          )}
          
          {location.pathname !== '/payables' && (
            <Link
              className="text-success btn"
              role="button"
              to="/payables"
            >
              Contas a Pagar 
            </Link>
          )}
          
          {(location.pathname === '/financial-movements' || location.pathname === '/payables') && (
            <span>|</span>
          )}

          {location.pathname !== '/receivables' && (
            <>
              <Link
                className="text-success btn"
                role="button"
                to="/receivables"
              >
                Contas a Receber
              </Link>
            </>
          )}
        </div>
        <div className="col-sm text-right">
          <a
            className={`${!isFilter ? 'text-success' : 'text-danger '} btn`}
            data-toggle="collapse"
            href="#collapseFilter"
            role="button"
            aria-expanded="false"
            aria-controls="collapseFilter"
            onClick={() => {
              setIsFilter(!isFilter)
              if (isFilter) cleanFilter()
            }}
          >
              {!isFilter && <>Filtrar <GoSearch /></>}
              {isFilter && <>Fechar Filtro <GoX /></>}
          </a>
        </div>
      </div>

      <div className="collapse filter" id="collapseFilter">
        <div className="row justify-content-center">
          <div className="col-sm col-md-8">
            <Form
              ref={formRef}
              onSubmit={submit}
              noValidate
            >
              <div className="row">
                <InputFiltro value={selectedMovimentType} className="col-sm mt-3">
                  <SelectLabel 
                    name="movement_type" 
                    label="Tipo Movimento" 
                    placeholder="Selecione" 
                    value={selectedMovimentType}
                    onChange={e => setSelectedMovimentType(e)}
                    options={optionsMovimentType} />
                </InputFiltro>
                    
                <InputFiltro value={selectedAccount} className="col-sm mt-3">
                  <SelectLabel 
                    name="account_id" 
                    label="Conta" 
                    placeholder="Selecione" 
                    value={selectedAccount}
                    onChange={e => setSelectedAccount(e)}
                    options={optionsAccount} />
                </InputFiltro>
              </div>
              
              <div className="row">
                <InputFiltro value={selectedAccounting} className="col-sm mt-3">
                  <SelectLabel 
                    name="accounting_id" 
                    label="Conta Movimento" 
                    placeholder="Selecione" 
                    value={selectedAccounting}
                    onChange={e => setSelectedAccounting(e)}
                    options={optionsAccountings} />
                </InputFiltro>

                <InputFiltro value={formRef.current?.getFieldValue('document')} className="col-sm" >
                  <InputLabel name="document" label="Documento" />
                </InputFiltro>
              </div>

                  
              <div className="row mt-3">
                <InputFiltro value={initialDueDate} className="col-sm">
                  <InputDateLabel 
                    name="init_due_date" 
                    label="Data de Vencimento de" 
                    onChange={(e) => setInitialDueDate(e)}
                    selected={initialDueDate}/>
                </InputFiltro>
                
                <InputFiltro value={endDueDate} className="col-sm">
                  <InputDateLabel 
                    name="end_due_date" 
                    label="Data de Vencimento até" 
                    onChange={(e) => setEndDueDate(e)}
                    selected={endDueDate}/>
                </InputFiltro>  
              </div>
              
              <div className="row mt-3">
                <InputFiltro value={initialPayDate} className="col-sm">
                  <InputDateLabel 
                    name="init_pay_date" 
                    label="Data de Pagamento de" 
                    onChange={(e) => setInitialPayDate(e)}
                    selected={initialPayDate}/>
                </InputFiltro>
                
                <InputFiltro value={endPayDate} className="col-sm">
                  <InputDateLabel 
                    name="end_pay_date" 
                    label="Data de Pagamento até" 
                    onChange={(e) => setEndPayDate(e)}
                    selected={endPayDate}/>
                </InputFiltro>  
              </div>

              <div className="row my-3">
                <div className="col-sm text-right">
                  <button type='button' className="btn btn-secondary m-2 px-5 text-white" onClick={() => cleanFilter()}>
                    Limpar Filtro
                  </button>

                  <button 
                    className="btn btn-success m-2 px-5" 
                    type="submit" >
                    Filtrar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}

export const Container = styled.div`
  .btn-secondary {
    height: 35px !important;
  }
  
  .btn-success{
    height: 35px !important;
  }

  .btn{
    :focus {
      border: none;
      box-shadow: none;
    }
  }

  .filter {
    background-color: #f9f9f9;
    border-radius: 3px;
  }
  
`

export const InputFiltro = styled.div`
  label {
    color: ${props => props.value ? '#388e3c' : ''};
    font-weight: ${props => props.value ? 'bold' : ''};
  }
`