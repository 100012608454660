import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../../components/widget'
import DateTime from '../../../components/date-time'
import InputText from '../../../components/input-text'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      date: '',
      crop: '',
      name: '',
      // crops: [],
      // crops_: [],
      // addCrop: false,
      fields: [
        {
          value: '', //0
          name: 'initial_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'end_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        // {
        //   value: '', //3
        //   name: 'crop',
        //   options: [],
        //   type: 'text',
        //   errors: [],
        //   rules: {}
        // },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    // this.getCrops();
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const budget = this.state;

    let fields = this.submitForm(e, budget.fields);
    this.setState({ fields: fields });

    if (budget.valid && budget.errors.length === 0) {
      budget.id ? this.update() : this.save();
    }
    return false
  }

  // getCrops = async () => {
  //   await api.get('crops')
  //     .then(result => {
  //       const crops = result.data

  //       this.setState({
  //         crops_: crops
  //       })
  //     })
  //     .catch(error => {
  //       toastError(handleError(error));
  //     })
  // }

  handleChange(event) {
    const name = event.target.name;
    const budget = this.state;
    const fields = this.state.fields;

    fields[0].value = budget.initial_date;
    fields[1].value = budget.end_date;
    fields[2].value = budget.name;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const budget = this.state;

    await api.post('budgets', budget)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push(`/budgets`)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  // selectCrop = event => {
  //   const { crops } = this.state

  //   const crops_ = crops

  //   if (event.nativeEvent.srcElement.checked) {
  //     crops_.push(event.target.value)
  //   } else {
  //     crops_.splice(crops_.indexOf(event.target.value), 1)
  //   }

  //   this.setState({ crops: crops_ })
  // }

  render() {
    const budget = this.state;

    return (
      <Widget
        title={budget.id ? `Editar Orçamento` : 'Novo Orçamento'}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="row ">
                <div className="col-sm">
                  <DateTime
                    label="* Data de Início"
                    value={budget.initial_date}
                    onChange={date => this.setState({ initial_date: date })}
                    field={budget.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, budget.fields[0])}
                  />
                </div>

                <div className="col-sm">
                  <DateTime
                    label="* Data de Término"
                    value={budget.end_date}
                    onChange={date => this.setState({ end_date: date })}
                    field={budget.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, budget.fields[1])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <InputText
                    label="* Nome da Safra"
                    value={budget.name}
                    onChange={this.handleChange}
                    field={budget.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, budget.fields[2])}
                  />
                </div>
              </div>

              {/* <div className="row p-0 m-0 ">
                <label className="form-control-label mr-2 mb-3 mt-3"><b>Safras</b></label>
              </div>

              {
                budget.crops_.map((crop, i) => (
                  <div key={i} className="form-group">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        value={crop.id}
                        onChange={this.selectCrop}
                      />
                      <span className="custom-control-indicator" />
                      <span className="custom-control-description">{crop.name}</span>
                    </label>
                  </div>
                ))
              } */}

              <div className="row justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!budget.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2  mt-3">
                  <Link to="/budgets">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									  </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index;