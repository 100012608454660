import React, { useCallback, useContext, useEffect, useState } from 'react'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { dateToString, formatSimplePrice, formatPrice } from '../../actions/util'
import {index, remove} from '../../actions/base-rest'
import CargoPackingsTable from './CargoPackingsTable'
import { AuthContext } from '../../actions/auth-context'
import CargoPackingsFilter from './filter/CargoPackingsFilter'

const URL = 'cargo-packings'

const default_params = {
  page: 1,
  perPage: 20,
  // detail_level: 'analytic'
};

const  CargoPackings = () => {
  const { defaultBudget } = useContext(AuthContext)

  const [params, setParams] = useState(default_params);

  const [scrolling, setScrolling] = useState(false);

  const [data, setData] = useState([])

  const [itemDelete, setItemDelete] = useState({});

  const [loading, setLoading] = useState(false)
  
  const [page, setPage] = useState({current_page: 0, has_next_page: false, total: 0})
  
  const actions = [
    {
      name: 'Novo Romaneio de Carga',
      class_btn: 'btn-success',
      class_collumn: 'col col-md-3',
      route: 'cargo-packings/new'
    }
  ]
  
  const getCargoPackings = useCallback(
    async (filter) => {
      if(filter.budget_id !== defaultBudget.value) filter.page = 1
      
      filter.budget_id = defaultBudget.value;
      filter.detail_level = 'analytic'

      let cargoPackings = [];

      setLoading(true);

      const response = await index(URL, filter);

      cargoPackings = response.data?.map(item => ({
        ...item,
        date_format: dateToString(item.receipt_forecast),
        average_price_format: formatPrice(item.average_price),
        cargo_packing_balance_format: formatPrice(item.cargo_packing_balance),
        cargo_weight_format: formatSimplePrice(item.cargo_weight),
        status: item.pay_date ? 'Pago' : 'Aberto'
      }))


      for (let i = 0; i < cargoPackings?.length; i++) {
        const item = cargoPackings[i];

        for (let x = 0; x < item.cargo_packing_qualities?.length; x++) {
          const element = item.cargo_packing_qualities[x];
          
            if(element.unit_price > 80) console.log(item.document);
        }
        
      }

      if (scrolling) {
        const new_data = (data) => [...data, ...cargoPackings];

        setData(new_data);

        setScrolling(false);
      } else {
        setData(cargoPackings);
      }

      setLoading(false);

      setPage({
        current_page: response.page,
        has_next_page: response.lastPage >= response.page ? true : false,
        total: response.total
      });
    },
    [scrolling, defaultBudget]
  );
 
  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);
    
    if (response) {
      setItemDelete({id: null, description: null})

      getCargoPackings(params)
    }
  }, [itemDelete, params, getCargoPackings]);


  useEffect(() => {
      if(defaultBudget) getCargoPackings(params)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, defaultBudget])

  return (
    <Widget title="Romaneio de Cargas" actions={actions} line={false}>


      <CargoPackingsFilter setParams={setParams}/>

      <div className="row d-flex">
        <div className="col table">
          <CargoPackingsTable
            data={data}
            page={page}
            setItemDelete={setItemDelete}
            setParams={setParams}
            loading={loading}
            setScrolling={setScrolling}
          />
        </div>
      </div>

     

      <Modal
        isOpen={itemDelete.id ? true : false}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader>Deletar Registro</ModalHeader>
        <ModalBody>
          Deseja excluir romaneio da safra {itemDelete.description}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success col-4" onClick={deleteItem}>
            Sim
          </button>
          <button className="btn btn-success" onClick={() => setItemDelete({id: null, description: null})}>
            Cancelar
          </button>
        </ModalFooter>
      </Modal>

    </Widget>
  )
}

export default CargoPackings