import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Widget from '../../../components/widget';
import Loading from '../../../components/loading';
import Select from '../../../components/select';

import api from '../../../actions/api';
import { toastError } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';
import { floatToCurrency, currencyToString } from '../../../actions/util';

import './style.css';

class Index extends Component {
  constructor() {
    super();

    this.state = {
      id: '',
      loading: false,
      data: [],
      budgets_accountings: [],
      budgets_months: [],
      months_and_amounts: [],
      filter: 'expected_value',
      data_whith_value: false,
      budget_name: '',
      recipe: {},
      recipe_months: [],
      expense: {},
      expense_months: [],
      fields: [
        {
          value: '', // 0
          name: 'type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Previsto', value: 'expected_value' },
            { label: 'Realizado', value: 'real_value' },
          ],
          rules: {},
        },
        {
          value: '', // 1
          name: 'type_value',
          type: 'text',
          errors: [],
          options: [
            { label: 'Todas', value: false },
            { label: 'Somente com valores', value: true },
          ],
          rules: {},
        },
      ],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getBudget(id);
  }

  getBudget = async id => {
    this.setState({ loading: true });
    await api
      .get(`budgets/${id}`)
      .then(result => {
        const budget = result.data;

        const budgets_months_ = this.getBudgetMonths(
          budget.budgets_accountings[0].budget_accounting_months
        );

        for (let i = 0; i < budget.budgets_accountings.length; i++) {
          const element = budget.budgets_accountings[i];
          element.expected_total = floatToCurrency(element.expected_total);
          element.real_total = floatToCurrency(element.real_total);

          for (let x = 0; x < element.budget_accounting_months.length; x++) {
            const item = element.budget_accounting_months[x];
            item.expected_value = floatToCurrency(item.expected_value);
            item.real_value = floatToCurrency(item.real_value);
            item.position = `${i},${x}`;
          }
        }

        this.setState({
          id: budget.id,
          crops: budget.crops || [],
          data: budget.budgets_accountings || [],
          budgets_accountings: budget.budgets_accountings || [],
          budgets_months: budgets_months_,
          loading: false,
          budget_name: budget.name || '',
          recipe: budget.recipe || {},
          recipe_months: budget.recipe.months || [],
          expense: budget.expense || {},
          expense_months: budget.expense.months || [],
        });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getBudgetMonths = months => {
    const months_ = [];

    for (let i = 0; i < months.length; i++) {
      const item = months[i];
      const m = item.month.slice(-2);
      const year = item.month.slice(2, -3);

      if (m === '01') months_.push({ ref: `Jan - ${year}` });
      if (m === '02') months_.push({ ref: `Fev - ${year}` });
      if (m === '03') months_.push({ ref: `Mar - ${year}` });
      if (m === '04') months_.push({ ref: `Abr - ${year}` });
      if (m === '05') months_.push({ ref: `Mai - ${year}` });
      if (m === '06') months_.push({ ref: `Jun - ${year}` });
      if (m === '07') months_.push({ ref: `Jul - ${year}` });
      if (m === '08') months_.push({ ref: `Ago - ${year}` });
      if (m === '09') months_.push({ ref: `Set - ${year}` });
      if (m === '10') months_.push({ ref: `Out - ${year}` });
      if (m === '11') months_.push({ ref: `Nov - ${year}` });
      if (m === '12') months_.push({ ref: `Dez - ${year}` });
    }

    return months_;
  };

  filter = e => {
    this.setState({ data_whith_value: e });

    const { data, filter } = this.state;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (element.accounting.name === 'Correio') {
        // console.log(currencyToString(element.expected_total) > 0);
      }
    }

    if (e === 'true') {
      const list = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (filter === 'expected_value') {
          if (currencyToString(element.expected_total) > 0) {
            list.push(element);
          }
        } else {
          if (currencyToString(element.real_total) > 0) {
            list.push(element);
          }
        }
      }

      this.setState({ budgets_accountings: list });
    } else {
      this.setState({ budgets_accountings: data });
    }
  };

  render() {
    const budget = this.state;

    return (
      <Widget title={`Resumo ${budget.budget_name}`} printer={true}>
        {!budget.loading && (
          <div className="row">
            <div className="col">
              <form onSubmit={this.submit}>
                <div className="row justify-content-end mt-2">
                  <div className="col-sm col-md-2">
                    <Link to="/budgets">
                      <button className="btn btn-success btn-block">
                        Voltar
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="col select-budget">
                  <span>{`Orçamento ${
                    budget.filter === 'expected_value'
                      ? 'Previsto'
                      : 'Realizado'
                  }`}</span>
                </div>

                <div className="row">
                  {/* Init Filter */}
                  <div className="col p-1">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm">
                          <Select
                            label="Tipo Orçamento"
                            value={budget.month_ref}
                            options={budget.fields[0].options}
                            onChange={e =>
                              this.setState({ filter: e.target.value })
                            }
                            field={budget.fields[0]}
                            validate={() => {}}
                          />
                        </div>

                        <div className="col-sm">
                          <Select
                            label="Mostrar categorias"
                            value={budget.data_whith_value}
                            options={budget.fields[1].options}
                            onChange={e => this.filter(e.target.value)}
                            field={budget.fields[1]}
                            validate={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Filter */}
                </div>

                {/* resume */}
                <div className="">
                  <table className="table table-responsive table-sm  table-bordered table-print">
                    <thead className="thead-dark bg-filter">
                      <tr>
                        <th scope="col" style={{ width: '100px' }}>
                          Resumo
                        </th>
                        {budget.budgets_months.map((month, i) => (
                          <th scope="col" key={i}>
                            <span>{month.ref}</span>
                          </th>
                        ))}
                        <th scope="col">Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{budget.recipe.name}</td>
                        {budget.recipe_months.map((recipe_month, y) => (
                          <td key={y} className="td-value">
                            {budget.filter === 'expected_value'
                              ? floatToCurrency(recipe_month.expected_value)
                              : floatToCurrency(recipe_month.real_value)}
                          </td>
                        ))}
                        <td>
                          {' '}
                          {budget.filter === 'expected_value'
                            ? floatToCurrency(
                                Number(budget.recipe.expected_total)
                              )
                            : floatToCurrency(Number(budget.recipe.real_total))}
                        </td>
                      </tr>

                      <tr>
                        <td>{budget.expense.name}</td>
                        {budget.expense_months.map((expense_month, y) => (
                          <td key={y} className="td-value">
                            {budget.filter === 'expected_value'
                              ? floatToCurrency(expense_month.expected_value)
                              : floatToCurrency(expense_month.real_value)}
                          </td>
                        ))}

                        <td>
                          {budget.filter === 'expected_value'
                            ? floatToCurrency(
                                Number(budget.expense.expected_total)
                              )
                            : floatToCurrency(
                                Number(budget.expense.real_total)
                              )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* resume */}

                <table className="table table-responsive table-sm mt-4 table-bordered table-print">
                  <thead className="thead-dark head bg-filter">
                    <tr>
                      <th scope="col">Categoria</th>
                      {budget.budgets_months.map((month, i) => (
                        <th scope="col" key={i}>
                          {month.ref}
                        </th>
                      ))}
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {budget.budgets_accountings.map((budget_accounting, x) => (
                      <tr key={x}>
                        {/* <td style={budget_accounting.accounting.number.length > 4 ? {} : { 'fontWeight': 'bold' }}>{budget_accounting.accounting.number}</td> */}
                        <td
                          style={
                            budget_accounting.accounting.number.length > 4
                              ? {}
                              : { fontWeight: 'bold' }
                          }
                        >
                          {budget_accounting.accounting.name}
                        </td>
                        {budget_accounting.budget_accounting_months.map(
                          (budget_accounting_month, y) => (
                            <td
                              key={y}
                              className="td-value"
                              style={
                                budget_accounting.accounting.number.length > 4
                                  ? {}
                                  : { fontWeight: 'bold' }
                              }
                            >
                              {budget.filter === 'expected_value'
                                ? budget_accounting_month.expected_value
                                : budget_accounting_month.real_value}
                            </td>
                          )
                        )}

                        <td
                          style={
                            budget_accounting.accounting.number.length > 4
                              ? {}
                              : { fontWeight: 'bold' }
                          }
                        >
                          {budget.filter === 'expected_value'
                            ? budget_accounting.expected_total
                            : budget_accounting.real_total}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {budget.loading && (
                  <div className="row justify-content-center mt-5">
                    <Loading />
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
        {budget.loading && (
          <div className="row justify-content-center mt-5">
            <Loading />
          </div>
        )}
      </Widget>
    );
  }
}

export default Index;
