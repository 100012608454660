import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import Select from '../../components/select'
import DateTime from '../../components/date-time'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { dateToString, stringToDate, formatPrice } from '../../actions/util'

import { Total } from './styles'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      title: '',
      id: '',
      budget_id: '',
      date: '',
      name: '',
      entry_accounting_id: '',
      output_accounting_id: '',
      interest_accounting_id: null, //TODO - remover da api
      financial_movements: [],
      balance: '',
      fields: [
        {
          value: '', //0
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {}
        },
        {
          value: '', //1
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'entry_accounting_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {}
        },
        {
          value: '', //4
          name: 'output_accounting_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {}
        },
      ]
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params

    if (id) this.getReceivableAntecipation(id)

    this.getBudgets()
    this.getEntryAccountings()
    this.getOutputAccountings()
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const antecipation = this.state

    let fields = this.submitForm(e, antecipation.fields)
    this.setState({ fields: fields })

    if (antecipation.valid && antecipation.errors.length === 0) {
      antecipation.id ? this.update() : this.save()
    }
    return false
  }

  getReceivableAntecipation = async id => {
    await api.get(`receivables-antecipations/${id}`)
      .then(result => {
        const antecipation = result.data
        const fields = this.state.fields

        fields[0].value = antecipation.budget_id
        fields[1].value = antecipation.date
        fields[2].value = antecipation.name
        fields[3].value = antecipation.entry_accounting_id
        fields[4].value = antecipation.output_accounting_id

        this.setState({
          id: antecipation.id,
          budget_id: antecipation.budget_id || '',
          date: antecipation.date ? stringToDate(antecipation.date) : '',
          name: antecipation.name || '',
          entry_accounting_id: antecipation.entry_accounting_id || '',
          output_accounting_id: antecipation.output_accounting_id || '',
          financial_movements: antecipation.financial_movements || [],
          balance: antecipation.balance || 0,
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Antecipação Financeira', url: '/receivables-antecipations' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/')
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    const name = event.target.name
    const state = this.state
    const fields = this.state.fields

    fields[0].value = state.budget_id
    fields[1].value = state.date
    fields[2].value = state.name
    fields[3].value = state.entry_accounting_id
    fields[4].value = state.output_accounting_id

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const antecipation = this.state

    await api.post('receivables-antecipations', antecipation)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push(`/receivables-antecipations`)
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  update = async () => {
    const antecipation = this.state

    await api.put(`receivables-antecipations/${antecipation.id}`, antecipation)
      .then(result => {
        toastSuccess(`Registro de ${antecipation.name} atualizado!`)
        this.props.history.push('/receivables-antecipations')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  getBudgets = async () => {
    let budgets = []
    const { fields } = this.state

    await api.get('budgets')
      .then(result => {
        fields[0].options = []

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name
        }))

        fields[0].options = budgets
        fields[0].options.unshift({ id: null, label: '' })

        this.setState({ fields: fields, budget_id: budgets[0].value })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getEntryAccountings = async () => {
    let accountings = []
    const { fields } = this.state

    await api.get('accountings?status_type=ATIVO&accounting_type=RECEITA')
      .then(result => {
        fields[3].options = []

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i]
          const item = { value: element.id, label: `${element.number} ${element.name}`, disabled: true }
          accountings.push(item)

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x]
              const item = { value: elem.id, label: `${elem.number} ${elem.name}` }
              accountings.push(item)
            }
          }
        }

        fields[3].options = accountings
        fields[3].options.unshift({ id: null, label: '' })

        this.setState({ fields: fields })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getOutputAccountings = async () => {
    let accountings = []
    const { fields } = this.state

    await api.get('accountings?status_type=ATIVO&accounting_type=DESPESA')
      .then(result => {

        fields[4].options = []

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i]
          const item = { value: element.id, label: `${element.number} ${element.name}`, disabled: true }
          accountings.push(item)

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x]
              const item = { value: elem.id, label: `${elem.number} ${elem.name}` }
              accountings.push(item)
            }
          }
        }

        fields[4].options = accountings
        fields[4].options.unshift({ id: null, label: '' })

        this.setState({ fields: fields })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  handleMovement = () => {
    this.setState({ addMovement: true })
  }

  render() {
    const antecipation = this.state

    return (
      <Widget title={antecipation.id ? `Editar ${antecipation.name}` : `Nova Antecipação Financeira`}>
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Orçamento"
                    value={antecipation.budget_id}
                    options={antecipation.fields[0].options}
                    disabled={antecipation.id}
                    onChange={this.handleChange}
                    field={antecipation.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, antecipation.fields[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-4">
                  <DateTime
                    label="Data do Empréstimo"
                    value={antecipation.date}
                    disabled={antecipation.id}
                    onChange={date => this.setState({ date: date })}
                    field={antecipation.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, antecipation.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="* Nome"
                    value={antecipation.name}
                    disabled={antecipation.id}
                    onChange={event => this.setState({ name: event.target.value })}
                    field={antecipation.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, antecipation.fields[2])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Conta Movimento de Entrada"
                    value={antecipation.entry_accounting_id}
                    options={antecipation.fields[3].options}
                    disabled={antecipation.id}
                    onChange={this.handleChange}
                    field={antecipation.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, antecipation.fields[3])}
                  />
                </div>
                <div className="col-sm">
                  <Select
                    label="* Conta Movimento de Saída"
                    value={antecipation.output_accounting_id}
                    options={antecipation.fields[4].options}
                    onChange={this.handleChange}
                    disabled={antecipation.id}
                    field={antecipation.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, antecipation.fields[4])}
                  />
                </div>
              </div>

              <div className="row mt-4 justify-content-end mb-3 ">
                <div className="col-sm col-md-2">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block "
                      disabled={!antecipation.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col-sm col-md-1">
                  <Link to="/receivables-antecipations">
                    <button
                      className="btn btn-success btn-block pl-1 pr-1">
                      Voltar
									  </button>
                  </Link>
                </div>
              </div>
            </form>

            {antecipation.id &&
              <>
                <Total className="row">
                  <div className="col">Saldo</div>
                  <div className="col text-right">{formatPrice(antecipation.balance)}</div>
                </Total>

                <div className="row mt-4 table-responsive">
                  <table className="table  table-striped table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Data</th>
                        <th scope="col">Conta</th>
                        <th scope="col">Data Venc</th>
                        <th scope="col">Data Pgto</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {antecipation.financial_movements &&
                        antecipation.financial_movements.map((movement, i) => (
                          <tr key={i}>
                            <td>{dateToString(movement.date)}</td>
                            <td>{movement.account.name}</td>
                            <td>{dateToString(movement.due_date)}</td>
                            <td>{movement.pay_date ? dateToString(movement.pay_date) : ''}</td>
                            <td>{movement.payment_status}</td>
                            <th className="text-right">{formatPrice(movement.value)}</th>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index