import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GiWaterSplash } from 'react-icons/gi';
import { BsFillClockFill, BsStopwatch } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { floatToTimeList } from '../../../actions/util';

const IrrigationsDashboard = ({ data, budget_id }) => {
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const _data = data.map(item => item.value);

    const _total = _data.reduce(function(total, numero) {
      return total + numero;
    }, 0);

    if (_total > 0) setTotalHours(_total);
  }, [data]);
  return (
    <Container>
      <h1 className="text-center">
        <GiWaterSplash size={25} className="mb-1" /> Irrigações
      </h1>

      <div className="row justify-content-center">
        {data.length === 0 && (
          <div className="col-sm col-md-3">
            <Link to="/irrigations">
              <div className="card shadow">
                <div className="card-body mb-4 text-center font-italic">
                  <b>Irrigações</b> ainda não possui lançamentos!
                </div>
              </div>
            </Link>
          </div>
        )}

        {data.length > 0 && (
          <>
            <div className="col-sm col-md-3">
              <Link to={`dashboard/irrigations/${budget_id}`}>
                <div className="card shadow">
                  <div className="card-body text-center">
                    <h5 className="card-title ">TOTAL GERAL</h5>

                    <div className="table-responsive">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>
                              <BsStopwatch size={60} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="card-footer text-center">
                      <small
                        className="text-muted"
                        style={{ fontSize: '14px' }}
                      >
                        <BsFillClockFill size={15} className="mb-1 mr-2" />
                        {floatToTimeList(totalHours)} H
                      </small>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {data.map((reg, i) => (
              <div key={i} className="col-sm col-md-3">
                <Link to={`dashboard/irrigations/${budget_id}/crop/${reg.id}`}>
                  <div className="card shadow">
                    <div className="card-body">
                      <h5 className="card-title text-center">{reg.name}</h5>

                      <div className="table-responsive">
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td> {floatToTimeList(reg.value)} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer text-center">
                      <small className="text-muted">
                        <BsFillClockFill size={15} className="mb-1" /> TOTAL DE
                        HORAS
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export default IrrigationsDashboard;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  padding-bottom: 30px;

  h1 {
    color: #094e62;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  h5 {
    color: #094e62;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;
  }

  small {
    color: #094e62 !important;
  }
  a {
    color: #094e62 !important;

    :hover {
      color: #fff !important;
      h5 {
        color: #fff;
      }
      td {
        color: #fff;
      }
      small {
        color: #fff !important;
      }
      .card {
        background-color: #094e62 !important;
      }
    }
  }

  .table td {
    font-size: 30px;
    font-weight: bold;
    color: #094e62;
    margin-top: 20px;
    border: 0;

    a {
      color: #094e62;

      :hover {
        color: #505050;
      }
    }
  }

  .table tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 0;
  }

  .table th {
    border: 0;

    a {
      color: #505050;

      :hover {
        color: #094e62;
      }
    }
  }

  .card {
    height: 100%;
    border-radius: 0;

    :hover {
      border-color: #094e62;
    }
  }

  .card-body {
    padding-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    background: none !important;
    border: none !important;

    a {
      color: #505050;
      :hover {
        color: #094e62;
      }
    }
  }

  @media screen and (max-width: 1290px) {
    h5 {
      font-size: 12px;
    }
  }
`;
