import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import Select from '../../components/select'
import InputCurrency from '../../components/input-mask-currency'
import InputDate from '../../components/date-time'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { currencyToString } from '../../actions/util'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      id: '',
      date: new Date(),
      budget_id: '',
      movement_type: 'TRANSFERENCIA',
      out_account_id: '',
      entry_account_id: '',
      payment_type: '',
      document: '',
      pay_date: new Date(),
      value: '',
      description: '',
      historic: '',
      fields: [
        {
          value: '', //0
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {}
        },
        {
          value: '', //1
          name: 'out_account_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {}
        },
        {
          value: '', //2
          name: 'entry_account_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {}
        },
        {
          value: '', //3
          name: 'payment_type',
          type: 'text',
          options: [
            { label: '', value: null },
            { label: 'Boleto', value: 'BOLETO' },
            { label: 'Cheque', value: 'CHEQUE' },
            { label: 'Dinheiro', value: 'DINHEIRO' },
            { label: 'Cartão de Crédito', value: 'CARTAO_CREDITO' },
            { label: 'Cartão de Débito', value: 'CARTAO_DEBITO' },
            { label: 'Doc TED', value: 'DOC_TED' },
            { label: 'Transferência', value: 'TRANSFERENCIA' },
            { label: 'Outros', value: 'OUTROS' },
          ],
          errors: [],
          rules: {}
        },
        {
          value: '', //4
          name: 'document',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //5
          name: 'description',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //6
          name: 'historic',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //7
          name: 'pay_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //8
          name: 'value',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        }
      ]
    }
  }

  componentDidMount() {
    this.getBudgets()
    this.getAccounts()
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const transfer = this.state

    let fields = this.submitForm(e, transfer.fields)
    this.setState({ fields: fields })

    if (transfer.valid && transfer.errors.length === 0) {
      this.save()
    }
    return false
  }

  handleChange(event) {
    const name = event.target.name
    const transfer = this.state
    const fields = this.state.fields

    fields[0].value = transfer.budget_id
    fields[1].value = transfer.out_account_id
    fields[2].value = transfer.entry_account_id
    fields[3].value = transfer.payment_type
    fields[4].value = transfer.document
    fields[5].value = transfer.pay_date
    fields[6].value = transfer.value
    fields[7].value = transfer.description
    fields[8].value = transfer.historic

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const transfer = this.state
    transfer.value = currencyToString(transfer.value)

    await api.post('financial-transfer', transfer)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push('/financial-movements')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  getBudgets = async () => {
    let budgets = []
    const { fields } = this.state

    await api.get('budgets')
      .then(result => {
        fields[0].options = []

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name
        }))

        fields[0].options = budgets
        fields[0].options.unshift({ id: null, label: '' })

        this.setState({ fields: fields, budget_id: budgets[0].value })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getAccounts = async () => {
    let accounts = []
    const { fields } = this.state

    await api.get('accounts?status_type=ATIVO')
      .then(result => {
        fields[1].options = []
        fields[2].options = []

        accounts = result.data.accounts.map(result => ({
          value: result.id,
          label: result.name
        }))

        fields[1].options = accounts
        fields[1].options.unshift({ id: null, label: '' })
        fields[2].options = accounts

        this.setState({ fields: fields })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  render() {
    const transfer = this.state

    return (
      <Widget title="Nova Transferência">
        <div className="row col-10">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Orçamento"
                    value={transfer.budget_id}
                    options={transfer.fields[0].options}
                    onChange={this.handleChange}
                    field={transfer.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Conta Bancária de Saída"
                    value={transfer.out_account_id}
                    options={transfer.fields[1].options}
                    onChange={this.handleChange}
                    field={transfer.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Conta Bancária de Entrada"
                    value={transfer.entry_account_id}
                    options={transfer.fields[2].options}
                    onChange={this.handleChange}
                    field={transfer.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[2])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Tipo de Pgto"
                    value={transfer.payment_type}
                    options={transfer.fields[3].options}
                    onChange={this.handleChange}
                    field={transfer.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[3])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="Documento"
                    value={transfer.document}
                    onChange={this.handleChange}
                    field={transfer.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[4])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <InputText
                    label="* Descrição"
                    value={transfer.description}
                    onChange={this.handleChange}
                    field={transfer.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[5])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="Histórico"
                    value={transfer.historic}
                    onChange={this.handleChange}
                    field={transfer.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[6])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <InputDate
                    label="* Data do Pagamento"
                    value={transfer.pay_date}
                    onChange={date => this.setState({ pay_date: date })}
                    field={transfer.fields[7]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[7])}
                  />
                </div>

                <div className="col-sm">
                  <InputCurrency
                    label="* Valor"
                    value={transfer.value}
                    onChange={this.handleChange}
                    field={transfer.fields[8]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[8])}
                  />
                </div>
              </div>

              {/* <div className="row">
                  <>
                    <div className="col">
                      <InputDate
                        label="* Data Vencimento"
                        value={transfer.due_date}
                        onChange={date => this.setState({ due_date: date })}
                        field={transfer.fields[8]}
                        validate={(e, field) =>
                          this.validate(e, transfer.fields[8])}
                      />
                    </div>

                    <div className="col col-md-2">
                      <InputDate
                        label="Data Pagamento"
                        value={transfer.pay_date}
                        onChange={date => this.setState({ pay_date: date })}
                        field={transfer.fields[9]}
                        validate={(e, field) =>
                          this.validate(e, transfer.fields[9])}
                      />
                    </div>
                  </>

                <div className="col">
                  <InputCurrency
                    label="* Valor"
                    value={transfer.value}
                    onChange={this.handleChange}
                    field={transfer.fields[10]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[10])}
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="Acréscimo"
                    value={transfer.increase}
                    onChange={this.handleChange}
                    field={transfer.fields[11]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[11])}
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="Desconto"
                    value={transfer.discount}
                    onChange={this.handleChange}
                    field={transfer.fields[12]}
                    validate={(e, field) =>
                      this.validate(e, transfer.fields[12])}
                  />
                </div>
              </div> */}

              <div className="row">
                {transfer.id &&
                  <div className="col">
                    <InputText
                      label="Histórico"
                      value={transfer.historic}
                      onChange={this.handleChange}
                      field={transfer.fields[14]}
                      validate={(e, field) =>
                        this.validate(e, transfer.fields[14])}
                    />
                  </div>
                }

              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-5">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!transfer.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-5">
                  <Link to="/financial-movements">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index