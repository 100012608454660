import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Logo from '../../logo'
import ToggleLayout1 from '../ToggleLayout1'
import ToggleLayout2 from '../ToggleLayout2'
import ToggleRightSidebar from '../ToggleRightSidebar'
import SelectLabel from '../../../components/unform/select-label';
import Profile from '../../profile/Profile'
import '../../../css/navbar/navbar-1.css'
import { AuthContext } from '../../../actions/auth-context'
import { index } from '../../../actions/base-rest'
import { Form } from '@unform/web';
import styled from 'styled-components'

const Navbar1 = () => {
  const { defaultBudget, setDefaultBudget, isAuthenticated } = useContext(AuthContext)

  const formRef = useRef(null);

  const [budgetOptions, setBudgetsOptions] = useState([])

  const getBudgets = useCallback(async () => {
    const  response = await index('budgets')
    
    if(response) {
      setBudgetsOptions(response.map(item => ({
        value: item.id,
        label: item.name
      })))
    }
  },[])

  useEffect(() => {
    if(isAuthenticated) getBudgets()
    
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBudget, isAuthenticated])

  return(
    <Container>
      <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
        <Logo />

        <ToggleLayout1 />

        <div className="separator" />

        <div className='col-sm-5 col-md-3 budget'>
          <Form ref={formRef} noValidate>
            <SelectLabel
              name="budget"
              label="ORCAMENTO PADRÃO"
              placeholder="selecione"
              options={budgetOptions}
              value={defaultBudget}
              onChange={(e) => setDefaultBudget(e)}
            />
          </Form>
        </div>

        <div className="separator" />
        
        <ul className="nav nav-inline nav-inline-2">
          <li className="nav-item nav-item-dropdown">
            <Profile />
          </li>
          <ToggleRightSidebar />
        </ul>
        <ToggleLayout2 />
      </nav>
    </Container>
  )
}

export default Navbar1

export const Container = styled.div`

  .react-select__control {
    height: 20px !important;
    color: #000 !important;
  }
  
  .react-select__menu {
      color: #000 !important;
  }

  @media screen and (min-width: 1200px) {
    .budget {
      margin-left: 90px;
    }
  }
`