
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import Phone from './Phone'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(Phone),
    renderNothing
  )
)(Phone)

export default Component