
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RiPlantFill } from 'react-icons/ri'
import { FaTractor } from 'react-icons/fa'
import { BsFillClockFill } from 'react-icons/bs'
import { floatToTimeList } from '../../../actions/util'

const TotalHours = ({ total_hours }) => {
  return (
    <Container className="row col-12 mb-3 justify-content-center">
      <div className="col-sm col-md-3">
        <Link to={`dashboard/machine-times/machines`}>
          <div className="card shadow">
            <div className="card-body text-center">
              <h5 className="card-title ">TOTAL POR MÁQUINA</h5>

              <div className="table-responsive">
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <FaTractor size={60} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="card-footer text-center">
                <small className="text-muted">
                  <p>
                    <BsFillClockFill size={15} className="mb-1 mr-2" />
                    {floatToTimeList(total_hours)} H
                  </p>
                </small>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="col-sm col-md-3">
        <Link to={`/dashboard/machine-times/crops/${undefined}`}>
          <div className="card shadow">
            <div className="card-body text-center">
              <h5 className="card-title ">TOTAL POR SAFRA</h5>

              <div className="table-responsive">
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <RiPlantFill size={60} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer text-center">
              <small className="text-muted">
                <p>
                  <BsFillClockFill size={15} className="mb-1 mr-2" />
                  {floatToTimeList(total_hours)} H
                </p>
              </small>
            </div>
          </div>
        </Link>
      </div>
    </Container>
  )
}

export default TotalHours

export const Container = styled.div`
  a {
    h5 { color: #4e3c32; }
    p { 
      color: #4e3c32; 
      margin: 0;
      font-size: 14px;
    }
    svg { color: #4e3c32; }
    
    :hover { 
      h5 { color: #fff !important; }
      p { color: #fff; }
      svg { color: #fff; }
      .card { background-color: #4e3c32 !important; }
    }
  }
`