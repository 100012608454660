
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import Tillage from './Tillage'
import api from '../../actions/api'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      const { cropId } = this.props.match.params;

      if (cropId) {
        api.get(`crops/${cropId}`)
          .then(result => {
            const crop = result.data;

            this.props.dispatch({
              type: 'SET_BREADCRUMB',
              items: [
                { page: 'Home', url: '/' },
                { page: 'Safras', url: '/' },
                { page: `${crop.cultivation.name}`, url: `/crops/${crop.id}` },
                { page: 'Lavouras', url: '#' },
                { page: 'Incluir', url: '#' },
              ]
            })
          });
      }
    },

  }),
  branch(
    () => {
      return true

    },
    renderComponent(Tillage),
    renderNothing
  )
)(Tillage)

export default Component