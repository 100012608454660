import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Widget from '../../../../components/widget'
import Loading from '../../../../components/loading'
import { handleError } from '../../../../actions/handle-error'
import { toastError } from '../../../../actions/toast'
import api from '../../../../actions/api'
import BudgetDetails from './BudgetDetails'
import OutOfBudget from './OutOfBudget'
import { BudgetTitle } from '../styles'

class Index extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      header_table: [],
      accounting_name: '',
      budget_id: '',
      budget_name: '',
      data: [],
      outOfBudgetData: []
    }
  }

  async componentDidMount() {
    await this.getBudgetType()
    await this.getOutOfBudget()
  }

  getBudgetType = async () => {
    const params = await this.getParams()

    await api.get(`dashboard/budget/accounting-type`, params)
      .then(result => {
        const data_ = result.data

        this.setState({ loading: false, data: data_ })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  getOutOfBudget = async () => {
    const params = await this.getParams()

    await api.get(`dashboard/budget/out-of-budget`, params)
      .then(result => {
        this.setState({ loading: false, outOfBudgetData: result.data })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  getParams = async () => {
    const budget_id = this.props.selected_budget.id
    const budget_accounting = this.props.selected_accounting
    const { accounting_type } = this.props.match.params

    let params_ = {}

    if (!budget_id) this.props.history.push('/')

    if (budget_id) params_.budget_id = budget_id
    if (accounting_type) params_.accounting_type = accounting_type

    if (budget_accounting && budget_accounting.id) {
      params_.accounting_type = budget_accounting.type
      params_.accounting_id = budget_accounting.id

      this.setState({
        accounting_name: budget_accounting.name,
        budget_id: budget_id
      })
    }

    this.setState({
      budget_name: this.props.selected_budget.name
    })

    return { params: params_ }
  }

  getTitle = () => {
    const { accounting_type } = this.props.match.params
    const budget_accounting = this.props.selected_accounting

    if (accounting_type) {
      return accounting_type
    } else {
      return budget_accounting.type
    }
  }

  render() {
    const budget = this.state

    return (
      <Widget title={`Orçamento - ${this.getTitle()}`} printer={true}>
        <BudgetTitle>
          <h2>{budget.budget_name}</h2>

          {budget.accounting_name &&
            <div className="col">
              <span>{budget.accounting_name}</span>
            </div>
          }
        </BudgetTitle>

        {budget.loading && (
          <div className="row justify-content-center mt-5">
            <Loading />
          </div>
        )
        }

        { !budget.loading && (
          <BudgetDetails data={budget.data} />
        )}

        { budget.outOfBudgetData.length > 0 && (
          <OutOfBudget data={budget.outOfBudgetData} />
        )}

        <div className="row no-gutters justify-content-end">
          <div className="col col-md-2 mt-3">
            <Link to={budget.budget_id ? `/budgets/${budget.budget_id}` : '/'}>
              <button
                className="btn btn-success btn-block">
                Voltar
							</button>
            </Link>
          </div>
        </div>

      </Widget >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selected_budget: state.selectedBudget,
    selected_accounting: state.selectedAccounting
  }
}
export default connect(mapStateToProps)(Index)