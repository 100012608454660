import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import Widget from '../../../components/widget'

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { store, index } from '../../../actions/base-rest';
import InputLabel from '../../../components/unform/input-label';
import DateLabel from '../../../components/unform/date-label';
import SelectLabel from '../../../components/unform/select-label';
import InputCurrencyLabel from '../../../components/unform/input-currency-label';
import { GroupData } from '../../../styles/GroupData';
import {wheigthToFloat, currencyToString} from '../../../actions/util'
import {dateToStringWithTime} from '../../../commons/date'

const URL = 'cargo-packings';

const NewCargoPacking = () => {

  const formRef = useRef(null);

  const history = useHistory();

  const [ cropsOptions, setCropsOptions ] = useState([])

  const [ budgtsOptions, setBudgetsOptions ] = useState([])

  const [ selectedCrop, setSelectedCrop ] = useState({})

  const [ selectedBudget, setSelectedBudget ] = useState({})

  const [ date, setDate ] = useState(new Date())

  const submit = useCallback( 
    async (data) => {
      data.date = date

      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          date: Yup.date().nullable(),
          budget_id: Yup.string().required('Orçamento é obrigatório'),
          crop_id: Yup.string().required('Safra é obrigatório'),
          document: Yup.string().required('Documento é obrigatório'),
          cargo_weight: Yup.number().typeError('Peso inválido').nullable(),
          receipt_forecast: Yup.date()
          .required('Data é obrigatório')
          .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        data.date = await dateToStringWithTime(data.date);

        data.receipt_forecast = await dateToStringWithTime(data.receipt_forecast);

        data.cargo_weight = await wheigthToFloat(data.cargo_weight);

        const qualities = data.cargo_packing_qualities.map(item => {
          if (item.quantity && item.unit_price) {
            const obj = {
              quality_id: item.quality_id,
              quantity: item.quantity,
              unit_price: currencyToString(item.unit_price)
            }
    
            return obj
          }
          return null
        })
    
        const qualities_not_blank_fields = qualities.filter(function (el) {
          return el != null;
        });

        data.cargo_packing_qualities = qualities_not_blank_fields

        const response = await store(URL, data);
        
        if (response.id) history.push(`/${URL}/${response.id}`);
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [history, date]
  );

  const getBudgets = useCallback(async() => {
    const response = await index('budgets')

    if (!response.error) {
      setBudgetsOptions(response.map(item => ({
        label: item.name,
        value: item.id
      })))
    }
  },[])

  const getCrops = useCallback(async(budget_id) => {
    const params = { budget_id }

    const response = await index('crops', params)

    if (!response.error) {
      setCropsOptions(response.map(item => ({
        label: item.name,
        value: item.id,
        qualities: item.cultivation.qualities
      })))
    }
  },[])

  useEffect(() => {
    getBudgets()
  }, [getBudgets])
  
  useEffect(() => {
    if(selectedBudget.value) getCrops(selectedBudget.value)
  }, [selectedBudget, getCrops])

  return (
    <Widget title="Novo Romaneio">
       <div className="row justify-content-center">
          <div className="col col-md-7">
          <Form ref={formRef} onSubmit={submit} noValidate>
              <GroupData>
                <span>DADOS DO ROMANEIO</span>

                <div className="row mt-3">
                  <div className="col-sm col-md-3">
                    <DateLabel
                      name="date"
                      label="* Data de Lançamento"
                      defaultValue={date || null}
                      onChange={e => setDate(e)}
                    />
                  </div>

                  <div className="col-sm">
                    <SelectLabel
                      name="budget_id"
                      label="* Orçamento"
                      placeholder="selecione"
                      options={budgtsOptions}
                      value={selectedBudget}
                      onChange={(e) => setSelectedBudget(e)}
                    />
                  </div>
                  
                  <div className="col-sm">
                    <SelectLabel
                      name="crop_id"
                      label="* Safra"
                      placeholder="selecione"
                      disabled={!selectedBudget.value}
                      options={cropsOptions}
                      value={selectedCrop}
                      onChange={(e) => setSelectedCrop(e)}
                    />
                  </div>
                </div>

                <div className='row '>
                  <div className="col-sm">
                    <InputLabel
                      name="document"
                      label="* Documento"
                    />
                  </div>
                  
                  <div className="col-sm">
                    <InputLabel
                      name="cargo_weight"
                      label="* Peso"
                    />
                  </div>
                  
                  <div className="col-sm mt-3">
                    <DateLabel
                      name="receipt_forecast"
                      label="* Data Prevista de Recebimento"
                    />
                  </div>
                </div>
              </GroupData>

              <GroupData>
                <div className='d-flex'>
                  <i className="material-icons text-warning mr-2 mt-2">warning</i>
                  <span className='mt-3 ml-1'>
                    Qualidades com campo quantidade e preço em <b>BRANCO</b> não serão incluídas!
                  </span>
                </div>

                {selectedCrop.qualities?.map((item, i) => (
                  <div className='row' key={i}> 
                    <div className="col-sm d-none">
                      <InputLabel
                        name={`cargo_packing_qualities[${i}].quality_id`}
                        label="ID *nao exibido"
                        value = {item.id}
                      />
                    </div>
                    
                    <div className="col-sm">
                      <InputLabel
                        name={`cargo_packing_qualities[${i}].name`}
                        label="Qualidade"
                        value = {item.name}
                      />
                    </div>
                    
                    <div className="col-sm">
                      <InputLabel
                        name={`cargo_packing_qualities[${i}].quantity`}
                        label="Quantidade"
                      />
                    </div>
                  
                    <div className="col-sm mt-3">
                      <InputCurrencyLabel
                        name={`cargo_packing_qualities[${i}].unit_price`}
                        label="Preço"
                      />
                    </div>
                </div>
                ))}
              </GroupData>

              <div className="row justify-content-center mt-4">
                <div className="col-6 col-md-4 col-xl-3">
                  <button
                    className="btn btn-outline-secondary btn-block col-12"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    voltar
                  </button>
                </div>
                <div className="col-6 col-md-4 col-xl-3">
                  <button
                    className="btn btn-success btn-block col-12"
                    type="submit"
                  >
                    salvar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
    </Widget>
  );
};

export default NewCargoPacking;
