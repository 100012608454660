import AWS from 'aws-sdk';

export const fetchImage = (thumbnail, folder) => {
  if (thumbnail) {
    const s3 = new AWS.S3({
      region: 'sa-east-1',
      accessKeyId: 'AKIAID23LMTODJ353VSA',
      secretAccessKey: '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',
      expires: 900
    });

    const params = { Bucket: `nossa-safra/${folder}`, Key: thumbnail };

    const imageUrl = s3.getSignedUrl('getObject', params);
    localStorage.setItem(`${thumbnail}`, `${imageUrl}`)

    return imageUrl;
  } else {
    return `../assets/images/crop.png`;
  }
}

export const saveImage = async (name, id, image, folder) => {
  const AWSService = AWS;
  AWSService.config.region = 'sa-east-1';
  AWSService.config.accessKeyId = 'AKIAID23LMTODJ353VSA';
  AWSService.config.secretAccessKey = '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';


  let nameImage = name.toLowerCase() + '_' + id + '.png';

  const specialcaracters = [
    ' ', '-', '+', '+', ',', '?', '/', '\\', '|', '[', ']',
    '{', '}', '=', '(', ')', '\'', '`', '~', '^', '&', '$',
    '#', '@', '!', '%', '*', ':', ';', '"', '<', '>'
  ];

  nameImage = replaceAll(nameImage, specialcaracters, '_');

  const bucket = new AWSService.S3({ params: { Bucket: `nossa-safra/${folder}` } });

  const finalImage = image.replace(/^data:image\/\w+;base64,/, '');

  const buf = new Buffer(finalImage, 'base64');
  const params = {
    Key: nameImage,
    Body: buf,
    ContentEncoding: 'base64',
    ContentType: 'image/png'
  };

  bucket.upload(params, function (error, res) { });

  return nameImage;
}

export const replaceAll = (string, targets, replacement) => {
  if (string !== null) {
    const length = string.length;
    let i = 0;

    for (i; i < length; i++) {
      for (const target of targets) {
        string = string.replace(target, replacement);
      }
    }
  }
  return string;
}


export default { fetchImage, saveImage };