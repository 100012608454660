import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import InputText from '../../../components/input-text';
import DateTime from '../../../components/date-time';
import InputCurrency from '../../../components/input-mask-currency';
import RadioButton from '../../../components/radio-button';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';
import { currencyToString, formatPrice } from '../../../actions/util';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      person_id: '',
      function_id: '',
      function_name: '',
      name: '',
      admission_date: '',
      salary: '',
      dismissal_date: '',
      dismissal_reason: '',
      fields: [
        {
          value: '', //0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //1
          name: 'function_name',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //2
          name: 'admission_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //3
          name: 'salary',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //4
          name: 'dismissal_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo data de demissão é obrigatório',
            },
          },
        },
        {
          value: '', //5
          name: 'dismissal_reason',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //6
          name: 'status',
          type: 'radio',
          errors: [],
          options: [
            { label: 'Ativo', value: 'ATIVO' },
            { label: 'Demitido', value: 'DEMITIDO' },
          ],
          rules: {},
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getEmployee(id);
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const employee = this.state;

    let fields = this.submitForm(e, employee.fields);
    this.setState({ fields: fields });

    if (employee.valid && employee.errors.length === 0) this.update();

    return false;
  }

  getEmployee = async id => {
    await api
      .get(`employees/${id}`)
      .then(result => {
        const employee = result.data;
        const fields = this.state.fields;

        fields[0].value = employee.person.name;
        fields[1].value = employee.function.name;
        fields[2].value = employee.admission_date
          ? new Date(employee.admission_date)
          : '';
        fields[3].value = formatPrice(employee.salary);
        fields[4].value = employee.dismissal_date
          ? new Date(employee.dismissal_date)
          : '';
        fields[5].value = employee.dismissal_reason;

        this.setState({
          id: employee.id || '',
          person_id: employee.person.id || '',
          name: employee.person.name || '',
          function_id: employee.function.id || '',
          function_name: employee.function.name || '',
          admission_date: employee.admission_date
            ? new Date(employee.admission_date)
            : '',
          salary: formatPrice(employee.salary) || 0,
          dismissal_date: employee.dismissal_date
            ? new Date(employee.dismissal_date)
            : '',
          dismissal_reason: employee.dismissal_reason || '',
          status: employee.dismissal_date ? 'DEMITIDO' : 'ATIVO',
          fields: fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Funcionários', url: '/employees' },
            { page: 'Demitir', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/employees');
        toastError(handleError(error));
      });
  };

  handleChange(event) {
    const name = event.target.name;
    const employee = this.state;
    const fields = this.state.fields;

    fields[0].value = employee.name;
    fields[1].value = employee.function_name;
    fields[2].value = employee.admission_date;
    fields[3].value = formatPrice(employee.salary);
    fields[4].value = employee.dismissal_date;
    fields[5].value = employee.dismissal_reason;

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  update = async () => {
    const employee = this.state;
    employee.salary = currencyToString(employee.salary);

    await api
      .put(`employees/${employee.id}`, employee)
      .then(result => {
        toastSuccess(`Registro de ${employee.name} atualizado!`);
        this.props.history.push(`/employees/${result.data.id}`);
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const employee = this.state;

    return (
      <Widget title={`Demitir ${employee.name}`}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="row m-0 p-0">
                <div className="col">
                  <RadioButton
                    label="Status"
                    value={employee.status}
                    options={employee.fields[6].options}
                    onChange={this.handleChange}
                    field={employee.fields[6]}
                    disabled={true}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[6])
                    }
                  />
                </div>
              </div>

              <div className="col">
                <InputText
                  label="* Nome"
                  value={employee.name}
                  onChange={this.handleChange}
                  field={employee.fields[0]}
                  disabled={true}
                  validate={(e, field) => this.validate(e, employee.fields[0])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* Função"
                  value={employee.function_name}
                  onChange={this.handleChange}
                  field={employee.fields[1]}
                  disabled={true}
                  validate={(e, field) => this.validate(e, employee.fields[1])}
                />
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <DateTime
                    label="* Data de Admissão"
                    value={employee.admission_date}
                    onChange={date => this.setState({ admission_date: date })}
                    field={employee.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[2])
                    }
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="* Salário"
                    value={employee.salary}
                    onChange={this.handleChange}
                    field={employee.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[3])
                    }
                  />
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-sm-12 col-md-4">
                  <DateTime
                    label="* Data de Demissao"
                    value={employee.dismissal_date}
                    onChange={date => this.setState({ dismissal_date: date })}
                    field={employee.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[4])
                    }
                  />
                </div>

                <div className="col-sm-12 col-md-8">
                  <InputText
                    label="Motivo da Demissão"
                    value={employee.dismissal_reason}
                    onChange={this.handleChange}
                    field={employee.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[5])
                    }
                  />
                </div>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to={`/employees/dismissal/${employee.id}`}>
                    <button
                      className="btn btn-success btn-block"
                      disabled={!employee.valid}
                      onClick={this.submit}
                    >
                      Demitir
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to={`/employees/${employee.id}`}>
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <form onSubmit={this.submit}>
					<div className="row">
						<div className="row col-12">
							<div className="col-sm-12">
								<InputText
									label="* Nome"
									value={employee.name}
									onChange={this.handleChange}
									field={employee.fields[0]}
									disabled={true}
									validate={(e, field) =>
										this.validate(e, employee.fields[0])}
								/>
							</div>
						</div>

						<div className="row col-12">
							<div className="col-sm-12 col-md-4">
								<InputText
									label="* Função"
									value={employee.function}
									onChange={this.handleChange}
									field={employee.fields[1]}
									disabled={true}
									validate={(e, field) =>
										this.validate(e, employee.fields[1])}
								/>
							</div>
							<div className="col-sm-12 col-md-4">
								<DateTime
									label="* Data de Admissão"
									value={employee.admission_date}
									onChange={date => this.setState({ admission_date: date })}
									disabled={true}
									field={employee.fields[2]}
									validate={(e, field) =>
										this.validate(e, employee.fields[2])}
								/>
							</div>

							<div className="col-sm-12 col-md-4">
								<InputCurrency
									label="* Salário"
									value={employee.salary}
									onChange={this.handleChange}
									field={employee.fields[3]}
									disabled={true}
									validate={(e, field) =>
										this.validate(e, employee.fields[3])}
								/>
							</div>
						</div>
					</div>

					<div className="row col-12">
						<div className="col-sm-12 col-md-4">
							<DateTime
								label="* Data de Demissao"
								value={employee.dismissal_date}
								onChange={date => this.setState({ dismissal_date: date })}
								field={employee.fields[4]}
								validate={(e, field) =>
									this.validate(e, employee.fields[4])}
							/>
						</div>

						<div className="col-sm-12 col-md-8">
							<InputText
								label="Motivo da Demissão"
								value={employee.dismissal_reason}
								onChange={this.handleChange}
								field={employee.fields[5]}
								validate={(e, field) =>
									this.validate(e, employee.fields[5])}
							/>
						</div>
					</div>

					<div className="row col-12 justify-content-end mb-3 mt-2">
						<Link to="#" className="mr-2">
							<button
								className="btn btn-primary text-white"
								disabled={!employee.valid}
								onClick={this.submit}>
								Demitir
								</button>
						</Link>

						<Link to="/employees">
							<button
								className="btn btn-warning btn-outline">
								Voltar
								</button>
						</Link>
					</div>
				</form> */}
      </Widget>
    );
  }
}

export default Index;
