import React from 'react'
import CurrencyFormat from 'react-currency-format'
import '../../css/components/input/input.css'
import './style.css'

const InputCurrencyBudget = ({ value, name, disabled, onChange, onBlur, onKeyPress, placeholder, className }) => {
  return (
    <div>
      <CurrencyFormat
        placeholder={placeholder || ''}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'R$'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
        className={`input-text input-value text-right ${className}`}
        name={name}
      />
    </div>
  )
}

export default InputCurrencyBudget