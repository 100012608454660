import React from 'react'
import { AiFillPrinter } from 'react-icons/ai'

function print() {
  window.print()
}

const PrintButton = () => {
  return (
    <button
      className="btn border-success btn-block "
      onClick={print}>
      <AiFillPrinter size={16} style={{ 'color': '#53883D' }} />
    </button>
  )
}

export default PrintButton