
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import Team from './Team'
import api from '../../actions/api'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      const { cultivationId, tillageId } = this.props.match.params;

      if (cultivationId && tillageId) {
        Promise.all([
          api.get(`cultivations/${cultivationId}`),
          api.get(`tillages/${tillageId}`)
        ]).then(result => {
          const cultivation = result[0].data;
          const tillage = result[1].data;

          this.props.dispatch({
            type: 'SET_BREADCRUMB',
            items: [
              { page: 'Home', url: '/' },
              { page: 'Safras', url: '/' },
              { page: `${cultivation.name}`, url: '/' },
              { page: `${tillage.name}`, url: '#' },
              { page: 'Equipes', url: '#' },
              { page: 'Incluir', url: '#' },
            ]
          })

        })
      }
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(Team),
    renderNothing
  )
)(Team)

export default Component