import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import Select from '../../components/select'
import RadioButton from '../../components/radio-button'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { formatPrice } from '../../actions/util'
import { handleError } from '../../actions/handle-error'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      account_type: '',
      bank_id: '',
      agency: '',
      number: '',
      name: '',
      status_type: 'ATIVO',
      balance: '',
      fields: [
        {
          value: '', //0
          name: 'account_type',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: null },
            { label: 'Local', value: 'LOCAL' },
            { label: 'Corrente', value: 'CORRENTE' },
            { label: 'Cartão', value: 'CARTAO' },
            { label: 'Poupança', value: 'POUPANCA' },
            { label: 'Investimento', value: 'INVESTIMENTO' },
          ],
          rules: {
            required: {
              message: 'Campo tipo conta é obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'bank_id',
          type: 'text',
          options: [
            { label: '', value: null },
            { label: 'Banco do Brasil', value: '001' },
            { label: 'Banco Santander', value: '033' },
            { label: 'Caixa Econômica Federal', value: '104' },
            { label: 'Banco Bradesco', value: '237' },
            { label: 'Banco ItauBank', value: '479' },
            { label: 'Cooperativa de Crédito - Sicredi', value: '748' },
            { label: 'Cooperativa de Crédito - Sicoob', value: '756' }
          ],
          errors: [],
          rules: {}
        },
        {
          value: '', //2
          name: 'agency',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //3
          name: 'number',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //4
          name: 'name',
          placeholder: 'Ex: CC - Bradesco - 1002011-8',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'status_type',
          type: 'text',
          options: [
            { label: 'Ativo', value: 'ATIVO' },
            { label: 'Inativo', value: 'INATIVO' },
          ],
          errors: [],
          rules: {}
        },
        {
          value: '', //6
          name: 'balance',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getAccount(id);
    this.setState({ id: id })
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const account = this.state;

    let fields = this.submitForm(e, account.fields);
    this.setState({ fields: fields });

    if (account.valid && account.errors.length === 0) {
      account.id ? this.update() : this.save();
    }
    return false
  }

  getAccount = async id => {
    await api.get(`accounts/${id}`)
      .then(result => {
        const account = result.data;
        const fields = this.state.fields;

        fields[0].value = account.account_type;
        fields[1].value = account.bank_id;
        fields[2].value = account.agency;
        fields[3].value = account.number;
        fields[4].value = account.name;
        fields[5].value = account.status_type;
        fields[6].value = account.balance;

        this.setState({
          id: account.id,
          account_type: account.account_type || '',
          bank_id: account.bank_id || '',
          agency: account.agency || '',
          number: account.number || '',
          name: account.name || '',
          status_type: account.status_type || '',
          balance: account.balance ? formatPrice(account.balance) : 0,
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Atividades', url: '/accounts' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/accounts')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const account = this.state;
    const fields = this.state.fields;

    fields[0].value = account.account_type;
    fields[1].value = account.bank_id;
    fields[2].value = account.agency;
    fields[3].value = account.number;
    fields[4].value = account.name;
    fields[5].value = account.status_type;
    fields[6].value = account.balance;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const account = this.state;

    await api.post('accounts', account)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/accounts')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const account = this.state;

    await api.put(`accounts/${account.id}`, account)
      .then(result => {
        toastSuccess(`Registro de ${account.name} atualizado!`);
        this.props.history.push('/accounts')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  render() {
    const account = this.state;

    return (
      <Widget title={account.id ? `Editar ${account.name}` : 'Nova Conta'}>
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row">
                <div className="col">
                  <RadioButton
                    label="* Status"
                    value={account.status_type}
                    options={account.fields[5].options}
                    onChange={event => this.setState({ status_type: event })}
                    field={account.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[5])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Select
                    label="* Tipo de Conta"
                    value={account.account_type}
                    onChange={this.handleChange}
                    options={account.fields[0].options}
                    field={account.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[0])}
                  />
                </div>

                <div className="col">
                  <Select
                    label="Banco"
                    value={account.bank_id}
                    options={account.fields[1].options}
                    onChange={this.handleChange}
                    field={account.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[1])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <InputText
                    label="Agência"
                    value={account.agency}
                    onChange={this.handleChange}
                    field={account.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[2])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="Número"
                    value={account.number}
                    onChange={this.handleChange}
                    field={account.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[3])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <InputText
                    label="* Nome da Conta"
                    value={account.name}
                    onChange={this.handleChange}
                    field={account.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[4])}
                  />
                </div>

                <div className="col-sm col-md-4">
                  <InputText
                    label="Saldo"
                    value={account.balance}
                    onChange={this.handleChange}
                    field={account.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, account.fields[6])}
                  />
                </div>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!account.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/accounts">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;