import React from 'react'
import '../../css/components/input/input.css'

const Select = ({ field, validate, label, value, options, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <div className="input-group">
        <select
          placeholder={field.placeholder || ''}
          type={field.type}
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
          className={
            hasErrors
              ? 'form-control custom-select is-invalid'
              : 'form-control custom-select'
          }
          name={field.name}
          onClick={e => validate(e, field)}
          onSelect={e => validate(e, field)}
          onBlur={e => validate(e, field)}>
          {options.map((option, i) => (
            <option key={i} value={option.value} disabled={option.disabled || false}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default Select