import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Navbar1 from './template/navbar/navbar-1';
import Navbar2 from './template/navbar/navbar-2';
import LeftSidebar from './template/left-sidebar';
import RightSidebar from './template/right-sidebar';
import Backdrops from './template/backdrops';
import TopNavigation1 from './template/top-navigation-1';
import Breadcrumb from './template/breadcrumb';
import GlobalStyle from './styles/GlobalStyles';
import AuthProvider from './actions/auth-context';
import Footer from './template/footer';
import ScrollToTopButton from './components/scroll-to-top-button';

import Routes from './Routes';

import './css/main.css';

class App extends Component {
  render() {
    let {
      layout,
      background,
      navbar,
      topNavigation,
      logo,
      leftSidebar,
      collapsed,
    } = this.props.config;

    return (
      <AuthProvider>
        <BrowserRouter>
          <div
            data-layout={layout}
            data-background={background}
            data-navbar={navbar}
            data-top-navigation={topNavigation}
            data-logo={logo}
            data-left-sidebar={leftSidebar}
            data-collapsed={collapsed}
          >
            <GlobalStyle />
            <ToastContainer autoClose={3500} />
            <Backdrops />
            <RightSidebar />
            <Navbar1 />
            <Navbar2 />
            <TopNavigation1 />
            <ScrollToTopButton />
            <div className="container-fluid">
              <div className="row">
                <LeftSidebar />
                <div className="col main">
                  <Breadcrumb />
                  <Routes />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config,
  };
};
export default connect(mapStateToProps)(App);
