import styled from 'styled-components';

export const Total = styled.div`
  margin-left: -10px;
  display: flex;
  align-items: baseline;

  h6 {
    font-weight: bold;
    font-size: 14px;
  }

  span {
    margin-left: 10px;
    font-size: 18px;
    color: #4D4D4D;
    font-weight: bold;
  }
`;

export const Resume = styled.div`
  p {
    font-weight: bold;
    margin: 0 10px 5px 0px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .data {
      display: flex;
      align-items: baseline;

      p {
        font-weight: bold;
        margin-right: 10px;
        font-size: 14px;
        padding-bottom: 0;
      }
    
      span {
        font-weight: bold;
        font-size: 18px;
        color: #4D4D4D;
      }
    }
  }

  .document {
    display: flex;
    justify-content: space-between;

    .doc {
      display: flex;
      align-items: baseline;
      margin-right: 23px;

      p {
        margin-left: 20px;
      }
    }
  }
`