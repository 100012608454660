import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import approve from 'approvejs';
import React, { Component } from 'react';
import {
  formatAddressType,
  formatPhoneType,
} from '../../actions/enum-formatter';
import { handleError } from '../../actions/handle-error';
import { stringToDate } from '../../actions/util';
import { toastError, toastSuccess } from '../../actions/toast';
import api from '../../actions/api';
import DateTime from '../../components/date-time';
import InputMask from '../../components/input-mask';
import InputText from '../../components/input-text';
import RadioButton from '../../components/radio-button';
import Select from '../../components/select';
import Widget from '../../components/widget';
import './style.css';
import { fetchImage } from '../../actions/aws.service';
import { save, update } from '../../actions/pages/person.service';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.removeItemDelete = this.removeItemDelete.bind(this);
    this.setProvider = this.setProvider.bind(this);
    this.handleClient = this.handleClient.bind(this);
    this.handleEmployee = this.handleEmployee.bind(this);

    this.state = {
      errors: [],
      id: '',
      person_type: 'FISICA',
      name: '',
      cpf_cnpj: '',
      rg_ie: '',
      birth_date: '',
      rg_issue_date: '',
      rg_emitter: '',
      gender_type: '',
      marital_status_type: '',
      thumbnail: '',
      addresses: [],
      phones: [],
      emails: [],
      modal: false,
      delete_item_id: '',
      delete_item_name: '',
      delete_url: '',
      employee_id: null,
      client_id: null,
      provider_id: null,
      fields: [
        {
          value: '', // 0
          name: 'person_type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Física', value: 'FISICA' },
            { label: 'Jurídica', value: 'JURIDICA' },
          ],
          rules: {},
        },
        {
          value: '', // 1
          name: 'gender_type',
          type: 'text',
          options: [
            { label: 'Masculino', value: 'MASCULINO' },
            { label: 'Feminino', value: 'FEMININO' },
          ],
          errors: [],
          rules: {},
        },

        {
          value: '', // 2
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório',
            },
          },
        },
        {
          value: '', // 3
          name: 'cpf_cnpj',
          type: 'text',
          errors: [],
          rules: {
            // validCpfCnj: true,
          },
        },
        {
          value: '', // 4
          name: 'rg_ie',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 5
          name: 'birth_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 6
          name: 'rg_issue_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 7
          name: 'rg_emitter',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 8
          name: 'marital_status_type',
          type: 'text',
          options: [
            { label: '', value: '' },
            { label: 'Casado', value: 'CASADO' },
            { label: 'Solteiro', value: 'SOLTEIRO' },
            { label: 'Divorciado', value: 'DIVORCIADO' },
            { label: 'Desquitado', value: 'DESQUITADO' },
            { label: 'União Estável', value: 'UNIAO_ESTAVEL' },
          ],
          errors: [],
          rules: {},
        },
      ],
      activeTab: 0,
      valid: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { path } = this.props.match;
    this.setState({ id });

    if (id) this.getPerson(id);
    if (path.includes('person')) this.setState({ person_type: 'FISICA' });
    if (path.includes('business')) this.setState({ person_type: 'JURIDICA' });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    const result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      }
      return item;
    });
    return fields;
  }

  validate(e, field) {
    const fields = this.validateField(e, field, this.state.fields);
    const errors = [];
    let valid = false;

    fields.map(field => {
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields,
      errors,
      valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit = async e => {
    e.preventDefault();
    const person = this.state;

    const fields = this.submitForm(e, person.fields);
    this.setState({ fields });

    if (person.valid && person.errors.length === 0) {
      let response;

      person.id
        ? (response = await update(person))
        : (response = await save(person));

      if (response) {
        this.props.history.push(`/people/${response.id}`);
        this.getPerson(response.id);
      }
    }
    return false;
  };

  getPerson = async id => {
    await api
      .get(`people/${id}`)
      .then(result => {
        const person = result.data;
        const { fields } = this.state;

        fields[0].value = person.person_type;
        fields[1].value = person.gender_type;
        fields[2].value = person.name;
        fields[3].value = person.cpf_cnpj;
        fields[4].value = person.rg_ie;
        fields[5].value = person.birth_date;
        fields[6].value = person.rg_issue_date;
        fields[7].value = person.rg_emitter;
        fields[8].value = person.marital_status_type;

        this.setState({
          id: person.id,
          person_type: person.person_type,
          name: person.name,
          cpf_cnpj: person.cpf_cnpj || '',
          rg_ie: person.rg_ie || '',
          birth_date: person.birth_date ? stringToDate(person.birth_date) : '',
          rg_issue_date: person.rg_issue_date
            ? stringToDate(person.rg_issue_date)
            : '',
          rg_emitter: person.rg_emitter || '',
          gender_type: person.gender_type || '',
          marital_status_type: person.marital_status_type || '',
          thumbnail: person.thumbnail || '',
          addresses: person.addresses || [],
          phones: person.phones || [],
          emails: person.emails || [],
          employee_id: person.employee_id,
          client_id: person.client_id,
          provider_id: person.provider_id,
          fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Pessoas', url: '/people' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/people');
        toastError(handleError(error));
      });
  };

  isFisicPerson = () => this.state.person_type === 'FISICA';

  onConfirmDelete = (delete_url, item) => {
    let delete_name = '';

    if (delete_url === 'addresses') delete_name = item.street;
    if (delete_url === 'phones') delete_name = item.number;
    if (delete_url === 'emails') delete_name = item.email;

    this.setState({
      modal: true,
      delete_item_name: delete_name,
      delete_item_id: item.id,
      delete_url,
    });
  };

  async onDeleteItem() {
    const person = this.state;

    await api
      .delete(`${person.delete_url}/${person.delete_item_id}`)
      .then(() => {
        toastSuccess(
          `Registro ${person.delete_item_name} removido com sucesso!`
        );
        this.removeItemDelete();
        this.getPerson(person.id);
      })
      .catch(error => {
        toastError(handleError(error));
      });
  }

  removeItemDelete = () => {
    this.setState({
      modal: false,
      delete_item_id: null,
      delete_item_name: null,
      delete_url: null,
    });
  };

  setProvider = async () => {
    const { id, provider_id } = this.state;
    if (provider_id) {
      await api
        .delete(`providers/${provider_id}`)
        .then(() => {
          toastSuccess('Fornecedor removido!');
          this.getPerson(id);
        })
        .catch(error => {
          toastError(handleError(error));
        });
    } else {
      await api
        .post('providers', { person_id: id })
        .then(() => {
          toastSuccess('Novo fornecedor incluído!');
          this.getPerson(id);
        })
        .catch(error => {
          toastError(handleError(error));
        });
    }
  };

  handleEmployee = () => {
    const { id, employee_id } = this.state;
    if (employee_id) {
      this.props.history.push(`/employees/${employee_id}`);
    } else {
      this.props.history.push(`/employees/new/${id}`);
    }
  };

  handleClient = async () => {
    const { id, client_id } = this.state;

    if (client_id) {
      await api
        .delete(`clients/${client_id}`)
        .then(() => {
          toastSuccess('Cliente removido!');
          this.getPerson(id);
        })
        .catch(error => {
          toastError(handleError(error));
        });
    } else {
      await api
        .post('clients', { person_id: id })
        .then(result => {
          toastSuccess('Novo cliente incluído!');
          this.getPerson(id);
        })
        .catch(error => {
          toastError(handleError(error));
        });
    }
  };

  render() {
    const person = this.state;

    return (
      <Widget
        title={
          person.id
            ? `Editar ${person.name}`
            : this.isFisicPerson()
            ? 'Nova Pessoa'
            : 'Nova Empresa'
        }
      >
        <div className="row">
          {person.id && (
            <div className="col-md-2  d-sm-block d-md-none">
              <form>
                <div className="row">
                  <div className="col">
                    <img
                      className="img-fluid img-thumbnail"
                      width="100%"
                      src={
                        fetchImage(person.thumbnail, 'people') ||
                        '../../../assets/images/profile.jpeg'
                      }
                      alt="Foto"
                    />
                  </div>
                </div>
              </form>

              <div className=" mt-2">
                <Link to={`/people/${person.id}/photo`}>
                  <button className="btn btn-success btn-block text-white">
                    Alterar Foto
                  </button>
                </Link>
              </div>
            </div>
          )}

          <div className="col-sm-12 col-md-10 p-0">
            <form onSubmit={this.submit}>
              <div className="row	col mb-2 align-items-end">
                <div className="col-sm-12 col-md-5">
                  <RadioButton
                    label="* Tipo Pessoa"
                    value={person.person_type}
                    options={person.fields[0].options}
                    onChange={event => this.setState({ person_type: event })}
                    field={person.fields[0]}
                    validate={(e, field) => this.validate(e, person.fields[0])}
                  />
                </div>

                {this.isFisicPerson() && (
                  <div className="col-sm-12 col-md-5">
                    <RadioButton
                      label="Sexo"
                      value={person.gender_type}
                      options={person.fields[1].options}
                      onChange={event => this.setState({ gender_type: event })}
                      field={person.fields[1]}
                      validate={(e, field) =>
                        this.validate(e, person.fields[1])
                      }
                    />
                  </div>
                )}
              </div>

              <div className="row ml-1 mr-1">
                <div className="col-sm-12 col-md-6">
                  <InputText
                    label="* Nome"
                    value={person.name}
                    onChange={event =>
                      this.setState({ name: event.target.value })
                    }
                    field={person.fields[2]}
                    validate={(e, field) => this.validate(e, person.fields[2])}
                  />
                </div>

                <div className="col-sm-12 col-md-4">
                  <InputMask
                    mask={
                      person.person_type === 'FISICA'
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                    label={person.person_type === 'FISICA' ? 'CPF' : 'CNPJ'}
                    value={person.cpf_cnpj}
                    onChange={event =>
                      this.setState({ cpf_cnpj: event.target.value })
                    }
                    field={person.fields[3]}
                    validate={(e, field) => this.validate(e, person.fields[3])}
                  />
                </div>

                <div className="col-sm-12 col-md-2">
                  <InputText
                    label={
                      person.person_type === 'FISICA'
                        ? 'RG'
                        : 'Inscrição Estadual'
                    }
                    value={person.rg_ie}
                    onChange={event =>
                      this.setState({ rg_ie: event.target.value })
                    }
                    field={person.fields[4]}
                    validate={(e, field) => this.validate(e, person.fields[4])}
                  />
                </div>
              </div>

              {this.isFisicPerson() && person.id && (
                <>
                  <div className="row m-1">
                    <div className="col-sm-12 col-md-3">
                      <DateTime
                        label="Data de Nascimento"
                        value={person.birth_date}
                        onChange={date => this.setState({ birth_date: date })}
                        field={person.fields[5]}
                        validate={(e, field) =>
                          this.validate(e, person.fields[5])
                        }
                      />
                    </div>

                    <div className="col-sm-12 col-md-3">
                      <DateTime
                        label="Data Expedição RG"
                        value={person.rg_issue_date}
                        onChange={date =>
                          this.setState({ rg_issue_date: date })
                        }
                        field={person.fields[6]}
                        validate={(e, field) =>
                          this.validate(e, person.fields[6])
                        }
                      />
                    </div>

                    <div className="col-sm-12 col-md-3">
                      <InputText
                        label="Orgão Emissor RG"
                        value={person.rg_emitter}
                        onChange={event =>
                          this.setState({ rg_emitter: event.target.value })
                        }
                        field={person.fields[7]}
                        validate={(e, field) =>
                          this.validate(e, person.fields[7])
                        }
                      />
                    </div>

                    <div className="col-sm-12 col-md-3">
                      <Select
                        label="Estado Civil"
                        value={person.marital_status_type}
                        options={person.fields[8].options}
                        onChange={event =>
                          this.setState({
                            marital_status_type: event.target.value,
                          })
                        }
                        field={person.fields[8]}
                        validate={(e, field) =>
                          this.validate(e, person.fields[8])
                        }
                      />
                    </div>
                  </div>

                  <div className="row m-1">
                    <div className="col form-group">
                      <div className="row col">
                        <label className="form-control-label mr-2">
                          {person.addresses.length > 0
                            ? 'Endereços'
                            : 'Adicionar Endereço'}
                        </label>
                        <Link to={`/people/${person.id}/address`}>
                          <i className="material-icons text-success btn-list-person">
                            add_box
                          </i>
                        </Link>
                      </div>
                      {person.addresses.length > 0 ? (
                        <ul className="list-group card">
                          {person.addresses.map(address => (
                            <li key={address.id} className="list-group-item">
                              <div className="row">
                                <Link
                                  className="col row"
                                  to={`/addresses/${address.id}`}
                                >
                                  <div className="col-md-1">
                                    <span className="badge badge-info badge-rounded">
                                      {formatAddressType(address.address_type)}
                                    </span>
                                  </div>
                                  <div className="col-md-1 mr-2">
                                    <span className="badge badge-warning badge-rounded">
                                      {address.cep}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <p className="list-group-item-text">
                                      {address.street} {address.number}
                                      {address.complement &&
                                        `, ${address.complement}`}
                                    </p>
                                  </div>
                                </Link>

                                <div className="col flex-40 align-self-center">
                                  <i
                                    className="material-icons text-danger btn-list-person"
                                    onClick={e =>
                                      this.onConfirmDelete('addresses', address)
                                    }
                                  >
                                    delete_forever
                                  </i>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="card p-4" />
                      )}
                    </div>
                  </div>

                  <div className="row m-1">
                    <div className="col-sm-12 col-md-6 form-group">
                      <div className="row col">
                        <label className="form-control-label mr-2">
                          {person.phones.length > 0
                            ? 'Telefones'
                            : 'Adicionar Telefone'}
                        </label>
                        <Link to={`/people/${person.id}/phones`}>
                          <i className="material-icons text-success btn-list-person">
                            add_box
                          </i>
                        </Link>
                      </div>

                      {person.phones.length > 0 ? (
                        <ul className="list-group card">
                          {person.phones.map(phone => (
                            <li key={phone.id} className="list-group-item">
                              <div className="row">
                                <Link
                                  className="col row"
                                  to={`/phones/${phone.id}`}
                                >
                                  <div className="col-md-2 mr-2">
                                    <span className="badge badge-info badge-rounded">
                                      {formatPhoneType(phone.phone_type)}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <p className="list-group-item-text">
                                      {phone.number}{' '}
                                      {phone.extension && (
                                        <span className="badge badge-warning">{`* ${phone.extension}`}</span>
                                      )}
                                    </p>
                                  </div>
                                </Link>

                                <div className="col flex-40 align-self-center">
                                  <i
                                    className="material-icons text-danger btn-list-person"
                                    onClick={e =>
                                      this.onConfirmDelete('phones', phone)
                                    }
                                  >
                                    delete_forever
                                  </i>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="card p-4" />
                      )}
                    </div>

                    <div className="col-sm-12 col-md-6 form-group">
                      <div className="row col">
                        <label className="form-control-label mr-2">
                          {person.emails.length > 0
                            ? 'E-mails'
                            : 'Adicionar E-mail'}
                        </label>
                        <Link to={`/people/${person.id}/emails`}>
                          <i className="material-icons text-success btn-list-person">
                            add_box
                          </i>
                        </Link>
                      </div>
                      {person.emails.length > 0 ? (
                        <ul className="list-group card">
                          {person.emails.map(email => (
                            <li key={email.id} className="list-group-item">
                              <div className="row">
                                <Link
                                  className="col row"
                                  to={`/emails/${email.id}`}
                                >
                                  <div className="col-md-2 mr-2">
                                    <span className="badge badge-info badge-rounded">
                                      {formatAddressType(email.email_type)}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <p className="list-group-item-text">
                                      {email.email}
                                    </p>
                                  </div>
                                </Link>

                                <div className="col flex-40 align-self-center">
                                  <i
                                    className="material-icons text-danger btn-list-person"
                                    onClick={e =>
                                      this.onConfirmDelete('emailS', email)
                                    }
                                  >
                                    delete_forever
                                  </i>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="card p-4" />
                      )}
                    </div>
                  </div>
                </>
              )}

              {person.id && (
                <div className="col ml-1">
                  <div className="col p-0">
                    <label>Perfil</label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={!!person.employee_id}
                      onChange={this.handleEmployee}
                      id="employee"
                    />
                    <label className="custom-control-label" htmlFor="employee">
                      Funcionário
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      onChange={this.setProvider}
                      checked={!!person.provider_id}
                      id="provider"
                    />
                    <label className="custom-control-label" htmlFor="provider">
                      Fornecedor
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      onChange={this.handleClient}
                      checked={!!person.client_id}
                      id="client"
                    />
                    <label className="custom-control-label" htmlFor="client">
                      Cliente
                    </label>
                  </div>
                </div>
              )}

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-3">
                  <Link to="#">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!person.valid}
                      onClick={this.submit}
                    >
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/people">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>

          {person.id && (
            <div className="col-md-2 d-none d-sm-none d-md-block">
              <div className="card shadow">
                <div className="row">
                  <div className="col">
                    <img
                      className="img-fluid img-thumbnail"
                      width="100%"
                      src={
                        fetchImage(person.thumbnail, 'people') ||
                        '../../../assets/images/profile.jpeg'
                      }
                      alt="Foto"
                    />
                  </div>
                </div>
              </div>

              <div className=" mt-2">
                <Link to={`/people/${person.id}/photo`}>
                  <button className="btn btn-success btn-block text-white">
                    Alterar Foto
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={person.modal} wrapClassName="modal-info" size="default">
          <ModalHeader>Deletar Registro</ModalHeader>
          <ModalBody>Deseja excluir {this.state.delete_item_name}?</ModalBody>
          <ModalFooter>
            <button className="btn btn-info" onClick={this.onDeleteItem}>
              Sim
            </button>
            <button className="btn btn-warning" onClick={this.removeItemDelete}>
              Cancelar
            </button>
          </ModalFooter>
        </Modal>
      </Widget>
    );
  }
}

export default connect()(Index);
