import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'
import { formatSimplePrice } from '../../actions/util';
import { MdMonetizationOn } from 'react-icons/md'
import './style.css'

class Accounts extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      availabilities: '',
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Nova Conta',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'accounts/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Tipo',
        selector: 'account_type_format',
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.status_formated === 'Inativo',
            style: {
              color: '#9E9E9E',
              textDecoration: 'line-through'
            },
          },
        ],
      },
      {
        name: 'Nome',
        selector: 'name',
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.status_formated === 'Inativo',
            style: {
              color: '#9E9E9E',
              textDecoration: 'line-through'
            },
          }
        ],
      },
      {
        name: 'Saldo(R$)',
        selector: 'balance_format',
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.balance_format,
            style: {
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'right',
            },
          },
          {
            when: row => row.balance < 0,
            style: {
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'right',
              color: 'red'
            }
          },
          {
            when: row => row.status_formated === 'Inativo',
            style: {
              color: '#9E9E9E',
              textDecoration: 'line-through',
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'right',
            },
          }
        ],
      },
      {
        name: 'Status',
        selector: 'status_formated',
        center: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.status_formated,
            style: {
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'center',
            },
          },
          {
            when: row => row.status_formated === 'Inativo',
            style: {
              color: '#9E9E9E',
              textDecoration: 'line-through',
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'center',
            },
          }
        ],
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  componentDidMount() {
    this.getAccounts();
  }

  formatStatus = status => {
    if (status === 'LOCAL') return 'Local';
    if (status === 'CORRENTE') return 'Corrente';
    if (status === 'CARTAO') return 'Cartão';
    if (status === 'POUPANCA') return 'Poupança';
    if (status === 'INVESTIMENTO') return 'Investimento';
  }

  getAccounts = async () => {
    let accounts = []
    let availabilities = 0

    this.setState({ loading: true });

    await api.get('accounts')
      .then(result => {
        accounts = result.data.accounts.map(result => ({
          ...result,
          balance_format: formatSimplePrice(result.balance),
          status_formated: result.status_type === 'ATIVO' ? 'Ativo' : 'Inativo',
          account_type_format: this.formatStatus(result.account_type)
        }))

        availabilities = formatSimplePrice(result.data.availabilities)

      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: accounts,
      totalItems: accounts.length,
      availabilities: availabilities,
      loading: false,
    });
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`accounts/${itemDeleteId}`)
      .then(() => {
        this.getAccounts();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/accounts/${reg.id}`)
  };

  render() {
    return (
      <Widget title="Contas" actions={this.state.actions} line={false}>
        <div className="row">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>

          <div className="col-12 mt-4">
            <div className="col-md-4 align-items-center d-flex bg-account p-0 text-white">
              <div className="col-4 justify-content-center align-items-center">
                <MdMonetizationOn size={50} className="card-img-top mt-2" />
              </div>
              <div className={this.state.availabilities.includes('-') ? 'bg-danger col' : 'bg-success col'}>
                <div className="card-body text-center">
                  <h6 className="card-text p-0 m-0"><b>Disponibilidade</b></h6>
                </div>
                <div className="card-body text-center pt-0">
                  <h4 className="card-text">{this.state.availabilities}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir {this.state.itemDeleteName}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget >
    )
  }
}

export default Accounts