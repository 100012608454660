import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { dateToString } from '../../actions/util'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'

class HarvestPackings extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      page: 1,
      perPage: 10,
      initial_date: '',
      end_date: '',
      tillage_id: '',
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Novo Romaneio Colheita',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-4',
          route: 'harvest-packings/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' }

    this.columns = [
      {
        name: 'Data',
        selector: 'date_format'
      },
      {
        name: 'Equipe',
        selector: 'team.name',
        sortable: true,
      },
      {
        name: 'Qtde Equipe',
        selector: 'quantity',
        sortable: true,
        center: true
      },
      {
        name: 'Qtde Diarista',
        selector: 'quantity_diarist',
        sortable: true,
        center: true
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  async componentDidMount() {
    await this.getHarvestParckings();
  }

  getHarvestParckings = async () => {
    let harvestPackings = [];
    this.setState({ loading: true });

    const params = this.setParams()

    await api.get(`harvest-packings`, params)
      .then(result => {
        harvestPackings = result.data

        harvestPackings.data = result.data.data.map(harvest => ({
          ...harvest,
          date_format: dateToString(harvest.date)
        }))
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: harvestPackings.data,
      totalRows: parseInt(harvestPackings.total),
      loading: false
    });
  }

  setParams = () => {
    const { initial_date, end_date, tillage_id, page, perPage } = this.state

    let params_ = {}

    if (initial_date) params_.initial_date = initial_date

    if (end_date) params_.end_date = end_date

    if (tillage_id) params_.tillage_id = tillage_id

    if (page) params_.page = page

    if (perPage) params_.perPage = perPage

    return { params: params_ }

  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.team.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`harvest-packings/${itemDeleteId}`)
      .then(() => {
        this.getHarvestParckings();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/harvest-packings/${reg.id}`)
  }

  handlePageChange = async page => {
    await this.setState({ page: page })

    this.getHarvestParckings()
  }

  handlePerRowsChange = async (perPage,) => {
    await this.setState({ perPage, })

    this.getHarvestParckings()
  }

  render() {
    return (
      <Widget title="Romaneio de Colheita" actions={this.state.actions} line={false}>
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
              // overflowYOffset="500px"
              pagination
              paginationServer
              paginationTotalRows={this.state.totalRows}
              paginationComponentOptions={this.paginationOptions}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir romaneio da {this.state.itemDeleteName}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default HarvestPackings