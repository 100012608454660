import React from 'react'

const LayoutSection = ({ title, layout, config, onClick }) => (
  <ul className="list-group unbordered unstriped">
    <li className="list-group-item list-group-item-layout" 
      onClick={() => {
        onClick(config, layout)

        if (layout === 'top-navigation-1') {
          onClick('navbar', 'info')
        } else {
          onClick('navbar', 'light')
        }
      }}>
      {title}
    </li>
  </ul>
)

export default LayoutSection
