import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import styled from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import InputLabel from '../../../components/unform/input-label';
import { forgotPasswordApi } from '../../../actions/pages/user.service';

const ForgotPassword = () => {
  const formRef = useRef(null);

  const [success, setSuccess] = useState(false);

  const submit = useCallback(async data => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await forgotPasswordApi(data);

      if (response) setSuccess(true);
    } catch (error) {
      const errorMessages = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm col-md-5 form shadow m-0 px-3">
            <div className="col-sm col-md-12 logo text-center mt-4">
              <Link to="/">
                <img
                  src="../../assets/images/logo.svg"
                  alt="logo nossa safra"
                />
              </Link>

              <h6>GESTÃO AGRÍCOLA DE QUALIDADE</h6>
            </div>

            {!success && (
              <div className="px-3">
                <Form ref={formRef} onSubmit={submit} noValidate>
                  <span className="text-center">Resetar Senha</span>

                  <InputLabel name="email" label="Insira seu e-mail" />

                  <div className="row justify-content-center">
                    <div className="col-sm col-md-8">
                      <button
                        type="submit"
                        className="btn btn-block btn-success mt-5 mb-4 btn-lg"
                      >
                        ENVIAR
                      </button>
                    </div>
                  </div>
                  <br />
                </Form>
              </div>
            )}

            {success && (
              <div className="col-12  mt-5 mb-5">
                <h2 className="text-center mb-4">E-mail enviado!</h2>

                <p>
                  Uma mensagem foi enviada a sua caixa de entrada com os passos
                  para a troca de senha.
                </p>

                <b className="text-center">Verifique seu e-mail.</b>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;

export const Container = styled.div`
  background-image: url('../../assets/images/background2.svg');
  height: 100vh;
  width: 110%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 10%;
  margin-left: -10px;

  .form {
    background-color: #fff;
    border-radius: 5px;

    span {
      font-size: 16px;
      font-weight: 600;
      display: block;
      margin-top: 30px;
    }
  }
`;
