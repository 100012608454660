import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'
import Widget from '../../../components/widget'
import InputText from '../../../components/input-text'
import Select from '../../../components/select'
import InputCurrency from '../../../components/input-mask-currency'
import DateTime from '../../../components/date-time'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { MdMonetizationOn } from 'react-icons/md'
import { GiWeight } from 'react-icons/gi'
import { FaBoxes, FaWeightHanging } from 'react-icons/fa'
import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { stringToDate, currencyToString, formatPrice, formatWeight, wheigthToFloat } from '../../../actions/util'

import './style.css'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCargoPackingQualities = this.handleCargoPackingQualities.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);

    this.state = {
      errors: [],
      id: '',
      crop_id: '',
      date: new Date(),
      crops: [],
      document: '',
      cargo_weight: '',
      receipt_forecast: '',
      pay_date: null,
      cargo_packing_balance: '',
      cargo_packing_qualities: [],
      quantity_total: '',
      average_price: '',
      weight_by_unit: '',
      qualities: [],
      add_quality: false,
      // edit_quality: false,
      quality_id: '',
      quality_quantity: '',
      quality_price: '',
      canAddQuality: false,
      avaiable_qualities: [],
      modal: false,
      quality_delete_name: '',
      quality_delete: '',
      unit_type: '',
      net_weight_by_unit: '',
      net_weight_total: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'crop_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'document',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'cargo_weight',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //4
          name: 'receipt_forecast',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'pay_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //6
          name: 'quality_name',
          type: 'text',
          options: [],
          errors: [],
          rules: {}
        },
        {
          value: '', //7
          name: 'quantity',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //8
          name: 'unit_price',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //9
          name: 'quality_balance',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getCargoPacking(id)

    this.getCrops()
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const cargoPacking = this.state;

    let fields = this.submitForm(e, cargoPacking.fields);
    this.setState({ fields: fields });

    if (cargoPacking.valid && cargoPacking.errors.length === 0) {
      this.update();
    }
    return false
  }

  getCrops = async () => {
    await api.get('crops').then(result => {
      const crops = result.data
      const options = [{ label: '', value: null }];

      crops.map(crop => {
        const p = {
          label: crop.name,
          value: crop.id
        }
        options.push(p)
        return null;
      })
      const { fields } = this.state;

      fields[1].options = options;
      this.setState({
        fields: fields
      })
    })
  }

  getCargoPacking = async id => {
    await api.get(`cargo-packings/${id}`)
      .then(result => {
        const cargoPacking = result.data
        const fields = this.state.fields

        fields[0].value = cargoPacking.date
        fields[1].value = cargoPacking.crop_id
        fields[2].value = cargoPacking.document
        fields[3].value = cargoPacking.cargo_weight
        fields[4].value = cargoPacking.receipt_forecast
        fields[5].value = cargoPacking.pay_date;

        this.getAvaiablesQualities(cargoPacking)

        this.setState({
          id: cargoPacking.id,
          date: stringToDate(cargoPacking.date) || new Date(),
          crop_id: cargoPacking.crop_id || '',
          document: cargoPacking.document || '',
          receipt_forecast: new Date(cargoPacking.receipt_forecast),
          quantity: cargoPacking.quantity || '',
          cargo_weight: formatWeight(cargoPacking.cargo_weight) || '',
          pay_date: cargoPacking.pay_date ? stringToDate(cargoPacking.pay_date) : '',
          cargo_packing_qualities: cargoPacking.cargo_packing_qualities || [],
          cargo_packing_balance: formatPrice(cargoPacking.cargo_packing_balance) || '',
          quantity_total: cargoPacking.quantity_total || '',
          average_price: formatPrice(cargoPacking.average_price) || '',
          weight_by_unit: formatWeight(cargoPacking.weight_by_unit) || '',
          net_weight_by_unit: formatWeight(cargoPacking.net_weight_by_unit) || '',
          net_weight_total: formatWeight(cargoPacking.net_weight_total) || '',
          unit_type: cargoPacking.crop.unit_type || '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Romaneio de Cargas', url: '/cargo-packings' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/cargo-packings')
        toastError(handleError(error))
      })
  }

  getAvaiablesQualities = async cargoPacking => {
    await api.get('crops').then(result => {
      const crops = result.data

      for (let i = 0; i < crops.length; i++) {
        const crop = crops[i];
        if (crop.id === cargoPacking.crop_id) {
          const qualities_ = crop.cultivation.qualities
          const cargo_packing_qualities_ = cargoPacking.cargo_packing_qualities.map(item => item.quality)
          let avaiables_qualities_ = []

          for (let i = 0; i < qualities_.length; i++) {
            const quality_ = qualities_[i];
            const cargo_quality_ = cargo_packing_qualities_[i];

            if (!cargo_quality_) {
              const item = {
                quality_id: quality_.id,
                quality_name: quality_.name,
                quantity: '',
                unit_price: ''
              }
              avaiables_qualities_.push(item)
            }
          }

          this.setState({ avaiable_qualities: avaiables_qualities_ })
        }
      }
    })

  }

  handleChange(event) {
    const name = event.target.name
    const cargoPacking = this.state
    const fields = this.state.fields

    fields[0].value = cargoPacking.date
    fields[1].value = cargoPacking.crop_id
    fields[2].value = cargoPacking.document
    fields[3].value = cargoPacking.cargo_weight
    fields[4].value = cargoPacking.receipt_forecast
    fields[5].value = cargoPacking.pay_date;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  update = async () => {
    const cargoPacking = this.prepareCargoPackingToSave()

    await api.put(`cargo-packings/${cargoPacking.id}`, cargoPacking)
      .then(result => {
        toastSuccess(`Registro de ${cargoPacking.name} atualizado!`)
        // this.props.history.push('/cargo-packings')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  prepareCargoPackingToSave = () => {
    const { id, avaiable_qualities, cargo_packing_qualities, date, pay_date, document, crop_id, cargo_weight, receipt_forecast, add_quality } = this.state

    const cargo_weight_ = wheigthToFloat(cargo_weight)

    let cargo_packing_qualities_ = cargo_packing_qualities.map(item => {
      const obj = {
        quality_id: item.quality_id,
        quantity: parseInt(item.quantity),
        unit_price: typeof(item.unit_price) === 'string' ? currencyToString(item.unit_price) : item.unit_price
      }


      return obj
    })

    if (add_quality) {
      for (let i = 0; i < avaiable_qualities.length; i++) {
        const item = avaiable_qualities[i];
        if (item.quantity && item.unit_price) {
          const obj = {
            quality_id: item.quality_id,
            quantity: parseInt(item.quantity),
            unit_price: typeof(item.unit_price) === 'string' ? currencyToString(item.unit_price) : item.unit_price
          }
          cargo_packing_qualities_.push(obj)
        }
      }
    }

    const cargoPacking = {
      id: id,
      crop_id: crop_id,
      date: date,
      pay_date: pay_date,
      document: document,
      cargo_weight: cargo_weight_,
      receipt_forecast: receipt_forecast,
      cargo_packing_qualities: cargo_packing_qualities_
    }

    return cargoPacking
  }

  handleAddQuality = async () => {
    await this.setState({ add_quality: true })
  }

  handleCargoPackingQualities(event, quality) {
    const { cargo_packing_qualities } = this.state

    for (let i = 0; i < cargo_packing_qualities.length; i++) {
      const cargo = cargo_packing_qualities[i];
      if (cargo.quality_id === quality.quality_id) {

        if (event.target.name === 'quantity') {
          cargo.quantity = event.target.value
        }
        if (event.target.name === 'unit_price') {
          cargo.unit_price = event.target.value
        }
      }
    }

    this.setState({ cargo_packing_qualities: cargo_packing_qualities })
  }

  handleCargoPackingQualitiesAdd(event, quality) {
    const { avaiable_qualities } = this.state

    for (let i = 0; i < avaiable_qualities.length; i++) {
      const cargo = avaiable_qualities[i];

      if (cargo.quality_id === quality.quality_id) {

        if (event.target.name === 'quantity') {
          cargo.quantity = event.target.value
        }
        if (event.target.name === 'unit_price') {
          cargo.unit_price = event.target.value
        }
      }
    }

    this.setState({ avaiable_qualities: avaiable_qualities })
  }

  onConfirmDelete = async quality => {
    await this.setState({
      modal: true,
      quality_delete_name: quality.quality.name,
      quality_delete: quality
    })
  }

  onDeleteItem = async () => {
    const { quality_delete, cargo_packing_qualities } = this.state

    const cargo_packing_qualities_ = cargo_packing_qualities.filter(item => {
      return item !== quality_delete
    })

    await this.setState({
      modal: false,
      quality_delete_name: '',
      quality_delete: '',
      cargo_packing_qualities: cargo_packing_qualities_
    })

    this.update()
  }

  render() {
    const cargoPacking = this.state

    return (
      <Widget
        title="Editar Romaneio">
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col col-md-3">
                  <DateTime
                    label="* Data"
                    value={cargoPacking.date}
                    onChange={date => this.setState({ date: date })}
                    field={cargoPacking.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[0])}
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Safra"
                    value={cargoPacking.crop_id}
                    options={cargoPacking.fields[1].options}
                    onChange={this.handleChange}
                    field={cargoPacking.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[1])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <InputText
                    label="* Documento"
                    value={cargoPacking.document}
                    onChange={this.handleChange}
                    field={cargoPacking.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[2])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="* Peso da Carga"
                    value={cargoPacking.cargo_weight}
                    onChange={this.handleChange}
                    field={cargoPacking.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[3])}
                  />
                </div>

                <div className="col">
                  <DateTime
                    label="* Data Prevista de Recebimento"
                    value={cargoPacking.receipt_forecast}
                    onChange={date => this.setState({ receipt_forecast: date })}
                    field={cargoPacking.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[4])}
                  />
                </div>

                <div className="col">
                  <DateTime
                    label="Data Pagamento"
                    value={cargoPacking.pay_date}
                    onChange={date => this.setState({ pay_date: date })}
                    field={cargoPacking.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, cargoPacking.fields[5])}
                  />
                </div>
              </div>

              <div className="col mt-2 mb-3">
                <div className="col row mt-4 ">
                  <h6 className="form-control-h6 mr-2 mt-1"><b>Qualidades</b></h6>
                  {cargoPacking.avaiable_qualities.length > 0 && !cargoPacking.add_quality &&
                    <Link to="#" onClick={e => this.setState({ add_quality: true })}>
                      <i className="material-icons text-success">add_box</i>
                    </Link>
                  }
                  {cargoPacking.add_quality &&
                    <Link to="#" onClick={e => this.setState({ add_quality: false })}>
                      <i className="material-icons text-danger">indeterminate_check_box</i>
                    </Link>
                  }
                </div>

                {cargoPacking.add_quality &&
                  cargoPacking.avaiable_qualities.map((quality, i) => (
                    <div className="row align-items-center" key={i}>
                      <div className="col">
                        <InputText
                          label="* Qualidade"
                          value={quality.quality_name}
                          disabled={true}
                          onChange={(e) => this.handleCargoPackingQualitiesAdd(e, quality)}
                          field={cargoPacking.fields[6]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[6])}
                        />
                      </div>

                      <div className="col">
                        <InputText
                          label="* Quantidade"
                          value={quality.quantity}
                          onChange={e => this.handleCargoPackingQualitiesAdd(e, quality)}
                          field={cargoPacking.fields[7]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[7])}
                        />
                      </div>

                      <div className="col">
                        <InputCurrency
                          label="* Preço"
                          value={quality.unit_price}
                          onChange={e => this.handleCargoPackingQualitiesAdd(e, quality)}
                          field={cargoPacking.fields[8]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[8])}
                        />
                      </div>
                    </div>
                  ))
                }
                {cargoPacking.add_quality &&
                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>
                  </div>
                }

                {
                  cargoPacking.cargo_packing_qualities.map((quality, i) => (
                    <div className="row mt-3 align-items-center" key={i}>
                      <div className="col">
                        <InputText
                          label="Qualidade"
                          value={quality.quality.name}
                          disabled={true}
                          onChange={(e) => this.handleCargoPackingQualities(e, quality)}
                          field={cargoPacking.fields[6]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[6])}
                        />
                      </div>

                      <div className="col">
                        <InputText
                          label="Quantidade"
                          value={quality.quantity}
                          onChange={e => this.handleCargoPackingQualities(e, quality)}
                          field={cargoPacking.fields[7]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[7])}
                        />
                      </div>

                      <div className="col">
                        <InputCurrency
                          label="Preço"
                          value={quality.unit_price}
                          onChange={e => this.handleCargoPackingQualities(e, quality)}
                          field={cargoPacking.fields[8]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[8])}
                        />
                      </div>

                      <div className="col">
                        <InputCurrency
                          label="Valor Total"
                          value={quality.quality_balance}
                          disabled={true}
                          onChange={e => this.handleCargoPackingQualities(e, quality)}
                          field={cargoPacking.fields[9]}
                          validate={(e, field) =>
                            this.validate(e, cargoPacking.fields[9])}
                        />
                      </div>

                      {cargoPacking.cargo_packing_qualities.length > 1 &&
                        <div className="col-1">
                          <Link to="#">
                            <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(quality)}>delete_forever</i>
                          </Link>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>

              <div className="row p-0 m-0 justify-content-end">
                <div className="col col-md-3 mt-2 mb-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2  mt-2 mb-3">
                  <Link to="/cargo-packings">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>

              <div className="d-flex">
                <div className="col-sm-6 col-md-3">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-info col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0 card-title"><b>Quantidade total / {cargoPacking.unit_type}</b></h6>
                      </div>
                      <div className="card-body pt-0 d-flex justify-content-center">
                        <FaBoxes size={24} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.quantity_total}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-warning col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0 card-title"><b>Preço médio / {cargoPacking.unit_type}</b></h6>
                      </div>
                      <div className="card-body pt-0 d-flex justify-content-center">
                        <MdMonetizationOn size={24} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.average_price}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-secondary col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0"><b>Peso médio / {cargoPacking.unit_type}</b></h6>
                      </div>
                      <div className="card-body pt-0 d-flex justify-content-center">
                        <FaWeightHanging size={20} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.weight_by_unit} Kg</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-secondary col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0"><b>Peso médio líquido/ {cargoPacking.unit_type}</b></h6>
                      </div>
                      <div className="card-body pt-0 d-flex justify-content-center">
                        <FaWeightHanging size={20} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.net_weight_by_unit} Kg</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3">
                <div className="col-sm-6 col-md-4">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-success col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0"><b>Peso total</b></h6>
                      </div>
                      <div className="card-body pt-0 d-flex justify-content-center">
                        <GiWeight size={24} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.cargo_weight} Kg</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-success col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0"><b>Peso total líquido</b></h6>
                      </div>
                      <div className="card-body d-flex justify-content-center pt-0">
                        <GiWeight size={24} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.net_weight_total} Kg</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="col align-items-center d-flex bg-card p-0 text-white">
                    <div className="bg-primary col p-0">
                      <div className="card-body text-center p-3">
                        <h6 className="card-text p-0 m-0"><b>Preço total</b></h6>
                      </div>
                      <div className="card-body d-flex justify-content-center pt-0">
                        <MdMonetizationOn size={24} className="mr-2" />
                        <h4 className="card-text">{cargoPacking.cargo_packing_balance}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir a qualidade {this.state.quality_delete_name} desse romaneio?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, quality_delete_name: '', quality_delete: '' })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget >
    )
  }
}

export default Index;