export function selectedAccounting(
  state = {
    budget_id: '',
    name: '',
    id: '',
    type: ''
  },
  action
) {
  switch (action.type) {
    case 'SET_SELECTED_ACCOUNTING':
      return Object.assign({}, state, {
        ...action.selectedAccounting
      })
    default:
      return state
  }
}

