import api from './api';
import { toastError } from './toast';
import { handleError } from './handle-error';

export const resumeStockByInputType = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`dashboard/stock/used-by-input-type`, { params: params })
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};

export const resumeStockDetailsByInputType = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`dashboard/stock/used-by-input-type/details`, { params: params })
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};
