import React from 'react'
import '../../css/components/input/input.css'

const InputText = ({ field, validate, label, value, disabled, onChange }) => {
  let hasErrors = field.errors && field.errors.length > 0 ? true : false;

  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <input
        autoComplete="new-password"
        placeholder={field.placeholder || ''}
        type={field.type || 'text'}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={hasErrors ? 'form-control input-text is-invalid' : 'form-control input-text'}
        name={field.name || ''}
        onBlur={(e, field) => validate(e, field)}
        onInput={(e, field) => validate(e, field)}
      />
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default InputText