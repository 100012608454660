import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { floatToTimeList } from '../../actions/util'
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'

class Machines extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Nova Máquina',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'machines/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Nome',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Horas de uso',
        selector: 'hours_use_format',
        center: true,
        conditionalCellStyles: [
          {
            when: row => row.hours_use_format,
            style: {
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'right',
            },
          },
        ],
      },
      {
        name: 'Status',
        selector: 'status',
        center: true
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  componentDidMount() {
    this.getMachines();
  }

  getMachines = async () => {
    let machines = [];
    this.setState({ loading: true });

    await api.get('machines')
      .then(result => {
        machines = result.data.map(machine => ({
          ...machine,
          status: machine.sale_date ? 'INATIVO' : 'ATIVO',
          hours_use_format: floatToTimeList(machine.hours_use)
        }))
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: machines,
      totalItems: machines.length,
      loading: false,
    });
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`machines/${itemDeleteId}`)
      .then(() => {
        this.getMachines();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/machines/${reg.id}`)
  };

  render() {
    return (
      <Widget title="Máquinas" actions={this.state.actions} line={false}>
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir {this.state.itemDeleteName}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default Machines