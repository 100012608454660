import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GiCash } from 'react-icons/gi';
import { FiCheckSquare, FiXSquare } from 'react-icons/fi';
import { formatPrice } from '../../../actions/util';

const FinancialMovementsDay = ({ movements }) => {
  return (
    <>
      {movements.data && movements.data.length > 0 && (
        <Container className="text-center">
          <h1>
            <GiCash size={22} /> Despesas a pagar hoje
          </h1>

          <div className="row justify-content-center ">
            <div className="col-sm-8 col-md-6 scrollme">
              <table className="table table-sm  table-hover">
                <thead>
                  <tr>
                    <th className="text-left">DESPESA</th>
                    <th className="text-right">VALOR</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {movements.data.map((reg, i) => (
                    <tr key={i}>
                      <Description className="text-left">
                        <Link to={`financial-movements/out/${reg.id}`}>
                          {reg.description}
                        </Link>
                      </Description>
                      <td className="text-right">
                        <Link to={`financial-movements/out/${reg.id}`}>
                          {formatPrice(reg.value)}
                        </Link>
                      </td>
                      <td className="text-right">
                        <Link to={`financial-movements/out/${reg.id}`}>
                          {reg.pay_date ? (
                            <FiCheckSquare size={15} color="#53883D" />
                          ) : (
                            <FiXSquare size={15} color="#cc0000" />
                          )}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
            </div>

            {/* <div className="col-sm-4 col-md-2">
          <Card>
            <div className="card shadow">
              <div className="card-body text-center p-1">
                <GiCash size={50} color="#cc0000" />
              </div>
              <div className="card-footer text-center p-0">
                <small>Total despesas de hoje</small>
                <h4>{formatPrice(movements.total_value_by_filters)}</h4>
              </div>
            </div>
          </Card>
        </div> */}
          </div>
        </Container>
      )}
    </>
  );
};

export default FinancialMovementsDay;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;

  hr {
    border-top: 1px solid #cc0000 !important;
  }

  h1 {
    color: #cc0000;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .table tr {
    :hover {
      a {
        color: #cc0000;
      }
    }
  }

  .table td {
    font-size: 11px;
    border: 0;
  }

  .table th {
    background-color: #ffe6e6;
    color: #505050;
    border: 0;
  }

  .scrollme {
    max-width: 100%;
    max-height: 110px;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    display: block;
  }

  .scrollme thead th {
    z-index: 10;
  }

  .scrollme tbody td {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

export const Card = styled.div`
  h4 {
    font-size: 16px;
    color: #cc0000;
    font-weight: bold;
  }

  small {
    color: #505050;
  }

  .card-footer {
    background: none !important;
    border: none !important;
  }
`;

export const Description = styled.td`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  text-transform: uppercase;
`;
