import React, { Component } from 'react';
import { GoSearch, GoX } from 'react-icons/go';
import { handleError } from '../../../actions/handle-error';
import { toastError } from '../../../actions/toast';
import api from '../../../actions/api';
import InputDate from '../../../components/date-time';
import Select from '../../../components/select';

class IrrigationsFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getParams = this.getParams.bind(this);
    this.filter = this.filter.bind(this);
    this.cleanFilter = this.cleanFilter.bind(this);

    this.state = {
      valid: false,
      errors: [],
      budget_id: '',
      crop_id: '',
      init_date: '',
      end_date: '',
      fields: [
        {
          value: '', //0
          name: 'budget_id',
          options: [],
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //1
          name: 'crop_id',
          options: [],
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //2
          name: 'init_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //3
          name: 'end_date',
          type: 'text',
          errors: [],
          rules: {},
        },
      ],
    };
  }

  async componentDidMount() {
    this.getBudgets();
  }

  getBudgets = async () => {
    let budgets = [];
    const { fields } = this.state;

    await api
      .get('budgets')
      .then(result => {
        fields[1].options = [];

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name,
        }));

        fields[0].options = budgets;
        fields[0].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getAccountings = async () => {
    let accountings = [];
    const { fields } = this.state;

    let url = '';
    if (this.props.page === 'receivables')
      url = 'accountings?status_type=ATIVO&accounting_type=RECEITA';
    if (this.props.page === 'payables')
      url = 'accountings?status_type=ATIVO&accounting_type=DESPESA';
    if (this.props.page === 'financialMovements')
      url = 'accountings?status_type=ATIVO';

    await api
      .get(url)
      .then(result => {
        fields[2].options = [];

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          const item = {
            value: element.id,
            label: `${element.number} ${element.name}`,
            disabled: true,
          };
          accountings.push(item);

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x];
              const item = {
                value: elem.id,
                label: `${elem.number} ${elem.name}`,
              };
              accountings.push(item);
            }
          }
        }

        fields[2].options = accountings;
        fields[2].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getParams = async () => {
    const state = this.state;
    let params_ = {};

    if (state.page) params_.page = 1;
    if (state.perPage) params_.perPage = state.perPage;
    if (state.crop_id) params_.crop_id = state.crop_id;
    if (state.budget_id) params_.budget_id = state.budget_id;
    if (state.init_date) params_.init_date = state.init_date;
    if (state.end_date) params_.end_date = state.end_date;

    return { params: params_ };
  };

  filter = async e => {
    e.preventDefault();
    await this.setState({ perPage: this.props.total });

    const params = await this.getParams();

    this.props.filter(params);
    this.props.isFilter(true);
  };

  cleanFilter = async e => {
    e.preventDefault();
    await this.props.isFilter(false);

    await this.setState({
      page: 1,
      perPage: 20,
      crop_id: '',
      budget_id: '',
      init_date: '',
      end_date: '',
    });

    const params = await this.getParams();
    this.props.filter(params);
  };

  handleChange(event) {
    const name = event.target.name;
    const irrigations = this.state;
    const fields = this.state.fields;

    fields[0].value = irrigations.budget_id;

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  render() {
    const state = this.state;

    return (
      <>
        <div className="row pt-2">
          <div className="col-sm text-right">
            <a
              className="text-success btn p-0"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Filtrar <GoSearch />
            </a>
          </div>
        </div>

        {/* Init Filter */}
        <div className="col p-1">
          <div className="collapse" id="collapseExample">
            <div className="card card-body">
              <form onSubmit={this.filter}>
                <div className="row">
                  <div className="col-sm">
                    <Select
                      label="Orçamento"
                      value={state.budget_id}
                      options={state.fields[0].options}
                      onChange={this.handleChange}
                      field={state.fields[0]}
                      validate={() => {}}
                    />
                  </div>

                  <div className="col-sm">
                    <Select
                      label="Safra"
                      value={state.crop_id}
                      options={state.fields[1].options}
                      onChange={this.handleChange}
                      field={state.fields[1]}
                      validate={() => {}}
                    />
                  </div>

                  <div className="col col-md-3">
                    <InputDate
                      label="Data inicial:"
                      value={state.init_date}
                      onChange={date => this.setState({ init_date: date })}
                      field={state.fields[2]}
                      validate={() => {}}
                    />
                  </div>

                  <div className="col-sm col-md-3">
                    <InputDate
                      label="Data final:"
                      value={state.end_date}
                      onChange={date => this.setState({ end_date: date })}
                      field={state.fields[3]}
                      validate={() => {}}
                    />
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-sm col-md-2 mb-1">
                    <button
                      className="btn btn-success btn-block"
                      onClick={this.filter}
                    >
                      Filtrar <GoSearch />
                    </button>
                  </div>

                  <div className="col-sm col-md-2 mb-1">
                    <button
                      href="#"
                      className="btn btn-success btn-block"
                      onClick={this.cleanFilter}
                    >
                      Limpar
                    </button>
                  </div>

                  <div className="ml-2 mr-3 mb-1">
                    <a
                      className="text-danger btn"
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={this.cleanFilter}
                    >
                      Fechar <GoX />
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* End Filter */}
      </>
    );
  }
}

IrrigationsFilter.defaultProps = {
  filter: Function,
  isFilter: Boolean,
  total: Number,
};

export default IrrigationsFilter;
