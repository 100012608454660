import { logoff } from './auth'
import { toastError } from './toast';

export const handleError = (error) => {
  const erro = error.response
  console.log(erro);

  if (erro === undefined) {
    // return `Servidor não encontrado! Entre em contato com o suporte! ${process.env.REACT_APP_API_BASE_URL}`
    window.location.href = '/server-error'
    toastError(`Servidor não encontrado! Saia do sistema e faça o login novamente!`)
    return `Servidor não encontrado! Saia do sistema e faça o login novamente!`
  }
  if (erro.status === 400) {

    if (erro.data?.message) {
      toastError(erro.data.message)
    }

    if (erro.data.length > 0) {
      for (let i = 0; i < erro.data.length; i++) {
        const error = erro.data[i];
        toastError(error.message)
      }
    }

    if (erro.data.message) {
      toastError(erro.data.message)
    }
    
    if (erro.data.error?.message) {
      toastError(erro.data.error.message)
    }

    if (erro.data.error?.messages && erro.data.error.messages.length > 0) {
      toastError(erro.data.error.messages[0].message)
    }
  };
  if (error.response.status === 500) {
    toastError(error.response.data.exception.detail.error.message)
  }
  if (erro.status === 401) {
    if (erro.data.error.message) {
      toastError(erro.data.error.message)
      
      if (erro.data.error.message === 'The Jwt token is invalid') {
        logoff();
      }
    } else {
      return 'Erro 401'
    }
  };
  if (erro.status === 404) {
    if(erro.data.exception) {
      toastError(erro.data.exception.message)
    }
    if(erro.data.error) {
      toastError(erro.data.error.message)
    }
  };

  return null
}
