import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  /* padding-right: 10px;
  padding-left: 10px; */
  width: 100%;

  label {
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    color: #4D4D4D;
    margin: 0;
    font-family: Poppins;
    font-size: 12px;
  }

  a {
    :hover {
      color: #dc3545 !important; 
      font-weight: 600;
    }
  }
  
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #4D4D4D;
    margin: 0;
    margin-top: 15px;
    padding: 0;
  }

  input {
    font-size: 14px;
    background: white;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    min-height: 31px;
    padding: 10px;
    color: #4D4D4D;

    :focus {
      border: 1px solid;
      border-color: #999 !important;
      outline: none; 
      box-shadow: 0 0 1px #999;
    }
  }
  
  textarea {
    font-size: 14px;
    background: #F9F9F9;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    min-height: 31px;
    padding: 10px;
    color: #4D4D4D;

    :focus {
      border: 1px solid;
      border-color: #999 !important;
      outline: none; 
      box-shadow: 0 0 1px #999;
    }
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .input-error {
    border: 1px solid red !important;
  }

  /* react-select */
  .react-select__value-container {
    font-size: 14px;
  }

  .react-select__control {
    background: white;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .react-select__single-value {
    font-size: 14px;
    color: #4D4D4D;
  }
  
  /* .react-select__menu {
    max-height: 120px;
  }

  .react-select__menu-list {
    max-height: 120px;
  } */

  
  /* react-select */


  /* react-datepicker */
  .react-datepicker-wrapper {
    input {
      height: 44px;
    }
  }
  /* react-datepicker */
`
