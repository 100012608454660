import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'


import './style.css'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      crop_id: '',
      cultivation_id: '',
      cultivation_name: '',
      name: '',
      description: '',
      area_in_ha: '',
      total_of_plants: '',
      number_of_employees: '',
      thumbnail: '',
      teams: [],
      fields: [
        {
          value: '', //0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'description',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //2
          name: 'area_in_ha',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo área é obrigatório'
            },
          }
        }
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { cropId, cultivationId, id } = this.props.match.params;
    this.setState({ crop_id: cropId });

    if (cultivationId) this.getCultivation(cultivationId);
    if (id) this.getTillage(id);
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const tillage = this.state;

    let fields = this.submitForm(e, tillage.fields);
    this.setState({ fields: fields });

    if (tillage.valid && tillage.errors.length === 0) {
      tillage.id ? this.update() : this.save();
    }
    return false
  }

  getCultivation = async id => {
    await api.get(`cultivations/${id}`)
      .then(result => {
        const cultivation = result.data;
        this.setState({
          cultivation_id: cultivation.id,
          cultivation_name: cultivation.name
        });
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/')
        toastError(handleError(error))
      });
  }

  getTillage = async id => {
    await api.get(`tillages/${id}`)
      .then(result => {
        const tillage = result.data;
        const fields = this.state.fields;

        fields[0].value = tillage.name || '';
        fields[1].value = tillage.description || '';
        fields[2].value = tillage.area_in_ha || '';

        this.setState({
          id: tillage.id || '',
          crop_id: tillage.crop_id || '',
          name: tillage.name || '',
          description: tillage.description || '',
          area_in_ha: tillage.area_in_ha || 0,
          total_of_plants: tillage.total_of_plants || 0,
          number_of_employees: tillage.number_of_employees || 0,
          thumbnail: tillage.thumbnail || '',
          teams: tillage.teams,
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Safras', url: '/' },
            { page: `${this.state.cultivation_name}`, url: `/crops/${tillage.crop_id}` },
            { page: `${tillage.name}`, url: '#' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/tillages')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const tillage = this.state;
    const fields = this.state.fields;

    fields[0].value = tillage.name || '';
    fields[1].value = tillage.description || '';
    fields[2].value = tillage.area_in_ha || '';

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const tillage = this.state;

    await api.post('tillages', tillage)
      .then(result => {
        toastSuccess('Novo registro incluído!');

        this.props.history.push(`/cultivations/${this.state.cultivation_id}/tillages/${result.data.id}`)
        this.getTillage(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const tillage = this.state;

    await api.put(`tillages/${tillage.id}`, tillage)
      .then(result => {
        toastSuccess(`Registro de ${tillage.name} atualizado!`);
        this.getTillage(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await this.readFile(e.target.files[0])

      this.setState({ imageUrl: imageDataUrl })
    }
  }

  readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  render() {
    const tillage = this.state;

    return (
      <Widget title={tillage.id ? `Editar ${tillage.name}` : 'Nova Lavoura'}>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm col-md-12">
                  <InputText
                    label="* Nome"
                    value={tillage.name}
                    onChange={this.handleChange}
                    field={tillage.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, tillage.fields[0])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="Descrição"
                    value={tillage.description}
                    onChange={this.handleChange}
                    field={tillage.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, tillage.fields[1])}
                  />
                </div>

                <div className="col-sm col-md-4">
                  <InputText
                    label="* Área Plantada (hectare)"
                    value={tillage.area_in_ha}
                    onChange={this.handleChange}
                    field={tillage.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, tillage.fields[2])}
                  />
                </div>
              </div>
            </div>

            {/* 
            ******
                Nao esta retornando do rest o campo thumbnail, comentado para vereficar se realmente precisa de uma foto por lavoura visto que ja tem a foto da safra 
            ******
            */}

            {/* {tillage.id &&
              <div className="col-md-2  mt-3">
                <div className="card shadow">
                  <div className="row">
                    <div className="col">
                      <img width="100%" src={fetchImage(tillage.thumbnail, 'tillages') || '../../../assets/images/crop.png'} alt="Foto" />
                    </div>
                  </div>
                </div>

                <div className=" mt-2">
                  <Link to={`/cultivations/${tillage.cultivation_id}/tillages/${tillage.id}/photo`}>
                    <button
                      className="btn btn-success btn-block text-white">
                      Alterar Imagem
                    </button>
                  </Link>
                </div>
              </div>
            } */}
          </div>

          {tillage.id &&
            <>
              <div className="d-flex mt-4">
                <h6 className="form-control-h6 mr-2 mt-1"><b>Equipes</b></h6>
                <Link to={`/cultivations/${tillage.cultivation_id}/tillages/${tillage.id}/teams`}>
                  <i className="material-icons text-primary">add_box</i>
                </Link>
              </div>

              <div className="row">
                {tillage.teams.map((team, i) => (
                  <div key={team.id} className="col-md-4 col-sm mt-2">
                    <Link to={`/cultivations/${tillage.cultivation_id}/tillages/${tillage.id}/teams/${team.id}`}>
                      <div className="col card-team bg-info">
                        <p className="m-0 pt-1">{team.name}</p>

                        <div className="row col p-0 m-0">
                          <div className="col text-center">
                            <span>{team.number_of_plants}</span>
                          </div>

                          <div className="col-3 p-1 mb-2 card-team-func text-center">
                            <p className="m-0 text-dark">Nº func</p>
                            <span>{team.number_of_employees}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="row mt-4">
                <div className="col-sm mt-2">
                  <div className="col-sm card-total text-center">
                    <p>Total de plantas</p>
                    <span>{tillage.total_of_plants}</span>
                  </div>
                </div>

                <div className="col-sm mt-2">
                  <div className="col-sm  card-total text-center">
                    <p>Total funcionários</p>
                    <span>{tillage.number_of_employees}</span>
                  </div>
                </div>
              </div>
            </>
          }

          <div className="row justify-content-end mt-4">
            <div className="col-sm col-md-3 mt-1">
              <Link to="#">
                <button
                  className="btn btn-success btn-block"
                  disabled={!tillage.valid}
                  onClick={this.submit}>
                  {this.state.id ? 'Atualizar' : 'Salvar'}
                </button>
              </Link>
            </div>

            <div className="col-sm col-md-2 mt-1">
              <Link to={`/crops/${tillage.crop_id}`}>
                <button
                  className="btn btn-success btn-block ">
                  Voltar
                </button>
              </Link>
            </div>
          </div>
        </form>
      </Widget >
    )
  }
}

export default Index;