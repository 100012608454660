import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IoMdHome } from 'react-icons/io';

import './breadcrumbs.css';

function Breadcrumb({ items, layout }) {
  const location = useLocation();

  return (
    <>
      {layout === 'login-layout' || layout === 'error-layout' ? null : (
        <>
          {location.pathname !== '/' && (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {items.map((item, i) => (
                  <li className="breadcrumb-item" key={i}>
                    <Link to={item.url}>
                      {item.page === 'Home' ? (
                        <IoMdHome size={18} />
                      ) : (
                        item.page
                      )}
                    </Link>
                  </li>
                ))}
              </ol>
            </nav>
          )}
        </>
      )}
    </>
  );
}

export default compose(
  connect(state => {
    return {
      items: state.breadcrumb,
      layout: state.config.layout,
    };
  }),
  withRouter
)(Breadcrumb);
