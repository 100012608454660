import React, { useCallback } from 'react';
import { IoIosTrash } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import SyncLoader from 'react-spinners/SyncLoader';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const AgriculturalInputsTable = ({
  data,
  page,
  setItemDelete,
  setParams,
  loading,
  setScrolling,
}) => {
  const history = useHistory();

  const changePage = useCallback(() => {
    setScrolling(true);

    setParams((prevState) => ({
      ...prevState,
      page: page.current_page + 1,
    }));
  }, [page, setParams, setScrolling]);

  return (
    <Container>      
      <div className="row">
        <div className="col mt-3">
          <InfiniteScroll
            dataLength={data?.length}
            next={changePage}
            hasMore={page.has_next_page}
            loader={
              <Loader>
                {loading && (
                  <div className="loader">
                    <SyncLoader color="#388e3c" size={15} margin={10} />
                  </div>
                )}
              </Loader>
            }
          >
            <div className="table-responsive">
              <table className="table table-hover text-center">
                <thead className='bg-success'>
                  <tr>
                    <th scope="col" className="text-start">Tipo</th>
                    <th scope="col" className="text-start">Nome</th>
                    <th scope="col" className="text-start">Unidade</th>
                    <th scope="col" className="text-center">Qtde</th>
                    <th scope="col" className="text-start">P. Médio(R$)</th>
                    <th scope="col" className="text-start">V. em estoque(R$)</th>
                    <th scope="col" className="text-end">
                      Ação
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, i) => (
                    <tr
                      key={i}
                      onClick={(e) => {
                        if (e.target.innerText !== undefined) {
                          history.push(`agricultural-inputs/${item.id}`)
                        }
                      }}
                    >
                      <td className="text-start">{item.input_type}</td>
                      <td className="text-start">{item.name}</td>
                      <td className="text-start">{item.unit_type_format.toUpperCase()}</td>
                      <td className="text-center">{item.quantity_format}</td>
                      <td className="text-start">{item.average_price_format}</td>
                      <td className="text-start">{item.balance_format}</td>

                      <td className="text-end action">
                        <IoIosTrash
                          size={25}
                          className="mb-1"
                          data-tip="Excluir"
                          onClick={() =>
                            setItemDelete({
                              id: item.id,
                              description: item.name,
                            })
                          }
                        />
                        <ReactTooltip />
                      </td>
                    </tr>
                  ))}

                  {data.length === 0 && (
                    <tr>
                      <td colSpan={9}>
                        Nenhum registro encontrado!
                      </td>
                    </tr>
                  )}      
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>

      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - {data?.length} de <b>{page?.total}</b>
        </h6>
      </div>
    </Container>
  );
};

AgriculturalInputsTable.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setScrolling: PropTypes.func.isRequired,
};

export default AgriculturalInputsTable;

export const Container = styled.section`
  height: 100vh;
  margin-top: -40px;

  h6 {
    font-size: 12px;
    margin-bottom: -20px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .bg-success {
    /* background-color: #E8E8E8 !important; */
    background-image: linear-gradient(white, #bfbfbf);
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .loader {
    position: absolute;
    z-index: 10;
    margin-top: 200px;
  }
`