
import { Link } from 'react-router-dom'
import React, { Component } from 'react'

import { formatPrice } from '../../../../actions/util'
import { handleError } from '../../../../actions/handle-error'
import { toastError } from '../../../../actions/toast'
import { Total, Resume } from './styles'
import api from '../../../../actions/api'
import Widget from '../../../../components/widget'

class Index extends Component {

  constructor() {
    super();

    this.state = {
      loading: false,
      crop_id: '',
      status: '',
      title: '',
      data: [],
    }
  }

  componentDidMount() {
    const { crop_id, status } = this.props.match.params
    if (crop_id) this.getCargoPacking(crop_id, status)

    status === 'PAGO' ? this.setState({ title: 'PAGO' }) : this.setState({ title: 'A RECEBER' })
  }

  getCargoPacking = async (crop_id, status) => {
    const status_ = status.toUpperCase()
    this.setState({ loading: true })

    await api.get(`dashboard/cargo-packings/resume?crop_id=${crop_id}&pay_date_status=${status_}`)
      .then(result => {
        let total_ = 0

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          total_ += element.total_receipt
        }

        this.setState({
          data: result.data,
          total: total_,
          crop_id: crop_id,
          status: status,
          loading: false
        })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }


  render() {
    const { loading, data, title, total, crop_id, status } = this.state

    return (
      <Widget title={`Romaneios ${title}`}>
        <div className="row">
          <div className="col">
            {!loading &&
              <div className="col-12">
                <div className="row justify-content-between mb-5">
                  <div className="col-sm row m-0 p-0">
                    <Link className="my-1 pl-0 col-sm col-md-2" to={`/dashboard/cargo-packings-pdf/${crop_id}/${status}`}>
                      <button className="btn btn-success btn-block ">
                        Gerar PDF
                      </button>
                    </Link>

                    <Link className="my-1 col-sm col-md-3 pl-0" to={`/dashboard/cargo-packings/report-date-group/${crop_id}/${status}`}>
                      <button className="btn btn-success btn-block">
                        Gerar PDF Relatório Final
                      </button>
                    </Link>
                  </div>


                  {data.length > 10 &&
                    <div className="col col-md-2">
                      <Link to="/">
                        <button className="btn btn-success btn-block">
                          Voltar
                        </button>
                      </Link>
                    </div>
                  }
                </div>

                <div className="row">
                  <Total className="col-12">
                    <h6 >TOTAL {title}:</h6>
                    <span>{formatPrice(total)}</span>
                  </Total>

                  <ul className="list-group col mt-3">
                    {data.map((cargo, i) => (
                      <Resume key={i}>
                        <li className="row col header">
                          <div className="data">
                            <p className="list-group-item">Data recebimento: </p> <span>{cargo.receipt_forecast}</span>
                          </div>
                          <div className="data">
                            <p>Total: </p>
                            <span>{formatPrice(cargo.total_receipt)}</span>
                          </div>
                        </li>

                        <p className="list-group-item">Documentos:</p>
                        {cargo.documents.map((doc, x) => (
                          <div key={x} className="document">
                            <div className="doc">
                              <p>Doc:</p> <span>{doc.document}</span>
                            </div>
                            <div className="doc">
                              <p>Valor:</p> <span>{formatPrice(doc.value)}</span>
                            </div>
                          </div>
                        ))}
                        <hr />
                      </Resume>
                    ))}
                  </ul>

                </div>

                <div className="row col p-0 m-0 justify-content-end mb-2">
                  <div className="col col-md-2 mt-1">
                    <Link to="/" className="mr-3">
                      <button className="btn btn-success btn-block">
                        Voltar
                      </button>
                    </Link>
                  </div>
                </div>

              </div>
            }
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;