import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'
import axios from 'axios'

import Widget from '../../../components/widget'
import Select from '../../../components/select'
import InputText from '../../../components/input-text'
import InputMask from '../../../components/input-mask'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleCep = this.handleCep.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      address_type: '',
      cep: '',
      street: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      state: '',
      person_id: '',
      person_name: '',
      fields: [
        {
          value: '',
          name: 'address_type',
          options: [
            { label: 'Casa', value: 'CASA' },
            { label: 'Trabalho', value: 'TRABALHO' },
            { label: 'Outro', value: 'OUTRO' },
          ],
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '',
          name: 'cep',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo Cep é obrigatório'
            },
          }
        },
        {
          value: '',
          name: 'street',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '',
          name: 'number',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo número é obrigatório'
            },
          }
        },
        {
          value: '',
          name: 'complement',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '',
          name: 'neighborhood',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '',
          name: 'city',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo cidade é obrigatório'
            }
          }
        },
        {
          value: '',
          name: 'state',
          type: 'text',
          options: [
            { label: '', value: '' },
            { label: 'Acre', value: 'AC' },
            { label: 'Alagoas', value: 'AL' },
            { label: 'Amapá', value: 'AP' },
            { label: 'Amazonas', value: 'AM' },
            { label: 'Bahia', value: 'BA' },
            { label: 'Ceará', value: 'CE' },
            { label: 'Distrito Federa;', value: 'DF' },
            { label: 'Goiás', value: 'GO' },
            { label: 'Maranhão', value: 'MA' },
            { label: 'MAto Grosso', value: 'MT' },
            { label: 'Mato Grosso do Sul', value: 'MT' },
            { label: 'Minas Gerais', value: 'MG' },
            { label: 'Pará', value: 'PA' },
            { label: 'Paraíba', value: 'PB' },
            { label: 'Paraná', value: 'PR' },
            { label: 'Pernambuco', value: 'PE' },
            { label: 'Piauí', value: 'PI' },
            { label: 'Rio de Janeiro', value: 'RJ' },
            { label: 'Rio Grande do Norte', value: 'RN' },
            { label: 'Rio Grande do Sul', value: 'RS' },
            { label: 'Rondônia', value: 'RO' },
            { label: 'Roraima', value: 'RR' },
            { label: 'Santa Catarina', value: 'SC' },
            { label: 'São Paulo', value: 'SP' },
            { label: 'Sergipe', value: 'SE' },
            { label: 'Tocantins', value: 'TO' }
          ],
          errors: [],
          rules: {
            required: {
              message: 'Campo estado é obrigatório'
            }
          }
        }
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { personId, id } = this.props.match.params;

    this.setState({
      person_id: personId,
      address_type: 'CASA',
      id: id,
    })

    if (personId) this.getPerson(personId);
    if (id) this.getAddress(id);
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()

    const { valid, errors, id } = this.state;

    let fields = this.submitForm(e, this.state.fields);
    this.setState({ fields: fields });

    if (valid && errors.length === 0) {
      id ? this.update() : this.save();
    }
    return false
  }

  handleCep = async cep => {
    if (!cep.includes('_')) {
      await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
          if (response.data.erro) {
            toastError('Cep inválido ou não encontrado')
          } else {
            this.setState({
              street: response.data.logradouro,
              neighborhood: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
            })
          }
        })
    }
  }

  getPerson = async id => {
    await api.get(`people/${id}`)
      .then(result => {
        const person = result.data;

        this.setState({
          person_id: person.id,
          person_name: person.name
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Pessoas', url: '/people' },
            { page: `${person.name}`, url: `people/${person.id}` },
            { page: 'Endereços', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response.status === 500) this.props.history.push('/people')
        toastError(handleError(error))
      });
  }

  getAddress = async id => {
    await api.get(`addresses/${id}`)
      .then(result => {
        const address = result.data;
        const fields = this.state.fields;

        fields[0].value = address.address_type;
        fields[1].value = address.cep;
        fields[2].value = address.street;
        fields[3].value = address.number;
        fields[4].value = address.complement;
        fields[5].value = address.neighborhood;
        fields[6].value = address.city;
        fields[7].value = address.state;


        this.setState({
          id: address.id,
          person_id: address.person_id,
          address_type: address.address_type || '',
          cep: address.cep || '',
          perfil_type: address.perfil_type || '',
          number: address.number || '',
          street: address.street || '',
          neighborhood: address.neighborhood || '',
          complement: address.complement || '',
          city: address.city || '',
          state: address.state || '',
        })

        this.getPerson(address.person_id);
      }).catch(error => {
        if (error.response.status && error.response.status === 500) this.props.history.push('/people')
        toastError(handleError(error))
      });
  }


  save = async () => {
    const address = this.state;

    await api.post(`people/${address.person_id}/addresses`, address)
      .then(result => {
        toastSuccess('Novo endereço incluído!');

        this.props.history.push(`/people/${address.person_id}`)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const address = this.state;

    await api.put(`addresses/${address.id}`, address).then(() => {
      toastSuccess(`Endereço de ${address.person_name} atualizado!`);
    }).catch(error => {
      toastError(handleError(error));
    })
  }

  handleChange(event) {
    const name = event.target.name;
    const address = this.state;
    const fields = this.state.fields;

    fields[0].value = address.address_type;
    fields[1].value = address.cep;
    fields[2].value = address.street;
    fields[3].value = address.number;
    fields[4].value = address.complement;
    fields[5].value = address.neighborhood;
    fields[6].value = address.city;
    fields[7].value = address.state;

    if (name === 'cep') this.handleCep(event.target.value)

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  render() {
    const address = this.state;

    return (
      <Widget
        title={address.id ? 'Editar Endereço' : 'Novo Endereço'}>
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="col col-md-3">
                <Select
                  label="* Tipo"
                  value={address.address_type}
                  options={address.fields[0].options}
                  onChange={this.handleChange}
                  field={address.fields[0]}
                  validate={(e, field) =>
                    this.validate(e, address.fields[0])}
                />
              </div>

              <div className="row p-0 m-0">
                <div className="col-sm col-md-3">
                  <InputMask
                    label="* Cep"
                    value={address.cep}
                    mask="99999-999"
                    onChange={this.handleChange}
                    field={address.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="* Rua"
                    value={address.street}
                    onChange={this.handleChange}
                    field={address.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[2])}
                  />
                </div>

                <div className="col-sm col-md-2">
                  <InputText
                    label="* Número"
                    value={address.number}
                    onChange={this.handleChange}
                    field={address.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[3])}
                  />
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-sm col-md-3">
                  <InputText
                    label="Complemento"
                    value={address.complement}
                    onChange={this.handleChange}
                    field={address.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[4])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <InputText
                    label="Bairro"
                    value={address.neighborhood}
                    onChange={this.handleChange}
                    field={address.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[5])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <InputText
                    label="* Cidade"
                    value={address.city}
                    onChange={this.handleChange}
                    field={address.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[6])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Estado"
                    value={address.state}
                    options={address.fields[7].options}
                    onChange={this.handleChange}
                    field={address.fields[7]}
                    validate={(e, field) =>
                      this.validate(e, address.fields[7])}
                  />
                </div>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-3">
                  <Link to="#">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!address.valid}
                      onClick={this.submit}>
                      {address.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to={`/people/${address.person_id}`}>
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;