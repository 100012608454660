import React from 'react';
import { useContext } from 'react';
import { BiRegistered } from 'react-icons/bi';
import { VscVersions } from 'react-icons/vsc';
import { GiField } from 'react-icons/gi';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const Footer = () => {
  const { isLoading, tenant, defaultBudget, isAuthenticated } = useContext(
    AuthContext
  );

  return (
    <>
      {!isLoading && (
        <Container auth={isAuthenticated}>
            <div className="row">
              <div className="col-6 col-md-9 pl-5">
                <p>
                  <BiRegistered size={15} />
                  {new Date().getFullYear()} Hyberica Softwares | Todos os
                  direitos reservados
                </p>
                <p>
                  <VscVersions size={15} /> Versão UI v
                  {process.env.REACT_APP_UI_VERSION} | API v
                  {process.env.REACT_APP_API_VERSION}
                </p>
              </div>

              <div className="col-6 col-md-3 tenant">
                <div className="row">
                  <div className="col-3">
                    <GiField size={30} />
                  </div>
                  <div className="col">
                    <div>
                      <span>{tenant?.name}</span>
                    </div>
                    {defaultBudget?.label}
                  </div>
                </div>
              </div>
            </div>
        </Container>
      )}
    </>
  );
};

export default Footer;

export const Container = styled.div`
  background-color: ${props => (props.auth ? '#464646' : '#53883D')};
  padding: 10px;
  padding-left: ${props => (props.auth ? '290px' : '10px')};
  position: fixed;
  bottom: 0;
  width: 100%;
  opacity: ${props => (!props.auth ? 0.5 : 1)};

  p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 10px;
    color: #fff;
  }

  .tenant {
    color: #fff;
    font-size: 10px;

    display: flex;
    justify-content: right;

    svg {
      margin-top: 2px;
    }

    span {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 912px) {
    padding-left: 10px;
  }
`;
