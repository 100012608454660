import React, { useCallback, useEffect, useRef, useState } from 'react';
import {GoSearch, GoX} from 'react-icons/go'
import { Form } from '@unform/web'
import InputLabel from '../../../components/unform/input-label'
import SelectLabel from '../../../components/unform/select-label'
import styled from 'styled-components';

export default function AgriculturalInputsFilter ({setParams}) {

  const formRef = useRef(null)

  const[isFilter, setIsFilter] = useState(false)

  const [selectedType, setSelectedType] = useState(null)

  const optionsType = [
    { label: 'Combustível', value: 'COMB' },
    { label: 'Fitosanitário', value: 'FITO' },
    { label: 'Fertilizante', value: 'FERT' },
    { label: 'Outro', value: 'OUTRO' },
  ]


  const cleanFilter = useCallback(async e => {
    formRef.current.reset()

    setSelectedType(null)

    setParams({
      page: 1,
      perPage: 20
    })

    localStorage.removeItem('filter_agri_inputs')
  },[formRef, setParams]);

  
  const submit = useCallback(async (data) => {
    let filter = {}

    if(selectedType) filter.input_type = selectedType.value
    if(data.name) filter.name = data.name

    if(Object.keys(filter).length > 0){
      filter.page = 1
      filter.perPage = 999999
    }

    setParams(filter)

    localStorage.setItem('filter_agri_inputs', JSON.stringify(filter))
  },[selectedType, setParams])
 
  const setFieldsFilter = useCallback((filter) => {
    setIsFilter(true)
    
    document.getElementById('collapseFilter').classList.add('show')

    if(filter.input_type) {
      const option = optionsType.filter(item => item.value === filter.input_type)
      
      setSelectedType(option[0]);
    }

    if(filter.name) formRef.current.setFieldValue('name', filter.name)
  },[optionsType])


  // se tiver filtro, seta os valores nos campos do formulário
  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_agri_inputs'))
    
    if(filter) setFieldsFilter(filter) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Container>
      <div className="row pt-2">
        <div className="col-sm text-right">
          <a
            className={`${!isFilter ? 'text-success' : 'text-danger '} btn`}
            data-toggle="collapse"
            href="#collapseFilter"
            role="button"
            aria-expanded="false"
            aria-controls="collapseFilter"
            onClick={() => {
              setIsFilter(!isFilter)
              if (isFilter) cleanFilter()
            }}
          >
              {!isFilter && <>Filtrar <GoSearch /></>}
              {isFilter && <>Fechar Filtro <GoX /></>}
          </a>
        </div>
      </div>

      <div className="collapse filter" id="collapseFilter">
        <div className="row justify-content-center">
          <div className="col-sm col-md-6">
            <Form
              ref={formRef}
              onSubmit={submit}
              noValidate
            >
              <div className="row">
                <InputFiltro value={formRef.current?.getFieldValue('name')} className="col-sm" >
                  <InputLabel name="name" label="Produto" />
                </InputFiltro>
              </div>
              
              <div className="row">
                <InputFiltro value={selectedType} className="col-sm mt-3">
                  <SelectLabel 
                    name="input_type" 
                    label="Tipo Movimento" 
                    placeholder="Selecione" 
                    value={selectedType}
                    onChange={e => setSelectedType(e)}
                    options={optionsType} />
                </InputFiltro>
              </div>
                  
              <div className="row my-3">
                <div className="col-sm text-right">
                  <button type='button' className="btn btn-secondary m-2 px-5" onClick={() => cleanFilter()}>
                    Limpar Filtro
                  </button>

                  <button className="btn btn-success m-2 px-5" type="submit" >
                    Filtrar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}

export const Container = styled.div`
  .btn-secondary {
    height: 35px !important;
  }
  
  .btn-success{
    height: 35px !important;
  }

  .btn{
    :focus {
      border: none;
      box-shadow: none;
    }
  }

  .filter {
    background-color: #f9f9f9;
    border-radius: 3px;
  }
  
`

export const InputFiltro = styled.div`
  label {
    color: ${props => props.value ? '#388e3c' : ''};
    font-weight: ${props => props.value ? 'bold' : ''};
  }
`