import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { dateToString, floatToTimeList } from '../../actions/util';
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'

class MachineTimes extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      modal: false,
      loading: false,
      totalRows: 0,
      page: 1,
      perPage: 10,
      itemDeleteId: '',
      itemDeleteName: '',
      actions: [
        {
          name: 'Nova Entrada',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'machine-times/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Data',
        selector: 'date_format',
        sortable: true,
      },
      {
        name: 'Safra',
        selector: 'crop.name',
        grow: 2
      },
      {
        name: 'Máquina',
        selector: 'machine.name',
        sortable: true,
        grow: 2
      },

      {
        name: 'Atividade',
        selector: 'activity.name',
        grow: 3
      },
      {
        name: 'Horas',
        selector: 'amount_of_hours_format',
        conditionalCellStyles: [
          {
            when: row => row.amount_of_hours_format,
            style: {
              display: 'block',
              marginRight: '30px',
              marginTop: '15px',
              textAlign: 'right',
            },
          },
        ],
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  async componentDidMount() {
    await this.getMachineTimes();
  }

  getMachineTimes = async () => {
    let machineTimes = []
    this.setState({ loading: true })

    const { page, perPage } = this.state

    await api.get(`machine-times?page=${page}&perPage=${perPage}`)
      .then(result => {
        machineTimes = result.data

        machineTimes.data = result.data.data.map(machineTime => ({
          ...machineTime,
          date_format: dateToString(machineTime.date),
          amount_of_hours_format: floatToTimeList(machineTime.amount_of_hours)
        }))
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: machineTimes.data,
      totalRows: parseInt(machineTimes.total),
      loading: false,
    });
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.machine.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`machine-times/${itemDeleteId}`)
      .then(() => {
        this.getMachineTimes();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDelete: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/machine-times/${reg.id}`)
  }

  handlePageChange = async page => {
    await this.setState({ page: page });

    this.getMachineTimes();
  }

  handlePerRowsChange = async (perPage,) => {
    await this.setState({ perPage: perPage });

    this.getMachineTimes();
  }

  render() {
    return (
      <Widget title="Horas Máquina" actions={this.state.actions} line={false} printer={true}>
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
              // overflowYOffset="500px"
              pagination
              paginationServer
              paginationTotalRows={this.state.totalRows}
              paginationComponentOptions={this.paginationOptions}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir o registro para máquina {this.state.itemDeleteName}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDeleteName: null, itemDeleteId: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default MachineTimes