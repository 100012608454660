import React, { useCallback, useRef, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import InputLabel from '../../components/unform/input-label';
import { Container } from './styles';
import { AuthContext } from '../../actions/auth-context';

const Login = () => {
  const { signIn, isAuthenticated } = useContext(AuthContext);

  const history = useHistory();

  const location = useLocation();

  const formRef = useRef(null);

  const submit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await signIn(data);

        if (response) window.location.href = '/';
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [signIn]
  );

  useEffect(() => {
    if (isAuthenticated) {
      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [isAuthenticated, history, location]);

  return (
    <Container>
      <div className="bg"></div>

      <div className="container">
      
        <div className="row align-items-center justify-content-center" >
          <div className="col-sm col-md-6 logo text-center">
            <img src="assets/images/logo.svg" alt="logo nossa safra" />
            <h2>GESTÃO AGRÍCOLA DE QUALIDADE</h2>
          </div>
          <div className="col-sm col-md-2"></div>
          <div className="col-sm form shadow">
            <Form ref={formRef} onSubmit={submit} noValidate>
              <InputLabel name="email" label="E-mail" />

              <div className="mt-4">
                <InputLabel type="password" name="password" label="Senha" />
              </div>

              <div className="text-right mt-2 forgot">
                <Link to="/forgot-password" className="forgot">
                  Esqueci a senha
                </Link>
              </div>

              <button className="btn btn-block btn-success mt-5 mb-4 btn-lg">
                ENTRAR
              </button>
              <br />
            </Form>
          </div>
      </div>  
      </div>
    </Container>
  );
};

export default Login;
