import React from 'react'
import { formatPrice } from '../../actions/util';
import styled from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader';


const loading = () => <PulseLoader color="#388e3c" size={8} />


function ResumeRecords({total_itens, total_value}) {
  return (
      <Total className='p-3  mb-2'>
        <div className="col">
          <p>Qtde Registros</p>
          <span> {total_itens || loading()}</span>
        </div>
        
        <div className="col text-right">
          <p>Total</p>
          <span>{formatPrice(total_value)}</span>
        </div>
      </Total>
  )
}

ResumeRecords.defaultProps = {
  perPage: Number,
  totalRows: Number,
  value: Number
}

export default ResumeRecords

export const Total = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(180deg, #C4C8C2 0%, rgba(253, 255, 252, 0) 100%);

  border-bottom: 1px solid #F1F4EF;

  p {
    font-size: 12px;
    color: #666;
    font-weight: normal;
    margin: 0;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    color: #666
  }
`