import React from 'react'
import { Switch, Route } from 'react-router'

// pages
import Login from './pages/login'
import Logout from './pages/logout'
import Home from './pages/home'
import PageError from './pages/error-page'
import ServerError from './pages/server-error'
import Entries from './pages/entries'

// people
import People from './pages/people'
import Person from './pages/person'
import Address from './pages/person/address'
import Phone from './pages/person/phone'
import Email from './pages/person/email'
import Photo from './pages/person/photo'

// users
import Users from './pages/users'
import NewUser from './pages/user/new-user'
import EditUser from './pages/user/edit-user'

// activities
import Activities from './pages/activities'
import Activity from './pages/activity'

// activities
import AgriculturalInputs from './pages/agricultural-inputs'
import AgriculturalInput from './pages/agricultural-input'

// cultivations
import Cultivations from './pages/cultivations'
import Cultivation from './pages/cultivation'

// machines
import Machines from './pages/machines'
import Machine from './pages/machine'

// functions
import Roles from './pages/roles'
import Role from './pages/role'

// employess
import Employees from './pages/employees'
import Employee from './pages/employee'
import Dismissal from './pages/employee/dismissal'

// crop
import Crop from './pages/crop'
import PhotoCrop from './pages/crop/photo'
import Crops from './pages/crops'

// tillage
import Tillage from './pages/tillage'
import PhotoTillage from './pages/tillage/photo'

// team
import Team from './pages/team'

// accounts
import Accounts from './pages/accounts'
import Account from './pages/account'

// accountings
import Accountings from './pages/accountings'

// clients
import Clients from './pages/clients'
import Client from './pages/client'

// providers
import Providers from './pages/providers'
import Provider from './pages/provider'

// financial movements
import FinancialMovements from './pages/financial-movements'
import FinancialMovement from './pages/financial-movement'
import FinancialTransfer from './pages/financial-transfer'

// cargo packings
import CargoPackings from './pages/cargo-packings'
import NewCargoPacking from './pages/cargo-packing/new'
import EditCargoPacking from './pages/cargo-packing/edit'

// harvest packings
import HarvestPackings from './pages/harvest-packings'
import NewHarvestPacking from './pages/harvest-packing/new'
import EditHarvestPacking from './pages/harvest-packing/edit'

// machine-times
import MachineTimes from './pages/machine-times'
import MachineTime from './pages/machine-time'

// irrigations
import Irrigations from './pages/irrigations'
import Irrigation from './pages/irrigation'

// budgets
import Budgets from './pages/budgets'
import BudgetNew from './pages/budget/budget-new'
import BudgetEdit from './pages/budget/budget-edit'
import BudgetFilter from './pages/budget/budget-filter'
import BudgetResume from './pages/budget/budget-resume'

// stocks
import Stocks from './pages/stocks'
import StockEntry from './pages/stock/entry'
import StockOut from './pages/stock/out'

// payables
import Payables from './pages/payables'
import NewPayable from './pages/payable/new'
import EditPayable from './pages/payable/edit'

// receivables
import Receivables from './pages/receivables'
import NewReceivable from './pages/receivable/new'
import EditReceivable from './pages/receivable/edit'

// reports
import HarvestPackingReport from './pages/reports/harvest-packing'

// receivables-antecipations
import ReceivablesAntecipations from './pages/receivables-antecipations'
import ReceivableAntecipation from './pages/receivable-antecipation'

// dashboard
import DashboardCargoPackingsPDF from './pages/dashboard/cargo-packings-dashboard/cargo-packings-pdf'
import DashboardReportDateGroup from './pages/dashboard/cargo-packings-dashboard/report-date-group'
import DashboardCargoPackings from './pages/dashboard/cargo-packings-dashboard/cargo-packing-resume'
import DashboardIrrigationResume from './pages/dashboard/irrigations-dashboard/irrigations-resume'
import DashboardMachineTimesCrops from './pages/dashboard/machine-hours-dashboard/machine-hours-crops'
import DashboardMachineTimesMachines from './pages/dashboard/machine-hours-dashboard/machine-hours-machines'
import DashboardBudgets from './pages/dashboard/budgets-dashboard/budgets-resume'
import StockDetailsByCrop from './pages/dashboard/details/stock-by-crop'
import StockDetailsByCropTillage from './pages/dashboard/details/stock-by-crop-tillage'
import StockDetailsByCropGeneral from './pages/dashboard/details/stock-by-crop-general'
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './pages/profile/forgot-password'
import ResetPassword from './pages/profile/reset-password'

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute exact path="/home" component={Home} />
    <Route path="/login" component={Login} />
    <Route path="/logout" component={Logout} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password/:token" component={ResetPassword} />

    <PrivateRoute path="/entries" component={Entries} />

    <PrivateRoute exact path="/people" component={People} />
    <PrivateRoute path="/people/new" component={Person} />
    <PrivateRoute exact path="/people/:id" component={Person} />

    <PrivateRoute path="/people/:personId/address" component={Address} />
    <PrivateRoute path="/addresses/:id" component={Address} />

    <PrivateRoute path="/people/:personId/phones" component={Phone} />
    <PrivateRoute path="/phones/:id" component={Phone} />

    <PrivateRoute path="/people/:personId/emails" component={Email} />
    <PrivateRoute path="/emails/:id" component={Email} />

    <PrivateRoute path="/people/:personId/photo" component={Photo} />
    <PrivateRoute path="/photo/:id" component={Photo} />

    <PrivateRoute exact path="/users" component={Users} />
    <PrivateRoute path="/users/new" component={NewUser} />
    <PrivateRoute path="/users/:id" component={EditUser} />

    <PrivateRoute exact path="/activities" component={Activities} />
    <PrivateRoute path="/activities/new" component={Activity} />
    <PrivateRoute path="/activities/:id" component={Activity} />

    <PrivateRoute exact path="/agricultural-inputs" component={AgriculturalInputs} />
    <PrivateRoute path="/agricultural-inputs/new" component={AgriculturalInput} />
    <PrivateRoute path="/agricultural-inputs/:id" component={AgriculturalInput} />

    <PrivateRoute exact path="/cultivations" component={Cultivations} />
    <PrivateRoute path="/cultivations/new" component={Cultivation} />
    <PrivateRoute exact path="/cultivations/:id" component={Cultivation} />

    <PrivateRoute exact path="/machines" component={Machines} />
    <PrivateRoute path="/machines/new" component={Machine} />
    <PrivateRoute path="/machines/:id" component={Machine} />

    <PrivateRoute exact path="/functions" component={Roles} />
    <PrivateRoute path="/functions/new" component={Role} />
    <PrivateRoute path="/functions/:id" component={Role} />

    <PrivateRoute exact path="/employees" component={Employees} />
    <PrivateRoute exact path="/employees/new" component={Employee} />
    <PrivateRoute exact path="/employees/new/:person_id" component={Employee} />
    <PrivateRoute exact path="/employees/:id" component={Employee} />
    <PrivateRoute path="/employees/:id/dismissal" component={Dismissal} />

    <PrivateRoute exact path="/crops/new" component={Crop} />
    <PrivateRoute exact path="/crops/:id" component={Crop} />
    <PrivateRoute exact path="/crops" component={Crops} />
    <PrivateRoute exact path="/crops/:cropId/photo" component={PhotoCrop} />

    <PrivateRoute path="/crops/:cropId/cultivations/:cultivationId/tillages" component={Tillage} />
    <PrivateRoute exact path="/cultivations/:cultivationId/tillages/:id" component={Tillage} />
    <PrivateRoute exact path="/cultivations/:cultivationId/tillages/:tillageId/photo" component={PhotoTillage} />

    <PrivateRoute exact path="/cultivations/:cultivationId/tillages/:tillageId/teams" component={Team} />
    <PrivateRoute exact path="/cultivations/:cultivationId/tillages/:tillageId/teams/:id" component={Team} />

    <PrivateRoute exact path="/accounts" component={Accounts} />
    <PrivateRoute path="/accounts/new" component={Account} />
    <PrivateRoute path="/accounts/:id" component={Account} />

    <PrivateRoute exact path="/accountings" component={Accountings} />

    <PrivateRoute exact path="/clients" component={Clients} />
    <PrivateRoute path="/clients/new" component={Client} />
    <PrivateRoute path="/clients/:id" component={Client} />

    <PrivateRoute exact path="/providers" component={Providers} />
    <PrivateRoute path="/providers/new" component={Provider} />
    <PrivateRoute path="/providers/:id" component={Provider} />

    <PrivateRoute exact path="/financial-movements" component={FinancialMovements} />
    <PrivateRoute exact path="/financial-movements/new/:type" component={FinancialMovement} />
    <PrivateRoute exact path="/financial-movements/:type/:id" component={FinancialMovement} />
    <PrivateRoute exact path="/financial-transfer" component={FinancialTransfer} />

    <PrivateRoute exact path="/cargo-packings" component={CargoPackings} />
    <PrivateRoute path="/cargo-packings/new" component={NewCargoPacking} />
    <PrivateRoute exact path="/cargo-packings/:id" component={EditCargoPacking} />

    <PrivateRoute exact path="/harvest-packings" component={HarvestPackings} />
    <PrivateRoute path="/harvest-packings/new" component={NewHarvestPacking} />
    <PrivateRoute exact path="/harvest-packings/:id" component={EditHarvestPacking} />

    <PrivateRoute exact path="/machine-times" component={MachineTimes} />
    <PrivateRoute path="/machine-times/new" component={MachineTime} />
    <PrivateRoute exact path="/machine-times/:id" component={MachineTime} />

    <PrivateRoute exact path="/irrigations" component={Irrigations} />
    <PrivateRoute path="/irrigations/new" component={Irrigation} />
    <PrivateRoute exact path="/irrigations/:id" component={Irrigation} />

    <PrivateRoute exact path="/budgets" component={Budgets} />
    <PrivateRoute path="/budgets/new" component={BudgetNew} />
    <PrivateRoute exact path="/budgets/:id" component={BudgetEdit} />
    <PrivateRoute exact path="/budgets/:id/filter" component={BudgetFilter} />
    <PrivateRoute exact path="/budgets/:id/resume" component={BudgetResume} />

    <PrivateRoute exact path="/stocks" component={Stocks} />
    <PrivateRoute path="/stocks/entry/new" component={StockEntry} />
    <PrivateRoute path="/stocks/entry/:id" component={StockEntry} />
    <PrivateRoute exact path="/stocks/out/new" component={StockOut} />
    <PrivateRoute exact path="/stocks/out/:id" component={StockOut} />

    <PrivateRoute exact path="/payables" component={Payables} />
    <PrivateRoute exact path="/payables/new" component={NewPayable} />
    <PrivateRoute exact path="/payables/:id" component={EditPayable} />

    <PrivateRoute exact path="/receivables" component={Receivables} />
    <PrivateRoute exact path="/receivables/new" component={NewReceivable} />
    <PrivateRoute exact path="/receivables/:id" component={EditReceivable} />

    <PrivateRoute exact path="/reports/harvest-packing" component={HarvestPackingReport} />

    <PrivateRoute exact path="/dashboard/irrigations/:budget_id/crop/:crop_id" component={DashboardIrrigationResume} />
    <PrivateRoute exact path="/dashboard/irrigations/:budget_id" component={DashboardIrrigationResume} />
    <PrivateRoute exact path="/dashboard/cargo-packings-pdf/:crop_id/:status" component={DashboardCargoPackingsPDF} />
    <PrivateRoute exact path="/dashboard/cargo-packings/report-date-group/:crop_id/:status" component={DashboardReportDateGroup} />
    <PrivateRoute exact path="/dashboard/cargo-packings/:crop_id/:status" component={DashboardCargoPackings} />
    <PrivateRoute exact path="/dashboard/machine-times/crops/:crop_id" component={DashboardMachineTimesCrops} />
    <PrivateRoute exact path="/dashboard/machine-times/machines" component={DashboardMachineTimesMachines} />
    <PrivateRoute exact path="/dashboard/budgets/:accounting_type" component={DashboardBudgets} />
    <PrivateRoute exact path="/dashboard/budgets/accountings/:accounting_id" component={DashboardBudgets} />
    <PrivateRoute exact path="/dashboard/stock/input_type/:input_type/budget_id/:budget_id/crop_id/:crop_id" component={StockDetailsByCrop} />
    <PrivateRoute exact path="/dashboard/stock/input_type/:input_type/budget_id/:budget_id/crop_id/:crop_id/tillage_id/:tillage_id" component={StockDetailsByCropTillage} />
    <PrivateRoute exact path="/dashboard/stock/input_type/:input_type/budget_id/:budget_id" component={StockDetailsByCropGeneral} />

    <PrivateRoute exact path="/receivables-antecipations" component={ReceivablesAntecipations} />
    <PrivateRoute exact path="/receivables-antecipations/new" component={ReceivableAntecipation} />
    <PrivateRoute exact path="/receivables-antecipations/:id" component={ReceivableAntecipation} />

    <PrivateRoute path="/server-error" component={ServerError} />

    <PrivateRoute path="*" component={PageError} />
  </Switch>
)

export default Routes
