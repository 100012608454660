import React, { Component } from 'react'
import './error-page.css'


class PageError extends Component {
  constructor() {
    super();

    this.toLogin = this.toLogin.bind(this)
  }

  toLogin = () => {
    this.props.history.push('/login')
    window.location.reload();
  }

  render() {
    return (
      <div className="row col error-page p-2">
        <h1 className="animated fadeIn delay-100 mt-5">ERROR</h1>
        <h3 className="animated fadeIn delay-500 mt-5">Servidor não encontrado</h3>
        <h4 className="animated fadeIn delay-500 mt-5">Parece que o servidor está offline, tente fazer o login novamente</h4>
        <div className="btn btn-primary btn-lg btn-block animated fadeIn delay-1000 error-link mt-5" onClick={this.toLogin}>
          <p>ir para login</p>
        </div>
      </div>
    )
  }
}

export default PageError