import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import InputText from '../../../components/input-text';
import Select from '../../../components/select';
import InputCurrency from '../../../components/input-mask-currency';
import InputDate from '../../../components/date-time';
import RadioButton from '../../../components/radio-button';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';
import {
  currencyToString,
  stringToDate,
  formatPrice,
} from '../../../actions/util';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      title: '',
      id: '',
      date: '',
      budget_id: '',
      movement_type: '',
      account_id: '',
      accounting_id: '',
      person_id: '',
      payment_type: 'DINHEIRO',
      document: '',
      due_date: '',
      pay_date: null,
      value: '',
      increase: 0,
      discount: 0,
      description: '',
      historic: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //1
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //2
          name: 'movement_type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Crédito', value: 'CREDITO' },
            { label: 'Débito', value: 'DEBITO' },
          ],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //3
          name: 'account_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //4
          name: 'accounting_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //5
          name: 'person_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //6
          name: 'payment_type',
          type: 'text',
          options: [
            { label: '', value: null },
            { label: 'Boleto', value: 'BOLETO' },
            { label: 'Cheque', value: 'CHEQUE' },
            { label: 'Dinheiro', value: 'DINHEIRO' },
            { label: 'Cartão de Crédito', value: 'CARTAO_CREDITO' },
            { label: 'Cartão de Débito', value: 'CARTAO_DEBITO' },
            { label: 'Doc TED', value: 'DOC_TED' },
            { label: 'Transferência', value: 'TRANSFERENCIA' },
            { label: 'Outros', value: 'OUTROS' },
          ],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //7
          name: 'document',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //8
          name: 'due_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //9
          name: 'pay_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //10
          name: 'value',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //11
          name: 'increase',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //12
          name: 'discount',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //13
          name: 'description',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //14
          name: 'historic',
          type: 'text',
          errors: [],
          rules: {},
        },
      ],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.getPayable(id);
    } else {
      this.setState({
        movement_type: 'DEBITO',
        date: new Date(),
      });
    }

    this.getBudgets();
    this.getAccounts();
    this.getAccountings();
    this.getPeople();
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const payable = this.state;

    let fields = this.submitForm(e, payable.fields);
    this.setState({ fields: fields });

    if (payable.valid && payable.errors.length === 0) this.update();

    return false;
  }

  getPayable = async id => {
    await api
      .get(`payables/${id}`)
      .then(result => {
        const payable = result.data;
        const fields = this.state.fields;

        fields[0].value = payable.date;
        fields[1].value = payable.budget_id;
        fields[2].value = payable.movement_type;
        fields[3].value = payable.account_id;
        fields[4].value = payable.accounting_id;
        fields[5].value = payable.person_id;
        fields[6].value = payable.payment_type;
        fields[7].value = payable.document;
        fields[8].value = payable.due_date;
        fields[9].value = payable.pay_date;
        fields[10].value = formatPrice(payable.value);
        fields[11].value = formatPrice(payable.increase);
        fields[12].value = formatPrice(payable.discount);
        fields[13].value = payable.description;
        fields[14].value = payable.historic;

        this.setState({
          id: payable.id,
          date: payable.date ? stringToDate(payable.date) : '',
          budget_id: payable.budget_id || '',
          movement_type: payable.movement_type || '',
          account_id: payable.account_id || '',
          accounting_id: payable.accounting_id || '',
          person_id: payable.person_id || '',
          payment_type: payable.payment_type || '',
          document: payable.document || '',
          due_date: payable.due_date ? stringToDate(payable.due_date) : '',
          pay_date: payable.pay_date ? stringToDate(payable.pay_date) : '',
          value: formatPrice(payable.value) || '',
          increase: formatPrice(payable.increase) || 0,
          discount: formatPrice(payable.discount) || 0,
          description: payable.description || '',
          historic: payable.historic || '',
          fields: fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Contas a Pagar', url: '/payables' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/payables');
        toastError(handleError(error));
      });
  };

  async handleChange(event) {
    const name = event.target.name;
    const payable = this.state;
    const fields = this.state.fields;

    fields[0].value = payable.date;
    fields[1].value = payable.budget_id;
    fields[2].value = payable.movement_type;
    fields[3].value = payable.account_id;
    fields[4].value = payable.accounting_id;
    fields[5].value = payable.person_id;
    fields[6].value = payable.payment_type;
    fields[7].value = payable.document;
    fields[8].value = payable.due_date;
    fields[9].value = payable.pay_date;
    fields[10].value = payable.value;
    fields[11].value = payable.increase;
    fields[12].value = payable.discount;
    fields[13].value = payable.description;
    fields[14].value = payable.historic;

    await this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  update = async () => {
    const payable = this.state;
    payable.value = currencyToString(payable.value);
    payable.increase = currencyToString(payable.increase);
    payable.discount = currencyToString(payable.discount);

    await api
      .put(`payables/${payable.id}`, payable)
      .then(result => {
        toastSuccess(`Registro de ${payable.name} atualizado!`);
        this.props.history.push('/payables');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getBudgets = async () => {
    let budgets = [];
    const { fields } = this.state;

    await api
      .get('budgets')
      .then(result => {
        fields[1].options = [];

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name,
        }));

        fields[1].options = budgets;
        fields[1].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields, budget_id: budgets[1].value });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getAccounts = async () => {
    let accounts = [];
    const { fields } = this.state;

    await api
      .get('accounts?status_type=ATIVO')
      .then(result => {
        fields[3].options = [];

        accounts = result.data.accounts.map(result => ({
          value: result.id,
          label: result.name,
        }));

        fields[3].options = accounts;
        fields[3].options.unshift({ id: null, label: '' });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getAccountings = async () => {
    let accountings = [];
    const { fields } = this.state;

    await api
      .get('accountings?status_type=ATIVO&accounting_type=DESPESA')
      .then(result => {
        fields[4].options = [];

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          const item = {
            value: element.id,
            label: `${element.number} ${element.name}`,
            disabled: true,
          };
          accountings.push(item);

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x];
              const item = {
                value: elem.id,
                label: `${elem.number} ${elem.name}`,
              };
              accountings.push(item);
            }
          }
        }

        fields[4].options = accountings;
        fields[4].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getPeople = async () => {
    let options = [];
    const { fields } = this.state;

    await api
      .get(`people?page=1&perPage=99999`)
      .then(result => {
        fields[5].options = [];

        options = result.data.data.map(result => ({
          value: result.id,
          label: result.name,
        }));

        options.sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        });

        fields[5].options = options;
        fields[5].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const payable = this.state;

    return (
      <Widget
        title={payable.id ? `Editar Conta a Pagar` : `Nova Conta a Pagar`}
      >
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              {payable.id && (
                <div className="row">
                  <div className="col-sm col-md-3">
                    <InputDate
                      label="* Data de Inclusão"
                      value={payable.date}
                      disabled
                      onChange={date => this.setState({ date: date })}
                      field={payable.fields[0]}
                      validate={(e, field) =>
                        this.validate(e, payable.fields[0])
                      }
                    />
                  </div>
                  <div className="col-sm col-md-3">
                    <RadioButton
                      label="* Tipo Movimento"
                      value={payable.movement_type}
                      options={payable.fields[2].options}
                      onChange={this.handleChange}
                      disabled={true}
                      field={payable.fields[2]}
                      validate={(e, field) =>
                        this.validate(e, payable.fields[2])
                      }
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Orçamento"
                    value={payable.budget_id}
                    options={payable.fields[1].options}
                    onChange={this.handleChange}
                    field={payable.fields[1]}
                    validate={(e, field) => this.validate(e, payable.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Conta Bancária"
                    value={payable.account_id}
                    options={payable.fields[3].options}
                    onChange={this.handleChange}
                    field={payable.fields[3]}
                    validate={(e, field) => this.validate(e, payable.fields[3])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Conta Movimento"
                    value={payable.accounting_id}
                    options={payable.fields[4].options}
                    onChange={this.handleChange}
                    field={payable.fields[4]}
                    validate={(e, field) => this.validate(e, payable.fields[4])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Nome"
                    value={payable.person_id}
                    options={payable.fields[5].options}
                    onChange={this.handleChange}
                    field={payable.fields[5]}
                    validate={(e, field) => this.validate(e, payable.fields[5])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-3">
                  <Select
                    label="* Tipo de Pgto"
                    value={payable.payment_type}
                    options={payable.fields[6].options}
                    onChange={this.handleChange}
                    field={payable.fields[6]}
                    validate={(e, field) => this.validate(e, payable.fields[6])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <InputText
                    label="Documento"
                    value={payable.document}
                    onChange={this.handleChange}
                    field={payable.fields[7]}
                    validate={(e, field) => this.validate(e, payable.fields[7])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="* Descrição"
                    value={payable.description}
                    onChange={this.handleChange}
                    field={payable.fields[13]}
                    validate={(e, field) =>
                      this.validate(e, payable.fields[13])
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-3">
                  <InputDate
                    label="* Data Vencimento"
                    value={payable.due_date}
                    onChange={date => this.setState({ due_date: date })}
                    field={payable.fields[8]}
                    validate={(e, field) => this.validate(e, payable.fields[8])}
                  />
                </div>

                {payable.id && (
                  <div className="col-sm">
                    <InputDate
                      label="Data Pagamento"
                      value={payable.pay_date}
                      onChange={date => this.setState({ pay_date: date })}
                      field={payable.fields[9]}
                      validate={(e, field) =>
                        this.validate(e, payable.fields[9])
                      }
                    />
                  </div>
                )}

                <div className="col-sm col-md-3'">
                  <InputCurrency
                    label="* Valor"
                    value={payable.value}
                    onChange={this.handleChange}
                    field={payable.fields[10]}
                    validate={(e, field) =>
                      this.validate(e, payable.fields[10])
                    }
                  />
                </div>

                {payable.id && (
                  <>
                    <div className="col-sm">
                      <InputCurrency
                        label="Acréscimo"
                        value={payable.increase}
                        onChange={this.handleChange}
                        field={payable.fields[11]}
                        validate={(e, field) =>
                          this.validate(e, payable.fields[11])
                        }
                      />
                    </div>

                    <div className="col-sm">
                      <InputCurrency
                        label="Desconto"
                        value={payable.discount}
                        onChange={this.handleChange}
                        field={payable.fields[12]}
                        validate={(e, field) =>
                          this.validate(e, payable.fields[12])
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {payable.id && (
                  <div className="col-sm">
                    <InputText
                      label="Histórico"
                      value={payable.historic}
                      onChange={this.handleChange}
                      field={payable.fields[14]}
                      validate={(e, field) =>
                        this.validate(e, payable.fields[14])
                      }
                    />
                  </div>
                )}
              </div>

              <div className="row justify-content-end mb-3 mt-5">
                <div className="col-sm col-md-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!payable.valid}
                      onClick={this.submit}
                    >
                      Atualizar
                    </button>
                  </Link>
                </div>

                <div className="col-sm col-md-2">
                  <Link to="/payables">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
