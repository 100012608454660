
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { formatPrice } from '../../../../actions/util'

const BudgetDetails = ({ data }) => {

  const rowTotalStyle = value => {
    const style = {
      backgroundColor: '#98c884',
      fontWeight: 'bold'
    }
    if (value.month === 'Total') return style

    return null
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <div className="row p-0 m-0">
        <div className="col">

          <div className="row mt-2">
            <div className="table-responsive">
              <table className="table table-sm table-striped table-bordered table-hover">
                <thead className="thead-report">
                  <tr>
                    <th scope="col">MÊS</th>
                    <th scope="col">PREVISTO</th>
                    <th scope="col">REALIZADO</th>
                    <th scope="col">PAGO</th>
                    <th scope="col">EM ABERTO</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    data.map((reg, y) => (
                      <tr key={y} style={rowTotalStyle(reg)}>
                        <td style={{ fontWeight: 'bold' }}>
                          {reg.month}
                        </td>
                        <td>
                          {formatPrice(reg.forecast)}
                        </td>
                        <td>
                          {formatPrice(reg.done)}
                        </td>
                        <td>
                          {formatPrice(reg.paid)}
                        </td>
                        <td style={rowTotalStyle(reg)}>
                          {formatPrice(reg.to_pay)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BudgetDetails

export const Container = styled.div`

`