import React from 'react'
import { Link } from 'react-router-dom'
import './logo.css'


const Logo = () => (
  <Link
    to="/"
    className="logo d-flex justify-content-start align-items-center flex-nowrap">
    <img width="40" src="/assets/images/fav_icon.png" alt="" />
    <span className="title ml-2 mt-1">
      <img width="114" src="/assets/images/logo2.png" alt="" />
    </span>
  </Link>
)

export default Logo
