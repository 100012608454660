const urls = [
  {
    title: 'Ações',
    items: [
      {
        url: '#',
        icon: 'settings_applications',
        title: 'Safra',
        items: [
          {
            url: '/cultivations',
            icon: 'terrain',
            title: 'Cultivos',
            items: [],
          },
          {
            url: '/crops',
            icon: 'spa',
            title: 'Safras',
            items: [],
          },
          {
            url: '/activities',
            icon: 'local_activity',
            title: 'Atividades',
            items: [],
          },
        ],
      },
      {
        url: '#',
        icon: 'settings_applications',
        title: 'Controle',
        items: [
          {
            url: '/agricultural-inputs',
            icon: 'spa',
            title: 'Insumos Agrícolas',
            items: [],
          },
          {
            url: '/stocks',
            icon: 'settings',
            title: 'Movimento de Estoque',
            items: [],
          },
          {
            url: '/machines',
            icon: 'settings',
            title: 'Máquinas',
            items: [],
          },
          {
            url: '/machine-times',
            icon: 'settings',
            title: 'Horas Máquina',
            items: [],
          },
          {
            url: '/irrigations',
            icon: 'settings',
            title: 'Irrigações',
            items: [],
          },
        ],
      },
      {
        url: '#',
        icon: 'monetization_on',
        title: 'Financeiro',
        items: [
          {
            url: '/receivables-antecipations',
            icon: 'account_balance_wallet',
            title: 'Antecipação Financeira',
            items: [],
          },
          {
            url: '/accounts',
            icon: 'account_balance_wallet',
            title: 'Contas',
            items: [],
          },
          {
            url: '/accountings',
            icon: 'account_balance',
            title: 'Plano de Contas',
            items: [],
          },
          {
            url: '/financial-movements',
            icon: 'attach_money',
            title: 'Movimento Financeiro',
            items: [],
          },
          {
            url: '/payables',
            icon: 'attach_money',
            title: 'Contas a Pagar',
            items: [],
          },
          {
            url: '/receivables',
            icon: 'attach_money',
            title: 'Contas a Receber',
            items: [],
          },
          {
            url: '/budgets',
            icon: 'attach_money',
            title: 'Orçamentos',
            items: [],
          },
        ],
      },
      {
        url: '#',
        icon: 'people',
        title: 'Pessoas',
        items: [
          {
            url: '/people',
            icon: 'people',
            title: 'Pessoas',
            items: [],
          },
          {
            url: '/functions',
            icon: 'transfer_within_a_station',
            title: 'Funções',
            items: [],
          },
          {
            url: '/employees',
            icon: 'supervisor_account',
            title: 'Funcionários',
            items: [],
          },
          {
            url: '/clients',
            icon: 'supervisor_account',
            title: 'Clientes',
            items: [],
          },
          {
            url: '/providers',
            icon: 'supervisor_account',
            title: 'Fornecedores',
            items: [],
          },
          {
            url: '/users',
            icon: 'supervisor_account',
            title: 'Usuários',
            items: [],
          },
        ],
      },
      {
        url: '#',
        icon: 'local_shipping',
        title: 'Romaneios',
        items: [
          {
            url: '/harvest-packings',
            icon: 'dashboards',
            title: 'Romaneio de Colheita',
            items: [],
          },
          {
            url: '/cargo-packings',
            icon: 'dashboards',
            title: 'Romaneio de Cargas',
            items: [],
          },
        ],
      },
      {
        url: '#',
        icon: 'assignment',
        title: 'Relatórios',
        items: [
          {
            url: '/reports/harvest-packing',
            title: 'Colheita',
            items: [],
          },
        ],
      },
    ],
  },
];
export function navigation(state = Array.from(urls), action) {
  switch (action.type) {
    case 'SET_NAVIGATION':
      return Object.assign({}, state, {
        ...action.navigation,
      });
    default:
      return state;
  }
}
