import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'

import api from '../../actions/api'
import { toastError } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'

import './style.css'

class Crops extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      select_budget: '',
      budgets: [],
      actions: [
        {
          name: 'Nova Safra',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'crops/new'
        }
      ]
    }

    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Orçamento',
        selector: 'budget.name',
        sortable: true,
      },
      {
        name: 'Safra',
        selector: 'name',
        sortable: true,
      }
    ]
  }

  componentDidMount() {
    this.getCrops();
    this.getBudgets();
  }

  getCrops = async id => {
    let crops = []
    this.setState({ loading: true });

    const url_api = id ? `crops?budget_id=${id}` : 'crops'

    await api.get(url_api)
      .then(result => {
        crops = result.data
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: crops,
      loading: false,
    });
  }

  getBudgets = async () => {
    let budgets = []
    await api.get('budgets')
      .then(result => {
        budgets = result.data

      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      budgets: budgets,
    });
  }

  handleRowClicked = reg => {
    this.props.history.push(`/crops/${reg.id}`)
  };

  selectBudget = budget => {
    this.getCrops(budget.id)
    this.setState({ select_budget: budget })
  }

  clearSelectedBudget = budget => {
    this.getCrops()
    this.setState({ select_budget: null })
  }

  render() {
    return (
      <Widget title="Safras" actions={this.state.actions} line={false}>
        <div className="col text-right mr-2">
          {!this.state.select_budget &&
            <div className="dropdown">
              <button
                className="btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                Filtrar por Orçamento
            </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  this.state.budgets.map((item, i) => (
                    <Link
                      to="#"
                      key={i}
                      className="dropdown-item"
                      onClick={e => this.selectBudget(item)}>{item.name}
                    </Link>
                  ))
                }
              </div>
            </div>
          }

          {this.state.select_budget &&
            <div className="dropdown">
              <button
                className="btn btn-success"
                type="button"
                onClick={e => this.clearSelectedBudget()}>
                Limpar Filtro
            </button>
            </div>
          }
        </div>

        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>
        </div>
      </Widget>
    )
  }
}

export default Crops;