import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../components/widget';
import InputText from '../../components/input-text';
import Select from '../../components/select';
import InputCurrency from '../../components/input-mask-currency';

import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import { currencyToString } from '../../actions/util';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      input_type: '',
      name: '',
      average_price: '',
      unit_type: '',
      quantity: '',
      fields: [
        {
          value: '', //0
          name: 'input_type',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: null },
            { label: 'Combustível', value: 'COMB' },
            { label: 'Fitosanitário', value: 'FITO' },
            { label: 'Fertilizante', value: 'FERT' },
            { label: 'Outro', value: 'OUTRO' },
          ],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //1
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //2
          name: 'average_price',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //3
          name: 'unit_type',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: null },
            { label: 'Caixa', value: 'CX' },
            { label: 'Metro', value: 'M' },
            { label: 'Metro Quadrado', value: 'M2' },
            { label: 'Metro Cúbico', value: 'M3' },
            { label: 'Litro', value: 'L' },
            { label: 'Mililitro', value: 'ML' },
            { label: 'Quilograma', value: 'KG' },
            { label: 'Grama', value: 'G' },
            { label: 'Unidade', value: 'UN' },
            { label: 'Milheiro', value: 'MIL' },
            { label: 'Saco', value: 'SAC' },
          ],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //4
          name: 'quantity',
          type: 'text',
          errors: [],
          rules: {},
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getAgriculturalInput(id);
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const agriculturalInput = this.state;

    let fields = this.submitForm(e, agriculturalInput.fields);
    this.setState({ fields: fields });

    if (agriculturalInput.valid && agriculturalInput.errors.length === 0) {
      agriculturalInput.id ? this.update() : this.save();
    }
    return false;
  }

  getAgriculturalInput = async id => {
    await api
      .get(`agricultural-inputs/${id}`)
      .then(result => {
        const agriculturalInput = result.data;
        const fields = this.state.fields;

        fields[0].value = agriculturalInput.input_type;
        fields[1].value = agriculturalInput.name;
        fields[2].value = agriculturalInput.average_price;
        fields[3].value = agriculturalInput.unit_type;
        fields[4].value = agriculturalInput.quantity;

        this.setState({
          id: agriculturalInput.id,
          name: agriculturalInput.name,
          input_type: agriculturalInput.input_type || '',
          average_price: agriculturalInput.average_price || '',
          unit_type: agriculturalInput.unit_type || '',
          quantity: agriculturalInput.quantity || 0,
          fields: fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Insumos Agrícolas', url: '/agricultural-inputs' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/agricultural-inputs');
        toastError(handleError(error));
      });
  };

  handleChange(event) {
    const name = event.target.name;
    const agriculturalInput = this.state;
    const fields = this.state.fields;

    fields[0].value = agriculturalInput.input_type;
    fields[1].value = agriculturalInput.name;
    fields[2].value = agriculturalInput.average_price;
    fields[3].value = agriculturalInput.unit_type;
    fields[4].value = agriculturalInput.quantity;

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  save = async () => {
    const agriculturalInput = this.state;
    agriculturalInput.average_price = currencyToString(
      agriculturalInput.average_price
    );

    await api
      .post('agricultural-inputs', agriculturalInput)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/agricultural-inputs');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  update = async () => {
    const agriculturalInput = this.state;
    agriculturalInput.average_price = currencyToString(
      agriculturalInput.average_price
    );

    await api
      .put(`agricultural-inputs/${agriculturalInput.id}`, agriculturalInput)
      .then(result => {
        toastSuccess(`Registro de ${agriculturalInput.name} atualizado!`);
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const agriInput = this.state;

    return (
      <Widget title={agriInput.id ? `Editar ${agriInput.name}` : 'Novo Insumo'}>
        <div className="row">
          <div className="col-md-8">
            <form onSubmit={this.submit}>
              <div className="col-sm-12 col-md-6">
                <Select
                  label="* Tipo Insumo"
                  value={agriInput.input_type}
                  options={agriInput.fields[0].options}
                  onChange={this.handleChange}
                  field={agriInput.fields[0]}
                  validate={(e, field) => this.validate(e, agriInput.fields[0])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* Nome"
                  value={agriInput.name}
                  onChange={this.handleChange}
                  field={agriInput.fields[1]}
                  validate={(e, field) => this.validate(e, agriInput.fields[1])}
                />
              </div>

              <div className="row  col m-0 p-0">
                <div className="col-sm">
                  <Select
                    label="* Unidade"
                    value={agriInput.unit_type}
                    options={agriInput.fields[3].options}
                    onChange={this.handleChange}
                    field={agriInput.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, agriInput.fields[3])
                    }
                  />
                </div>
                <div className="col-sm">
                  <InputCurrency
                    label="* Preço Médio"
                    value={agriInput.average_price}
                    onChange={this.handleChange}
                    field={agriInput.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, agriInput.fields[2])
                    }
                  />
                </div>
              </div>

              {agriInput.id && (
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="row line-list">
                      <div className="col">
                        <p className="list-group-item-text">
                          Quantidade em estoque
                        </p>
                      </div>
                      <div className="col text-right p-0 m-0">
                        <span className="badge badge-success badge-rounded">
                          {agriInput.quantity}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              )}

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!agriInput.valid}
                      onClick={this.submit}
                    >
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3 col-lg-2 mt-3">
                  <Link to="/agricultural-inputs">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
