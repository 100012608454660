import React from 'react'
import DatePicker from "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR';
import './date-time.css'
import '../../css/components/input/input.css'

const InputText = ({ field, validate, label, value, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="label">{label}</label>
      <div className="">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          locale={pt_BR}
          selected={value}
          value={value}
          disabled={disabled}
          onChange={onChange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className={
            hasErrors
              ? 'form-control is-invalid input-text'
              : 'form-control input-text'
          }
          name={field.name}
          onInput={e => validate(e, field)}
          onBlur={e => validate(e, field)}
        />
      </div>
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default InputText