
import api from '../api'
import { toastError, toastSuccess } from '../toast'
import { handleError } from '../handle-error'

export const update = async tillage => {
  let response;

  await api.put(`tillages/${tillage.id}`, tillage).then(result => {
    response = result.data;
    toastSuccess(`Registro de ${tillage.name} atualizado!`);
  }).catch(error => {
    response = false;
    toastError(handleError(error));
  })
  return response
}

export const save = async tillage => {
  let response;

  await api.post('tillages', tillage)
    .then(result => {
      response = result.data;
      toastSuccess('Novo registro incluído!');
    }).catch(error => {
      response = false;
      toastError(handleError(error));
    })

  return response
}

export const listTillages = async () => {
  const result = new Promise((resolve, reject) => {
    api.get(`tillages`)
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export default { update, save };