import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import Widget from '../../../components/widget'
import SelectLabel from '../../../components/unform/select-label'
import DateLabel from '../../../components/unform/date-label'
import { listCropsByBudgetId, showCropById } from '../../../actions/pages/crop.service'
import { listBudgets } from '../../../actions/pages/budget.service'
import {
  generateHarvestPackingReport, generateHarvestPackingTeamReport,
  generateHarvestPackingTeamsSummaryReport, generateHarvestPackingSummaryReport
} from '../../../actions/report.service'
import { dateToStringFilter } from '../../../actions/util'
import { PdfViewer } from './styles'

const HarvestPackingReport = (props) => {
  const formRef = useRef(null)
  const [optionsBudget, setOptionsBudget] = useState([])
  const [optionsCrop, setOptionsCrop] = useState([])
  const [optionsTillage, setOptionsTillage] = useState([])
  const [noDataMessageTillage, setNoDataMessageTillage] = useState('Selecione uma SAFRA')
  const [noDataMessageCrop, setNoDataMessageCrop] = useState('Selecione um ORÇAMENTO')
  const [typeReport, setTypeReport] = useState('')
  const [pdfURL, setPdfURL] = useState('')

  const prepareOptionsCrop = useCallback((budget_id) => {
    listCropsByBudgetId(budget_id)
      .then(crops => {
        if (crops.length > 0) {
          const _crops = crops.map(crop => ({ value: crop.id, label: crop.name }))
          setOptionsCrop(_crops)
        } else {
          setNoDataMessageCrop('ORÇAMENTO sem safras!')
        }
      })
  }, [])

  const prepareOptionsTillage = useCallback((crop_id) => {
    showCropById(crop_id)
      .then(crop => {
        if (crop.tillages.length > 0) {
          const _tillages = crop.tillages.map(tillage => ({ value: tillage.id, label: tillage.name }))
          setOptionsTillage(_tillages)
        } else {
          setNoDataMessageTillage('SAFRA sem lavouras!')
        }
      })
  }, [])

  useEffect(() => {
    listBudgets().then(budgets => {
      if (budgets.length > 0) {
        const _budgets = budgets.map(item => ({ value: item.id, label: item.name }))
        setOptionsBudget(_budgets)
      }
    })
  }, [])

  const generateReportHarvestPackingPDF = useCallback((params) => {
    generateHarvestPackingReport(params)
      .then(response => setPdfURL(response))
  }, [])

  const generateReportHarvestPackingTeamPDF = useCallback((params) => {
    generateHarvestPackingTeamReport(params)
      .then(response => setPdfURL(response))
  }, [])

  const generateReportHarvestPackingTeamsSummaryPDF = useCallback((params) => {
    generateHarvestPackingTeamsSummaryReport(params)
      .then(response => setPdfURL(response))
  }, [])

  const generateReportHarvestPackingSummaryPDF = useCallback((params) => {
    generateHarvestPackingSummaryReport(params)
      .then(response => setPdfURL(response))
  }, [])

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        budget_id: Yup.string().required('Orçamento é obrigatório'),
        crop_id: Yup.string().required('Safra é obrigatório'),
        tillage_id: Yup.string().required('Lavoura é obrigatório'),
        initial_date: Yup.date().required('Data inicial é obrigatório').nullable(),
        end_date: Yup.date().required('Data final é obrigatório').nullable(),
      })

      await schema.validate(data, { abortEarly: false })

      let params = {}
      params.initial_date = dateToStringFilter(data.initial_date)
      params.end_date = dateToStringFilter(data.end_date)
      params.tillage_id = data.tillage_id


      switch (typeReport) {
        case 'harvest-week':
          generateReportHarvestPackingPDF({ params: params })
          break;

        case 'harvest-week-team':
          generateReportHarvestPackingTeamPDF({ params: params })
          break;

        case 'harvest-teams-summary':
          generateReportHarvestPackingTeamsSummaryPDF({ params: params })
          break;

        case 'harvest-summary':
          generateReportHarvestPackingSummaryPDF({ params: params })
          break;

        default:
          break;
      }

    } catch (error) {
      const errorMessages = {}
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message
        })

        formRef.current.setErrors(errorMessages)
      }
    }
  }, [
    typeReport,
    generateReportHarvestPackingPDF,
    generateReportHarvestPackingTeamPDF,
    generateReportHarvestPackingTeamsSummaryPDF,
    generateReportHarvestPackingSummaryPDF
  ])

  return (
    <Widget title="Relatório de Colheita" >
      <div className="row justify-content-center">
        {!pdfURL ? (
          <div className="col-sm col-md-8 col-xlg-6">
            <Form
              ref={formRef}
              onSubmit={submit}
              noValidate>

              <div className="row">
                <div className="col">
                  <SelectLabel
                    name="budget_id"
                    label="* Orçamento"
                    placeholder="Selecione.."
                    onChange={e => prepareOptionsCrop(e.value)}
                    options={optionsBudget}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-sm col-md-6">
                  <SelectLabel
                    name="crop_id"
                    label="* Safra"
                    placeholder="Selecione.."
                    onChange={e => prepareOptionsTillage(e.value)}
                    noOptionsMessage={() => noDataMessageCrop}
                    options={optionsCrop}
                  />
                </div>

                <div className="col-sm col-md-6">
                  <SelectLabel
                    name="tillage_id"
                    label="* Lavoura"
                    placeholder="Selecione.."
                    options={optionsTillage}
                    noOptionsMessage={() => noDataMessageTillage}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm col-md-6">
                  <DateLabel
                    name="initial_date"
                    label="* Data Inicial"
                  />
                </div>

                <div className="col-sm col-md-6">
                  <DateLabel
                    name="end_date"
                    label="* Data Final"
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm mb-2">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-success"
                    onClick={() => setTypeReport('harvest-week')} >
                    Relatório da Semana
                </button>
                </div>

                <div className="col-sm">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-success"
                    onClick={() => setTypeReport('harvest-week-team')}>
                    Relatório por Equipe
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm mb-2">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-success"
                    onClick={() => setTypeReport('harvest-teams-summary')} >
                    Relatório Final(senama)
                </button>
                </div>

                <div className="col-sm">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-success"
                    onClick={() => setTypeReport('harvest-summary')}>
                    Relatório Final Pgto
                  </button>
                </div>
              </div>

              <div className="row mt-3 justify-content-end">
                <div className="col-sm col-md-3 mb-2">
                  <button
                    className="btn btn-lg btn-success btn-block"
                    type="button"
                    onClick={() => window.location.reload()}>
                    Limpar Campos
                </button>
                </div>

                <div className="col-6 col-md-3">
                  <Link
                    className="btn btn-lg btn-success btn-block"
                    to="/">Voltar
                </Link>
                </div>
              </div>
            </Form >
          </div>
        ) : (
          <PdfViewer className="col">
            <div className="col-12">
              <div className="row justify-content-end">
                <div className="col-sm col-md-2 mr-2 mb-3">
                  <button
                    className="btn btn-success btn-block"
                    onClick={() => setPdfURL(null)}>
                    Cancelar
                    </button>
                </div>
              </div>
              <object className="col-sm pdf" data={pdfURL} type="application/pdf">
                <embed src={pdfURL} type="application/pdf" />
              </object>
            </div>
          </PdfViewer>
        )}
      </div>
    </Widget>
  )
}

export default HarvestPackingReport