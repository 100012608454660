import React from 'react';
import DropdownUser from './DropdownUser';

const Profile = () => {
  return (
    <div className="nav-link nav-link-avatar">
      <img
        src="/assets/images/profile.jpeg"
        className="rounded-circle"
        alt="avatar"
      />
      <DropdownUser />
    </div>
  );
};

export default Profile;
