import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';

import { formatPrice } from '../../../../actions/util';
import Widget from '../../../../components/widget';
import { useParams, useHistory } from 'react-router-dom';
import { resumeStockByInputType } from '../../../../actions/agricultural-input';
import styled from 'styled-components';

const StockDetails = () => {
  const params = useParams();
  const history = useHistory();

  const [resume, setResume] = useState({});
  const [tillages, setTillages] = useState([]);

  const getStockDetais = useCallback(async () => {
    const result = await resumeStockByInputType(params);

    if (result) {
      setResume(result[0]);
      setTillages(result[0].tillages);
    }
  }, [params]);

  useEffect(() => {
    const { input_type, budget_id, crop_id } = params;

    if (input_type && budget_id && crop_id) getStockDetais();
  }, [getStockDetais, params]);

  return (
    <Widget title={`Custo Estoque`} printer={true}>
      <div className="row">
        <div className="col-sm">
          <Caption>Safra</Caption>
          <Label>{resume?.name}</Label>
        </div>

        <div className="col-sm text-center">
          <Caption>Insumo</Caption>
          <Label>
            {params.input_type === 'FITO' ? 'Fitossanitário' : 'Fertilizante'}
          </Label>
        </div>

        <div className="col-sm text-right">
          <Caption>Valor por Ha </Caption>
          <Label>{formatPrice(resume?.total_by_area)}</Label>
        </div>

        <div className="col-sm text-right">
          <Caption>Valor total</Caption>
          <Label>{formatPrice(resume?.total)}</Label>
        </div>
      </div>

      <div className="table-responsive mt-5">
        <table className="table table-borderless table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Lavoura</th>
              <th scope="col" className="text-end">
                Total p/ Ha
              </th>
              <th scope="col" className="text-end">
                Total
              </th>
            </tr>
          </thead>

          <tbody>
            {tillages.map((tillage, i) => (
              <TrLink
                key={i}
                onClick={() =>
                  history.push(
                    `/dashboard/stock/input_type/${params.input_type}/budget_id/${params.budget_id}/crop_id/${params.crop_id}/tillage_id/${tillage.id}`
                  )
                }
              >
                <th scope="row">{i + 1}</th>
                <td>{tillage.name}</td>
                <td className="text-end">
                  {formatPrice(tillage.value_by_area)}
                </td>
                <td className="text-end">{formatPrice(tillage.value)}</td>
              </TrLink>
            ))}
          </tbody>
        </table>
      </div>

      <div className="row justify-content-between">
        <div className="col">
          <h5>Total</h5>
        </div>
        <div className="col text-end">
          <h5>{formatPrice(resume.total)}</h5>
        </div>
      </div>

      <div className="row no-gutters justify-content-end mt-5">
        <div className="col-sm col-md-2">
          <Link to="/">
            <button className="btn btn-success btn-block">Voltar</button>
          </Link>
        </div>
      </div>
    </Widget>
  );
};

export default StockDetails;

export const Caption = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Label = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  font-weight: bold;

  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Header = styled.div`
  margin: 0;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
`;

export const Resume = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  color: #4d4d4d;
  margin-left: 5px;
`;

export const TopBar = styled.div`
  margin-top: 40px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  background-color: #cdf0b4;
  padding: 5px;
`;
export const Value = styled.tr`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px;

  a {
    color: #53883d !important;
  }
`;

export const Total = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px;
`;

export const TrLink = styled.tr`
  :hover {
    cursor: pointer;
  }
`;
