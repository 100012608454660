import api from './api'
import { toastError } from './toast'
import { handleError } from './handle-error'

export const indexFinancialMovements = async params => {
  const result = new Promise((resolve, reject) => {
    api.get(`financial-movements`, { params: params })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}

export const indexPayables = async params => {
  const result = new Promise((resolve, reject) => {
    api.get(`payables`, { params: params })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(null)
        toastError(handleError(error))
      });
  })
  return result
}