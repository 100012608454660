import { format, parseISO, addHours } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const { format: formatSimplePrice } = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
});

export const { format: formatWeight } = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 0,
});

export const wheigthToFloat = wheigth => {
  let value = wheigth.toString();
  value = value.replace('.', '');
  value = value.replace(',', '.');

  return Number(value);
};

export const currencyToString = currency => {
  let valueFloat = currency.toString();
  valueFloat = valueFloat.replace(/[^0-9,-]+/g, '').replace(',', '.');

  return parseFloat(valueFloat);
};

export const dateToString = date => {
  const parsedDate = parseISO(date);
  const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');
  const addedDate = addHours(znDate, 1);
  const stringDate = format(new Date(addedDate), 'dd/MM/yyyy');

  return stringDate;
};

export const dateToStringFilter = date => {
  const timeZone = 'America/Sao_Paulo';
  return format(new Date(date), 'yyyy-MM-dd', { timeZone });
};

export const stringToDate = string => {
  const parsedDate = parseISO(string);
  const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');

  const addedDate = addHours(znDate, 1);
  const date = new Date(addedDate);

  return date;
};

export const numberToTime = number => {
  const stringNumber = number.toString();
  let time = '';
  let hora = '';
  let minutos = '';

  if (stringNumber.includes('.')) {
    minutos = stringNumber.slice(stringNumber.indexOf('.')).replace('.', '');
    minutos = minutos.length < 2 ? `${minutos}0` : minutos;
    hora = stringNumber.slice('.', 2).replace('.', '');
    hora = hora.length < 2 ? `0${hora}` : hora;
  } else {
    minutos = '00';
    hora = stringNumber.length < 2 ? `0${stringNumber}` : stringNumber;
  }

  time = `${hora}:${minutos}`;

  return time;
};

export const timeToNumber = time => {
  let number = '';
  const hora = parseInt(time);
  const minutos = time.slice(time.indexOf(':')).replace(':', '');

  number = `${hora}.${minutos}`;

  return number;
};

export const timeToFloat = timeHHmm => {
  const fields = timeHHmm.split(':');
  const hours = Number(fields[0]);
  const mins = Number(fields[1]);

  return Number(hours + mins / 60);
};

export const floatToTime = timeFloat => {
  const fields = timeFloat.toString().split('.');
  const hours = fields[0];
  const mins =
    fields.length > 1 ? Number(Number('0.' + fields[1]) * 60).toFixed(0) : 0;
  const zeros = hours.length === 1 ? '00' : hours.length === 2 ? '0' : '';

  return `${zeros}${hours}:${mins > 9 ? '' : '0'}${mins}`;
};

export const floatToTimeList = timeFloat => {
  const fields = timeFloat.toString().split('.');
  const hours = fields[0];
  const mins =
    fields.length > 1 ? Number(Number('0.' + fields[1]) * 60).toFixed(0) : 0;
  const zeros = hours.length === 1 ? '0' : '';

  return `${zeros}${hours}:${mins > 9 ? '' : '0'}${mins}`;
};

export const floatToCurrency = number => {
  let value = number.toLocaleString('pt-BR');
  if (value.indexOf(',') === -1) value = `${value},00`;
  return `R$ ${value}`;
};

export const formatUnity = unity => {
  if (unity === 'CX') return 'Caixa';
  if (unity === 'M') return 'Metro';
  if (unity === 'M2') return 'Metro Quadrado';
  if (unity === 'M3') return 'Metro Cúbico';
  if (unity === 'L') return 'Litro';
  if (unity === 'ML') return 'Mililitro';
  if (unity === 'KG') return 'Quilograma';
  if (unity === 'G') return 'Grama';
  if (unity === 'UN') return 'Unidade';
  if (unity === 'MIL') return 'Milheiro';
  if (unity === 'SAC') return 'Saco';
};

export const formatType = type => {
  if (type === 'COMB') return 'Combustível';
  if (type === 'FERT') return 'Fertilizante';
  if (type === 'FITO') return 'Fitossanitário';
  if (type === 'OUTRO') return 'Outro';
};

export const formatDateToDashboard = data => {
  let year_month = '';
  let year = '';
  let month = '';
  let month_format = '';

  if (data.includes('-')) {
    year_month = data.split('-');
    year = year_month[0];
    month = year_month[1];
  } else {
    return data;
  }

  if (month === '01') month_format = 'Jan';
  if (month === '02') month_format = 'Fev';
  if (month === '03') month_format = 'Mar';
  if (month === '04') month_format = 'Abr';
  if (month === '05') month_format = 'Mai';
  if (month === '06') month_format = 'Jun';
  if (month === '07') month_format = 'Jul';
  if (month === '08') month_format = 'Ago';
  if (month === '09') month_format = 'Set';
  if (month === '10') month_format = 'Out';
  if (month === '11') month_format = 'Nov';
  if (month === '12') month_format = 'Dez';

  return `${month_format}-${year}`;
};
