import React, { Component } from 'react'
import approve from 'approvejs'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

import InputText from '../../components/input-text'

import './style.css'

class Accountings extends Component {
  constructor() {
    super()
    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)
    this.onActiveAccount = this.onActiveAccount.bind(this)

    this.state = {
      revenues: [],
      expenses: [],
      addRevenueItem: false,
      addRevenueSubItem: false,
      addExpenseSubItem: false,
      selectedAccounting: '', // item or subItem
      modal: false,
      deletedItem: {},
      id: '',
      account_id: '',
      accounting_type: '',
      accounting_id: '',
      name: '',
      status_type: '',
      nextRevenueItem: 0,
      nextRevenueSubItem: 0,
      nextExpenseItem: 0,
      nextExpenseSubItem: 0,
      fields: [
        {
          value: '', //0
          name: 'name',
          placeholder: 'Nome',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
      ],
      errors: [],
      valid: false,
    }

  }

  componentDidMount() {
    this.getAccountings();
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    const { fields, valid, errors, id } = this.state;

    let fieldsValid = this.submitForm(e, fields);
    this.setState({ fields: fieldsValid });

    if (valid && errors.length === 0) {
      id ? this.update() : this.save();
    }
  }

  save = async () => {
    const accounting = this.state;

    await api.post('accountings', accounting)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push(`/accountings`)
        this.getAccountings()

        this.handleCancel();
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const accounting = this.state;

    await api.put(`accountings/${accounting.id}`, accounting)
      .then(result => {
        toastSuccess(`Registro de ${accounting.name} atualizado!`);
        this.props.history.push(`/accountings`)
        this.getAccountings();

        this.handleCancel();
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  addLastNumberToResult = (result) => {
    if (result.accountings.length > 0) {
      let numbers = []
      numbers = result.accountings.map(item => {
        return item.number
      })

      const lastNumber = parseInt(numbers.pop().split('.').join('')) + 1;
      const numberStr = (lastNumber + '')
      const part1 = numberStr.substring(0, 1)
      const part2 = numberStr.substring(1, 3)
      const part3 = numberStr.substring(3)

      const nextAccoutingNumber = `${part1}.${part2}${part3 ? '.' : ''}${part3 || ''}`;

      result.nextAccoutingNumber = nextAccoutingNumber;

    } else {
      result.nextAccoutingNumber = result.number + '.01';
    }

    return result;
  }

  getAccountings = async () => {
    let revenues = [];
    let expenses = [];

    await api.get('accountings')
      .then(result => {
        result.data.map(result => {
          if (result.accounting_type === 'RECEITA') {
            revenues.push(this.addLastNumberToResult(result))
          } else {
            expenses.push(this.addLastNumberToResult(result))
          }
          return null
        })
      })
      .catch(error => {
        toastError(handleError(error));
      })

    const nextRevenueItem = revenues.length > 0 ? (parseFloat(revenues[revenues.length - 1].number) + 0.01).toFixed(2) : '1.01';

    const nextExpenseItem = expenses.length > 0 ? (parseFloat(expenses[expenses.length - 1].number) + 0.01).toFixed(2) : '2.01';

    this.setState({
      revenues: revenues,
      expenses: expenses,
      nextRevenueItem: nextRevenueItem,
      nextExpenseItem: nextExpenseItem,
    });
  }

  onConfirmDelete(e, item) {
    e.preventDefault();

    this.setState({ modal: true, deletedItem: item })

    this.handleCancel()
  }

  onDeleteItem = async () => {
    const { deletedItem } = this.state;

    await api.delete(`accountings/${deletedItem.id}`)
      .then(() => {
        this.getAccountings();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, deletedItem: {} })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleAddRevenueItem = () => {
    this.setState({
      accounting_type: 'RECEITA',
      accounting_id: null,
      status_type: 'ATIVO',
      addRevenueItem: true
    })
  }

  handleAddExpenseItem = () => {
    this.setState({
      accounting_type: 'DESPESA',
      accounting_id: null,
      status_type: 'ATIVO',
      addExpenseItem: true
    })
  }

  handleAddRevenueSubItem = accounting_id => {
    this.setState({
      accounting_type: 'RECEITA',
      accounting_id: accounting_id,
      status_type: 'ATIVO',
      addRevenueSubItem: true
    })
  }

  handleAddExpenseSubItem = accounting_id => {
    this.setState({
      accounting_type: 'DESPESA',
      accounting_id: accounting_id,
      status_type: 'ATIVO',
      addExpenseSubItem: true
    })
  }

  handleSelectAccounting = accounting => {
    this.setState({
      id: accounting.id,
      accounting_type: accounting.accounting_type,
      accounting_id: accounting.account_id,
      status_type: accounting.status_type,
      name: accounting.name,
      selectedAccounting: accounting.accounting_id ? 'subItem' : 'item'
    })
  }

  handleCancel = () => {
    this.setState({
      id: '',
      accounting_type: '',
      accounting_id: null,
      status_type: '',
      addRevenueItem: false,
      addRevenueSubItem: false,
      addExpenseItem: false,
      addExpenseSubItem: false,
      selectedAccounting: '',
      name: ''
    })
  }

  onActiveAccount = e => {
    e.preventDefault()

    this.setState({ status_type: 'ATIVO' })

    setTimeout(() => {
      this.update()
    }, 500);
  }

  render() {
    const state = this.state;

    return (
      <Widget title="Plano de Contas" >
        <ul className="list-group">
          <li className="list-group-item row main-title">
            <span className="mr-3">1.0</span>
            <span>Receitas</span>
          </li>

          {state.revenues.map(item => (
            <div key={item.id}>
              <li className="list-group-item col">
                {(state.selectedAccounting !== 'item' || item.id !== state.id) &&
                  <div className="account-item title-item" onClick={() => this.handleSelectAccounting(item)}>
                    <span className="mr-3">{item.number}</span>
                    <span>{item.name} </span>
                  </div>
                }

                {/* formulario receita  1.01*/}
                {state.selectedAccounting === 'item' && item.id === state.id &&
                  <form className="row no-gutters justify-content-center bg-form">
                    <div className="row col-sm align-items-center p-0">
                      <span className="ml-4 d-none d-md-block">{item.number}</span>

                      <div className="col-sm text-accounting">
                        <InputText
                          value={state.name}
                          onChange={e => this.setState({ name: e.target.value })}
                          field={state.fields[0]}
                          validate={(e, field) =>
                            this.validate(e, state.fields[0])}
                        />
                      </div>
                    </div>

                    <div className="row col-sm col-md-5 ml-2 mt-1 align-items-center">
                      <button className="btn btn-success" disabled={!state.valid} onClick={this.submit}>
                        Atualizar
                </button>

                      {item.accountings.length === 0 &&
                        <button className={item.accountings.length === 0 ? `btn btn-danger  m-2` : ''}
                          onClick={e => this.onConfirmDelete(e, item)}>
                          Excluir / Inativar
                  </button>
                      }

                      <button className={item.accountings.length > 0 ? 'btn btn-warning m-2' : 'btn btn-warning mb-2'}
                        onClick={this.handleCancel}>
                        Cancelar
                </button>
                    </div>
                  </form>
                }
              </li>

              {item.accountings.map(subItem => (
                <div key={subItem.id}>
                  <li className="list-group-item col">
                    {(state.selectedAccounting !== 'subItem' || subItem.id !== state.id) &&
                      <div
                        className={subItem.status_type === 'ATIVO' ? 'account-item row' : 'account-item row inative-account'}
                        onClick={() => this.handleSelectAccounting(subItem)}>
                        <div className="col-sm">
                          <span> {subItem.number} <span className="ml-2">{subItem.name}</span></span>
                        </div>
                      </div>
                    }

                    {/* form 1.01.01 */}
                    {state.selectedAccounting === 'subItem' && subItem.id === state.id &&
                      <form className="row no-gutters justify-content-center bg-form">
                        <div className="row col-sm align-items-center p-0">
                          <span className="ml-4 d-none d-md-block">{subItem.number}</span>

                          <div className="col-sm text-accounting">
                            <InputText
                              value={state.name}
                              onChange={e => this.setState({ name: e.target.value })}
                              field={state.fields[0]}
                              validate={(e, field) =>
                                this.validate(e, state.fields[0])}
                            />
                          </div>
                        </div>

                        <div className="row col-sm m-2  col-md-5 align-items-center">
                          <button className="btn btn-success" disabled={!state.valid} onClick={this.submit}>
                            Atualizar
                    </button>

                          {subItem.status_type === 'ATIVO' &&
                            <button className="btn btn-danger m-2" onClick={e => this.onConfirmDelete(e, subItem)}>
                              Excluir / Inativar
                      </button>
                          }
                          {subItem.status_type === 'INATIVO' &&
                            <button className="btn btn-success m-2" onClick={this.onActiveAccount}>
                              Ativar Conta
                      </button>
                          }

                          <button className="btn btn-warning" onClick={this.handleCancel}>
                            Cancelar
                    </button>
                        </div>
                      </form>
                    }
                  </li>
                </div>
              ))}

              <li className="list-group-item  bg-light">
                {(!state.addRevenueSubItem || item.id !== state.accounting_id) &&
                  < button className="btn btn-success" onClick={() => this.handleAddRevenueSubItem(item.id)}>
                    Adicionar {item.nextAccoutingNumber}
                  </button>
                }

                {/* form add 1.02.01 */}
                {state.addRevenueSubItem && item.id === state.accounting_id &&
                  <form className="row justify-content-center bg-form py-2">
                    <div className="col-sm text-accounting">
                      <InputText
                        value={state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                        field={state.fields[0]}
                        validate={(e, field) =>
                          this.validate(e, state.fields[0])}
                      />
                    </div>

                    <div className="row col-sm col-md-5 align-items-center">
                      <button className="btn btn-success mt-1" disabled={!state.valid} onClick={this.submit}>
                        Salvar
                </button>

                      <button className="btn btn-warning ml-2 mt-1" onClick={this.handleCancel}>
                        Cancelar
                </button>
                    </div>
                  </form>
                }
              </li>
            </div>
          ))}

          <li className="list-group-item  bg-light">
            {!state.addRevenueItem &&
              <button className="btn btn-success" onClick={this.handleAddRevenueItem}>
                Adicionar {state.nextRevenueItem}
              </button>
            }

            {/* form add 1.01 */}
            {state.addRevenueItem &&
              <form className="row justify-content-center bg-form py-2">
                <div className="col-sm text-accounting">
                  <InputText
                    value={state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                    field={state.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, state.fields[0])}
                  />
                </div>

                <div className="row col-sm col-md-5 align-items-center">
                  <button className="btn btn-success mt-1" disabled={!state.valid} onClick={this.submit}>
                    Salvar
            </button>

                  <button className="btn btn-warning ml-2 mt-1" onClick={this.handleCancel}>
                    Cancelar
            </button>
                </div>
              </form>
            }
          </li>
        </ul>


        <ul className="list-group">
          <li className="list-group-item row main-title">
            <span className="mr-3">2.0</span>
            <span>Despesas</span>
          </li>

          {state.expenses.map(item => (
            <div key={item.id}>
              <li className="list-group-item col">
                {(state.selectedAccounting !== 'item' || item.id !== state.id) &&
                  <div className="account-item title-item" onClick={() => this.handleSelectAccounting(item)}>
                    <span className="mr-3">{item.number}</span>
                    <span>{item.name}</span>
                  </div>
                }

                {/* form despesa 1.0 */}
                {state.selectedAccounting === 'item' && item.id === state.id &&
                  <form className="row no-gutters justify-content-center bg-form">
                    <div className="row col-sm align-items-center p-0">
                      <span className="ml-4 d-none d-md-block">{item.number}</span>

                      <div className="col-sm text-accounting">
                        <InputText
                          value={state.name}
                          onChange={e => this.setState({ name: e.target.value })}
                          field={state.fields[0]}
                          validate={(e, field) =>
                            this.validate(e, state.fields[0])}
                        />
                      </div>
                    </div>

                    <div className="row col-sm col-md-5 ml-2 mt-1 align-items-center">
                      <button className="btn btn-success" disabled={!state.valid} onClick={this.submit}>
                        Atualizar
                      </button>

                      {item.accountings.length === 0 &&
                        <button className={item.accountings.length === 0 ? 'btn btn-danger m-2' : ''}
                          onClick={e => this.onConfirmDelete(e, item)}>
                          Excluir / Inativar
                        </button>
                      }

                      <button className={item.accountings.length > 0 ? 'btn btn-warning m-2' : 'btn btn-warning mb-2'}
                        onClick={this.handleCancel}>
                        Cancelar
                      </button>
                    </div>
                  </form>
                }
              </li>

              {item.accountings.map(subItem => (
                <div key={subItem.id}>
                  <li className="list-group-item col">
                    {(state.selectedAccounting !== 'subItem' || subItem.id !== state.id) &&
                      <div
                        className={subItem.status_type === 'ATIVO' ? 'account-item row' : 'account-item row inative-account'}
                        onClick={() => this.handleSelectAccounting(subItem)}>
                        <div className="col-sm">
                          <span>{subItem.number} <span className="ml-2">{subItem.name}</span></span>
                        </div>
                      </div>
                    }

                    {/* form add 1.02.01 */}
                    {state.selectedAccounting === 'subItem' && subItem.id === state.id &&
                      <form className="row no-gutters justify-content-center bg-form">
                        <div className="row col-sm align-items-center p-0">
                          <span className="ml-4 d-none d-md-block">{subItem.number}</span>

                          <div className="col-sm text-accounting">
                            <InputText
                              value={state.name}
                              onChange={e => this.setState({ name: e.target.value })}
                              field={state.fields[0]}
                              validate={(e, field) =>
                                this.validate(e, state.fields[0])}
                            />
                          </div>
                        </div>

                        <div className="row col-sm m-2 col-md-5 align-items-center">
                          <button className="btn btn-success" disabled={!state.valid} onClick={this.submit}>
                            Atualizar
                          </button>

                          {subItem.status_type === 'ATIVO' &&
                            <button className="btn btn-danger m-2" onClick={e => this.onConfirmDelete(e, subItem)}>
                              Excluir / Inativar
                            </button>
                          }
                          {subItem.status_type === 'INATIVO' &&
                            <button className="btn btn-success m-2" onClick={this.onActiveAccount}>
                              Ativar Conta
                            </button>
                          }

                          <button className="btn btn-warning" onClick={this.handleCancel}>
                            Cancelar
                          </button>
                        </div>
                      </form>
                    }
                  </li>
                </div>
              ))}

              <li className="list-group-item bg-light">
                {(!state.addExpenseSubItem || item.id !== state.accounting_id) &&
                  < button className="btn btn-success" onClick={() => this.handleAddExpenseSubItem(item.id)}>
                    Adicionar {item.nextAccoutingNumber}
                  </button>
                }

                {state.addExpenseSubItem && item.id === state.accounting_id &&
                  <form className="row justify-content-center bg-form py-2">
                    <div className="col-sm text-accounting">
                      <InputText
                        value={state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                        field={state.fields[0]}
                        validate={(e, field) =>
                          this.validate(e, state.fields[0])}
                      />
                    </div>

                    <div className="row col-sm col-md-5 align-items-center">
                      <button className="btn btn-success mt-1" disabled={!state.valid} onClick={this.submit}>
                        Salvar
                      </button>

                      <button className="btn btn-warning ml-2 mt-1" onClick={this.handleCancel}>
                        Cancelar
                      </button>
                    </div>
                  </form>
                }
              </li>
            </div>
          ))}

          <li className="list-group-item  bg-light">
            {!state.addExpenseItem &&
              <button className="btn btn-success" onClick={this.handleAddExpenseItem}>
                Adicionar {state.nextExpenseItem}
              </button>
            }

            {state.addExpenseItem &&
              <form className="row justify-content-center bg-form py-2">
                <div className="col-sm text-accounting">
                  <InputText
                    value={state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                    field={state.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, state.fields[0])}
                  />
                </div>

                <div className="row col-sm col-md-5 align-items-center">
                  <button className="btn btn-success mt-1" disabled={!state.valid} onClick={this.submit}>
                    Salvar
                  </button>

                  <button className="btn btn-warning ml-2 mt-1" onClick={this.handleCancel}>
                    Cancelar
                  </button>
                </div>
              </form>
            }
          </li>
        </ul>


        <Modal
          isOpen={state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir / inativar {state.deletedItem.name || ''}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
            </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
            </button>
          </ModalFooter>
        </Modal>
      </Widget >
    )
  }
}

export default Accountings