import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../../components/widget'
import Select from '../../../components/select'
import InputText from '../../../components/input-text'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      email_type: '',
      email: '',
      person_id: '',
      person_name: '',
      fields: [
        {
          value: '',
          name: 'email_type',
          options: [
            { label: 'Trabalho', value: 'TRABALHO' },
            { label: 'Casa', value: 'CASA' },
            { label: 'Outro', value: 'OUTRO' },
          ],
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '',
          name: 'email',
          type: 'email',
          errors: [],
          rules: {
            email: {
              message: 'E-mail inválido'
            }
          }
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { personId, id } = this.props.match.params;
    this.setState({
      person_id: personId,
      email_type: 'CASA',
      id: id
    })

    if (personId) this.getPerson(personId);
    if (id) this.getEmail(id);
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const { valid, errors, id } = this.state;

    let fields = this.submitForm(e, this.state.fields);
    this.setState({ fields: fields });

    if (valid && errors.length === 0) {
      id ? this.update() : this.save();
    }
    return false
  }


  getPerson = async id => {
    await api.get(`people/${id}`)
      .then(result => {
        const person = result.data;

        this.setState({
          person_id: person.id,
          person_name: person.name
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Pessoas', url: '/people' },
            { page: `${person.name}`, url: `people/${person.id}` },
            { page: 'E-mails', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response.status === 500) this.props.history.push('/people')
        toastError(handleError(error))
      });
  }

  getEmail = async id => {
    await api.get(`emails/${id}`)
      .then(result => {
        const email = result.data;
        const fields = this.state.fields;

        fields[0].value = email.email_type;
        fields[1].value = email.email;

        this.setState({
          id: email.id,
          person_id: email.person_id,
          email_type: email.email_type || '',
          email: email.email || '',
          fields: fields
        })

        this.getPerson(email.person_id);
      }).catch(error => {
        if (error.response.status && error.response.status === 500) this.props.history.push('/people')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const email = this.state;
    const fields = this.state.fields;

    fields[0].value = email.email_type;
    fields[1].value = email.email;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const email = this.state;

    await api.post(`people/${email.person_id}/emails`, email)
      .then(result => {
        toastSuccess('Novo telefone incluído!');

        this.props.history.push(`/people/${email.person_id}`)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const email = this.state;

    await api.put(`emails/${email.id}`, email).then(() => {
      toastSuccess(`E-mail de ${email.person_name} atualizado!`);
    }).catch(error => {
      toastError(handleError(error));
    })
  }

  render() {
    const email = this.state;

    return (
      <Widget
        title={email.id ? 'Editar E-mail' : 'Novo E-mail'}
        description="">
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="col col-md-3">
                <Select
                  label="* Tipo"
                  value={email.email_type}
                  options={email.fields[0].options}
                  onChange={this.handleChange}
                  field={email.fields[0]}
                  validate={(e, field) =>
                    this.validate(e, email.fields[0])}
                />
              </div>

              <div className="col-sm col-md-6">
                <InputText
                  label="* E-mail"
                  value={email.email}
                  onChange={this.handleChange}
                  field={email.fields[1]}
                  validate={(e, field) =>
                    this.validate(e, email.fields[1])}
                />
              </div>

              <div className="row col-6 p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-4 mt-3">
                  <Link to="#">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!email.valid}
                      onClick={this.submit}>
                      {email.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3 mt-3">
                  <Link to={`/people/${email.person_id}`}>
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;