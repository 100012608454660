import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setConfig } from '../../actions/config'

import { MdSettings } from 'react-icons/md'

class ToggleRightSidebar extends Component {
  constructor() {
    super()
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this)
  }
  toggleRightSidebar() {
    this.props.setConfig('rightSidebar', !this.props.config.rightSidebar)
  }
  render() {
    return (
      <li className="nav-item">
        <div
          className="nav-link toggle-right-sidebar"
          onClick={this.toggleRightSidebar}
          style={{ 'cursor': 'pointer' }}>
          <MdSettings size={24} />
        </div>
      </li>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setConfig: (key, value) => dispatch(setConfig(key, value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ToggleRightSidebar)
