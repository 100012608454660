import api from './api';
import { toastError } from './toast';
import { handleError } from './handle-error';

const preparePDFFile = data => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  return fileURL;
};

export const generateHarvestPackingReport = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`reports/harvest-packing`, params)
      .then(response => {
        const fileURL = preparePDFFile(response.data);
        resolve(fileURL);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};

export const generateHarvestPackingTeamReport = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`reports/harvest-packing/teams`, params)
      .then(response => {
        const fileURL = preparePDFFile(response.data);
        resolve(fileURL);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};

export const generateHarvestPackingSummaryReport = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`reports/harvest-packing/summary`, params)
      .then(response => {
        const fileURL = preparePDFFile(response.data);
        resolve(fileURL);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};

export const generateHarvestPackingTeamsSummaryReport = async params => {
  const result = new Promise((resolve, reject) => {
    api
      .get(`reports/harvest-packing/teams/summary`, params)
      .then(response => {
        const fileURL = preparePDFFile(response.data);
        resolve(fileURL);
      })
      .catch(error => {
        reject(null);
        toastError(handleError(error));
      });
  });
  return result;
};
