import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import DateTime from '../../../components/date-time';
import InputText from '../../../components/input-text';
import InputCurrencyBudget from '../../../components/input-mask-currency-budget';
import InputTextBudget from '../../../components/input-text-budget';
import Loading from '../../../components/loading';
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiFillFileText,
} from 'react-icons/ai';
import { FaFilter } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';
import {
  stringToDate,
  currencyToString,
  floatToCurrency,
} from '../../../actions/util';

import './style.css';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleBudgetMonthValue = this.handleBudgetMonthValue.bind(this);
    this.handleKeyEnter = this.handleKeyEnter.bind(this);

    this.scrollToLeft = this.scrollToLeft.bind(this);
    this.repeatLeft = this.repeatLeft.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.repeatRight = this.repeatRight.bind(this);
    this.scrollToRight = this.scrollToRight.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);

    this.state = {
      errors: [],
      id: '',
      initial_date: '',
      loading: false,
      end_date: '',
      name: '',
      crops: [],
      budgets_accountings: [],
      budgets_months: [],
      months_and_amounts: [],
      recipe: {},
      recipe_months: [],
      expense: {},
      expense_months: [],
      hasUpdates: false,
      fields: [
        {
          value: '', //0
          name: 'initial_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //1
          name: 'end_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //2
          name: 'name',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //3
          name: 'expected_value',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //4
          name: 'real_value',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //5
          name: 'divergence',
          type: 'text',
          errors: [],
          rules: {},
        },
      ],
      valid: false,
    };

    this.time_clicked = undefined;
    this.start = 100;
    this.selectedTableScroller = '';
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getBudget(id);
  }

  validateField(e, field, fields) {
    e.preventDefault();

    field.value = '';
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password;
    }
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e, budget_accounting_month) {
    e.preventDefault();
    const budget = this.state;

    let fields = this.submitForm(e, budget.fields);
    this.setState({ fields: fields });

    this.updateBudgetAccountingMonth(budget_accounting_month);
    return false;
  }

  updateBudgetAccountingMonth = async budget_accounting_month => {
    const budget_accounting_month_ = {
      id: budget_accounting_month.id,
      expected_value: currencyToString(budget_accounting_month.expected_value),
    };

    if (budget_accounting_month.id && budget_accounting_month.expected_value) {
      await api
        .put(
          `budget-accounting-months/${budget_accounting_month_.id}`,
          budget_accounting_month_
        )
        .then(result => {
          toastSuccess('Valor atualizado!');
          this.setState({ hasUpdates: true });
        })
        .catch(error => {
          toastError(handleError(error));
        });
    }
  };

  getBudget = async id => {
    this.setState({ loading: true });
    await api
      .get(`budgets/${id}`)
      .then(result => {
        const budget = result.data;
        const { fields } = this.state;

        fields[2].value = budget.name;

        const budgets_months_ = this.getBudgetMonths(
          budget.budgets_accountings[0].budget_accounting_months
        );

        for (let i = 0; i < budget.budgets_accountings.length; i++) {
          const element = budget.budgets_accountings[i];

          element.expected_total = floatToCurrency(element.expected_total);
          element.real_total = floatToCurrency(element.real_total);

          for (let x = 0; x < element.budget_accounting_months.length; x++) {
            const item = element.budget_accounting_months[x];
            item.expected_value = floatToCurrency(item.expected_value);
            item.real_value = floatToCurrency(item.real_value);
            item.position = `${i},${x}`;
          }
        }

        this.setState({
          id: budget.id,
          initial_date: stringToDate(budget.initial_date) || new Date(),
          end_date: stringToDate(budget.end_date) || new Date(),
          name: budget.name || '',
          crops: budget.crops || [],
          budgets_accountings: budget.budgets_accountings || [],
          budgets_months: budgets_months_,
          recipe: budget.recipe || {},
          recipe_months: budget.recipe.months || [],
          expense: budget.expense || {},
          expense_months: budget.expense.months || [],
          loading: false,
          hasUpdates: false,
          fields: fields,
        });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getBudgetMonths = months => {
    const months_ = [];

    for (let i = 0; i < months.length; i++) {
      const item = months[i];
      const m = item.month.slice(-2);
      const year = item.month.slice(2, -3);

      if (m === '01') months_.push({ ref: `Jan - ${year}` });
      if (m === '02') months_.push({ ref: `Fev - ${year}` });
      if (m === '03') months_.push({ ref: `Mar - ${year}` });
      if (m === '04') months_.push({ ref: `Abr - ${year}` });
      if (m === '05') months_.push({ ref: `Mai - ${year}` });
      if (m === '06') months_.push({ ref: `Jun - ${year}` });
      if (m === '07') months_.push({ ref: `Jul - ${year}` });
      if (m === '08') months_.push({ ref: `Ago - ${year}` });
      if (m === '09') months_.push({ ref: `Set - ${year}` });
      if (m === '10') months_.push({ ref: `Out - ${year}` });
      if (m === '11') months_.push({ ref: `Nov - ${year}` });
      if (m === '12') months_.push({ ref: `Dez - ${year}` });
    }

    return months_;
  };

  handleBudgetMonthValue = (event, budget_accounting_month) => {
    const { budgets_accountings } = this.state;

    const positions = budget_accounting_month.position.split(',');
    budgets_accountings[positions[0]].budget_accounting_months[
      positions[1]
    ].expected_value = event.target.value;

    this.submit(event, budget_accounting_month);
  };

  handleKeyEnter = (event, budget_accounting_month) => {
    if (event.key === 'Enter') {
      this.handleBudgetMonthValue(event, budget_accounting_month);
      // const { budgets_accountings } = this.state

      // const positions = budget_accounting_month.position.split(',')
      // budgets_accountings[positions[0]].budget_accounting_months[positions[1]].expected_value = event.target.value
      // this.submit(event, budget_accounting_month)
    }
  };

  viewDashboardBudget = (budget, accounting) => {
    if (accounting.accounting) {
      this.props.dispatch({
        type: 'SET_SELECTED_ACCOUNTING',
        selectedAccounting: {
          budget_id: budget.id,
          name: accounting.name,
          id: accounting.id,
          type: accounting.accounting.accounting_type,
        },
      });

      this.props.dispatch({
        type: 'SET_SELECTED_BUDGET',
        selectedBudget: { name: budget.name, id: budget.id },
      });

      this.props.history.push(
        `/dashboard/budgets/accountings/${accounting.id}`
      );
    }
  };

  updateTotalFields = () => {
    const { id } = this.state;
    this.getBudget(id);
  };

  // init
  scrollToLeft = selectedTableScroller => {
    this.selectedTableScroller = selectedTableScroller;
    this.repeatLeft();
  };

  repeatLeft() {
    this.scrollLeft();
    this.time_clicked = setTimeout(this.repeatLeft, this.start);
    this.start = this.start / 2;
  }

  scrollLeft() {
    const table = document.getElementById(this.selectedTableScroller);
    table.scrollLeft -= 10;
  }

  scrollRight() {
    const table = document.getElementById(this.selectedTableScroller);
    table.scrollLeft += 10;
  }

  repeatRight() {
    this.scrollRight();
    this.time_clicked = setTimeout(this.repeatRight, this.start);
    this.start = this.start / 2;
  }

  scrollToRight = selectedTableScroller => {
    this.selectedTableScroller = selectedTableScroller;
    this.repeatRight();
  };

  onMouseUp() {
    clearTimeout(this.time_clicked);
    // this.selectedTableScroller = undefined
    this.start = 100;
  }

  scrollTableHome = selectedTableScroller => {
    this.selectedTableScroller = selectedTableScroller;
    const table = document.getElementById(this.selectedTableScroller);
    table.scrollLeft -= 9999999;
  };

  scrollTableEnd = selectedTableScroller => {
    this.selectedTableScroller = selectedTableScroller;
    const table = document.getElementById(this.selectedTableScroller);
    table.scrollLeft += 9999999;
  };

  render() {
    const budget = this.state;

    return (
      <Widget title={`Editar Orçamento`}>
        {budget.hasUpdates && (
          <div className="alert alert-warning budget-alert" role="alert">
            <h4 className="alert-heading text-center">ATENÇÃO!</h4>
            <div className="">
              <p>Você inseriu novos valores ao orçamento.</p>
              <p>
                Clique no botão ATUALIZAR para atualizar os campos de TOTAIS.
              </p>
            </div>
            <hr />
            <div class="mb-0 text-center">
              <button
                type="button"
                class="btn btn-success"
                onClick={this.updateTotalFields}
              >
                Atualizar Totais
              </button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="row">
                <div className="col-sm col-md-3">
                  <DateTime
                    label="Data de Início"
                    value={budget.initial_date}
                    disabled={true}
                    onChange={e => {}}
                    field={budget.fields[0]}
                    validate={(e, field) => this.validate(e, budget.fields[0])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <DateTime
                    label="Data de Término"
                    value={budget.end_date}
                    disabled={true}
                    onChange={e => {}}
                    field={budget.fields[1]}
                    validate={(e, field) => this.validate(e, budget.fields[1])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="* Nome"
                    value={budget.name}
                    onChange={e => {}}
                    disabled={true}
                    field={budget.fields[2]}
                    validate={(e, field) => this.validate(e, budget.fields[2])}
                  />
                </div>
              </div>

              <div className="row justify-content-end">
                <div className="col col-md-3 ">
                  <Link to="/budgets">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>

              <div className="row mt-2">
                {budget.crops.map((crop, i) => (
                  <div key={i} className="col">
                    <Link to={`/crops/${crop.id}`}>
                      <div className="col card text-center border-success p-0 m-0">
                        <h6 className="m-2">{crop.name}</h6>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              {!budget.loading && (
                <div className="row mt-2 p-0 m-0">
                  <div className="row col bg-table-budget justify-content-between p-0 m-0">
                    <div className="col text-center">
                      <span>Resumo Orçamento</span>
                    </div>

                    <div className="d-flex p-1">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onMouseUp={this.onMouseUp}
                          onMouseDown={() =>
                            this.scrollToLeft('scroller-resume')
                          }
                        >
                          <AiOutlineLeft />
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onMouseUp={this.onMouseUp}
                          onMouseDown={() =>
                            this.scrollToRight('scroller-resume')
                          }
                        >
                          <AiOutlineRight />
                        </button>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() =>
                            this.scrollTableHome('scroller-resume')
                          }
                        >
                          <AiOutlineDoubleLeft />
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => this.scrollTableEnd('scroller-resume')}
                        >
                          <AiOutlineDoubleRight />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="scrollme-resume" id="scroller-resume">
                    <table className="table table-sm table-striped table-bordered table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="headcol">
                            <div className="col headcol-id">#</div>
                          </th>
                          <th scope="col" className="headcol">
                            <div className="col headcol-title">Tipo</div>
                          </th>
                          {budget.budgets_months.map((month, i) => (
                            <th scope="col" key={i} className="text-center">
                              <span className="ref-month">{month.ref}</span>
                              <div className="d-flex">
                                <div className="col">Previsto</div>
                                <div className="col">Realizado</div>
                                <div className="col">Divergência</div>
                              </div>
                            </th>
                          ))}
                          <th scope="col" className="text-center">
                            <span className="ref-month">Total Geral</span>
                            <div className="d-flex">
                              <div className="col">Previsto</div>
                              <div className="col">Realizado</div>
                              <div className="col">Divergência</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="headcol-content">
                            {budget.recipe.id}
                          </td>
                          <td className="headcol-content">
                            {budget.recipe.name}
                          </td>
                          {budget.recipe_months.map((recipe_month, y) => (
                            <td key={y}>
                              <div className="d-flex">
                                <InputCurrencyBudget
                                  disabled={true}
                                  value={recipe_month.expected_value}
                                  onChange={e => {}}
                                  name="expected_value"
                                />
                                <InputCurrencyBudget
                                  value={recipe_month.real_value}
                                  name="real_value"
                                  disabled={true}
                                  onChange={e => {}}
                                />
                                <InputTextBudget
                                  value={recipe_month.divergence}
                                  name="divergence"
                                  disabled={true}
                                  onChange={e => {}}
                                />
                                %
                              </div>
                            </td>
                          ))}

                          <td>
                            <div className="d-flex">
                              <InputCurrencyBudget
                                value={budget.recipe.expected_total}
                                onChange={e => {}}
                                name="expected_value"
                              />
                              <InputCurrencyBudget
                                value={budget.recipe.real_total}
                                name="real_total"
                                disabled={true}
                                onChange={e => {}}
                              />
                              <InputTextBudget
                                value={budget.recipe.divergence}
                                name="divergence"
                                disabled={true}
                                onChange={e => {}}
                              />
                              %
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="headcol-content">
                            {budget.expense.id}
                          </td>
                          <td className="headcol-content">
                            {budget.expense.name}
                          </td>
                          {budget.expense_months.map((expense_month, y) => (
                            <td key={y}>
                              <div className="d-flex">
                                <InputCurrencyBudget
                                  disabled={true}
                                  value={expense_month.expected_value}
                                  onChange={e => {}}
                                  name="expected_value"
                                />
                                <InputCurrencyBudget
                                  value={expense_month.real_value}
                                  name="real_value"
                                  disabled={true}
                                  onChange={e => {}}
                                />
                                <InputTextBudget
                                  value={expense_month.divergence}
                                  name="divergence"
                                  disabled={true}
                                  onChange={e => {}}
                                />
                                %
                              </div>
                            </td>
                          ))}

                          <td>
                            <div className="d-flex">
                              <InputCurrencyBudget
                                value={budget.expense.expected_total}
                                onChange={e => {}}
                                name="expected_value"
                              />
                              <InputCurrencyBudget
                                value={budget.expense.real_total}
                                name="real_total"
                                disabled={true}
                                onChange={e => {}}
                              />
                              <InputTextBudget
                                value={budget.expense.divergence}
                                name="divergence"
                                disabled={true}
                                onChange={e => {}}
                              />
                              %
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {!budget.loading && (
                <div className="row mt-2 p-0 m-0">
                  <div className="row col bg-table-budget justify-content-between p-0 m-0">
                    <div className="col text-center">
                      <span>{budget.name}</span>
                    </div>

                    <div className="d-flex p-1">
                      <div className="col">
                        <Link to={`/budgets/${budget.id}/filter`}>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                          >
                            <FaFilter size={16} data-tip="" data-for="filter" />
                            <ReactTooltip id="filter">
                              Filtrar Orçamento
                            </ReactTooltip>
                          </button>
                        </Link>
                      </div>

                      <div className="col">
                        <Link to={`/budgets/${budget.id}/resume`}>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                          >
                            <AiFillFileText
                              size={16}
                              data-tip=""
                              data-for="resume"
                            />
                            <ReactTooltip id="resume">
                              Resumo Orçamento
                            </ReactTooltip>
                          </button>
                        </Link>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onMouseUp={this.onMouseUp}
                          onMouseDown={() =>
                            this.scrollToLeft('scroller-budget')
                          }
                        >
                          <AiOutlineLeft />
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onMouseUp={this.onMouseUp}
                          onMouseDown={() =>
                            this.scrollToRight('scroller-budget')
                          }
                        >
                          <AiOutlineRight />
                        </button>
                      </div>

                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() =>
                            this.scrollTableHome('scroller-budget')
                          }
                        >
                          <AiOutlineDoubleLeft />
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => this.scrollTableEnd('scroller-budget')}
                        >
                          <AiOutlineDoubleRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="scrollme" id="scroller-budget">
                    <table className="table table-sm table-striped table-bordered table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="headcol">
                            <div className="col headcol-id">#</div>
                          </th>
                          <th scope="col" className="headcol">
                            <div className="col headcol-title">Categoria</div>
                          </th>
                          {budget.budgets_months.map((month, i) => (
                            <th scope="col" key={i} className="text-center">
                              <span className="ref-month">{month.ref}</span>
                              <div className="d-flex">
                                <div className="col">Previsto</div>
                                <div className="col">Realizado</div>
                                <div className="col">Divergência</div>
                              </div>
                            </th>
                          ))}
                          <th scope="col" className="text-center">
                            <span className="ref-month">Total Geral</span>
                            <div className="d-flex">
                              <div className="col">Previsto</div>
                              <div className="col">Realizado</div>
                              <div className="col">Divergência</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {budget.budgets_accountings.map(
                          (budget_accounting, x) => (
                            <tr key={x}>
                              <td
                                style={
                                  budget_accounting.accounting.number.length > 4
                                    ? {}
                                    : { fontWeight: 'bold', fontSize: '1rem' }
                                }
                              >
                                {budget_accounting.accounting.number}
                              </td>
                              <td
                                className="headcol-content "
                                onClick={e =>
                                  this.viewDashboardBudget(
                                    budget,
                                    budget_accounting.accounting
                                  )
                                }
                                style={
                                  budget_accounting.accounting.number.length > 4
                                    ? { cursor: 'pointer' }
                                    : { fontWeight: 'bold', fontSize: '1rem' }
                                }
                              >
                                <div className="div d-flex justify-content-between">
                                  {budget_accounting.accounting.name}
                                  {budget_accounting.accounting.number.length >
                                    4 && (
                                    <span
                                      style={{
                                        fontSize: '11px',
                                        color: '#53883D',
                                      }}
                                    >
                                      ver +
                                    </span>
                                  )}
                                </div>
                              </td>
                              {budget_accounting.budget_accounting_months.map(
                                (budget_accounting_month, y) => (
                                  <td key={y}>
                                    <div className="d-flex">
                                      <InputCurrencyBudget
                                        className={
                                          budget_accounting.accounting.number
                                            .length === 4
                                            ? 'font-weight-bold'
                                            : ''
                                        }
                                        disabled={
                                          budget_accounting.accounting.number
                                            .length === 4
                                        }
                                        value={
                                          budget_accounting_month.expected_value
                                        }
                                        // onChange={e => this.handleBudgetMonthValue(e, budget_accounting_month)}
                                        onKeyPress={e =>
                                          this.handleKeyEnter(
                                            e,
                                            budget_accounting_month
                                          )
                                        }
                                        onBlur={e =>
                                          this.handleBudgetMonthValue(
                                            e,
                                            budget_accounting_month
                                          )
                                        }
                                        name="expected_value"
                                      />
                                      <InputCurrencyBudget
                                        className={
                                          budget_accounting.accounting.number
                                            .length === 4
                                            ? 'font-weight-bold'
                                            : 'font-weight-lighter'
                                        }
                                        value={
                                          budget_accounting_month.real_value
                                        }
                                        name="real_value"
                                        disabled={true}
                                        onChange={e => {}}
                                      />
                                      <InputTextBudget
                                        className={
                                          budget_accounting.accounting.number
                                            .length === 4
                                            ? 'font-weight-bold'
                                            : 'font-weight-lighter'
                                        }
                                        value={
                                          budget_accounting_month.divergence
                                        }
                                        name="divergence"
                                        disabled={true}
                                        onChange={e => {}}
                                      />
                                      %
                                    </div>
                                  </td>
                                )
                              )}

                              <td>
                                <div className="d-flex">
                                  <InputCurrencyBudget
                                    value={budget_accounting.expected_total}
                                    onChange={e => {}}
                                    name="expected_value"
                                  />
                                  <InputCurrencyBudget
                                    value={budget_accounting.real_total}
                                    name="real_total"
                                    disabled={true}
                                    onChange={e => {}}
                                  />
                                  <InputTextBudget
                                    value={budget_accounting.divergence}
                                    name="divergence"
                                    disabled={true}
                                    onChange={e => {}}
                                  />
                                  %
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {budget.loading && (
                <div className="row justify-content-center mt-5">
                  <Loading />
                </div>
              )}
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
