import styled from 'styled-components'

export const Caption = styled.div`
  font-size: 14px;
  font-weight: bold;
  
  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Label = styled.div`
  font-size: 18px;
  color: #4D4D4D;
  font-weight: bold;

  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Crop = styled.div`
  background-color: ${props => props.children[0].props.children === 'Total' ? '' : '#79C047'};
  color: ${props => props.children[0].props.children === 'Total' ? '' : '#fff'};
  font-weight: bold;
  padding: 5px;
  margin: 5px;

  @media (max-width: 1024px) {
    .text-right {
      text-align: left !important;
    }
  }
`
export const Tillage = styled.div`
  background-color: #CDF0B4;
  padding: 5px;
  font-weight: bold;

  @media (max-width: 1024px) {
    .text-right {
      text-align: left !important;
    }
  }
`

export const HeaderInsumo = styled.div`
  padding: 5px;
  font-weight: bold;
`
export const Insumos = styled.div`
  padding-left: 5px;
`