import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { GiPlainCircle } from 'react-icons/gi'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      id: '',
      name: '',
      description: '',
      add_quality: false,
      edit_quality: false,
      quality_name: '',
      quality_id: '',
      qualities: [],
      modal: false,
      item_delete_id: null,
      item_delete_name: '',
      fields: [
        {
          value: '', //0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'quality_name',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getCultivation(id)
    this.setState({ id: id })
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const cultivation = this.state

    let fields = this.submitForm(e, cultivation.fields)
    this.setState({ fields: fields })

    if (cultivation.valid && cultivation.errors.length === 0) {
      cultivation.id ? this.update() : this.save()
    }
    return false
  }

  getCultivation = async id => {
    await api.get(`cultivations/${id}`)
      .then(result => {
        const cultivation = result.data
        const fields = this.state.fields

        fields[0].value = cultivation

        this.setState({
          id: cultivation.id,
          name: cultivation.name,
          qualities: cultivation.qualities || [],
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Cultivos', url: '/cultivations' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/cultivations')
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    const name = event.target.name
    const cultivation = this.state
    const fields = this.state.fields

    fields[0].value = cultivation.name

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const { name } = this.state

    await api.post('cultivations', { name: name })
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push(`/cultivations/${result.data.id}`)
        this.getCultivation(result.data.id)
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  update = async () => {
    const cultivation = this.state

    await api.put(`cultivations/${cultivation.id}`, cultivation)
      .then(result => {
        toastSuccess(`Registro de ${cultivation.name} atualizado!`)
        this.props.history.push('/cultivations')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  addQuality = () => {
    this.setState({ add_quality: true })
  }

  editQuality = item => {
    this.setState({
      quality_name: item.name,
      edit_quality: true,
      quality_id: item.id,
      add_quality: true
    })
  }

  submitQuality = () => {
    const { edit_quality } = this.state

    if (edit_quality) {
      this.updateQuality()
    } else {
      this.includeQuality()
    }
  }

  includeQuality = async () => {
    const { id, quality_name } = this.state

    await api.post(`cultivations/${id}/qualities`, { name: quality_name })
      .then(result => {
        toastSuccess('Nova qualidade adicionada!')
        this.setState({ quality_name: '', add_quality: false })
        this.getCultivation(id)
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  updateQuality = async () => {
    const { id, quality_name, quality_id } = this.state

    await api.put(`cultivations/${id}/qualities/${quality_id}`, { name: quality_name })
      .then(result => {
        toastSuccess('Qualidade Atualizada!')
        this.setState({ quality_name: '', add_quality: false, quality_id: '', edit_quality: false })
        this.getCultivation(id)
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  onConfirmDelete = item => {
    this.setState({ modal: true, item_delete_id: item.id, item_delete_name: item.name })
  }

  onDeleteItem = () => {
    const { id, item_delete_id, item_delete_name } = this.state

    setTimeout(() => {
      api.delete(`cultivations/${id}/qualities/${item_delete_id}`)
        .then(() => {
          toastSuccess(`Registro ${item_delete_name} removido com sucesso!`)
          this.getCultivation(id)
          this.setState({
            modal: false,
            item_delete_id: null,
            item_delete_name: '',
          })
        })
        .catch(error => {
          toastError(handleError(error))
        })
    }, 100)
  }

  render() {
    const cultivation = this.state

    return (
      <Widget
        title={cultivation.id ? `Editar ${cultivation.name}` : 'Novo Cultivo'}
        description="">
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col">
                  <InputText
                    label="* Nome"
                    value={cultivation.name}
                    onChange={this.handleChange}
                    field={cultivation.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, cultivation.fields[0])}
                  />
                </div>
              </div>

              {cultivation.id &&
                <div className="col mt-3">
                  <div className="row p-0 m-0">
                    <h6 className="form-control-h6 mr-2 mt-1"><b>{cultivation.qualities.length === 0 ? 'Adicionar Qualidade' : 'Qualidades'}</b></h6>
                    <Link to="#" onClick={this.addQuality}>
                      <i className="material-icons text-success">add_box</i>
                    </Link>
                  </div>
                </div>
              }

              {cultivation.add_quality &&
                <div className="col mt-2">
                  <div className="card">
                    <div className="row p-2">
                      <div className="col-sm">
                        <InputText
                          label="Nome"
                          value={cultivation.quality_name}
                          onChange={event => this.setState({ quality_name: event.target.value })}
                          field={cultivation.fields[1]}
                          validate={(e, field) =>
                            this.validate(e, cultivation.fields[1])}
                        />
                      </div>

                      <div className="row col p-0 m-0 align-self-center justify-content-between">
                        <Link to="#" className="col-sm">
                          <button className="btn btn-success btn-block" onClick={this.submitQuality}>
                            {cultivation.edit_quality ? 'Atualizar' : 'Incluir'}
                          </button>
                        </Link>
                        <Link to="#" className="col-sm">
                          <button className="btn btn-danger btn-block" onClick={() => this.setState({ add_quality: false, quality_name: '' })}>
                            Cancelar
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {cultivation.qualities.length > 0 &&
                <div className="col mt-3">
                  <ul className="list-group card">
                    {cultivation.qualities.map((quality, i) => (
                      <li key={i} className="list-group-item">
                        <div className="row">
                          <GiPlainCircle size={6} className="mt-2" />
                          <Link className="col" to="#" onClick={() => this.editQuality(quality)}>
                            <div className=" row col-md-2 mr-2">
                              <span>{quality.name}</span>
                            </div>
                          </Link>

                          <div className="col flex-40 align-self-center">
                            <i
                              className="material-icons text-danger btn-list-person"
                              onClick={e => this.onConfirmDelete(quality)}>
                              delete_forever
                            </i>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!cultivation.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/cultivations">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir {this.state.item_delete_name}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
            </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, item_delete_id: null, item_delete_name: null })}>
              Cancelar
            </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default Index