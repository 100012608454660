import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputMask from '../../components/input-mask'
import DateTime from '../../components/date-time'
import Select from '../../components/select'

import api from '../../actions/api'
import { stringToDate, timeToFloat, floatToTime } from '../../actions/util'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      id: '',
      date: new Date(),
      crop_id: '',
      amount_of_hours: '',
      amount_of_hours_format: '000:00',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'crop_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'amount_of_hours_format',
          type: 'text',
          options: [],
          placeholder: '0.0',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getIrrigation(id)
    this.setState({ id: id })
    this.getCrops()
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const irrigation = this.state

    let fields = this.submitForm(e, irrigation.fields)
    this.setState({ fields: fields })

    if (irrigation.valid && irrigation.errors.length === 0) {
      irrigation.id ? this.update() : this.save()
    }
    return false
  }

  getIrrigation = async id => {
    await api.get(`irrigations/${id}`)
      .then(result => {
        const irrigation = result.data
        const fields = this.state.fields

        fields[0].value = irrigation.date
        fields[1].value = irrigation.crop_id
        fields[2].value = irrigation.amount_of_hours_format

        this.setState({
          id: irrigation.id,
          date: irrigation.date ? stringToDate(irrigation.date) : '',
          crop_id: irrigation.crop_id || '',
          amount_of_hours_format: irrigation.amount_of_hours ? floatToTime(irrigation.amount_of_hours) : '000:00',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Irrigações', url: '/irrigations' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/irrigations')
        toastError(handleError(error))
      })
  }

  getCrops = async () => {
    await api.get('crops')
      .then(result => {
        const crops = result.data
        const options = [{ label: '', value: null }]

        crops.map(crop => {
          const p = {
            label: crop.name,
            value: crop.id
          }
          options.push(p)
          return null
        })
        const { fields } = this.state

        fields[1].options = options
        this.setState({
          fields: fields
        })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    const name = event.target.name
    const irrigation = this.state
    const fields = this.state.fields

    fields[0].value = irrigation.date
    fields[1].value = irrigation.crop_id
    fields[2].value = irrigation.amount_of_hours_format

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const irrigation = this.state
    irrigation.amount_of_hours = timeToFloat(irrigation.amount_of_hours_format)

    await api.post('irrigations', irrigation)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push('/irrigations')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  update = async () => {
    const irrigation = this.state
    irrigation.amount_of_hours = timeToFloat(irrigation.amount_of_hours_format)

    await api.put(`irrigations/${irrigation.id}`, irrigation)
      .then(result => {
        toastSuccess(`Registro de irrigação atualizado!`)
        this.props.history.push('/irrigations')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  render() {
    const irrigation = this.state

    return (
      <Widget
        title={irrigation.id ? `Editar Irrigação` : 'Novo Irrigação'}>
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col-sm col-md-3">
                  <DateTime
                    label="* Data"
                    value={irrigation.date}
                    onChange={date => this.setState({ date: date })}
                    field={irrigation.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, irrigation.fields[0])}
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Safra"
                    value={irrigation.crop_id}
                    options={irrigation.fields[1].options}
                    onChange={this.handleChange}
                    field={irrigation.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, irrigation.fields[1])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <InputMask
                    label="* Horas Trabalhadas"
                    value={irrigation.amount_of_hours_format}
                    onChange={this.handleChange}
                    mask="999:99"
                    field={irrigation.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, irrigation.fields[2])}
                  />
                </div>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-4 mt-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!irrigation.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3  mt-3">
                  <Link to="/irrigations">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index