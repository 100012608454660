import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GiStopwatch } from 'react-icons/gi';
import { BsFillClockFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import TotalHours from './TotalHours';
import { floatToTimeList } from '../../../actions/util';

const MachineTimesDashboard = ({ data, budget_id }) => {
  const [cropHours, setCropHours] = useState([]);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const _data = data.filter(item => item.name !== 'total');
    setCropHours(_data);
  }, [data]);
  useEffect(() => {
    const _hour = data.filter(item => item.name === 'total');

    if (_hour.length > 0) {
      setTotalHours(_hour[0].value);
    }
  }, [data]);
  return (
    <Container className="mb-5">
      <h1 className="text-center">
        <GiStopwatch size={25} className="mb-1" /> Horas Máquina
      </h1>

      <div className="row justify-content-center">
        {cropHours.length === 0 && (
          <div className="col-sm col-md-3 mb-2">
            <div className="card shadow">
              <Link to="/machine-times">
                <Alerta className="card-body mb-4">
                  Horas Máquina ainda não possui lançamentos!
                </Alerta>
              </Link>
            </div>
          </div>
        )}

        {cropHours.length > 0 && (
          <>
            <TotalHours total_hours={totalHours} />

            {cropHours.map((reg, i) => (
              <div key={i} className="col-sm col-md-3">
                <Link to={`dashboard/irrigations/${budget_id}`}>
                  <div className="card shadow">
                    <div className="card-body">
                      <h5 className="card-title text-center">{reg.name}</h5>

                      <div className="table-responsive">
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td>{floatToTimeList(reg.value)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer text-center">
                      <small className="text-muted">
                        <BsFillClockFill size={15} className="mb-1" /> TOTAL DE
                        HORAS
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export default MachineTimesDashboard;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  padding-bottom: 30px;

  h1 {
    color: #4e3c32;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  h5 {
    color: #4e3c32;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;
  }

  a {
    :hover {
      h5 {
        color: #fff;
      }
      td {
        color: #fff;
      }
      small {
        color: #fff !important;
      }

      .card {
        background-color: #4e3c32;
      }
    }
  }

  .table td {
    font-size: 30px;
    font-weight: bold;
    color: #4e3c32;
    margin-top: 20px;
    border: 0;
  }

  .table tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 0;
  }

  .table th {
    border: 0;
  }

  .card {
    height: 100%;
    border-radius: 0;
  }

  .card-body {
    padding-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    background: none !important;
    border: none !important;

    small {
      color: #4e3c32 !important;
    }
  }

  @media screen and (max-width: 1290px) {
    h5 {
      font-size: 12px;
    }
  }
`;
export const Alerta = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 12px;
  padding: 10px;
  color: #505050;
`;
