import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../components/widget';
import InputText from '../../components/input-text';
import Select from '../../components/select';
import DateTime from '../../components/date-time';
import InputCurrency from '../../components/input-mask-currency';
import RadioButton from '../../components/radio-button';

import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import {
  currencyToString,
  formatPrice,
  stringToDate,
} from '../../actions/util';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      person: '',
      person_id: '',
      function_id: '',
      admission_date: '',
      salary: 0,
      dismissal_date: '',
      dismissal_reason: '',
      status: '',
      fields: [
        {
          value: '', // 0
          name: 'person_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório',
            },
          },
        },
        {
          value: '', // 1
          name: 'function_id',
          type: 'text',
          errors: [],
          options: [{ label: '', value: null }],
          rules: {
            required: {
              message: 'Campo função é obrigatório',
            },
          },
        },
        {
          value: '', // 2
          name: 'admission_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo data de admissão é obrigatório',
            },
          },
        },
        {
          value: '', // 3
          name: 'salary',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 4
          name: 'dismissal_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 5
          name: 'dismissal_reason',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', // 6
          name: 'status',
          type: 'radio',
          errors: [],
          options: [
            { label: 'Ativo', value: 'ATIVO' },
            { label: 'Demitido', value: 'DEMITIDO' },
          ],
          rules: {
            required: {
              message: 'Campo nome é obrigatório',
            },
          },
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    const { id, person_id } = this.props.match.params;

    if (id) {
      this.getEmployee(id);
    } else {
      this.getPeople(person_id);
    }

    this.getFunctions();
  }

  getPeople = async person_id => {
    await api.get('people?page=1&perPage=99999').then(result => {
      const { fields } = this.state;
      const options = [];

      for (let i = 0; i < result.data.data.length; i++) {
        const person = result.data.data[i];

        if (!person.employee_id) {
          options.push({
            value: person.id,
            label: person.name,
          });
        }
      }

      options.sort((a, b) => {
        return a.label < b.label ? -1 : 1;
      });

      fields[0].options = options;
      fields[0].options.unshift({ id: null, label: '' });

      this.setState({
        fields,
        person_id,
      });
    });
  };

  getFunctions = () => {
    api.get('functions').then(result => {
      const functions = result.data;
      const options = [{ label: '', value: null }];

      functions.map(func => {
        const f = {
          label: func.name,
          value: func.id,
        };
        options.push(f);
        return null;
      });
      const { fields } = this.state;

      fields[1].options = options;
      this.setState({
        fields,
      });
    });
  };

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    const result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      }
      return item;
    });
    return fields;
  }

  validate(e, field) {
    const fields = this.validateField(e, field, this.state.fields);
    const errors = [];
    let valid = false;

    fields.map(field => {
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields,
      errors,
      valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const employee = this.state;

    const fields = this.submitForm(e, employee.fields);
    this.setState({ fields });

    if (employee.valid && employee.errors.length === 0) {
      employee.id ? this.update() : this.save();
    }
    return false;
  }

  getEmployee = async id => {
    await api
      .get(`employees/${id}`)
      .then(result => {
        const employee = result.data;
        const { fields } = this.state;

        fields[0].value = employee.person.id;
        fields[1].value = employee.function_id;
        fields[2].value = employee.admission_date;
        fields[3].value = formatPrice(employee.salary);
        fields[4].value = employee.dismissal_date;
        fields[5].value = employee.dismissal_reason;
        fields[6].value = employee.dismissal_date ? 'DEMITIDO' : 'ATIVO';

        fields[0].options = [
          {
            value: employee.person_id,
            label: employee.person.name,
          },
        ];

        this.setState({
          id: employee.id || '',
          person: employee.person || '',
          person_id: employee.person_id || '',
          function_id: employee.function_id || '',
          admission_date: employee.admission_date
            ? stringToDate(employee.admission_date)
            : '',
          salary: formatPrice(employee.salary) || 0,
          dismissal_date: employee.dismissal_date
            ? stringToDate(employee.dismissal_date)
            : '',
          dismissal_reason: employee.dismissal_reason || '',
          status: employee.dismissal_date ? 'DEMITIDO' : 'ATIVO',
          fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Funcionários', url: '/employees' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/employees');
        toastError(handleError(error));
      });
  };

  handleChange(event) {
    const { name } = event.target;
    const employee = this.state;
    const { fields } = this.state;

    fields[0].value = employee.person_id;
    fields[1].value = employee.function_id;
    fields[2].value = employee.admission_date;
    fields[3].value = employee.salary;
    fields[4].value = employee.dismissal_date;
    fields[5].value = employee.dismissal_reason;
    fields[6].value = employee.dismissal_date ? 'DEMITIDO' : 'ATIVO';

    this.setState({
      [name]: event.target.value,
      // person_id: employee.person_id,
      fields,
    });
  }

  save = async () => {
    const employee = this.state;
    employee.salary = currencyToString(employee.salary);

    await api
      .post('employees', employee)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/employees');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  update = async () => {
    const employee = this.state;
    employee.salary = currencyToString(employee.salary);

    await api
      .put(`employees/${employee.id}`, employee)
      .then(result => {
        toastSuccess(`Registro de ${employee.name} atualizado!`);
        this.props.history.push('/employees');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const employee = this.state;

    return (
      <Widget
        title={
          employee.id ? `Editar ${employee.person.name}` : 'Novo Funcionário'
        }
      >
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="row m-0 p-0">
                {employee.id && (
                  <div className="col">
                    <RadioButton
                      label="Status"
                      value={employee.status}
                      options={employee.fields[6].options}
                      onChange={this.handleChange}
                      field={employee.fields[6]}
                      disabled
                      validate={(e, field) =>
                        this.validate(e, employee.fields[6])
                      }
                    />
                  </div>
                )}
              </div>

              <div className="col">
                <Select
                  label="* Nome"
                  value={employee.person_id}
                  options={employee.fields[0].options}
                  onChange={this.handleChange}
                  field={employee.fields[0]}
                  validate={(e, field) => this.validate(e, employee.fields[0])}
                />
              </div>

              <div className="col">
                <Select
                  label="* Função"
                  value={employee.function_id}
                  options={employee.fields[1].options}
                  onChange={this.handleChange}
                  field={employee.fields[1]}
                  validate={(e, field) => this.validate(e, employee.fields[1])}
                />
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <DateTime
                    label="* Data de Admissão"
                    value={employee.admission_date}
                    onChange={date => this.setState({ admission_date: date })}
                    field={employee.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[2])
                    }
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="* Salário"
                    value={employee.salary}
                    onChange={this.handleChange}
                    field={employee.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, employee.fields[3])
                    }
                  />
                </div>
              </div>

              {employee.dismissal_date && (
                <>
                  <div className="row m-0 p-0">
                    <div className="col col-md-3">
                      <DateTime
                        label="Data de Demissao"
                        value={employee.dismissal_date}
                        onChange={date =>
                          this.setState({ dismissal_date: date })
                        }
                        field={employee.fields[4]}
                        disabled
                        validate={(e, field) =>
                          this.validate(e, employee.fields[4])
                        }
                      />
                    </div>

                    <div className="col">
                      <InputText
                        label="Motivo da Demissão"
                        value={employee.dismissal_reason}
                        onChange={this.handleChange}
                        field={employee.fields[5]}
                        disabled
                        validate={(e, field) =>
                          this.validate(e, employee.fields[5])
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!employee.valid}
                      onClick={this.submit}
                    >
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                {employee.id && !employee.dismissal_date && (
                  <div className="col col-md-3 mt-3">
                    <Link to={`/employees/${employee.id}/dismissal`}>
                      <button
                        type="button"
                        className="btn btn-danger btn-block	"
                      >
                        Demitir
                      </button>
                    </Link>
                  </div>
                )}

                <div className="col col-md-2 mt-3">
                  <button
                    className="btn btn-success btn-block "
                    type="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
