import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setConfig } from '../../actions/config'
import ColorsSection from './ColorsSection'
import LayoutSection from './LayoutSection'
import '../../css/right-sidebar/right-sidebar.css'

class Index extends Component {
  constructor() {
    super()
    this.colors = [
      'light',
      'dark',
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'danger'
    ]

    this.setConfig = this.setConfig.bind(this)
  }

  setConfig(config, color) {
    this.props.setConfig(config, color)
  }

  render() {
    return (
      <div
        className={
          this.props.rightSidebar
            ? 'right-sidebar-outer show-from-right'
            : 'right-sidebar-outer'
        }>
        <div className="right-sidebar-inner">
          <div className="right-sidebar">
            <h4><b>Configuração Tema</b></h4>
            <ul className="list-group unbordered unstriped">
              <LayoutSection
                title="Default sidebar"
                layout="default-sidebar"
                onClick={this.setConfig}
                config={'layout'}
              />
              <LayoutSection
                title="Collapsed sidebar"
                layout="collapsed-sidebar"
                onClick={this.setConfig}
                config={'layout'}
              />
              <LayoutSection
                title="Top Navgation"
                layout="top-navigation-1"
                onClick={this.setConfig}
                config={'layout'}
              />
            </ul>
            <ColorsSection
              title="Navbar color"
              colors={this.colors}
              onClick={this.setConfig}
              config={'navbar'}
            />
            <ColorsSection
              title="Top navigation color"
              colors={this.colors}
              onClick={this.setConfig}
              config={'topNavigation'}
            />
            <ColorsSection
              title="Logo color"
              colors={this.colors}
              onClick={this.setConfig}
              config={'logo'}
            />
            <ColorsSection
              title="Left sidebar color"
              colors={this.colors}
              onClick={this.setConfig}
              config={'leftSidebar'}
            />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    config: state.config,
    navbarColors: state.config.navbarColors,
    leftSidebarColors: state.config.leftSidebarColors,
    colors: state.colors,
    backgroundColors: state.backgroundColors,
    rightSidebar: state.config.rightSidebar
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setConfig: (key, value) => dispatch(setConfig(key, value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index)
