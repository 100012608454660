
import React, { useCallback, useEffect, useState } from 'react'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { dateToString, formatSimplePrice } from '../../actions/util'
import { index, remove } from '../../actions/base-rest'
import styled from 'styled-components'
import StocksTable from './StocksTable'
import StocksFilter from './filter/StocksFilter'
import TableTotalData from '../../components/table-total-data'
import PulseLoader from 'react-spinners/PulseLoader';

const URL = 'stocks'

const default_params = {
  page: 1,
  perPage: 20,
};

function Stocks () {

  const [params, setParams] = useState(default_params);

  const [scrolling, setScrolling] = useState(false);

  const [data, setData] = useState([])

  const [itemDelete, setItemDelete] = useState({});

  const [loading, setLoading] = useState(false)

  const [loadingFilter, setLoadingFilter] = useState(false)
  
  const [page, setPage] = useState({current_page: 0, has_next_page: false, total: 0})
  
  const actions = [
    {
      name: 'Nova Entrada',
      class_btn: 'btn-success',
      class_collumn: 'col col-md-2',
      route: 'stocks/entry/new'
    },
    {
      name: 'Nova Saída',
      class_btn: 'btn-danger',
      class_collumn: 'col col-md-2 ml-2',
      route: 'stocks/out/new'
    }
  ]
  
  const getStocks = useCallback(
    async (filter) => {
      let stocks = [];

      const isFiltering = Object.keys(filter).length > 2

      if(isFiltering) setLoadingFilter(true)

      setLoading(true);

      const response = await index(URL, filter);

      stocks = response.data?.map(item => ({
        ...item,
        date_format: dateToString(item.date),
        tillage_name: item.tillage ? item.tillage.name : '',
        average_price_format: formatSimplePrice(item.average_price),
        quantity_format: formatSimplePrice(item.quantity)
      }))

      if (scrolling) {
        const new_data = (data) => [...data, ...stocks];

        setData(new_data);

        setScrolling(false);
      } else {
        setData(stocks);
      }

      setPage({
        current_page: response.page,
        has_next_page: !isFiltering && (response.lastPage >= response.page) ? true : false,
        total: response.total,
        balance: response.balance,
      });

      setLoading(false);
      setLoadingFilter(false);
    },
    [scrolling]
  );
 
  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);
    
    if (response) {
      setItemDelete({id: null, description: null})

      getStocks(params)
    }
  }, [itemDelete, params, getStocks]);


  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_stoke'))

    if (filter) {
      getStocks(filter)
    }else {
      getStocks(params)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <Widget title="Estoque" actions={actions} line={false}>

      <StocksFilter setParams={setParams}/>

      {!loadingFilter && (
        <>
          <TableTotalData 
            total_itens={page.total}
            total_itens_exibidos={data.length}
            total_value={page.balance}/>

          <div className="row d-flex">
            <div className="col table">
              <StocksTable
                data={data}
                page={page}
                setItemDelete={setItemDelete}
                setParams={setParams}
                loading={loading}
                setScrolling={setScrolling}
              />
            </div>
          </div>
        </>
      )}

      {loadingFilter && (
        <div className="row mt-5">
          <div className="col text-center">
            <PulseLoader color="#388e3c" size={15} margin={10} />
          </div>
        </div>
      )} 

      <Modal
        isOpen={itemDelete.id ? true : false}
        wrapClassName="modal-danger"
        size="default">
        <ModalHeader>Deletar Registro</ModalHeader>
        <ModalBody>
          Deseja excluir movimento do produto {itemDelete.description}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success col-4" onClick={deleteItem}>
            Sim
          </button>
          <button className="btn btn-success" onClick={() => setItemDelete({id: null, description: null})}>
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    </Widget>
  )
}

export default Stocks


export const TableContainer = styled.section`
  height: 100vh;
  margin-top: -40px;

  h6 {
    font-size: 12px;
    margin-bottom: -20px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .bg-success {
    /* background-color: #E8E8E8 !important; */
    background-image: linear-gradient(white, #bfbfbf);
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;