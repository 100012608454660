import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import CropResumeDashboard from './crop-resume-dashboard/CropResumeDashboard';
import FinancialMovementsDay from './financial-movements-day/FinancialMovementsDay';
import AgriculturalInputs from './agricultural-inputs-dashboard/AgriculturalInputs';
import CargoPacking from './cargo-packings-dashboard/CargoPackingDashboard';
import Irrigations from './irrigations-dashboard/IrrigationsDashboard';
import MachineHours from './machine-hours-dashboard/MachineHoursDashboard';
import BudgetsDashboard from './budgets-dashboard/BudgetsDashboard';
import { indexBudgetResume } from '../../actions/budget';
import { indexResumeCrops } from '../../actions/crop';
import { indexFinancialMovements } from '../../actions/financial-movement';
import { resumeStockByInputType } from '../../actions/agricultural-input';
import { indexCargoPacking } from '../../actions/cargo-packing';
import { indexIrrigations } from '../../actions/irrigation';
import { indexMachineHours } from '../../actions/machine-hour';
import { dateToStringFilter } from '../../actions/util';
import { AuthContext } from '../../actions/auth-context';
import { useDispatch } from 'react-redux';

import './style.css';

const Dashboard = props => {
  const { defaultBudget } = useContext(AuthContext);

  const dispatch = useDispatch();

  const [financialMovementsDay, setFinancialMovementsDay] = useState([]);
  const [fito, setFito] = useState([]);
  const [ferti, setFert] = useState([]);
  const [outro, setOutro] = useState([]);
  const [combustivel, setCombustivel] = useState([]);
  const [cargoPackings, setCargoPackings] = useState([]);
  const [irrigations, setIrrigations] = useState([]);
  const [machineHours, setMachineHours] = useState([]);
  const [budgetResume, setBudgetResume] = useState([]);
  const [resumeCrops, setResumeCrops] = useState([]);

  const getResumeStockByInputType = useCallback(async budget_id => {
    let params = {
      input_type: '',
      budget_id: budget_id,
    };

    params.input_type = 'FITO';
    const _fito = await resumeStockByInputType(params);
    setFito(_fito);

    params.input_type = 'FERT';
    const _fert = await resumeStockByInputType(params);
    setFert(_fert);

    params.input_type = 'OUTRO';
    const _outro = await resumeStockByInputType(params);
    setOutro(_outro);

    params.input_type = 'COMB';
    const _combustivel = await resumeStockByInputType(params);
    setCombustivel(_combustivel);
  }, []);

  const getFinancialMovementsDay = useCallback(async () => {
    const params = {
      page: 1,
      perPage: 5,
      movement_type: 'DEBITO',
      init_due_date: dateToStringFilter(new Date()),
      end_due_date: dateToStringFilter(new Date()),
    };

    const _movements = await indexFinancialMovements(params);
    setFinancialMovementsDay(_movements);
  }, []);

  const getResumeCrops = useCallback(async budget_id => {
    const response = await indexResumeCrops({ budget_id: budget_id });
    setResumeCrops(response);
  }, []);

  const getResumeCargoPacking = useCallback(async budget_id => {
    const params = { budget_id: budget_id };

    const _cargoPackings = await indexCargoPacking(params);

    setCargoPackings(_cargoPackings);
  }, []);

  const getResumeIrrigations = useCallback(async budget_id => {
    const params = { budget_id: budget_id };
    const _irrigations = await indexIrrigations(params);
    setIrrigations(_irrigations);
  }, []);

  const getResumeMachineHours = useCallback(async budget_id => {
    const params = { budget_id: budget_id };
    const _machineHours = await indexMachineHours(params);
    setMachineHours(_machineHours);
  }, []);

  const getResumeBudget = useCallback(
    async budget_id => {
      const params = { budget_id: defaultBudget.value };

      const _budget_resume = await indexBudgetResume(params);

      setBudgetResume(_budget_resume);
    },
    [defaultBudget]
  );

  const loadDashboard = useCallback(
    budget => {
      getFinancialMovementsDay();
      getResumeStockByInputType(budget.value);
      getResumeCargoPacking(budget.value);
      getResumeIrrigations(budget.value);
      getResumeMachineHours(budget.value);
      getResumeBudget(budget.value);
      getResumeCrops(budget.value);

      dispatch({
        type: 'SET_SELECTED_BUDGET',
        selectedBudget: { name: budget.label, id: budget.value },
      });
    },
    [
      getFinancialMovementsDay,
      getResumeStockByInputType,
      getResumeCargoPacking,
      getResumeIrrigations,
      getResumeMachineHours,
      getResumeBudget,
      getResumeCrops,
      dispatch,
    ]
  );

  useEffect(() => {
    if (defaultBudget) loadDashboard(defaultBudget);
  }, [defaultBudget, loadDashboard]);

  return (
    <Container>
      <CropResumeDashboard resume={resumeCrops} />

      <FinancialMovementsDay movements={financialMovementsDay} />

      <BudgetsDashboard data={budgetResume} />

      <AgriculturalInputs
        fito={fito}
        ferti={ferti}
        outro={outro}
        combustivel={combustivel}
        budget_id={defaultBudget?.value}
      />

      <CargoPacking data={cargoPackings} />

      <Irrigations data={irrigations} budget_id={defaultBudget?.value} />

      <MachineHours data={machineHours} budget_id={defaultBudget?.value} />
    </Container>
  );
};

export default Dashboard;

export const Container = styled.div`
  select {
    height: 30px !important;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-bottom: -10px;
  text-transform: uppercase;
  font-size: 18px;
  justify-content: center;
  color: #53883d;
  margin-top: -30px;

  p {
    color: #505050;
    margin-left: 3px;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
