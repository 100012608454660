import styled from 'styled-components';

export const GroupData = styled.div`
  margin-top: 10px;
  background-color: #f5f5f5;
  padding: 0 20px 20px 20px;

  span {
    font-size: 10px;
    font-weight: 500;
    margin-left: -10px;
  }

  /* Ajuste para quando o componente precisa iniciar com um valor setado - exemplo tela novo pedido */
  .react-select__single-value {
    top: 50%;
  }
`;
