import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';
import Widget from '../../../components/widget';
import Loading from '../../../components/loading';
import Select from '../../../components/select';

import api from '../../../actions/api';
import { toastError } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';
import { floatToCurrency, currencyToString, formatPrice } from '../../../actions/util';

import './style.css';

class Index extends Component {
  constructor() {
    super();

    this.state = {
      id: '',
      loading: false,
      data: [],
      budget_accountings: [],
      budget_months: [],
      months_and_amounts: [],
      budget_name: '',
      month_ref: '',
      data_whith_value: false,
      expense_forecast_month: 0,
      expense_done_month: 0,
      recipe_forecast_month: 0,
      recipe_done_month: 0,
      titleFilter: "Filtrar Orçamento",
      fields: [
        {
          value: '', // 0
          name: 'month',
          type: 'text',
          errors: [],
          options: [],
          rules: {},
        },
        {
          value: '', // 1
          name: 'type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Todas', value: false },
            { label: 'Somente com valores', value: true },
          ],
          rules: {},
        },
      ],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getBudget(id);
  }

  getBudget = async id => {
    this.setState({ loading: true });
    await api
      .get(`budgets/${id}`)
      .then(result => {
        const budget = result.data;
        const { fields } = this.state;

        const budget_months_ = this.getBudgetMonths(
          budget.budgets_accountings[0].budget_accounting_months
        );

        for (let i = 0; i < budget.budgets_accountings.length; i++) {
          const element = budget.budgets_accountings[i];
          element.expected_total = floatToCurrency(element.expected_total);
          element.real_total = floatToCurrency(element.real_total);

          for (let x = 0; x < element.budget_accounting_months.length; x++) {
            const item = element.budget_accounting_months[x];
            item.expected_value = floatToCurrency(item.expected_value);
            item.real_value = floatToCurrency(item.real_value);
            item.position = `${i},${x}`;
          }
        }


        fields[0].options = budget_months_;
        fields[0].options.unshift({ label: 'Escolha um mês', value: 0 });

        this.setState({
          id: budget.id,
          month_ref: budget_months_[0].value,
          data: budget.budgets_accountings || [],
          budget_months_data: budget_months_,
          loading: false,
          budget_name: budget.name || '',
          fields,
        });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getBudgetMonths = months => {
    const months_ = [];

    for (let i = 0; i < months.length; i++) {
      const item = months[i];
      const m = item.month.slice(-2);
      const year = item.month.slice(2, -3);

      if (m === '01')
        months_.push({ label: `Jan - ${year}`, value: item.month });
      if (m === '02')
        months_.push({ label: `Fev - ${year}`, value: item.month });
      if (m === '03')
        months_.push({ label: `Mar - ${year}`, value: item.month });
      if (m === '04')
        months_.push({ label: `Abr - ${year}`, value: item.month });
      if (m === '05')
        months_.push({ label: `Mai - ${year}`, value: item.month });
      if (m === '06')
        months_.push({ label: `Jun - ${year}`, value: item.month });
      if (m === '07')
        months_.push({ label: `Jul - ${year}`, value: item.month });
      if (m === '08')
        months_.push({ label: `Ago - ${year}`, value: item.month });
      if (m === '09')
        months_.push({ label: `Set - ${year}`, value: item.month });
      if (m === '10')
        months_.push({ label: `Out - ${year}`, value: item.month });
      if (m === '11')
        months_.push({ label: `Nov - ${year}`, value: item.month });
      if (m === '12')
        months_.push({ label: `Dez - ${year}`, value: item.month });
    }

    return months_;
  };

  cleanFilter = e => {
    e.preventDefault();
    const { fields } = this.state;
    fields[0].value = 0;

    this.setState({
      month_ref: 0,
      data_whith_value: false,
      budget_accountings: [],
      budget_months: [],
      fields,
    });
    this.filterData([], []);
  };

  filter = e => {
    e.preventDefault();

    this.filterData(this.state.data, this.state.budget_months_data);
    this.getTotalExpenseMonth()
    this.getTotalRecipeMonth()
  };

  getTotalExpenseMonth = async () => {
    const { month_ref, id } = this.state

    const params_ = {
      budget_id: id,
      accounting_type: 'DESPESA'
    }
    await api.get('dashboard/budget/accounting-type', { params: params_ })
      .then(result => {
        const expense_total_month_ = result.data.filter(item => item.month === month_ref)
        this.setState({
          expense_forecast_month: formatPrice(expense_total_month_[0].forecast),
          expense_done_month: formatPrice(expense_total_month_[0].done)
        })
      })
  }

  getTotalRecipeMonth = async () => {
    const { month_ref, id } = this.state

    const params_ = {
      budget_id: id,
      accounting_type: 'RECEITA'
    }
    await api.get('dashboard/budget/accounting-type', { params: params_ })
      .then(result => {
        const recipe_total_month_ = result.data.filter(item => item.month === month_ref)
        this.setState({
          recipe_forecast_month: formatPrice(recipe_total_month_[0].forecast),
          recipe_done_month: formatPrice(recipe_total_month_[0].done)
        })
      })
  }

  filterData = (data, budget_months) => {
    const { month_ref, data_whith_value } = this.state;
    let budget_accountings_ = [];
    let budget_months_ = [];

    if (month_ref) {
      budget_months_ = budget_months.filter(item => item.value === month_ref);

      budget_accountings_ = data.map(budget => ({
        ...budget,
        budget_accounting_months: budget.budget_accounting_months.filter(
          item => item.month === month_ref
        ),
      }));

      if (data_whith_value) {
        let list = [];

        for (let i = 0; i < budget_accountings_.length; i++) {
          const element = budget_accountings_[i];
          const valor1 = currencyToString(element.budget_accounting_months[0].expected_value)
          const valor2 = currencyToString(element.budget_accounting_months[0].real_value)

          if (valor1 > 0 || valor2 > 0) list.push(element);
        }
        budget_accountings_ = list;
      }
    }

    this.setState({
      budget_accountings: budget_accountings_ || [],
      budget_months: budget_months_,
      titleFilter: budget_months_.length > 0 ? `Mês de Referência (${budget_months_[0].label})` : 'Filtrar Orçamento'
    });
  };

  render() {
    const budget = this.state;
    return (
      <Widget title={budget.budget_name} printer>
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              <div className="d-flex justify-content-between mt-4">
                <div className="col title-filter">
                  <span>{budget.titleFilter}</span>
                </div>

                <div className="col-sm col-md-2">
                  <Link to="/budgets">
                    <button className="btn btn-success btn-block">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>

              <div className="row">
                {/* Init Filter */}
                <div className="col p-1">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm">
                        <Select
                          label="Mês"
                          value={budget.month_ref}
                          options={budget.fields[0].options}
                          onChange={e =>
                            this.setState({ month_ref: e.target.value })
                          }
                          field={budget.fields[0]}
                          validate={() => { }}
                        />
                      </div>

                      <div className="col-sm">
                        <Select
                          label="Mostrar categorias"
                          value={budget.data_whith_value}
                          options={budget.fields[1].options}
                          onChange={e =>
                            this.setState({ data_whith_value: e.target.value })
                          }
                          field={budget.fields[1]}
                          validate={() => { }}
                        />
                      </div>
                    </div>

                    <div className="row justify-content-end">
                      <div className="col-sm col-md-2 mb-1">
                        <button
                          className="btn btn-success btn-block"
                          onClick={this.filter}
                        >
                          Filtrar <GoSearch />
                        </button>
                      </div>

                      <div className="col-sm col-md-2 mb-1">
                        <button
                          href="#"
                          className="btn btn-success btn-block"
                          onClick={this.cleanFilter}
                        >
                          Limpar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Filter */}
              </div>

              {budget.budget_accountings.length > 0 && (
                <>
                  {/* init resume */}
                  <div className="col">
                    <table className="table table-sm resume">
                      <thead className="thead-report bg-filter">
                        <tr>
                          <th scope="col">Orçamento</th>
                          <th scope="col">Previsto</th>
                          <th scope="col">Realizado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Receitas</th>
                          <td>{budget.recipe_forecast_month}</td>
                          <td>{budget.recipe_done_month}</td>
                        </tr>
                        <tr>
                          <th scope="row">Despesas</th>
                          <td>{budget.expense_forecast_month}</td>
                          <td>{budget.expense_done_month}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* finish resume */}

                  <div className="col">
                    <table className="table-sm  table-bordered-print table-hover">
                      <thead className="bg-filter">
                        <tr>
                          <th scope="col">
                            <div className="col">#</div>
                          </th>
                          <th scope="col">
                            <div className="col headcol-title">Categoria</div>
                          </th>
                          {budget.budget_months.map((month, i) => (
                            <th scope="col" key={i} className="text-center">
                              <span className="ref-month">{month.label}</span>
                              <div className="d-flex">
                                <div className="col">Previsto</div>
                                <div className="col">Realizado</div>
                                <div className="col">Divergência</div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {budget.budget_accountings.map((budget_accounting, x) => (
                          <tr key={x}>
                            <td
                              style={
                                budget_accounting.accounting.number.length > 4
                                  ? {}
                                  : { fontWeight: 'bold', fontSize: '1rem' }
                              }
                            >
                              {budget_accounting.accounting.number}
                            </td>
                            <td
                              style={
                                budget_accounting.accounting.number.length > 4
                                  ? { cursor: 'pointer' }
                                  : { fontWeight: 'bold', fontSize: '1rem' }
                              }
                            >
                              {budget_accounting.accounting.name}
                            </td>
                            {budget_accounting.budget_accounting_months.map(
                              (budget_accounting_month, y) => (
                                < td key={y} className="col" >
                                  <div className="d-flex ">
                                    <div className="col"
                                      style={
                                        budget_accounting.accounting.number.length > 4
                                          ? {}
                                          : { fontWeight: 'bold', fontSize: '1rem' }
                                      }
                                    >
                                      {budget_accounting_month.expected_value}
                                    </div>
                                    <div className="col"
                                      style={
                                        budget_accounting.accounting.number.length > 4
                                          ? {}
                                          : { fontWeight: 'bold', fontSize: '1rem' }
                                      }
                                    >
                                      {budget_accounting_month.real_value}
                                    </div>
                                    <div className="col text-center"
                                      style={
                                        budget_accounting.accounting.number.length > 4
                                          ? {}
                                          : { fontWeight: 'bold', fontSize: '1rem' }
                                      }
                                    >
                                      {budget_accounting_month.divergence} %
                                </div>
                                  </div>
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {budget.budget_accountings.length === 0 && (
                <div className="col filter-alert">
                  <div className="alert alert-success text-center" role="alert">
                    <span>Selcione um mês de referência para filtrar!</span>
                  </div>
                </div>
              )}

              {budget.loading && (
                <div className="row justify-content-center mt-5">
                  <Loading />
                </div>
              )}
            </form>
          </div>
        </div>
      </Widget >
    );
  }
}

export default Index;
