import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../../components/widget'
import InputText from '../../../components/input-text'
import DateTime from '../../../components/date-time'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { stringToDate } from '../../../actions/util'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)

    this.state = {
      errors: [],
      id: '',
      team_id: '',
      date: new Date(),
      crop_name: '',
      tillage_name: '',
      team_name: '',
      harvest_packings: [],
      quantity: '',
      quantity_diarist: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //1
          name: 'crop_name',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //2
          name: 'tillage_name',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //3
          name: 'team_name',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //4
          name: 'quantity',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //5
          name: 'quantity_diarist',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getHarvestPacking(id)
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const harvestPacking = this.state

    let fields = this.submitForm(e, harvestPacking.fields)
    this.setState({ fields: fields })

    if (harvestPacking.valid && harvestPacking.errors.length === 0) {
      this.update()
    }
    return false
  }

  getHarvestPacking = async id => {
    await api.get(`harvest-packings/${id}`)
      .then(result => {
        const harvestPacking = result.data
        const fields = this.state.fields

        fields[4].value = harvestPacking.quantity
        fields[5].value = harvestPacking.quantity_diarist

        this.setState({
          id: harvestPacking.id,
          team_id: harvestPacking.team_id,
          date: harvestPacking.date ? stringToDate(harvestPacking.date) : '',
          crop_name: harvestPacking.team.tillage.crop.name || '',
          tillage_name: harvestPacking.team.tillage.name || '',
          team_name: harvestPacking.team.name || '',
          quantity: harvestPacking.quantity || 0,
          quantity_diarist: harvestPacking.quantity_diarist || 0,
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Romaneio de Colheita', url: '/harvest-packings' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/harvest-packings')
        toastError(handleError(error))
      })
  }

  update = async () => {
    const { id } = this.state
    const harvestPacking = this.prepareHarvestPackingToSave()

    await api.put(`harvest-packings/${id}`, harvestPacking)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push('/harvest-packings')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  prepareHarvestPackingToSave = () => {
    const { team_id, date, quantity, quantity_diarist } = this.state

    const harvest = {
      team_id: team_id,
      date: date,
      quantity: quantity,
      quantity_diarist: quantity_diarist,
    }

    return harvest
  }

  render() {
    const harvestPacking = this.state

    return (
      <Widget
        title={harvestPacking.id ? `Editar Romaneio` : 'Novo Romaneio'}>
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col-sm col-md-3">
                  <DateTime
                    label="Data"
                    value={harvestPacking.date}
                    onChange={date => this.setState({ date: date })}
                    field={harvestPacking.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[0])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <InputText
                    label="Safra"
                    value={harvestPacking.crop_name}
                    onChange={() => { }}
                    disabled={true}
                    field={harvestPacking.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[1])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="Lavoura"
                    value={harvestPacking.tillage_name}
                    onChange={() => { }}
                    field={harvestPacking.fields[2]}
                    disabled={true}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[2])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col-12">
                  <InputText
                    label="Equipe"
                    value={harvestPacking.team_name}
                    onChange={() => { }}
                    disabled={true}
                    field={harvestPacking.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[3])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="* Quantidade Equipe"
                    value={harvestPacking.quantity}
                    onChange={event => this.setState({ quantity: event.target.value })}
                    field={harvestPacking.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[4])}
                  />
                </div>

                <div className="col">
                  <InputText
                    label="* Quantidade Diarista"
                    value={harvestPacking.quantity_diarist}
                    onChange={event => this.setState({ quantity_diarist: event.target.value })}
                    field={harvestPacking.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, harvestPacking.fields[5])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                {harvestPacking.tillage_id &&
                  <div className="row col mb-3 d-flex align-items-end">
                    <i className="material-icons text-warning mr-2">warning</i>
                    <span>
                      Equipes com campo quantidade em <b>BRANCO</b> não serão incluídas!
                    </span>
                  </div>
                }
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-4 mt-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!harvestPacking.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3  mt-3">
                  <Link to="/harvest-packings">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index