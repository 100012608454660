import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { IoIosPeople } from 'react-icons/io'
import { IoMdPeople } from 'react-icons/io'
import { MdMonetizationOn } from 'react-icons/md'
import { AiFillFund } from 'react-icons/ai'
import { GiTomato } from 'react-icons/gi'
import { GiCardboardBox } from 'react-icons/gi'
import { GiFarmer } from 'react-icons/gi'
import { GiOrganigram } from 'react-icons/gi'
import { GiGrain } from 'react-icons/gi'
import { GiFarmTractor } from 'react-icons/gi'
import { GiBackup } from 'react-icons/gi'
import { GiCash } from 'react-icons/gi'
import { GiChecklist } from 'react-icons/gi'
import { GiTruck } from 'react-icons/gi'
import { GiFruitTree } from 'react-icons/gi'
import { GiWaterSplash } from 'react-icons/gi'
import { GiStopwatch } from 'react-icons/gi'

import Widget from '../../components/widget'

class Index extends Component {

  constructor() {
    super();

    this.state = {}

  }

  componentDidMount() { }

  render() {

    return (
      <Widget title="Cadastrar">
        <div className="row">
          <div className="col-sm col-md-2">
            <Link to="/activities">
              <div className="card bg-primary text-white">
                <GiChecklist size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Atividades</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm col-md-2">
            <Link to="/clients">
              <div className="card bg-warning text-white">
                <GiBackup size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Clientes</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm col-md-2">
            <Link to="/accounts">
              <div className="card bg-info text-white">
                <MdMonetizationOn size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Conta</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm col-md-2">
            <Link to="/cultivations">
              <div className="card bg-success text-white">
                <GiTomato size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Cultivos</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm col-md-2">
            <Link to="/providers">
              <div className="card bg-primary text-white">
                <GiCardboardBox size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Fornecedores</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm col-md-2">
            <Link to="/employees">
              <div className="card bg-warning text-white">
                <GiFarmer size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Funcionários</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm col-md-2">
            <Link to="/functions">
              <div className="card bg-info text-white">
                <GiOrganigram size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Funções</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/machine-times">
              <div className="card bg-success text-white">
                <GiStopwatch size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Horas Máquina</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/agricultural-inputs">
              <div className="card bg-primary text-white">
                <GiGrain size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Insumos Agr.</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/irrigations">
              <div className="card bg-warning text-white">
                <GiWaterSplash size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Irrigações</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/machines">
              <div className="card bg-info text-white">
                <GiFarmTractor size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Máquinas</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/financial-movements">
              <div className="card bg-success text-white">
                <GiCash size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Mov. Financeiro</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm col-md-2">
            <Link to="/people">
              <div className="card bg-primary text-white">
                <IoIosPeople size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Pessoas</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/accountings">
              <div className="card bg-warning text-white">
                <AiFillFund size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Plano de Contas</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/users">
              <div className="card bg-info text-white">
                <IoMdPeople size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Usuários</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/cargo-packings">
              <div className="card bg-success text-white">
                <GiFruitTree size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Rom. de Colheita</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-sm col-md-2">
            <Link to="/cargo-packings">
              <div className="card bg-primary text-white">
                <GiTruck size={50} className="card-img-top mt-2" />
                <div className="card-body text-center">
                  <p className="card-text">Rom. de Cargas</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;