import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import InputText from '../../../components/input-text';
import RadioButton from '../../../components/radio-button';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    this.state = {
      errors: [],
      id: '',
      username: '',
      email: '',
      perfil_type: '',
      fields: [
        {
          value: '',
          name: 'perfil_type',
          type: 'text',
          options: [
            { label: 'Usuário', value: 'USER' },
            { label: 'Administrador', value: 'ADMIN' },
          ],
          errors: [],
          rules: {},
        },
        {
          value: '',
          name: 'username',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo username é obrigatório',
            },
          },
        },
        {
          value: '',
          name: 'email',
          type: 'email',
          errors: [],
          rules: {
            email: {
              message: 'E-mail inválido',
            },
          },
        },
      ],
      valid: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id });

    if (id) this.getUser(id);
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    const result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      }
      return item;
    });
    return fields;
  }

  validate(e, field) {
    const fields = this.validateField(e, field, this.state.fields);
    const errors = [];
    let valid = false;

    fields.map(field => {
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields,
      errors,
      valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      const result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const { valid, errors } = this.state;

    const fields = this.submitForm(e, this.state.fields);
    this.setState({ fields });

    if (valid && errors.length === 0) this.update();

    return false;
  }

  handleChange(event) {
    const { name } = event.target;
    const user = this.state;
    const { fields } = this.state;

    fields[0].value = user.perfil_type;
    fields[1].value = user.username;
    fields[2].value = user.email;

    this.setState({
      [name]: event.target.value,
      fields,
    });
  }

  getUser = async id => {
    await api
      .get(`users/${id}`)
      .then(result => {
        const user = result.data;
        const { fields } = this.state;

        fields[0].value = user.perfil_type;
        fields[1].value = user.username;
        fields[2].value = user.email;

        this.setState({
          id: user.id,
          username: user.username || '',
          email: user.email || '',
          perfil_type: user.perfil_type || '',
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Usuários', url: '/users' },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response.status === 500) this.props.history.push('/users');
        toastError(handleError(error));
      });
  };

  update = async () => {
    const user = this.state;

    await api
      .put(`users/${user.id}`, user)
      .then(() => {
        toastSuccess(`Registro de ${user.name} atualizado!`);
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    // TODO - update function disabled, fields like user and email are unique fields and give error when trying to update

    const user = this.state;

    return (
      <Widget title={user.id ? `Editar ${user.username}` : 'Novo Usuário'}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              {user.id && (
                <div className="col">
                  <RadioButton
                    label="* Perfil"
                    value={user.perfil_type}
                    options={user.fields[0].options}
                    onChange={event => this.setState({ perfil_type: event })}
                    disabled
                    field={user.fields[0]}
                    validate={(e, field) => this.validate(e, user.fields[0])}
                  />
                </div>
              )}

              <div className="col">
                <InputText
                  label="* Usuário"
                  value={user.username}
                  disabled
                  onChange={event =>
                    this.setState({ username: event.target.value })
                  }
                  field={user.fields[1]}
                  validate={(e, field) => this.validate(e, user.fields[1])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* E-mail"
                  value={user.email}
                  disabled
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                  field={user.fields[2]}
                  validate={(e, field) => this.validate(e, user.fields[2])}
                />
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="/forgot-password" className="mr-2">
                    <button
                      className="btn btn-warning btn-block"
                      disabled={!user.valid}
                      onClick={this.submit}
                    >
                      Alterar Senha
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!user.valid}
                      onClick={this.submit}
                    >
                      Atualizar
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/users">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
