
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import DashboardMachineHoursCrops from './DashboardMachineHoursCrops'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {

      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Dashboard', url: '/' },
          { page: 'Horas Máquina', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(DashboardMachineHoursCrops),
    renderNothing
  )
)(DashboardMachineHoursCrops)

export default Component