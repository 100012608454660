import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../../components/widget';
import InputText from '../../../components/input-text';

import api from '../../../actions/api';
import { toastError, toastSuccess } from '../../../actions/toast';
import { handleError } from '../../../actions/handle-error';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      perfil_type: '',
      fields: [
        {
          value: '',
          name: 'perfil_type',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '',
          name: 'username',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo username é obrigatório',
            },
          },
        },
        {
          value: '',
          name: 'email',
          type: 'email',
          errors: [],
          rules: {
            email: {
              message: 'E-mail inválido',
            },
          },
        },
        {
          value: '',
          name: 'password',
          type: 'password',
          errors: [],
          rules: {
            title: 'Senha',
            required: {
              message: 'Campo senha é obrigatório',
            },
            min: {
              min: 8,
              message: 'Campo senha deve ter no mínimo 8 caracteres',
            },
            // strength: {
            //   min: 8,
            // 	bonus: 10,
            // 	options: {
            // 	},
            //   config: {
            // 		messages: {
            //       isMinimum: '{title} deve conter no mínimo {min} caracteres',
            //       hasLower: '{title} deve conter ao menos 1 letra minúscula',
            //       hasUpper: '{title} deve conter ao menos 1 letra maiúscula',
            //       hasNumber: '{title} deve conter ao menos 1 número',
            //       hasSpecial: '{title} deve conter ao menos 1 caracter especial'
            //     }
            //   },
            //   message: 'Senha inválida',
            // }
          },
        },
        {
          value: '',
          name: 'confirm_password',
          type: 'password',
          errors: [],
          rules: {
            required: {
              message: 'Campo confirmar senha é obrigatório',
            },
            equal: {
              value: '',
              field: 'password',
              message: 'Senhas não conferem',
            },
          },
        },
      ],
      valid: false,
    };
  }

  validateField(e, field, fields) {
    e.preventDefault();

    field.value = '';
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password;
    }
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const { valid, errors } = this.state;

    let fields = this.submitForm(e, this.state.fields);
    this.setState({ fields: fields });

    if (valid && errors.length === 0) {
      this.save();
    }
    return false;
  }

  handleChange(event) {
    const name = event.target.name;
    const user = this.state;
    const fields = this.state.fields;

    fields[0].value = user.perfil_type;
    fields[1].value = user.username;
    fields[2].value = user.email;
    fields[3].value = user.password;
    fields[4].value = user.confirm_password;

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  save = async () => {
    const user = this.state;

    await api
      .post('users', user)
      .then(() => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/users');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  render() {
    const user = this.state;

    return (
      <Widget title={user.id ? `Editar ${user.username}` : 'Novo Usuário'}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="col">
                <InputText
                  label="* Usuário"
                  value={user.username}
                  onChange={this.handleChange}
                  field={user.fields[1]}
                  validate={(e, field) => this.validate(e, user.fields[1])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* E-mail"
                  value={user.email}
                  onChange={this.handleChange}
                  field={user.fields[2]}
                  validate={(e, field) => this.validate(e, user.fields[2])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* Senha"
                  value={user.password}
                  onChange={this.handleChange}
                  field={user.fields[3]}
                  validate={(e, field) => this.validate(e, user.fields[3])}
                />
              </div>

              <div className="col">
                <InputText
                  label="* Confirmar Senha"
                  value={user.confirm_password}
                  onChange={this.handleChange}
                  field={user.fields[4]}
                  validate={(e, field) => this.validate(e, user.fields[4])}
                />
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!user.valid}
                      onClick={this.submit}
                    >
                      Salvar
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/users">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
