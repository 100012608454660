import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../../components/widget'
import InputText from '../../../components/input-text'
import Select from '../../../components/select'
import InputCurrency from '../../../components/input-mask-currency'
import InputDate from '../../../components/date-time'
import RadioButton from '../../../components/radio-button'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { currencyToString, stringToDate } from '../../../actions/util'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      errors: [],
      title: '',
      id: '',
      date: '',
      budget_id: '',
      movement_type: '',
      account_id: '',
      accounting_id: '',
      person_id: '',
      payment_type: 'DINHEIRO',
      document: '',
      due_date: '',
      pay_date: '',
      value: '',
      increase: 0,
      discount: 0,
      description: '',
      historic: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'movement_type',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: '' },
            { label: 'Crédito', value: 'CREDITO' },
            { label: 'Débito', value: 'DEBITO' },
          ],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'account_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //4
          name: 'accounting_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'person_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //6
          name: 'payment_type',
          type: 'text',
          options: [
            { label: '', value: ' ' },
            { label: 'Boleto', value: 'BOLETO' },
            { label: 'Cheque', value: 'CHEQUE' },
            { label: 'Dinheiro', value: 'DINHEIRO' },
            { label: 'Cartão de Crédito', value: 'CARTAO_CREDITO' },
            { label: 'Cartão de Débito', value: 'CARTAO_DEBITO' },
            { label: 'Doc TED', value: 'DOC_TED' },
            { label: 'Transferência', value: 'TRANSFERENCIA' },
            { label: 'Outros', value: 'OUTROS' },
          ],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //7
          name: 'document',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //8
          name: 'due_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //9
          name: 'pay_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //10
          name: 'value',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //11
          name: 'increase',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //12
          name: 'discount',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //13
          name: 'description',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //14
          name: 'historic',
          type: 'text',
          errors: [],
          rules: {},
        }
      ]
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params

    if (id) {
      this.getReceivable(id)
    } else {
      this.setState({
        payment_status_type: 'ABERTO',
        movement_type: 'CREDITO',
        date: new Date(),
      })
    }

    this.getBudgets()
    this.getAccounts()
    this.getAccountings()
    this.getPeople()
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const receivable = this.state

    let fields = this.submitForm(e, receivable.fields)
    this.setState({ fields: fields })

    if (receivable.valid && receivable.errors.length === 0) this.update()

    return false
  }

  getReceivable = async id => {
    await api.get(`receivables/${id}`)
      .then(result => {
        const receivable = result.data
        const fields = this.state.fields

        fields[0].value = receivable.date
        fields[1].value = receivable.budget_id
        fields[2].value = receivable.movement_type
        fields[3].value = receivable.account_id
        fields[4].value = receivable.accounting_id
        fields[5].value = receivable.person_id
        fields[6].value = receivable.payment_type
        fields[7].value = receivable.document
        fields[8].value = receivable.due_date
        fields[9].value = receivable.pay_date
        fields[10].value = receivable.value
        fields[11].value = receivable.increase
        fields[12].value = receivable.discount
        fields[13].value = receivable.description
        fields[14].value = receivable.historic

        this.setState({
          id: receivable.id,
          date: receivable.date ? stringToDate(receivable.date) : '',
          budget_id: receivable.budget_id || '',
          movement_type: receivable.movement_type || '',
          account_id: receivable.account_id || '',
          accounting_id: receivable.accounting_id || '',
          person_id: receivable.person_id || '',
          payment_type: receivable.payment_type || '',
          document: receivable.document || '',
          due_date: receivable.due_date ? stringToDate(receivable.due_date) : '',
          pay_date: receivable.pay_date ? stringToDate(receivable.pay_date) : '',
          value: receivable.value || '',
          increase: receivable.increase || 0,
          discount: receivable.discount || 0,
          description: receivable.description || '',
          historic: receivable.historic || '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Contas a Pagar', url: '/receivables' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/receivables')
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    const name = event.target.name
    const receivable = this.state
    const fields = this.state.fields

    fields[0].value = receivable.date
    fields[1].value = receivable.budget_id
    fields[2].value = receivable.movement_type
    fields[3].value = receivable.account_id
    fields[4].value = receivable.accounting_id
    fields[5].value = receivable.person_id
    fields[6].value = receivable.payment_type
    fields[7].value = receivable.document
    fields[8].value = receivable.due_date
    fields[9].value = receivable.pay_date
    fields[10].value = receivable.value
    fields[11].value = receivable.increase
    fields[12].value = receivable.discount
    fields[13].value = receivable.description
    fields[14].value = receivable.historic

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  update = async () => {
    const receivable = this.state
    receivable.value = currencyToString(receivable.value)
    receivable.increase = currencyToString(receivable.increase)
    receivable.discount = currencyToString(receivable.discount)

    await api.put(`receivables/${receivable.id}`, receivable)
      .then(result => {
        toastSuccess(`Registro de ${receivable.name} atualizado!`)
        // this.props.history.push('/receivables')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  getBudgets = async () => {
    let budgets = []
    const { fields } = this.state

    await api.get('budgets')
      .then(result => {
        fields[1].options = []

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name
        }))

        fields[1].options = budgets
        fields[1].options.unshift({ id: ' ', label: '' })

        this.setState({ fields: fields, budget_id: budgets[1].value })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getAccounts = async () => {
    let accounts = []
    const { fields } = this.state

    await api.get('accounts?status_type=ATIVO')
      .then(result => {
        fields[3].options = []

        accounts = result.data.accounts.map(result => ({
          value: result.id,
          label: result.name
        }))

        fields[3].options = accounts
        fields[3].options.unshift({ id: ' ', label: '' })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getAccountings = async () => {
    let accountings = []
    const { fields } = this.state

    await api.get('accountings?status_type=ATIVO&accounting_type=RECEITA')
      .then(result => {

        fields[4].options = []

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i]
          const item = { value: element.id, label: `${element.number} ${element.name}`, disabled: true }
          accountings.push(item)

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x]
              const item = { value: elem.id, label: `${elem.number} ${elem.name}` }
              accountings.push(item)
            }
          }
        }

        fields[4].options = accountings
        fields[4].options.unshift({ id: ' ', label: '' })

        this.setState({ fields: fields })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getPeople = async () => {
    let options = []
    const { fields } = this.state

    await api.get(`people?page=1&perPage=99999`)
      .then(result => {
        fields[5].options = []

        options = result.data.data.map(result => ({
          value: result.id,
          label: result.name
        }))

        options.sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        })

        fields[5].options = options
        fields[5].options.unshift({ id: ' ', label: '' })

        this.setState({ fields: fields })

      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  render() {
    const receivable = this.state

    return (
      <Widget title="Editar Conta a Receber">
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              {receivable.id &&
                <div className="row">
                  <div className="col-sm col-md-3">
                    <InputDate
                      label="* Data de Inclusão"
                      value={receivable.date}
                      disabled
                      onChange={date => this.setState({ date: date })}
                      field={receivable.fields[0]}
                      validate={(e, field) =>
                        this.validate(e, receivable.fields[0])}
                    />
                  </div>
                  <div className="col-sm col-md-3">
                    <RadioButton
                      label="* Tipo Movimento"
                      value={receivable.movement_type}
                      options={receivable.fields[2].options}
                      onChange={this.handleChange}
                      disabled={true}
                      field={receivable.fields[2]}
                      validate={(e, field) =>
                        this.validate(e, receivable.fields[2])}
                    />
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-sm">
                  <Select
                    label="* Orçamento"
                    value={receivable.budget_id}
                    options={receivable.fields[1].options}
                    onChange={this.handleChange}
                    field={receivable.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Conta Bancária"
                    value={receivable.account_id}
                    options={receivable.fields[3].options}
                    onChange={this.handleChange}
                    field={receivable.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[3])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Conta Movimento"
                    value={receivable.accounting_id}
                    options={receivable.fields[4].options}
                    onChange={this.handleChange}
                    field={receivable.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[4])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Nome"
                    value={receivable.person_id}
                    options={receivable.fields[5].options}
                    onChange={this.handleChange}
                    field={receivable.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[5])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-3">
                  <Select
                    label="* Tipo de Pgto"
                    value={receivable.payment_type}
                    options={receivable.fields[6].options}
                    onChange={this.handleChange}
                    field={receivable.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[6])}
                  />
                </div>

                <div className="col-sm col-md-3">
                  <InputText
                    label="Documento"
                    value={receivable.document}
                    onChange={this.handleChange}
                    field={receivable.fields[7]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[7])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label="* Descrição"
                    value={receivable.description}
                    onChange={this.handleChange}
                    field={receivable.fields[13]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[13])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-3">
                  <InputDate
                    label="* Data Vencimento"
                    value={receivable.due_date}
                    onChange={date => this.setState({ due_date: date })}
                    field={receivable.fields[8]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[8])}
                  />
                </div>

                {receivable.id &&
                  <div className="col-sm">
                    <InputDate
                      label="Data Pagamento"
                      value={receivable.pay_date}
                      onChange={date => this.setState({ pay_date: date })}
                      field={receivable.fields[9]}
                      validate={(e, field) =>
                        this.validate(e, receivable.fields[9])}
                    />
                  </div>
                }

                <div className="col-sm col-md-3">
                  <InputCurrency
                    label="* Valor"
                    value={receivable.value}
                    onChange={this.handleChange}
                    field={receivable.fields[10]}
                    validate={(e, field) =>
                      this.validate(e, receivable.fields[10])}
                  />
                </div>

                {receivable.id &&
                  <>
                    <div className="col-sm">
                      <InputCurrency
                        label="Acréscimo"
                        value={receivable.increase}
                        onChange={this.handleChange}
                        field={receivable.fields[11]}
                        validate={(e, field) =>
                          this.validate(e, receivable.fields[11])}
                      />
                    </div>

                    <div className="col-sm">
                      <InputCurrency
                        label="Desconto"
                        value={receivable.discount}
                        onChange={this.handleChange}
                        field={receivable.fields[12]}
                        validate={(e, field) =>
                          this.validate(e, receivable.fields[12])}
                      />
                    </div>
                  </>
                }
              </div>

              <div className="row">
                {receivable.id &&
                  <div className="col-sm">
                    <InputText
                      label="Histórico"
                      value={receivable.historic}
                      onChange={this.handleChange}
                      field={receivable.fields[14]}
                      validate={(e, field) =>
                        this.validate(e, receivable.fields[14])}
                    />
                  </div>
                }
              </div>

              <div className="row col-sm p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-5">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      // disabled={!receivable.valid}
                      onClick={this.submit}>
                      Atualizar
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-5">
                  <Link to="/receivables">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index