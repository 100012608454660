import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Widget from '../../components/widget';
import { index, remove } from '../../actions/base-rest';
import EmployeesTable from './EmployeesTable';
import EmployeesFilter from './EmployeesFilter';
import { dateToString } from '../../actions/util';

const URL = 'employees';

const default_params = {
  page: 1,
  perPage: 10,
};

const Employees = () => {
  const [params, setParams] = useState(default_params);

  const [scrolling, setScrolling] = useState(false);

  const [data, setData] = useState([]);

  const [itemDelete, setItemDelete] = useState({});

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState({
    current_page: 0,
    has_next_page: false,
    total: 0,
  });

  const actions = [
    {
      name: 'Novo Funcionário',
      class_btn: 'btn-success',
      class_collumn: 'col col-md-3',
      route: 'employees/new',
    },
  ];

  const getPeople = useCallback(
    async filter => {
      setLoading(true);

      const response = await index(URL, filter);

      let employees = [];

      employees = response?.data?.map(item => ({
        ...item,
        name: item.person.name.toUpperCase(),
        function: item.function.name,
        admission_date: dateToString(item.admission_date),
        status: item.dismissal_date ? 'INATIVO' : 'ATIVO',
        // gender_type_format: item.gender_type === 'MASCULINO' ? 'M' : 'F',
      }));

      if (scrolling) {
        const new_data = data => [...data, ...employees];

        setData(new_data);

        setScrolling(false);
      } else setData(employees);

      setLoading(false);

      setPage({
        current_page: response.page,
        has_next_page: response.lastPage >= response.page,
        total: response.total,
      });
    },
    [scrolling]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setData([]);

      getPeople();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDelete]);

  useEffect(() => {
    getPeople(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Widget title="Funcionários" actions={actions} line={false}>
      <EmployeesFilter setParams={setParams} default_params={default_params} />

      <div className="row d-flex">
        <div className="col table">
          <EmployeesTable
            data={data}
            page={page}
            setItemDelete={setItemDelete}
            setParams={setParams}
            loading={loading}
            setScrolling={setScrolling}
          />
        </div>
      </div>

      <Modal
        isOpen={!!itemDelete.id}
        wrapClassName="modal-danger"
        size="default"
      >
        <ModalHeader>Deletar Registro</ModalHeader>
        <ModalBody>
          Deseja excluir o registro de {itemDelete.description}?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-success col-4" onClick={deleteItem}>
            Sim
          </button>
          <button
            className="btn btn-success"
            onClick={() => setItemDelete({ id: null, description: null })}
          >
            Cancelar
          </button>
        </ModalFooter>
      </Modal>
    </Widget>
  );
};

export default Employees;
