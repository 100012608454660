import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import Select from '../../components/select'
import DateTime from '../../components/date-time'
import InputCurrency from '../../components/input-mask-currency'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { currencyToString } from '../../actions/util'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      person_id: '',
      person: '',
      credit_limit: 0,
      last_sale_date: '',
      fields: [
        {
          value: '', //0
          name: 'person_id',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: null },
          ],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'credit_limit',
          type: 'text',
          errors: [],
          options: [
            { label: '', value: null },
          ],
          rules: {}
        },
        {
          value: '', //2
          name: 'last_sale_date',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getClient(id);

    this.getPeople();
  }

  getPeople = () => {
    api.get('people?page=1&perPage=99999')
      .then(result => {
        const { fields } = this.state
        let options = []

        for (let i = 0; i < result.data.data.length; i++) {
          const person = result.data.data[i];

          if (!person.client_id) {
            options.push({
              value: person.id,
              label: person.name
            })
          }
        }

        options.sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })

        fields[0].options = options
        fields[0].options.unshift({ id: null, label: '' })
        this.setState({
          fields: fields
        })
      })
  }

  validateField(e, field, fields) {
    e.preventDefault()
    field.value = ''
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const client = this.state;

    let fields = this.submitForm(e, client.fields);
    this.setState({ fields: fields });

    if (client.valid && client.errors.length === 0) {
      client.id ? this.update() : this.save();
    }
    return false
  }

  getClient = async id => {
    await api.get(`clients/${id}`)
      .then(result => {
        const client = result.data;
        const fields = this.state.fields;

        fields[0].value = client.person_id;
        fields[1].value = client.credit_limit || 0;
        fields[2].value = client.last_sale_date ? new Date(client.last_sale_date) : '';

        this.setState({
          id: client.id || '',
          person: client.person || '',
          person_id: client.person_id || '',
          credit_limit: client.credit_limit || 0,
          last_sale_date: client.last_sale_date ? new Date(client.last_sale_date) : '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Clientes', url: '/clients' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/clients')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const client = this.state;
    const fields = this.state.fields;

    fields[0].value = client.person_id;
    fields[1].value = client.credit_limit;
    fields[2].value = client.last_sale_date ? new Date(client.last_sale_date) : '';

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const client = this.state;
    client.credit_limit = currencyToString(client.credit_limit)

    await api.post('clients', client)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/clients')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const client = this.state;
    client.credit_limit = client.credit_limit ? currencyToString(client.credit_limit) : 0;

    await api.put(`clients/${client.id}`, client)
      .then(result => {
        toastSuccess(`Registro de ${client.name} atualizado!`);
        this.props.history.push('/clients')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  render() {
    const client = this.state;

    return (
      <Widget title={client.id ? `Editar ${client.person.name}` : 'Novo Cliente'}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="col">
                <Select
                  label="* Nome"
                  value={client.person_id}
                  options={client.fields[0].options}
                  onChange={this.handleChange}
                  field={client.fields[0]}
                  validate={(e, field) =>
                    this.validate(e, client.fields[0])}
                />
              </div>

              <div className="row m-0 p-0">
                <div className="col">
                  <InputCurrency
                    label="Limite de Crédito"
                    value={client.credit_limit}
                    onChange={this.handleChange}
                    field={client.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, client.fields[1])}
                  />
                </div>

                {client.last_sale_date &&
                  <div className="col">
                    <DateTime
                      label="Data Última Venda"
                      value={client.last_sale_date}
                      disabled={true}
                      onChange={date => this.setState({ last_sale_date: date })}
                      field={client.fields[2]}
                      validate={(e, field) =>
                        this.validate(e, client.fields[2])}
                    />
                  </div>
                }
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!client.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/clients">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;