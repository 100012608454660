import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer } from 'recharts';
import {
  formatPrice,
  formatSimplePrice,
  formatWeight,
} from '../../../actions/util';
import { GrMoney } from 'react-icons/gr';

const CropResumeDashboard = ({ resume }) => {
  return (
    <Container>
      {resume.map(crop => (
        <div key={crop.id} className="row mb-3">
          <div className="col-12 text-center mb-3">
            <h5>{crop.name}</h5>
            {crop.initial_date_format} - {crop.end_date_format} |{' '}
            {crop.area_in_ha} hectares | {formatWeight(crop.number_of_plants)}{' '}
            plantas | {formatWeight(crop.number_employees)} funcionários
          </div>

          <div className="row justify-content-center">
            <div className="col-sm col-md-6 col-lg-3 mb-2">
              <div
                className="card card-invoicing-by-kg"
                // style={{ height: '210px' }}
              >
                <div className="card-header text-center text-uppercase">
                  Valor p/ Kg Produzido
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-sm text-center">
                      <GrMoney size={60} className="svg" />
                    </div>
                    <div className="col-sm text-center">
                      <h1>{formatPrice(crop.invoicing_by_kg)}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm col-md-6 col-lg-3 mb-2">
              <div className="card" style={{ height: '220px' }}>
                <div className="card-header bg-production text-center text-uppercase">
                  Produção total ({crop?.unit_type})
                </div>

                <div className="card-body">
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={[crop]}
                      margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey={[]} label="META / REALIZADO" />

                      <Bar
                        isAnimationActive={false}
                        dataKey="goal"
                        fill="#7DCEA0"
                        barSize={80}
                      >
                        <LabelList
                          dataKey={e => e.goal_format}
                          position="top"
                        />
                      </Bar>

                      <Bar
                        isAnimationActive={false}
                        dataKey="quantity"
                        fill={crop.quantity < crop.goal ? '#DC3545' : '#DAF7A6'}
                        barSize={80}
                      >
                        <LabelList
                          dataKey={e => formatWeight(e.quantity)}
                          position="top"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="col-sm col-md-6 col-lg-3 mb-2">
              <div className="card" style={{ height: '220px' }}>
                <div className="card-header bg-production text-center text-uppercase">
                  Prod. p/ {crop?.unit_type} e Categoria
                </div>

                <div className="card-body">
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={crop.qualities}
                      margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />

                      <Bar
                        isAnimationActive={false}
                        dataKey="quantity"
                        fill="#7DCEA0"
                        barSize={80}
                      >
                        <LabelList
                          dataKey={e => formatWeight(e.quantity)}
                          position="top"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="col-sm col-md-6 col-lg-3 mb-2">
              <div className="card" style={{ height: '220px' }}>
                <div className="card-header bg-primary text-center text-uppercase">
                  Preço Médio (R$) p/ {crop?.unit_type}
                </div>

                <div className="card-body">
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={[crop]}
                      margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis
                        dataKey={[]}
                        label={{ value: 'META / REALIZADO' }}
                      />

                      <Bar
                        isAnimationActive={false}
                        dataKey="average_price"
                        fill="#0D6EFD"
                        barSize={80}
                      >
                        <LabelList
                          dataKey={crop.average_price}
                          position="top"
                        />
                      </Bar>

                      <Bar
                        isAnimationActive={false}
                        dataKey="invoicing_by_unit"
                        fill={
                          crop.invoicing_by_unit < crop.average_price
                            ? '#DC3545'
                            : '#0DCAF0'
                        }
                        barSize={80}
                      >
                        <LabelList
                          dataKey={e => formatWeight(e.invoicing_by_unit)}
                          position="top"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="col-sm col-md-6 col-lg-3 mb-2">
              <div className="card" style={{ height: '220px' }}>
                <div className="card-header bg-primary text-center text-uppercase">
                  Preço Méd. (R$) p/ Cat.
                </div>

                <div className="card-body">
                  <ResponsiveContainer width="100%" height={150}>
                    <BarChart
                      data={crop.qualities}
                      margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />

                      <Bar
                        isAnimationActive={false}
                        dataKey="invoicing_by_unit"
                        fill="#0DCAF0"
                        barSize={80}
                      >
                        <LabelList
                          dataKey={e => formatWeight(e.invoicing_by_unit)}
                          position="top"
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="row mt-3 p-0">
              <div className="col-sm col-md-6 col-lg-4 mb-2">
                <div className="card" style={{ height: '210px' }}>
                  <div className="card-header bg-production text-center text-uppercase">
                    Produção em KG
                  </div>

                  <div className="card-body">
                    <div className="col text-center">
                      <h6>{formatSimplePrice(crop.weight_net)}</h6>
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        <p>ÁREA / Ha </p>
                        <span>{formatWeight(crop.weight_by_area)}</span>
                      </div>
                      <div className="col text-center">
                        <p>{crop.unit_type}</p>
                        <span>{formatWeight(crop.weight_net_avg_unit)}</span>
                      </div>
                      <div className="col text-center">
                        <p>PLANTA</p>
                        <span>{formatWeight(crop.weight_by_plant)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm col-md-6 col-lg-4 mb-2">
                <div className="card" style={{ height: '210px' }}>
                  <div className="card-header bg-production text-center text-uppercase">
                    Produção em R$
                  </div>

                  <div className="card-body">
                    <div className="col text-center">
                      <h6>{formatSimplePrice(crop.invoicing_total)}</h6>
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        <p>ÁREA / Ha </p>
                        <span>{formatWeight(crop.invoicing_by_area)}</span>
                      </div>

                      <div className="col text-center">
                        <p>{crop.unit_type}</p>
                        <span>{formatWeight(crop.invoicing_by_unit)}</span>
                      </div>

                      <div className="col text-center">
                        <p>PLANTA</p>
                        <span>{formatWeight(crop.invoicing_by_plant)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm col-md-6 col-lg-4 mb-2">
                <div className="card" style={{ height: '210px' }}>
                  <div className="card-header bg-production text-center text-uppercase">
                    Produção em {crop.unit_type}
                  </div>

                  <div className="card-body">
                    <div className="col text-center">
                      <h6>{formatSimplePrice(crop.quantity)}</h6>
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        <p>ÁREA / Ha </p>
                        <span>{formatWeight(crop.quantity_by_area)}</span>
                      </div>

                      <div className="col text-center">
                        <p>PLANTA</p>
                        <span>{formatWeight(crop.quantity_by_plant)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export default CropResumeDashboard;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  padding-top: 20px;

  .card-header {
    color: #fff;
  }

  .card-body {
    color: #464646;
    h6 {
      font-weight: bold;
      font-size: 28px;
    }

    p {
      font-size: 12px;
      line-height: 2px;
      margin-top: 30px;
    }

    span {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .bg-production {
    background-color: #198754;
  }

  .bg-preco-medio {
    background-color: #6c757d;
  }

  .card-invoicing-by-kg {
    .card-header {
      background-color: #303f9f;
    }

    .card-body {
      h1 {
        color: #464646;
        font-weight: bold;
      }

      svg path {
        stroke: #464646;
      }
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 300px;

  background-color: #ffff;
`;
