import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      name: '',
      description: '',
      fields: [
        {
          value: '', //0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'description',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getRole(id);
    this.setState({ id: id })
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const role = this.state;

    let fields = this.submitForm(e, role.fields);
    this.setState({ fields: fields });

    if (role.valid && role.errors.length === 0) {
      role.id ? this.update() : this.save();
    }
    return false
  }

  getRole = async id => {
    await api.get(`functions/${id}`)
      .then(result => {
        const role = result.data;
        const fields = this.state.fields;

        fields[0].value = role.name;
        fields[0].description = role.description;

        this.setState({
          id: role.id,
          name: role.name || '',
          description: role.description || '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Funções', url: '/functions' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/functions')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const role = this.state;
    const fields = this.state.fields;

    fields[0].value = role.name;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const role = this.state;

    await api.post('functions', role)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/functions')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const role = this.state;

    await api.put(`functions/${role.id}`, role)
      .then(result => {
        toastSuccess(`Registro de ${role.name} atualizado!`);
        this.props.history.push('/functions')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  render() {
    const role = this.state;

    return (
      <Widget
        title={role.id ? `Editar ${role.name}` : 'Nova Função'}
        description="">
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="col">
                <InputText
                  label="* Nome"
                  value={role.name}
                  onChange={this.handleChange}
                  field={role.fields[0]}
                  validate={(e, field) =>
                    this.validate(e, role.fields[0])}
                />
              </div>

              <div className="col">
                <InputText
                  label="Descrição"
                  value={role.description}
                  onChange={this.handleChange}
                  field={role.fields[1]}
                  validate={(e, field) =>
                    this.validate(e, role.fields[1])}
                />
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!role.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/functions">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
										</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index;