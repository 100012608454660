import styled from 'styled-components'

export const Total = styled.div`
  display: flex;
  align-content: space-between;
  align-items: center;
  background-color: #EBFBE1;
  padding-bottom: 10px;
  padding-top: 10px;

  div {
    font-size: 16px;
    font-weight: bold;
    color: #444
  }
`