
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import EditUser from './EditUser'

const Component = compose(
  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Usuários', url: '/users' },
          { page: 'Incluir', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => { return true },
    renderComponent(EditUser),
    renderNothing
  )
)(EditUser)

export default Component