import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { resumeStockDetailsByInputType } from '../../../../actions/agricultural-input';

import { useParams } from 'react-router-dom';
import { formatPrice, formatSimplePrice } from '../../../../actions/util';
import Widget from '../../../../components/widget';
import styled from 'styled-components';

const StockDetailsByCropTillage = () => {
  const params = useParams();

  const [resume, setResume] = useState([]);
  const [agriculturalInputs, setAgriculturalInputs] = useState([]);
  const [tillage, setTillage] = useState('');

  const getStockTillageDetais = useCallback(async () => {
    const result = await resumeStockDetailsByInputType(params);
    if (result) {
      setResume(result[0]);

      setTillage(result[0].tillages[0].name);

      setAgriculturalInputs(result[0].tillages[0].agricultural_inputs);
    }
  }, [params]);

  useEffect(() => {
    getStockTillageDetais();
  }, [getStockTillageDetais]);

  return (
    <Widget title={`Custo Estoque por Lavoura`} printer={true}>
      <div className="row">
        <div className="col-sm col-md-6 col-lg-3 mb-2">
          <Caption>Safra</Caption>
          <Label>{resume?.name}</Label>
        </div>

        <div className="col-sm col-md-6 col-lg-3 mb-2">
          <Caption>Lavoura</Caption>
          <Label>{tillage}</Label>
        </div>

        <div className="col-sm col-md-6 col-lg-2 mb-2 ">
          <Caption>Insumo</Caption>
          <Label>
            {params.input_type === 'FITO' ? 'Fitossanitário' : 'Fertilizante'}
          </Label>
        </div>

        <div className="col-sm col-md-6 col-lg-2 mb-2">
          <Caption>Valor p/ Ha</Caption>
          <Label>{formatPrice(resume?.total_by_area)}</Label>
        </div>

        <div className="col-sm col-md-6 col-lg-2 mb-2">
          <Caption>Valor</Caption>
          <Label>{formatPrice(resume?.total)}</Label>
        </div>
      </div>

      <Table className="table-responsive mt-5">
        <table className="table table-borderless table-sm table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Insumo</th>
              <th scope="col">Un</th>
              <th scope="col ">
                <div className="col">Qtde</div>
              </th>
              <th scope="col">
                <div style={{ width: '80px' }}>Qtde p/ Ha</div>
              </th>
              <th scope="col " className="text-center">
                <div style={{ width: '90px' }}>Preço Médio</div>
              </th>
              <th scope="col " className="text-center">
                <div style={{ width: '90px' }}>P. Méd. p/ Ha</div>
              </th>
              <th scope="col" className="text-end">
                Total
              </th>
            </tr>
          </thead>

          <tbody>
            {agriculturalInputs.map((agri_input, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{agri_input.name}</td>
                <td>{agri_input.unit_type}</td>
                <td>{formatSimplePrice(agri_input.quantity)}</td>
                <td>{formatSimplePrice(agri_input.quantity_by_area)}</td>
                <td>{formatPrice(agri_input.average_price)}</td>
                <td>{formatPrice(agri_input.value_by_area)}</td>
                <td className="text-end">{formatPrice(agri_input.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>

      <div className="row justify-content-between mt-3">
        <div className="col">
          <h5>Total</h5>
        </div>
        <div className="col text-end">
          <h5>{formatPrice(resume.total)}</h5>
        </div>
      </div>

      <div className="row no-gutters justify-content-end mt-5">
        <div className="col-sm col-md-2">
          <Link
            to={`/dashboard/stock/input_type/${params.input_type}/budget_id/${params.budget_id}/crop_id/${params.crop_id}`}
          >
            <button className="btn btn-success btn-block">Voltar</button>
          </Link>
        </div>
      </div>
    </Widget>
  );
};

export default StockDetailsByCropTillage;

export const Caption = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Label = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  font-weight: bold;

  @media (max-width: 1024px) {
    text-align: left;
  }
`;

export const Header = styled.div`
  margin: 0;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
`;

export const Resume = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  color: #4d4d4d;
  margin-left: 5px;
`;

export const TopBar = styled.div`
  margin-top: 40px;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  background-color: #cdf0b4;
  padding: 5px;
`;

export const Value = styled.tr`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px;

  a {
    color: #53883d !important;
  }
`;

export const Total = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px;
`;

export const Table = styled.div`
  .bg-title {
    background-color: #cecece;
  }
`;
