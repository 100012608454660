import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import DateTime from '../../components/date-time'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { stringToDate, timeToFloat, floatToTimeList } from '../../actions/util'

class Index extends Component {

  constructor() {
    super()

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.linkToMachineTime = this.linkToMachineTime.bind(this)

    this.state = {
      errors: [],
      id: '',
      name: '',
      description: '',
      acquisition_date: '',
      sale_date: '',
      hours_use: '000:00',
      fields: [
        {
          value: '', //0
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo nome é obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'description',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //2
          name: 'acquisition_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //3
          name: 'sale_date',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //4
          name: 'hours_use',
          type: 'text',
          errors: [],
          rules: {}
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) this.getMachine(id)
    this.setState({ id: id })
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = []
    let valid = false

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name)
      }

      return errors
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const machine = this.state

    let fields = this.submitForm(e, machine.fields)
    this.setState({ fields: fields })

    if (machine.valid && machine.errors.length === 0) {
      machine.id ? this.update() : this.save()
    }
    return false
  }

  getMachine = async id => {
    await api.get(`machines/${id}`)
      .then(result => {
        const machine = result.data
        const fields = this.state.fields

        fields[0].value = machine.name
        fields[1].value = machine.description || ''
        fields[2].value = machine.acquisition_date || ''
        fields[3].value = machine.sale_date || ''
        fields[4].value = machine.hours_use || 0

        this.setState({
          id: machine.id,
          name: machine.name,
          description: machine.description || '',
          acquisition_date: machine.acquisition_date ? stringToDate(machine.acquisition_date) : '',
          sale_date: machine.sale_date ? stringToDate(machine.sale_date) : '',
          hours_use: floatToTimeList(machine.hours_use),
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Máquinas', url: '/machines' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/machines')
        toastError(handleError(error))
      })
  }

  handleChange(event) {
    const name = event.target.name
    const machine = this.state
    const fields = this.state.fields

    fields[0].value = machine.name
    fields[1].value = machine.description
    fields[2].value = machine.acquisition_date
    fields[3].value = machine.sale_date
    fields[4].value = machine.hours_use

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const machine = this.state
    machine.hours_use = timeToFloat(machine.hours_use)

    await api.post('machines', machine)
      .then(result => {
        toastSuccess('Novo registro incluído!')
        this.props.history.push('/machines')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  update = async () => {
    const machine = this.state
    machine.hours_use = timeToFloat(machine.hours_use)

    await api.put(`machines/${machine.id}`, machine)
      .then(result => {
        toastSuccess(`Registro de ${machine.name} atualizado!`)
        this.props.history.push('/machines')
      }).catch(error => {
        toastError(handleError(error))
      })
  }

  linkToMachineTime = machine_time_id => {
    this.props.history.push(`/machine-times/${machine_time_id}`)
  }

  render() {
    const machine = this.state

    return (
      <Widget
        title={machine.id ? `Editar ${machine.name}` : 'Nova Máquina'}>
        <div className="row">
          <div className="col col-md-7">
            <form onSubmit={this.submit}>
              <div className="col">
                <InputText
                  label="* Nome"
                  value={machine.name}
                  onChange={this.handleChange}
                  field={machine.fields[0]}
                  validate={(e, field) =>
                    this.validate(e, machine.fields[0])}
                />
              </div>

              <div className="col">
                <InputText
                  label="Descrição"
                  value={machine.description}
                  onChange={this.handleChange}
                  field={machine.fields[1]}
                  validate={(e, field) =>
                    this.validate(e, machine.fields[1])}
                />
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <DateTime
                    label="Data de Aquisição"
                    value={machine.acquisition_date}
                    onChange={date => this.setState({ acquisition_date: date })}
                    field={machine.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, machine.fields[2])}
                  />
                </div>

                <div className="col">
                  <DateTime
                    label="Data de Venda"
                    value={machine.sale_date}
                    onChange={date => this.setState({ sale_date: date })}
                    field={machine.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, machine.fields[3])}
                  />
                </div>
              </div>

              <div>
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="row line-list">
                      <div className="col">
                        <p className="list-group-item-text">Horas trabalhadas</p>
                      </div>
                      <div className="col text-right p-0 m-0">
                        <span className="badge badge-success badge-rounded">{machine.hours_use}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-3 mt-3">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!machine.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-3">
                  <Link to="/machines">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index