import React, { useCallback, useEffect, useRef, useState } from 'react';
import {GoSearch, GoX} from 'react-icons/go'
import { Form } from '@unform/web'
import InputLabel from '../../../components/unform/input-label'
import SelectLabel from '../../../components/unform/select-label'
import InputDateLabel from '../../../components/unform/date-label'
import styled from 'styled-components';
import api from '../../../actions/api'

export default function StocksFilter ({setParams}) {

  const formRef = useRef(null)

  const [optionsBudget, setOptionsBudget] = useState([])

  const [optionsCrop, setOptionsCrop] = useState([])

  const [optionsAgriculturalInputs, setOptionsAgriculturalInputs] = useState([])

  const[isFilter, setIsFilter] = useState(false)

  const [selectedCrop, setSelectedCrop] = useState(null)

  const [selectedBudget, setSelectedBudget] = useState(null)

  const [selectedMovimentType, setSelectedMovimentType] = useState(null)

  const [selectedInputType, setSelectedInputType] = useState(null)

  const [selectedAgriculturalInput, setSelectedAgriculturalInput] = useState(null)

  const [initialDate, setInitialDate] = useState(null)
  
  const [endDate, setEndDate] = useState(null)

  const optionsMovimentType = [
    { label: 'Entrada', value: 'ENTRADA'},
    { label: 'Saída', value: 'SAIDA'},
  ]

  const optionsInputType = [
    { label: 'Fitossanitário', value: 'FITO'},
    { label: 'Fertilizante', value: 'FERT'},
    { label: 'Combustível', value: 'COMB'},
    { label: 'Outro', value: 'OUTRO'},
  ]

  const cleanFilter = useCallback(async e => {
    formRef.current.reset()

    setSelectedBudget(null)
    setSelectedCrop(null)
    setSelectedMovimentType(null)
    setSelectedInputType(null)
    setInitialDate(null)
    setEndDate(null)
    setOptionsCrop([])


    setParams({
      page: 1,
      perPage: 20
    })

    localStorage.removeItem('filter_stoke')
  },[formRef, setParams]);

  const getBudgets = useCallback(async () => {
    await api.get('budgets')
    .then(response => {
      const options = response.data.map(budget => ({
        label: budget.name,
        value: budget.id
      }))
      
      setOptionsBudget(options)
    })
    .catch(error => console.log(error))
  },[])
  
  const getCrops = useCallback(async (budget_id) => {
    setSelectedCrop(null)

    await api.get(`crops?budget_id=${budget_id}`)
    .then(response => {
      const options = response.data.map(crop => ({
        label: crop.name,
        value: crop.id
      }))

      setOptionsCrop(options)
    })
    .catch(error => console.log(error))
  },[])

  const getAgriculturalInputs = useCallback(async()=> {
    setSelectedAgriculturalInput(null)

    await api.get(`agricultural-inputs?page=1&perPage=99999`)
    .then(response => {
      const options = response.data.data.map(crop => ({
        label: crop.name,
        value: crop.name
      }))

      setOptionsAgriculturalInputs(options)
    })
    .catch(error => console.log(error))
  },[])

  const submit = useCallback(async (data) => {
    let filter = {}

    if(selectedBudget) filter.budget_id = selectedBudget.value
    if(selectedCrop) filter.crop_id = selectedCrop.value
    if(selectedMovimentType) filter.stoke_movement_type = selectedMovimentType.value
    if(selectedInputType) filter.input_type = selectedInputType.value
    if(selectedAgriculturalInput) filter.input_name = selectedAgriculturalInput.value
    if(initialDate) filter.init_date = initialDate
    if(endDate) filter.end_date = endDate

    if(Object.keys(filter).length > 0){
      filter.page = 1
      filter.perPage = 999999
    }

    setParams(filter)

    localStorage.setItem('filter_stoke', JSON.stringify(filter))
  },[selectedBudget, selectedCrop, selectedMovimentType, selectedAgriculturalInput, selectedInputType, initialDate, endDate, setParams])
 
  const setFieldsFilter = useCallback((filter) => {
    setIsFilter(true)
    
    document.getElementById('collapseFilter').classList.add('show')

    if(filter.budget_id) {
      const option = optionsBudget.filter(item => item.value === filter.budget_id)
      
      setSelectedBudget(option[0]);
    }
    
    if(filter.crop_id) {
      const option = optionsCrop.filter(item => item.value === filter.crop_id)
      
      setSelectedCrop(option[0]);
    }
    
    if(filter.stoke_movement_type) {
      const option = optionsMovimentType.filter(item => item.value === filter.stoke_movement_type)
      
      setSelectedMovimentType(option[0]);
    }

    if(filter.input_name) {
      const option = optionsAgriculturalInputs.filter(item => item.value === filter.input_name)
      
      setSelectedAgriculturalInput(option[0]);
    }

    if(filter.init_date) setInitialDate(new Date(filter.init_date))

    if(filter.end_date) setEndDate(new Date(filter.end_date))

  },[optionsBudget, optionsCrop, optionsMovimentType])

  useEffect(() => {
    getBudgets()
    getAgriculturalInputs()
  },[getBudgets, getAgriculturalInputs])
 

  // se tiver filtro com orcamento, busca as safras
  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_stoke'))

    if(filter?.budget_id) getCrops(filter.budget_id)
  },[getCrops])


  // se tiver filtro, seta os valores nos campos do formulário
  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem('filter_stoke'))
    
    if(filter) setFieldsFilter(filter) 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[optionsBudget, optionsCrop])

  return (
    <Container>
      <div className="row pt-2">
        <div className="col-sm text-right">
          <a
            className={`${!isFilter ? 'text-success' : 'text-danger '} btn`}
            data-toggle="collapse"
            href="#collapseFilter"
            role="button"
            aria-expanded="false"
            aria-controls="collapseFilter"
            onClick={() => {
              setIsFilter(!isFilter)
              if (isFilter) cleanFilter()
            }}
          >
              {!isFilter && <>Filtrar <GoSearch /></>}
              {isFilter && <>Fechar Filtro <GoX /></>}
          </a>
        </div>
      </div>

      <div className="collapse filter" id="collapseFilter">
        <div className="row justify-content-center">
          <div className="col-sm col-md-8">
            <Form
              ref={formRef}
              onSubmit={submit}
              noValidate
            >
              <div className="row">
                <InputFiltro value={selectedBudget} className="col-sm mt-3">
                  <SelectLabel 
                    name="budget_id" 
                    label="Orçamento" 
                    placeholder="Selecione"
                    value={selectedBudget}
                    onChange={e => {
                      getCrops(e.value)
                      setSelectedBudget(e)
                    }} 
                    options={optionsBudget} />
                </InputFiltro>

                <InputFiltro value={selectedCrop} className="col-sm mt-3">
                  <SelectLabel name="crop_id" 
                    label="Safra" 
                    placeholder="Selecione" 
                    value={selectedCrop}
                    options={optionsCrop} 
                    noOptionsMessage={() => 'Selecione um orçamento'}
                    onChange={e => setSelectedCrop(e)}/>
                </InputFiltro>
              </div>

              <div className="row">
                <InputFiltro value={selectedMovimentType} className="col-sm mt-3">
                  <SelectLabel 
                    name="stoke_movement_type" 
                    label="Tipo Movimento" 
                    placeholder="Selecione" 
                    value={selectedMovimentType}
                    onChange={e => setSelectedMovimentType(e)}
                    options={optionsMovimentType} />
                </InputFiltro>
                    
                <InputFiltro value={selectedInputType} className="col-sm mt-3">
                  <SelectLabel 
                    name="input_type" 
                    label="Tipo Insumo" 
                    placeholder="Selecione" 
                    value={selectedInputType}
                    onChange={e => setSelectedInputType(e)}
                    options={optionsInputType} />
                </InputFiltro>
              </div>

              <div className="row">
                <InputFiltro value={formRef.current?.getFieldValue('input_name')} className="col-sm" >
                  {/* <InputLabel name="input_name" label="Produto" /> */}
                  <SelectLabel 
                    name="input_name" 
                    label="Produto" 
                    placeholder="Selecione"
                    value={selectedAgriculturalInput}
                    onChange={e => setSelectedAgriculturalInput(e)} 
                    options={optionsAgriculturalInputs} />
                </InputFiltro>
              </div>
                  
              <div className="row mt-3">
                <InputFiltro value={initialDate} className="col-sm">
                  <InputDateLabel 
                    id="init_date"
                    name="init_date" 
                    label="Data Inicial" 
                    onChange={(e) => setInitialDate(e)}
                    selected={initialDate}/>
                </InputFiltro>
                
                <InputFiltro value={endDate} className="col-sm">
                  <InputDateLabel 
                    name="end_date" 
                    label="Data Final" 
                    onChange={(e) => setEndDate(e)}
                    selected={endDate}/>
                </InputFiltro>  
              </div>

              <div className="row my-3">
                <div className="col-sm text-right">
                  <button type='button' className="btn btn-secondary text-white m-2 px-5" onClick={() => cleanFilter()}>
                    Limpar Filtro
                  </button>

                  <button 
                    className="btn btn-success m-2 px-5" 
                    disabled={selectedBudget && !selectedCrop}
                    type="submit" >
                    Filtrar
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}

export const Container = styled.div`
  .btn-secondary {
    height: 35px !important;
  }
  
  .btn-success{
    height: 35px !important;
  }

  .btn{
    :focus {
      border: none;
      box-shadow: none;
    }
  }

  .filter {
    background-color: #f9f9f9;
    border-radius: 3px;
  }
  
`

export const InputFiltro = styled.div`
  label {
    color: ${props => props.value ? '#388e3c' : ''};
    font-weight: ${props => props.value ? 'bold' : ''};
  }
`