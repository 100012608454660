import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { MdMonetizationOn } from 'react-icons/md';
import { BiCalendarExclamation } from 'react-icons/bi';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {
  formatPrice,
  formatSimplePrice,
  formatWeight,
} from '../../../actions/util';
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  Label,
  ResponsiveContainer,
} from 'recharts';

const BudgetsDashboard = ({ data }) => {
  const [sortedData, setSortedData] = useState([]);
  const [dataRecipe, setDataRecipe] = useState([]);
  const [dataExpensive, setDataExpensive] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);

  const legendColorRecipe = ['#DAF7A6', '#7DCEA0'];

  const legendColorExpensive = ['#EC7063', '#FF5733'];

  const sum = useCallback(input => {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }, []);

  const toPrepareRecharts = useCallback(
    data => {
      const expensive = data.find(intem => intem.type === 'DESPESA');

      const data_expensive = [
        {
          name: 'Previsto',
          forecast: expensive.forecast * -1,
          forecast_label: formatWeight(expensive.forecast.toFixed(0) * -1),
        },
        {
          name: 'Ralizado',
          done: sum([expensive.done, expensive.out_of_budget]) * -1,
          done_label: formatWeight(
            sum([expensive.done, expensive.out_of_budget]).toFixed(0) * -1
          ),
        },
      ];

      setDataExpensive(data_expensive);

      const recipe = data.find(intem => intem.type === 'RECEITA');

      const data_recipe = [
        {
          name: 'Previsto',
          forecast: recipe.forecast + recipe.out_of_budget,
          forecast_label: formatWeight(
            sum([recipe.forecast, recipe.out_of_budget]).toFixed(0)
          ),
        },
        {
          name: 'Realizado',
          done: recipe.done,
          done_label: formatWeight(recipe.done.toFixed(0)),
        },
      ];

      setDataRecipe(data_recipe);

      const result = data.find(intem => intem.type === 'RESULTADO');

      const data_result = [
        {
          name: 'Previsto',
          forecast: result.forecast,
          forecast_label: formatWeight(result.forecast.toFixed(0)),
        },
        {
          name: 'Realizado',
          done: sum([result.done, result.out_of_budget]),
          done_label: formatWeight(
            sum([result.done, result.out_of_budget]).toFixed(0)
          ),
        },
      ];

      setDataResult(data_result);
    },
    [sum]
  );

  const orderData = useCallback(data => {
    const expensive = data.find(intem => intem.type === 'DESPESA');

    const recipe = data.find(intem => intem.type === 'RECEITA');

    const result = data.find(intem => intem.type === 'RESULTADO');

    const data_ = [expensive, recipe, result];

    setSortedData(data_);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      toPrepareRecharts(data);
      orderData(data);
    }
  }, [data, toPrepareRecharts, orderData]);

  return (
    <Container>
      <h1 className="text-center">
        <MdMonetizationOn size={25} className="mb-1" /> Orçamento
      </h1>

      <div className="row">
        <div className="col-sm col-md-4 ">
          <h6 className="text-center">DESPESAS (R$)</h6>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dataExpensive} margin={{ top: 15, bottom: 5 }}>
              <XAxis dataKey="name" />

              <Bar dataKey="done" fill={legendColorExpensive[0]} barSize={100}>
                <LabelList dataKey="done_label" position="top" />
              </Bar>

              <Bar
                dataKey="forecast"
                fill={legendColorExpensive[1]}
                barSize={100}
              >
                <LabelList dataKey="forecast_label" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="col-sm col-md-4 ">
          <h6 className="text-center">RECEITA</h6>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dataRecipe} margin={{ top: 15, bottom: 5 }}>
              <XAxis dataKey="name" />

              <Bar dataKey="done" fill={legendColorRecipe[0]} barSize={100}>
                <LabelList dataKey="done_label" position="top" />
              </Bar>

              <Bar dataKey="forecast" fill={legendColorRecipe[1]} barSize={100}>
                <LabelList dataKey="forecast_label" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="col-sm col-md-4 ">
          <h6 className="text-center">RESULTADO</h6>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dataResult} margin={{ top: 15, bottom: 5 }}>
              <XAxis dataKey="name">
                <Label value="RESULTADO" offset={0} position="insideBottom" />
              </XAxis>

              <Bar
                dataKey="done"
                fill={data[2]?.done >= 0 ? '#79C047' : '#FF5733'}
                barSize={100}
              >
                <LabelList dataKey="done_label" position="top" />
              </Bar>

              <Bar dataKey="forecast" fill="#9999" barSize={100}>
                <LabelList dataKey="forecast_label" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col text-center">
          {!viewDetails && (
            <div
              className="detail"
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => setViewDetails(true)}
            >
              <span>Relatório detalhado</span>

              <div>
                <BsChevronCompactDown />
              </div>
            </div>
          )}
        </div>
      </div>

      {viewDetails && (
        <div className="row justify-content-center">
          {data.length > 0 && (
            <>
              {sortedData.map((reg, i) => (
                <div key={i} className="col-sm col-md-3">
                  <BudgetCard type={reg.type}>
                    <Link
                      to={
                        reg.type !== 'RESULTADO'
                          ? `dashboard/budgets/${reg.type}`
                          : '#'
                      }
                    >
                      <div className="card shadow">
                        <div className="card-body">
                          <h5 className="card-title text-center">{reg.type}</h5>

                          <div className="table-responsive">
                            <table className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="text-left">PREVISTO</td>
                                  <td className="text-right">
                                    {formatPrice(reg.forecast)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>REALIZADO</td>
                                  <td>{formatPrice(reg.done)}</td>
                                </tr>
                                <tr>
                                  <td>FORA DATA **</td>
                                  <td>{formatPrice(reg.out_of_budget)}</td>
                                </tr>
                                <tr>
                                  <td>DIVERGÊNCIA</td>
                                  <td>
                                    {formatSimplePrice(reg.percent_divergence)}%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="card-footer text-center">
                          <small className="text-muted">
                            <BiCalendarExclamation
                              size={15}
                              className="mb-1 mr-1"
                            />
                            ** Lançamentos fora da data de início e término do
                            ORÇAMENTO!
                          </small>
                        </div>
                      </div>
                    </Link>
                  </BudgetCard>
                </div>
              ))}
            </>
          )}

          <div className="col-12 text-center">
            {viewDetails && (
              <div
                className="detail mt-3"
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() => setViewDetails(false)}
              >
                <div>
                  <BsChevronCompactUp />
                </div>

                <span>Recolher relatório</span>
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default BudgetsDashboard;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;

  h1 {
    color: #53883d;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h5 {
    color: #53883d;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-top: -10px;
  }

  h6 {
    color: #7d7d7d;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
  }

  small {
    color: #53883d !important;
  }
  .table td {
    font-size: 11px;
    font-weight: bold;
    color: #53883d;
    border: 0;
  }

  .table tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .table th {
    border: 0;
  }

  .card {
    height: 100%;
    border-radius: 0;
  }

  .card-body {
    padding-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    background: none !important;
    border: none !important;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #53883d;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }

  @media screen and (max-width: 1290px) {
    h5 {
      font-size: 12px;
    }
  }
`;

export const BudgetCard = styled.div`
  a {
    color: #53883d !important;
    cursor: auto;

    ${props =>
      props.type !== 'RESULTADO'
        ? `
     cursor: pointer;
    :hover {
      color: #fff!important;
      h5 { color: #fff; }
      td { color: #fff; }
      small { color: #fff !important; }
      .card { 
        background-color: #53883d !important; 
        }
    }
    `
        : ''}
  }
`;
