import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { dateToString, floatToTimeList } from '../../actions/util'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import DataTable from 'react-data-table-component'
import Loading from '../../components/loading'
import IrrigationsFilter from './filter/IrrigationsFilter'

class Irrigations extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      page: 1,
      perPage: 10,
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Nova Irrigação',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-3',
          route: 'irrigations/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' }

    this.columns = [
      {
        name: 'Data',
        selector: 'date_format',
      },
      {
        name: 'Safra',
        selector: 'crop.name',
        sortable: true,
      },
      {
        name: 'Horas',
        selector: 'amount_of_hours_format',
        sortable: true,
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  async componentDidMount() {
    await this.getIrrigations()
  }

  getIrrigations = async () => {
    let irrigations = []
    this.setState({ loading: true })

    const { page, perPage } = this.state

    await api.get(`irrigations?page=${page}&perPage=${perPage}`)
      .then(result => {
        irrigations = result.data

        irrigations.data = result.data.data.map(irrigation => ({
          ...irrigation,
          date_format: dateToString(irrigation.date),
          amount_of_hours_format: floatToTimeList(irrigation.amount_of_hours)
        }))
      })
      .catch(error => {
        toastError(handleError(error))
      })

    this.setState({
      data: irrigations.data,
      totalRows: parseInt(irrigations.total),
      loading: false,
    })
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.crop.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state

    await api.delete(`irrigations/${itemDeleteId}`)
      .then(() => {
        this.getIrrigations()
        toastSuccess('Registro removido com sucesso!')
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/irrigations/${reg.id}`)
  }

  handlePageChange = async page => {
    await this.setState({ page: page });

    this.getIrrigations();
  }

  handlePerRowsChange = async (perPage,) => {
    await this.setState({ perPage: perPage });

    this.getIrrigations();
  }

  handleFilter = async isFilter => {
    await this.setState({ isFilter: isFilter })
  }

  render() {
    return (
      <Widget title="Irrigações" actions={this.state.actions} line={false}>
        <IrrigationsFilter
          filter={this.getIrrigations.bind(this)}
          isFilter={this.handleFilter.bind(this)}
          total={this.state.totalRows}
        />

        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
              // overflowYOffset="500px"
              pagination
              paginationServer
              paginationTotalRows={this.state.totalRows}
              paginationComponentOptions={this.paginationOptions}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir irrigação da safra {this.state.itemDeleteName}?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default Irrigations