import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import approve from 'approvejs';

import Widget from '../../components/widget';
import InputText from '../../components/input-text';
import Select from '../../components/select';
import InputCurrency from '../../components/input-mask-currency';
import InputDate from '../../components/date-time';
import RadioButton from '../../components/radio-button';

import api from '../../actions/api';
import { toastError, toastSuccess } from '../../actions/toast';
import { handleError } from '../../actions/handle-error';
import {
  currencyToString,
  stringToDate,
  formatPrice,
} from '../../actions/util';

class Index extends Component {
  constructor() {
    super();

    this.submit = this.submit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      title: '',
      id: '',
      date: '',
      budget_id: '',
      movement_type: '',
      account_id: '',
      accounting_id: '',
      person_id: '',
      payment_type: 'DINHEIRO',
      document: '',
      due_date: '',
      pay_date: '',
      value: '',
      increase: 0,
      discount: 0,
      description: '',
      historic: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //1
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //2
          name: 'movement_type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Crédito', value: 'CREDITO' },
            { label: 'Débito', value: 'DEBITO' },
            { label: 'Transferência', value: 'TRANSFERENCIA' },
          ],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //3
          name: 'account_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //4
          name: 'accounting_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //5
          name: 'person_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //6
          name: 'payment_type',
          type: 'text',
          options: [
            { label: '', value: null },
            { label: 'Boleto', value: 'BOLETO' },
            { label: 'Cheque', value: 'CHEQUE' },
            { label: 'Dinheiro', value: 'DINHEIRO' },
            { label: 'Cartão de Crédito', value: 'CARTAO_CREDITO' },
            { label: 'Cartão de Débito', value: 'CARTAO_DEBITO' },
            { label: 'Doc TED', value: 'DOC_TED' },
            { label: 'Transferência', value: 'TRANSFERENCIA' },
            { label: 'Outros', value: 'OUTROS' },
          ],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //7
          name: 'document',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //8
          name: 'due_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //9
          name: 'pay_date',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //10
          name: 'value',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //11
          name: 'increase',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //12
          name: 'discount',
          type: 'text',
          errors: [],
          rules: {},
        },
        {
          value: '', //13
          name: 'description',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório',
            },
          },
        },
        {
          value: '', //14
          name: 'historic',
          type: 'text',
          errors: [],
          rules: {},
        },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const { id, type } = this.props.match.params;

    if (id) this.getFinancialMovement(id);

    if (type) {
      this.setState({
        title: type === 'entry' ? 'Receita' : 'Despesa',
        movement_type: type === 'entry' ? 'CREDITO' : 'DEBITO',
        date: new Date(),
        due_date: new Date(),
        pay_date: new Date(),
      });
    }

    this.getBudgets();
    this.getAccounts();
    this.getAccountings();
    this.getPeople();
  }

  validateField(e, field, fields) {
    e.preventDefault();
    field.value = '';
    let result = approve.value(e.target.value, field.rules);
    field.errors = [];
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors);
      field.value = '';
    } else {
      field.value = e.target.value;
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field;
      } else {
        return item;
      }
    });
    return fields;
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields);
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    });

    if (errors.length > 0) {
      valid = false;
    } else {
      valid = true;
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid,
    });
    return false;
  }

  submitForm = (e, fields) => {
    e.preventDefault();
    fields = fields.map(field => {
      field.errors = [];
      let result = approve.value(field.value, field.rules);

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors);
      }
      return field;
    });
    return fields;
  };

  submit(e) {
    e.preventDefault();
    const financialMovement = this.state;

    let fields = this.submitForm(e, financialMovement.fields);
    this.setState({ fields: fields });

    if (financialMovement.valid && financialMovement.errors.length === 0) {
      financialMovement.id ? this.update() : this.save();
    }
    return false;
  }

  getFinancialMovement = async id => {
    await api
      .get(`financial-movements/${id}`)
      .then(result => {
        const financialMovement = result.data;
        const fields = this.state.fields;

        fields[0].value = financialMovement.date;
        fields[1].value = financialMovement.budget_id;
        fields[2].value = financialMovement.movement_type;
        fields[3].value = financialMovement.account_id;
        fields[4].value = financialMovement.accounting_id;
        fields[5].value = financialMovement.person_id;
        fields[6].value = financialMovement.payment_type;
        fields[7].value = financialMovement.document;
        fields[8].value = financialMovement.due_date;
        fields[9].value = financialMovement.pay_date;
        fields[10].value = formatPrice(financialMovement.value);
        fields[11].value = formatPrice(financialMovement.increase);
        fields[12].value = formatPrice(financialMovement.discount);
        fields[13].value = financialMovement.description;
        fields[14].value = financialMovement.historic;

        this.setState({
          id: financialMovement.id,
          date: financialMovement.date
            ? stringToDate(financialMovement.date)
            : '',
          budget_id: financialMovement.budget_id || '',
          movement_type: financialMovement.movement_type || '',
          account_id: financialMovement.account_id || '',
          accounting_id: financialMovement.accounting_id || '',
          person_id: financialMovement.person_id || '',
          payment_type: financialMovement.payment_type || '',
          document: financialMovement.document || '',
          due_date: financialMovement.due_date
            ? stringToDate(financialMovement.due_date)
            : '',
          pay_date: financialMovement.pay_date
            ? stringToDate(financialMovement.pay_date)
            : '',
          value: formatPrice(financialMovement.value) || 0,
          increase: formatPrice(financialMovement.increase) || 0,
          discount: formatPrice(financialMovement.discount) || 0,
          description: financialMovement.description || '',
          historic: financialMovement.historic || '',
          fields: fields,
        });

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Movimentação Financeira', url: '/financial-movements' },
            {
              page:
                financialMovement.movement_type === 'CREDITO'
                  ? 'Entrada'
                  : 'Saída',
              url: '#',
            },
            { page: 'Editar', url: '#' },
          ],
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 500)
          this.props.history.push('/financial-movements');
        toastError(handleError(error));
      });
  };

  handleChange(event) {
    const name = event.target.name;
    const financialMovement = this.state;
    const fields = this.state.fields;

    fields[0].value = financialMovement.date;
    fields[1].value = financialMovement.budget_id;
    fields[2].value = financialMovement.movement_type;
    fields[3].value = financialMovement.account_id;
    fields[4].value = financialMovement.accounting_id;
    fields[5].value = financialMovement.person_id;
    fields[6].value = financialMovement.payment_type;
    fields[7].value = financialMovement.document;
    fields[8].value = financialMovement.due_date;
    fields[9].value = financialMovement.pay_date;
    fields[10].value = financialMovement.value;
    fields[11].value = financialMovement.increase;
    fields[12].value = financialMovement.discount;
    fields[13].value = financialMovement.description;
    fields[14].value = financialMovement.historic;

    this.setState({
      [name]: event.target.value,
      fields: fields,
    });
  }

  save = async () => {
    const financialMovement = this.state;
    let financialMovements = [];
    financialMovement.value = currencyToString(financialMovement.value);
    financialMovement.increase = currencyToString(financialMovement.increase);
    financialMovement.discount = currencyToString(financialMovement.discount);

    financialMovements.push(financialMovement);

    await api
      .post('financial-movements', { movements: financialMovements })
      .then(() => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/financial-movements');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  update = async () => {
    const financialMovement = this.state;
    financialMovement.value = currencyToString(financialMovement.value);
    financialMovement.increase = currencyToString(financialMovement.increase);
    financialMovement.discount = currencyToString(financialMovement.discount);

    await api
      .put(`financial-movements/${financialMovement.id}`, financialMovement)
      .then(() => {
        toastSuccess(`Registro de ${financialMovement.name} atualizado!`);
        // this.props.history.push('/financial-movements');
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getBudgets = async () => {
    let budgets = [];
    const { fields } = this.state;

    await api
      .get('budgets')
      .then(result => {
        fields[1].options = [];

        budgets = result.data.map(result => ({
          value: result.id,
          label: result.name,
        }));

        fields[1].options = budgets;
        fields[1].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields, budget_id: budgets[1].value });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getAccounts = async () => {
    let accounts = [];
    const { fields } = this.state;

    await api
      .get('accounts?status_type=ATIVO')
      .then(result => {
        fields[3].options = [];

        accounts = result.data.accounts.map(result => ({
          value: result.id,
          label: result.name,
        }));

        fields[3].options = accounts;
        fields[3].options.unshift({ id: null, label: '' });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getAccountings = async () => {
    let accountings = [];
    const { fields } = this.state;
    const { type } = this.props.match.params;
    const accouting_type = type === 'entry' ? 'RECEITA' : 'DESPESA';

    await api
      .get(`accountings?status_type=ATIVO&accounting_type=${accouting_type}`)
      .then(result => {
        fields[4].options = [];

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          const item = {
            value: element.id,
            label: `${element.number} ${element.name}`,
            disabled: true,
          };
          accountings.push(item);

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x];
              const item = {
                value: elem.id,
                label: `${elem.number} ${elem.name}`,
              };
              accountings.push(item);
            }
          }
        }

        fields[4].options = accountings;
        fields[4].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getPeople = async () => {
    let options = [];
    const { fields } = this.state;

    await api
      .get(`people?page=1&perPage=99999`)
      .then(result => {
        fields[5].options = [];

        options = result.data.data.map(result => ({
          value: result.id,
          label: result.name,
        }));

        options.sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        });

        fields[5].options = options;
        fields[5].options.unshift({ id: null, label: '' });

        this.setState({ fields: fields });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  handleMovement = () => {
    this.setState({ addMovement: true });
  };

  render() {
    const financialMovement = this.state;

    return (
      <Widget
        title={
          financialMovement.id
            ? `Editar ${financialMovement.title}`
            : `Nova ${financialMovement.title}`
        }
      >
        <div className="row">
          <div className="col">
            <form onSubmit={this.submit}>
              {financialMovement.id && (
                <div className="row">
                  <div className="col col-md-3">
                    <InputDate
                      label="* Data de Inclusão"
                      value={financialMovement.date}
                      disabled
                      onChange={date => this.setState({ date: date })}
                      field={financialMovement.fields[0]}
                      validate={(e, field) =>
                        this.validate(e, financialMovement.fields[0])
                      }
                    />
                  </div>
                  <div className="col col-md-4">
                    <RadioButton
                      label="* Tipo Movimento"
                      value={financialMovement.movement_type}
                      options={financialMovement.fields[2].options}
                      onChange={this.handleChange}
                      disabled={true}
                      field={financialMovement.fields[2]}
                      validate={(e, field) =>
                        this.validate(e, financialMovement.fields[2])
                      }
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col">
                  <Select
                    label="* Orçamento"
                    value={financialMovement.budget_id}
                    options={financialMovement.fields[1].options}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[1])
                    }
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Conta Bancária"
                    value={financialMovement.account_id}
                    options={financialMovement.fields[3].options}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[3])
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-md-6">
                  <Select
                    label="* Conta Movimento"
                    value={financialMovement.accounting_id}
                    options={financialMovement.fields[4].options}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[4])
                    }
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Nome"
                    value={financialMovement.person_id}
                    options={financialMovement.fields[5].options}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[5])
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col col-md-3">
                  <Select
                    label="* Tipo de Pgto"
                    value={financialMovement.payment_type}
                    options={financialMovement.fields[6].options}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[6])
                    }
                  />
                </div>

                <div className="col col-md-3">
                  <InputText
                    label="Documento"
                    value={financialMovement.document}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[7]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[7])
                    }
                  />
                </div>

                <div className="col">
                  <InputText
                    label="* Descrição"
                    value={financialMovement.description}
                    onChange={this.handleChange}
                    field={financialMovement.fields[13]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[13])
                    }
                  />
                </div>
              </div>

              <div className="row">
                {/* {financialMovement.id && */}
                <>
                  <div className="col">
                    <InputDate
                      label="* Data Vencimento"
                      value={financialMovement.due_date}
                      disabled={
                        financialMovement.movement_type === 'TRANSFERENCIA'
                      }
                      onChange={date => this.setState({ due_date: date })}
                      field={financialMovement.fields[8]}
                      validate={(e, field) =>
                        this.validate(e, financialMovement.fields[8])
                      }
                    />
                  </div>

                  <div className="col col-md-2">
                    <InputDate
                      label="* Data Pagamento"
                      value={financialMovement.pay_date}
                      onChange={date => this.setState({ pay_date: date })}
                      disabled={
                        financialMovement.movement_type === 'TRANSFERENCIA'
                      }
                      field={financialMovement.fields[9]}
                      validate={(e, field) =>
                        this.validate(e, financialMovement.fields[9])
                      }
                    />
                  </div>
                </>
                {/* } */}

                <div className="col">
                  <InputCurrency
                    label="* Valor"
                    value={financialMovement.value}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[10]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[10])
                    }
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="Acréscimo"
                    value={financialMovement.increase}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[11]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[11])
                    }
                  />
                </div>

                <div className="col">
                  <InputCurrency
                    label="Desconto"
                    value={financialMovement.discount}
                    onChange={this.handleChange}
                    disabled={
                      financialMovement.movement_type === 'TRANSFERENCIA'
                    }
                    field={financialMovement.fields[12]}
                    validate={(e, field) =>
                      this.validate(e, financialMovement.fields[12])
                    }
                  />
                </div>
              </div>

              <div className="row">
                {financialMovement.id && (
                  <div className="col">
                    <InputText
                      label="Histórico"
                      value={financialMovement.historic}
                      onChange={this.handleChange}
                      disabled={
                        financialMovement.movement_type === 'TRANSFERENCIA'
                      }
                      field={financialMovement.fields[14]}
                      validate={(e, field) =>
                        this.validate(e, financialMovement.fields[14])
                      }
                    />
                  </div>
                )}
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3 ">
                <div className="col col-md-3 mt-5">
                  <Link to="#" className="mr-2">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!financialMovement.valid}
                      onClick={this.submit}
                    >
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-2 mt-5">
                  <Link to="/financial-movements">
                    <button className="btn btn-success btn-block ">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default Index;
