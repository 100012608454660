import React from 'react';
import styled from 'styled-components';
import { GiGrain } from 'react-icons/gi';
import StockDetail from './StockDetail';

function AgriculturalInputs({ fito, ferti, outro, combustivel, budget_id }) {
  return (
    <Container className="text-center">
      <h1>
        <GiGrain size={22} /> Insumos Agrícolas
      </h1>

      <div className="row justify-content-center card-group">
        {fito[0]?.id && (
          <div className="col-sm col-md-6 col-lg-3 mb-3">
            <StockDetail
              data={fito}
              budget_id={budget_id}
              title="fito"
              input_type="FITO"
            />
          </div>
        )}

        {ferti[0]?.id && (
          <div className="col-sm col-md-6 col-lg-3 mb-3">
            <StockDetail
              data={ferti}
              budget_id={budget_id}
              title="Ferti"
              input_type="FERT"
            />
          </div>
        )}

        {combustivel[0]?.id && (
          <div className="col-sm col-md-6 col-lg-3 mb-3">
            <StockDetail
              data={combustivel}
              budget_id={budget_id}
              title="Combustível"
              input_type="COMB"
            />
          </div>
        )}

        {outro[0]?.id && (
          <div className="col-sm col-md-6 col-lg-3 mb-3">
            <StockDetail
              data={outro}
              budget_id={budget_id}
              title="outros"
              input_type="OUTRO"
            />
          </div>
        )}
      </div>
    </Container>
  );
}

export default AgriculturalInputs;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  padding-bottom: 30px;

  h1 {
    color: #53883d;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }
`;
