import React from 'react'
import Logo from '../../logo'
import ToggleRightSidebar from '../ToggleRightSidebar'
import Profile from  '../../profile/Profile'
import '../../../css/navbar/navbar-2.css'

const Navbar2 = () => (
  <nav className="navbar navbar-2 d-flex justify-content-around align-items-center flex-nowrap">
    <Logo />
    <div className="separator" />
    <ul className="nav nav-inline nav-inline-2">
      <li className="nav-item nav-item-dropdown">
        <Profile />
      </li>
      <ToggleRightSidebar />
    </ul>
  </nav>
)

export default Navbar2
