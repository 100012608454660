import React, { useContext } from 'react';
import Menu from './Menu';
import { AuthContext } from '../../actions/auth-context';
import './left-sidebar-1.css';

const LeftSidebar = ({ navigation }) => {
  const { user, tenant } = useContext(AuthContext);

  const profile = {
    title: tenant?.name,
    items: [
      {
        url: '#',
        icon: 'person',
        title: user?.username,
        items: [
          {
            url: `users/${user?.id}`,
            title: 'Meus Dados',
            items: [],
          },
          {
            url: '/logout',
            title: 'Sair',
            items: [],
          },
        ],
      },
      {
        url: '/',
        icon: 'dashboard',
        title: 'Dashboard',

        items: [],
      },
    ],
  };

  return (
    <div>
      <div className="left-sidebar-placeholder" />
      <div className="left-sidebar left-sidebar-1">
        <div className="wrapper">
          <div className="content">
            <div className="section">
              <div className="section-title">{tenant?.name}</div>
              <ul className="list-unstyled">
                {profile.items.map((items, i) => (
                  <Menu key={i} items={items} />
                ))}
              </ul>
            </div>

            {navigation.map((menu, i) => (
              <div key={i} className="section">
                <div className="section-title">{menu.title}</div>
                <ul className="list-unstyled">
                  {menu.items.map((items, i) => (
                    <Menu key={i} items={items} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
