import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { formatPerfilType } from '../../actions/enum-formatter'
import { getUserLogged } from '../../actions/pages/user.service'

import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'

class Users extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Novo Usuário',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'users/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Usuário',
        selector: 'username',
        sortable: true,
      },
      {
        name: 'E-mail',
        selector: 'email',
        sortable: true
      },
      {
        name: 'Perfil',
        selector: 'perfil_type',
        sortable: true
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            {this.props.userLogged.perfil_type === 'ADMIN' &&
              <Link to="#">
                <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
              </Link>
            }
          </div>
        ),
        button: true,
      },
    ]
  }

  componentDidMount() {
    getUserLogged(this.props);
    this.getUsers();
  }

  getUsers = async () => {
    let users = [];
    this.setState({ loading: true });

    await api.get('users')
      .then(result => {
        users = result.data

        for (let i = 0; i < users.length; i++) {
          const data = users[i];
          if (data.perfil_type) users[i].perfil_type = formatPerfilType(data.perfil_type);
        }
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: users,
      totalItems: users.length,
      loading: false,
    });
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.username, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`users/${itemDeleteId}`)
      .then(() => {
        this.getUsers();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/users/${reg.id}`)
  };

  render() {
    const { userLogged } = this.props

    return (
      <Widget
        title="Usuários"
        actions={userLogged.perfil_type === 'ADMIN' ? this.state.actions : []}
        line={false}
      >
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir {this.state.itemDeleteName}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
            </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDelete: null })}>
              Cancelar
            </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userLogged: state.user
  }
}

export default connect(mapStateToProps)(Users)