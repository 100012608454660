import styled from 'styled-components'

export const Parcelas = styled.div`
  padding: 0;

  label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #4D4D4D;
    margin: 0;
  }

  .input {
    margin-top: -20px;
  }

  input {
    width: 50px;
    text-align: center;
    margin-left: -2px;
    margin-right: -3px;
  }

  button {
    margin-top: 19px !important;
    height: 36px;
  }

`