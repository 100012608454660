import styled from 'styled-components';

export const Container = styled.div`
  background: url('/assets/images/background2.svg') center center no-repeat;
  height: 100vh;
  background-size: cover;
  width: 110%;
  margin-left: -10px;
  padding-left: 20px;
  padding-right: 40px;

  img {
    width: 70%;
    margin-top: 150px;
  }

  h2 {
    font-weight: bold;
    color: #4d4d4d;
  }

  .bg {
    background-color: #fff;
    position: absolute;
    opacity: 0.6;
    width: 110%;
    margin-left: -20px;
    height: 100vh;
  }


  .form {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: 20px;
    margin-top: 20%;

    label {
      font-size: 14px;
      font-weight: bold;
    }

    button {
      font-size: 18px;
      font-weight: bold;
      height: 50px;
      border-radius: 50px;
    }
  }

  .forgot {
    a {
      font-size: 16px;
      text-transform: uppercase;
      color: #4d4d4d;
      font-weight: bold;

      :hover {
        color: #388e3c;
      }
    }
  }

  @media (max-width: 768px) {
    img {
      width: 50%;
      margin-top: 20px;
    }

    h2 {
      font-size: 16px;
    }

    .form {
      margin-top: 20px;
      margin: 10px;
    }
  }
`;
