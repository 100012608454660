import React, { useCallback } from 'react';
import { IoIosTrash, IoMdBarcode } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import SyncLoader from 'react-spinners/SyncLoader';
import ReactTooltip from 'react-tooltip';
import {AiFillPlusCircle, AiFillMinusCircle} from 'react-icons/ai'
import {MdSwapVerticalCircle} from 'react-icons/md'
import {FaMoneyCheckAlt, FaRegCreditCard, FaCreditCard,} from 'react-icons/fa'
import {GiMoneyStack} from 'react-icons/gi'
import {BiTransfer} from 'react-icons/bi'
import {LiaMoneyCheckAltSolid} from 'react-icons/lia'
import {BsCreditCard2Back} from 'react-icons/bs'

import PropTypes from 'prop-types';
import styled from 'styled-components';

const FinancialMovementsTable = ({
  data,
  page,
  setItemDelete,
  setParams,
  loading,
  setScrolling,
}) => {
  const history = useHistory();

  const changePage = useCallback(() => {
    setScrolling(true);

    setParams((prevState) => ({
      ...prevState,
      page: page.current_page + 1,
    }));

    const filter = JSON.parse(localStorage.getItem('filter_financial_movements'))

    if(filter) {
      filter.page = page.current_page + 1

      localStorage.setItem('filter_financial_movements', JSON.stringify(filter))
    }
  }, [page, setParams, setScrolling]);

  const handleRowClicked = useCallback(reg => {
    const movement_type = reg.movement_type === 'CREDITO' ? 'entry' : 'out';
    
    history.push(`/financial-movements/${movement_type}/${reg.id}`);
  },[history])

  return (
    <Container>      
      <div className="row">
      
        <div className="col mt-3">
          <InfiniteScroll
            dataLength={data?.length}
            next={changePage}
            hasMore={page.has_next_page}
            loader={
              <Loader>
                {loading && (
                  <div className="loader">
                    <SyncLoader color="#388e3c" size={15} margin={10} />
                  </div>
                )}
              </Loader>
            }
          >
            <div className="table-responsive">
              <table className="table table-hover text-center table-sm">
                <thead className='bg-success'>
                  <tr>
                    <th scope="col" className="text-start">Tipo</th>
                    <th scope="col" className="text-start">D. Venc.</th>
                    <th scope="col" className="text-start">D. Pgto</th>
                    <th scope="col" className="text-start">Descrição</th>
                    <th scope="col" className="text-center">T. Pgto</th>
                    <th scope="col" className="text-start">Doc.</th>
                    <th scope="col">Valor(R$)</th>
                    <th scope="col" className="text-end">Ação</th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, i) => (
                    <tr
                      key={i}
                      onClick={(e) => {
                        if (e.target.innerText !== undefined) handleRowClicked(item)
                      }}
                    >
                      <td className="text-center">
                        {item.movement_type === 'CREDITO' && 
                          <AiFillPlusCircle data-tip="Crédito" size={20} color="#3F9D2F"/>}

                        {item.movement_type === 'DEBITO' && 
                          <AiFillMinusCircle data-tip="Débito" size={20} color="#D1151A"/>}

                        {item.movement_type === 'TRANSFERENCIA' && 
                          <MdSwapVerticalCircle data-tip="Transferência" size={20} color="#8A2BE2"/>}

                        <ReactTooltip />
                      </td>
                      <td>{item.due_date_format}</td>
                      <td>{item.pay_date_format}</td>
                      <td className="text-start">{item.description}</td>
                      <td className='text-center'>
                        {item.payment_type === 'CARTAO_CREDITO' && (
                          <FaCreditCard size={18} color='#696969' data-tip="Cartão de Crédito"/>
                        )}
                        
                        {item.payment_type === 'DINHEIRO' && (
                          <GiMoneyStack size={22} color='#696969' data-tip="Dinheiro"/>
                        )}
                        
                        {item.payment_type === 'BOLETO' && (
                          <IoMdBarcode size={20} color='#696969' data-tip="Boleto"/>
                        )}
                        
                        {item.payment_type === 'CHEQUE' && (
                          <LiaMoneyCheckAltSolid size={22} color='#696969' data-tip="Cheque"/>
                        )}

                        {item.payment_type === 'CARTAO_DEBITO' && (
                          <BsCreditCard2Back size={20} color='#696969' data-tip="Cartão de Débito"/>
                        )}
                        
                        {item.payment_type === 'TRANSFERENCIA' && (
                          <BiTransfer size={20} color='#696969' data-tip="Transferência"/>
                        )}

                        <ReactTooltip />
                      </td>
                      <td className='text-start'>{item.document}</td>
                      <td>{item.value_format}</td>

                      <td>
                        <div className="action">
                          <IoIosTrash
                            size={20}
                            data-tip="Excluir"
                            onClick={() =>
                              setItemDelete({
                                id: item.id,
                                description: item.description,
                              })
                            }
                          />
                          <ReactTooltip />
                        </div>
                      </td>
                    </tr>
                  ))}

                  {data.length === 0 && (
                    <tr>
                      <td colSpan={9}>
                        Nenhum registro encontrado!
                      </td>
                    </tr>
                  )}      
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>

      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - {data?.length} de <b>{page?.total}</b>
        </h6>
      </div>
    </Container>
  );
};

FinancialMovementsTable.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setScrolling: PropTypes.func.isRequired,
};

export default FinancialMovementsTable;

export const Container = styled.section`
  height: 100vh;
  margin-top: -40px;

  h6 {
    font-size: 12px;
    margin-bottom: -20px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .bg-success {
    /* background-color: #E8E8E8 !important; */
    background-image: linear-gradient(white, #bfbfbf);
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;


export const Loader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .loader {
    position: absolute;
    z-index: 10;
    margin-top: 200px;
  }
`