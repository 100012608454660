import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../../components/widget'
import InputText from '../../../components/input-text'
import InputQuantity from '../../../components/input-text-quantity'
import DateTime from '../../../components/date-time'
import Select from '../../../components/select'
import RadioButton from '../../../components/radio-button'

import api from '../../../actions/api'
import { toastError, toastSuccess } from '../../../actions/toast'
import { handleError } from '../../../actions/handle-error'
import { stringToDate, wheigthToFloat } from '../../../actions/util'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      date: new Date(),
      crop_id: '',
      tillage_id: '',
      agricultural_input_id: '',
      stoke_movement_type: 'SAIDA',
      document: '',
      quantity: '',
      fields: [
        {
          value: '', //0
          name: 'date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'stoke_movement_type',
          type: 'text',
          options: [
            { label: 'Entrada', value: 'ENTRADA' },
            { label: 'Saída', value: 'SAIDA' },
          ],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'crop_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'tillage_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //4
          name: 'agricultural_input_id',
          type: 'text',
          options: [],
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'document',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //6
          name: 'quantity',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getStock(id);
    this.getCrops()
    this.getTillages();
    this.getAgruculturalInputs();
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const stock = this.state;

    let fields = this.submitForm(e, stock.fields);
    this.setState({ fields: fields });

    if (stock.valid && stock.errors.length === 0) {
      stock.id ? this.update() : this.save();
    }
    return false
  }

  getTillages = async () => {
    await api.get('tillages')
      .then(result => {
        this.setState({ tillages: result.data })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getCrops = async () => {
    await api.get('crops')
      .then(result => {
        const crops = result.data
        const options = [{ label: '', value: null }]

        crops.map(team => {
          const p = {
            label: team.name,
            value: team.id
          }
          options.push(p)
          return null
        })
        const { fields } = this.state

        fields[2].options = options
        this.setState({
          fields: fields
        })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getAgruculturalInputs = () => {
    api.get(`agricultural-inputs?page=1&perPage=99999`)
      .then(result => {
        const list = result.data.data
        const options = [{ label: '', value: null }];

        list.map(item => {
          const p = {
            label: item.name,
            value: item.id
          }
          options.push(p)
          return null;
        })
        const { fields } = this.state;

        fields[4].options = options;
        this.setState({
          fields: fields
        })
      })
  }

  getStock = async id => {

    await api.get(`stocks/${id}`)
      .then(result => {

        const stock = result.data;
        const fields = this.state.fields;

        fields[0].value = stock.date;
        fields[1].value = stock.stoke_movement_type;
        fields[2].value = stock.crop_id;
        fields[3].value = stock.tillage_id;
        fields[4].value = stock.agricultural_input_id;
        fields[5].value = stock.document;
        fields[6].value = stock.quantity;

        fields[3].options = [{ label: stock.tillage.name, value: stock.tillage.id }]

        this.setState({
          id: stock.id,
          date: stock.date ? stringToDate(stock.date) : '',
          stoke_movement_type: stock.stoke_movement_type || '',
          crop_id: stock.tillage.crop.id || '',
          tillage_id: stock.tillage_id || '',
          agricultural_input_id: stock.agricultural_input_id || '',
          document: stock.document || '',
          quantity: stock.quantity || '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Estoque', url: '/stocks' },
            { page: 'Saída', url: '#' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/stocks')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const stock = this.state;
    const fields = this.state.fields;

    fields[0].value = stock.date;
    fields[1].value = stock.stoke_movement_type;
    fields[2].value = stock.crop_id;
    fields[3].value = stock.tillage_id;
    fields[4].value = stock.agricultural_input_id;
    fields[5].value = stock.document;
    fields[6].value = stock.quantity;

    if (name === 'crop_id') {
      const options = this.setOptionsTillages(event.target.value)
      fields[3].options = options
    }

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  setOptionsTillages = crop_id => {
    const { tillages } = this.state

    const options = [{ label: '', value: null }]

    tillages.map(item => {
      if (item.crop_id === crop_id) {
        const p = {
          label: item.name,
          value: item.id
        }
        options.push(p)
      }
      return null
    })

    return options
  }

  save = async () => {
    const stock = this.state;
    stock.quantity = wheigthToFloat(stock.quantity)

    await api.post('stocks', stock)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push('/stocks')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const stock = this.state;
    stock.quantity = wheigthToFloat(stock.quantity)

    await api.put(`stocks/${stock.id}`, stock)
      .then(result => {
        toastSuccess(`Registro de ${stock.name} atualizado!`);
        this.props.history.push('/stocks')
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  render() {
    const stock = this.state;

    return (
      <Widget
        title={stock.id ? `Editar Saída` : 'Nova Saída'}>
        <div className="row">
          <div className="col col-md-8">
            <form onSubmit={this.submit}>
              <div className="row p-0 m-0">
                <div className="col col-md-4">
                  <DateTime
                    label="* Data"
                    value={stock.date}
                    onChange={date => this.setState({ date: date })}
                    field={stock.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[0])}
                  />
                </div>

                <div className="col offset-md-2">
                  <RadioButton
                    label="* Tipo"
                    value={stock.stoke_movement_type}
                    disabled={true}
                    options={stock.fields[1].options}
                    onChange={event => this.setState({ stoke_movement_type: event })}
                    field={stock.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[1])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <Select
                    label="* Safra"
                    value={stock.crop_id}
                    options={stock.fields[2].options}
                    onChange={this.handleChange}
                    field={stock.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[2])}
                  />
                </div>

                <div className="col">
                  <Select
                    label="* Lavoura"
                    value={stock.tillage_id}
                    options={stock.fields[3].options}
                    onChange={this.handleChange}
                    field={stock.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[3])}
                  />
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col col-md-6">
                  <Select
                    label="* Produto"
                    value={stock.agricultural_input_id}
                    options={stock.fields[4].options}
                    onChange={this.handleChange}
                    field={stock.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[4])}
                  />
                </div>
                <div className="col">
                  <InputText
                    label="Documento"
                    value={stock.document}
                    onChange={this.handleChange}
                    field={stock.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[5])}
                  />
                </div>

                <div className="col col-md-2">
                  <InputQuantity
                    label="* Quantidade"
                    value={stock.quantity}
                    onChange={this.handleChange}
                    field={stock.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, stock.fields[6])}
                  />
                </div>
              </div>

              <div className="row col p-0 m-0 justify-content-end mb-3">
                <div className="col col-md-4 mt-3">
                  <Link to="#" className="mr-3">
                    <button
                      className="btn btn-success btn-block"
                      disabled={!stock.valid}
                      onClick={this.submit}>
                      {this.state.id ? 'Atualizar' : 'Salvar'}
                    </button>
                  </Link>
                </div>

                <div className="col col-md-3  mt-3">
                  <Link to="/stocks">
                    <button
                      className="btn btn-success btn-block ">
                      Voltar
									</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    )
  }
}

export default Index;