
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import FinancialTransfer from './FinancialTransfer'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Movimentação Financeira', url: '/financial-movements' },
          { page: 'Transferência', url: '#' },
          { page: 'Incluir', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(FinancialTransfer),
    renderNothing
  )
)(FinancialTransfer)

export default Component