import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

const LoginLayout = compose(
  connect(state => {
    return {
      config: state.config
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_CONFIG',
        config: { layout: 'login-layout' }
      })
    }
  })
)

export default LoginLayout
