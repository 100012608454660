
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import Photo from './Photo'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(Photo),
    renderNothing
  )
)(Photo)

export default Component