import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Widget from '../../components/widget'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import DataTable from 'react-data-table-component';
import Loading from '../../components/loading'
import { dateToString } from '../../actions/util';
import { formatPrice } from '../../actions/util';

class Clients extends Component {
  constructor() {
    super()
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onConfirmDelete = this.onConfirmDelete.bind(this)

    this.state = {
      data: [],
      modal: false,
      itemDeleteName: null,
      itemDeleteId: null,
      actions: [
        {
          name: 'Novo Cliente',
          class_btn: 'btn-success',
          class_collumn: 'col col-md-2',
          route: 'clients/new'
        }
      ]
    }
    this.paginationOptions = { rowsPerPageText: 'Itens por página', rangeSeparatorText: 'de' };

    this.columns = [
      {
        name: 'Nome',
        selector: 'person.name',
        sortable: true,
      },
      {
        name: 'Limite de Crédito',
        selector: 'credit_limit_format',
        sortable: true,
      },
      {
        name: 'Data Última Compra',
        selector: 'last_sale_date_format',
        sortable: true,
      },
      {
        name: 'Ações',
        cell: (item) => (
          <div>
            <Link to="#">
              <i className="material-icons text-danger rtd_button" onClick={(e) => this.onConfirmDelete(item)}>delete_forever</i>
            </Link>
          </div>
        ),
        button: true,
      },
    ]
  }

  componentDidMount() {
    this.getClients();
  }

  getClients = async () => {
    let clients = [];
    this.setState({ loading: true });

    await api.get('clients')
      .then(result => {
        clients = result.data.map(client => ({
          ...client,
          last_sale_date_format: client.last_sale_date ? dateToString(client.last_sale_date) : '',
          credit_limit_format: formatPrice(client.credit_limit)
        }))
      })
      .catch(error => {
        toastError(handleError(error));
      })

    this.setState({
      data: clients,
      totalItems: clients.length,
      loading: false,
    });
  }

  onConfirmDelete(item) {
    this.setState({ modal: true, itemDeleteName: item.person.name, itemDeleteId: item.id })
  }

  onDeleteItem = async () => {
    const { itemDeleteId } = this.state;

    await api.delete(`clients/${itemDeleteId}`)
      .then(() => {
        this.getClients();
        toastSuccess('Registro removido com sucesso!');
        this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  handleRowClicked = reg => {
    this.props.history.push(`/clients/${reg.id}`)
  };

  render() {
    return (
      <Widget title="Clientes" actions={this.state.actions} line={false}>
        <div className="row d-flex">
          <div className="col table">
            <DataTable
              columns={this.columns}
              data={this.state.data}
              progressPending={this.state.loading}
              pointerOnHover
              highlightOnHover
              noDataComponent="Nenhum registro encontrado"
              onRowClicked={this.handleRowClicked}
              progressComponent={<Loading />}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          wrapClassName="modal-danger"
          size="default">
          <ModalHeader toggle={this.toggle}>Deletar Registro</ModalHeader>
          <ModalBody>
            Deseja excluir {this.state.itemDeleteName} ?
            </ModalBody>
          <ModalFooter>
            <button className="btn btn-success col-4" onClick={this.onDeleteItem}>
              Sim
              </button>
            <button className="btn btn-success" onClick={event => this.setState({ modal: false, itemDeleteId: null, itemDeleteName: null })}>
              Cancelar
              </button>
          </ModalFooter>
        </Modal>
      </Widget>
    )
  }
}

export default Clients