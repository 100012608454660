
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #765A4B;

  :hover {
    background-color: #B2967D;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    padding: 10px;
    color: #fff;
  }

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    color: #FFFFFF;
  }
`
export const Alerta = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 12px;
  background-color: #765A4B;
  padding: 10px;
  color: #fff;

`

export const BudgetTitle = styled.div`
  
  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
 
  span {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`