import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Widget from '../../../../components/widget'

import { handleError } from '../../../../actions/handle-error'
import { toastError } from '../../../../actions/toast'
import api from '../../../../actions/api'

class Index extends Component {

  constructor() {
    super();

    this.state = {
      loading: false,
      header_table: [],
      data: [],
      pdf_url: ''
    }
  }

  componentDidMount() {
    const { crop_id, status } = this.props.match.params
    if (crop_id) this.getCargoPacking(crop_id, status)
  }

  getCargoPacking = async (crop_id, status) => {
    const status_ = status.toUpperCase()

    await api.get(`dashboard/cargo-packings/report?crop_id=${crop_id}&pay_date_status=${status_}`)
      .then(result => {
        const fileURL = this.preparePDFFile(result.data)

        this.setState({ pdf_url: fileURL })
      })
      .catch(error => {
        toastError(handleError(error));
      })
  }

  preparePDFFile = data => {
    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)

    return fileURL
  }

  handleCancel = () => {
    this.setState({
      pdf_url: '',
    })

    this.props.history.goBack()
  }

  render() {
    const getCargoPacking = this.state

    return (
      <Widget title="Resumo Romaneio de Cargas">
        <div className="row" >
          <div className="col">
            {getCargoPacking.pdf_url &&
              <div className="col-12">
                <div className="row col p-0 m-0 justify-content-end mb-2">
                  <div className="col col-md-2 mt-1">
                    <Link to="#" className="mr-3">
                      <button
                        className="btn btn-success btn-block"
                        onClick={this.handleCancel}>
                        Cancelar
                    </button>
                    </Link>
                  </div>
                </div>

                <object className="col pdf-view" data={getCargoPacking.pdf_url} type="application/pdf" style={{ height: '800px' }}>
                  <embed src={getCargoPacking.pdf_url} type="application/pdf" />
                </object>
              </div>
            }
          </div>
        </div>
      </Widget >
    )
  }
}

export default Index;
