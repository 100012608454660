export function selectedBudget(
  state = {
    name: '',
    id: '',
  },
  action
) {
  switch (action.type) {
    case 'SET_SELECTED_BUDGET':
      return Object.assign({}, state, {
        ...action.selectedBudget
      })
    default:
      return state
  }
}

