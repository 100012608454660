/* eslint-disable react/prop-types */
import React, { useCallback, useState, useRef } from 'react';
import { GoSearch, GoX } from 'react-icons/go';
import { Form } from '@unform/web';
import styled from 'styled-components';
import InputLabel from '../../components/unform/input-label';

const EmployeesFilter = ({ setParams, default_params }) => {
  const formRef = useRef(null);

  const [isFilter, setIsFilter] = useState(false);

  const searchByName = useCallback(
    name => {
      if (name?.length >= 3) {
        setParams(prevState => ({
          ...prevState,
          name,
        }));
      } else if (name.length === 0) {
        setParams(default_params);
      }
    },
    [default_params, setParams]
  );

  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('name', '');

    setParams(default_params);
  }, [default_params, setParams]);

  return (
    <FilterContainer>
      <div className="row pt-2 ">
        <div className="col-sm text-right">
          <a
            className="text-success btn p-0"
            data-toggle="collapse"
            href="#collapsePeople"
            role="button"
            aria-expanded="false"
            aria-controls="collapsePeople"
            onClick={() => {
              setIsFilter(!isFilter);
              if (isFilter) clearFieldsSearch();
            }}
          >
            {!isFilter && (
              <>
                Filtrar <GoSearch />
              </>
            )}
            {isFilter && (
              <>
                Fechar Filtro <GoX />
              </>
            )}
          </a>
        </div>
      </div>

      <div className="col p-1">
        <div className="collapse filter" id="collapsePeople">
          <div className="card card-body">
            <Form ref={formRef} noValidate onSubmit={e => searchByName(e)}>
              <div className="row justify-content-center">
                <div className="col-sm col-md-8">
                  <div className="col-sm ">
                    <InputLabel
                      label="Busca rápida por nome"
                      name="name"
                      onChange={e => searchByName(e.target.value)}
                    />
                  </div>

                  <div className="row justify-content-end mt-3">
                    <div className="col-sm col-md-2 mb-1">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={clearFieldsSearch}
                      >
                        Limpar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </FilterContainer>
  );
};

export default EmployeesFilter;

export const FilterContainer = styled.div`
  .card {
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 10px;
    border: none;
  }

  .btn-success {
    height: 35px !important;
  }
`;
