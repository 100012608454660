import { connect } from 'react-redux';
import {
  compose,
  branch,
  renderComponent,
  renderNothing,
  lifecycle,
} from 'recompose';

import Dashboard from '../dashboard';

const layouts = ['default-sidebar', 'collapsed-sidebar'];

const Component = compose(
  connect(state => {
    return {
      navigation: state.navigation,
      layout: state.config.layout,
      user: state.user,
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [{ page: 'Home', url: '#' }],
      });
    },
  }),
  branch(
    ({ layout }) => layouts.includes(layout),
    renderComponent(Dashboard),
    renderNothing
  )
)(Dashboard);

export default Component;
