import React from 'react'

const RaddioButton = ({ field, validate, label, value, disabled, onChange }) => {
  let hasErrors = field.errors.length > 0 ? true : false
  return (
    <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
      <label className="form-control-label">{label}</label>
      <div className="input-group">
        {field.options.map(item => (
          <label key={item.value} className="custom-control custom-radio">
            <input
              name={field.name}
              type="radio"
              value={item.value}
              onChange={(e) => onChange(item.value)}
              disabled={disabled}
              checked={value === item.value ? true : false}
              className={
                hasErrors
                  ? 'custom-control-input is-invalid'
                  : 'custom-control-input'
              }
              onInput={e => validate(e, field)}
              onBlur={e => validate(e, field)}
            />
            <span className="custom-control-indicator" />
            <span className="custom-control-description">{item.label}</span>
          </label>
        ))}
      </div>
      {field.errors.map((error, i) => (
        <div key={i} className="form-text text-danger">
          {error}
        </div>
      ))}
    </div>
  )
}

export default RaddioButton