
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import FinancialMovement from './FinancialMovement'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      const { type } = this.props.match.params;
      const movement_type = type === 'entry' ? 'Receita' : 'Despesa';

      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Movimentação Financeira', url: '/financial-movements' },
          { page: `${movement_type}`, url: '#' },
          { page: 'Incluir', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(FinancialMovement),
    renderNothing
  )
)(FinancialMovement)

export default Component