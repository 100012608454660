import React, { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { index, show } from './base-rest';
import { authenticate } from './auth';
import jwt from 'jwt-decode';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  const [tenant, setTenant] = useState({ name: 'Nome Inquilino' });

  const [isLoading, setLoading] = useState(false);

  const [defaultBudget, setDefaultBudget] = useState('');

  const isAuthenticated = localStorage.getItem('ns_access_token');

  const signIn = async data => {
    const response = await authenticate(data);

    if (response.token) {
      localStorage.setItem('ns_access_token', response.token);
    }

    return response;
  };

  const signOut = useCallback(() => {
    localStorage.removeItem('ns_access_token');
    window.location.reload();
  }, []);

  const loadUserLogged = useCallback(async access_token => {
    const { uid } = await jwt(access_token);

    const response = await show('users', uid);

    if (response) {
      setUser(response);
    }
  }, []);

  const loadTenant = useCallback(async () => {
    const response = await index('tenants');

    if (response) {
      setTenant(response[0]);
    }
  }, []);

  const loadDefaultBudget = useCallback(async () => {
    const response = await index('budgets');

    if (response) {
      setDefaultBudget({
        label: response[0].name,
        value: response[0].id,
      });
    }
  }, []);

  const reloadUser = useCallback(
    async access_token => {
      setLoading(true);

      loadUserLogged(access_token);

      loadTenant();

      loadDefaultBudget();

      setLoading(false);
    },
    [loadDefaultBudget, loadUserLogged, loadTenant]
  );

  useEffect(() => {
    const access_token = localStorage.getItem('ns_access_token');

    if (access_token) reloadUser(access_token);
  }, [reloadUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        defaultBudget,
        user,
        tenant,
        signIn,
        signOut,
        setLoading,
        loadDefaultBudget,
        setDefaultBudget,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
