import React, { useContext } from 'react';
import { FaRegUser, FaPowerOff } from 'react-icons/fa';
import '../../css/navbar/dropdown-user.css';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../actions/auth-context';

const DropdownUser = () => {
  const { signOut, user } = useContext(AuthContext);

  return (
    <div className="navbar-dropdown dropdown-user">
      <div className="dropdown-title text-uppercase">
        {user?.username || 'Nome do usuário'}
      </div>
      <div className="dropdown-item">
        <FaRegUser />
        <span className="title">
          <Link to={`users/${user?.id}`}>Meus dados</Link>
        </span>
        <div className="separator" />
      </div>

      <div className="dropdown-item">
        <FaPowerOff />
        <span className="title" onClick={signOut}>
          Sair
        </span>
        <div className="separator" />
      </div>
    </div>
  );
};

export default DropdownUser;
