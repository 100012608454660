
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import AgriculturalInputs from './AgriculturalInputs'

const Component = compose(
  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Insúmos Agrícolas', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true
    },
    renderComponent(AgriculturalInputs),
    renderNothing
  )
)(AgriculturalInputs)

export default Component