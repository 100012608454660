import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import {
  formatPrice,
  formatSimplePrice,
  formatUnity,
} from '../../../../actions/util';
import api from '../../../../actions/api';
import Widget from '../../../../components/widget';

import { Caption, Label, Crop, Tillage, HeaderInsumo, Insumos } from './styles';

class StockDetails extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      crop_name: '',
      total: 0,
      input_type: '',
      data: [
        {
          name: '',
          total: '',
          tillages: [
            {
              name: '',
              total: '',
              agricultural_inputs: [
                {
                  name: '',
                  value: '',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  async componentDidMount() {
    const { input_type, budget_id } = this.props.match.params;
    if (input_type && budget_id) await this.getStockByCropGeneral();
  }

  getStockByCropGeneral = async () => {
    const { input_type, budget_id } = this.props.match.params;

    await api
      .get(
        `dashboard/stock/used-by-input-type/details?input_type=${input_type}&budget_id=${budget_id}`
      )
      .then(result => {
        const lastItem = result.data.slice(-1);

        this.setState({
          data: result.data,
          total: lastItem[0].total,
          input_type: input_type,
          budget_id: budget_id,
        });
      });
  };

  render() {
    const { data, total, input_type } = this.state;

    return (
      <Widget title={`Custo Estoque Geral`} printer={true}>
        <div className="row">
          <div className="col-sm">
            <Caption>Insumo</Caption>
            <Label>
              {input_type === 'FITO' ? 'Fitossanitário' : 'Fertilizante'}
            </Label>
          </div>

          <div className="col-sm text-right">
            <Caption>Valor total em estoque</Caption>
            <Label>{formatPrice(total)}</Label>
          </div>
        </div>

        {data.map((crop, i) => (
          <div key={i} className="mt-3">
            <Crop className="row ml-0">
              <div className="col-sm">
                {crop.name === 'Total' ? crop.name : `Safra: ${crop.name}`}
              </div>
              <div className="col-sm text-right">
                {crop.name === 'Total'
                  ? formatPrice(crop.total)
                  : `Total: ${formatPrice(crop.total)}`}
              </div>
            </Crop>

            {crop.tillages &&
              crop.tillages.map((tillage, x) => (
                <div key={x}>
                  <Tillage className="row ml-4">
                    <div className="col-sm">Lavoura: {tillage.name}</div>
                    <div className="col-sm text-right">
                      Total: {formatPrice(tillage.total)}
                    </div>
                  </Tillage>

                  <HeaderInsumo className="row ml-4">
                    <div className="col">Insumos</div>
                    <div className="col">Quantidade</div>
                    <div className="col">Unidade</div>
                    <div className="col">Preço Médio</div>
                    <div className="col text-right">Valor</div>
                  </HeaderInsumo>

                  {tillage.agricultural_inputs &&
                    tillage.agricultural_inputs.map((agriInput, x) => (
                      <div key={x}>
                        <Insumos className="row ml-4">
                          <div className="col">{agriInput.name}</div>
                          <div className="col">
                            {formatSimplePrice(agriInput.quantity)}
                          </div>
                          <div className="col">
                            {formatUnity(agriInput.unit_type)}
                          </div>
                          <div className="col">
                            {formatPrice(agriInput.average_price)}
                          </div>
                          <div className="col text-right">
                            {formatPrice(agriInput.value)}
                          </div>
                        </Insumos>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}

        {/* <TopBar>
          <div className="col">Lavoura</div>
          <div className="col text-right">Valor</div>
        </TopBar> */}

        {/* {
          tillages.map((tillage, i) => (
            <Link key={i} to={`/dashboard/stock/input_type/${input_type}/budget_id/${budget_id}/crop_id/${crop_id}/tillage_id/${tillage.id}`}>
              <Value>
                <div className="col">{tillage.name}</div>
                <div className="col text-right">{formatPrice(tillage.value)}</div>
              </Value>
            </Link>
          ))
        } */}

        {/* <Total>
          <div className="col">Total</div>
          <div className="col text-right">{formatPrice(total)}</div>
        </Total> */}

        <div className="row no-gutters justify-content-end mt-5">
          <div className="col-sm col-md-1">
            <Link to="/">
              <button className="btn btn-success btn-block">Voltar</button>
            </Link>
          </div>
        </div>
      </Widget>
    );
  }
}

export default StockDetails;
