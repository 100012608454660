
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import Employee from './Employee'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {

      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Funcionários', url: '/employees' },
          { page: 'Incluir', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(Employee),
    renderNothing
  )
)(Employee)

export default Component