import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import approve from 'approvejs'

import Widget from '../../components/widget'
import InputText from '../../components/input-text'
import Select from '../../components/select'
import DateTime from '../../components/date-time'
import InputCurrency from '../../components/input-mask-currency'

import api from '../../actions/api'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import { currencyToString, stringToDate, formatWeight, wheigthToFloat } from '../../actions/util'
import { fetchImage } from '../../actions/aws.service'

import './style.css'

class Index extends Component {

  constructor() {
    super();

    this.submit = this.submit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.validate = this.validate.bind(this)
    this.validateField = this.validateField.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      errors: [],
      id: '',
      cultivation_id: '',
      cultivation_name: '',
      initial_date: '',
      end_date: '',
      name: '',
      unit_type: 'CX',
      goal: '',
      average_price: '',
      area_in_ha: '',
      thumbnail: '',
      tillages: [],
      total_of_plants: 0,
      partner_pricing: 0,
      packaging_tare: 0,
      fields: [
        {
          value: '', //0
          name: 'budget_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //1
          name: 'cultivation_id',
          type: 'text',
          errors: [],
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //2
          name: 'initial_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //3
          name: 'end_date',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //4
          name: 'name',
          type: 'text',
          errors: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          }
        },
        {
          value: '', //5
          name: 'unit_type',
          type: 'text',
          errors: [],
          options: [
            { label: 'Caixa', value: 'CX' },
            { label: 'Metro', value: 'M' },
            { label: 'Metro Quadrado', value: 'M2' },
            { label: 'Metro Cúbico', value: 'M3' },
            { label: 'Litro', value: 'L' },
            { label: 'Mililitro', value: 'ML' },
            { label: 'Quilograma', value: 'KG' },
            { label: 'Grama', value: 'G' },
            { label: 'Unidade', value: 'UN' },
            { label: 'Milheiro', value: 'MIL' },
            { label: 'Saco', value: 'SAC' }
          ],
          rules: {}
        },
        {
          value: '', //6
          name: 'goal',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //7
          name: 'average_price',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //8
          name: 'partner_pricing',
          type: 'text',
          errors: [],
          rules: {}
        },
        {
          value: '', //9
          name: 'antecipation_accounting_id',
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          },
          type: 'text',
          errors: [],
        },
        {
          value: '', //10
          name: 'gratification_accounting_id',
          options: [],
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          },
          type: 'text',
          errors: [],
        },
        {
          value: '', //11
          name: 'packaging_tare',
          rules: {
            required: {
              message: 'Campo obrigatório'
            },
          },
          type: 'text',
          errors: [],
        },
      ],
      valid: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.getCrop(id);

    this.getBudgets();
    this.getCultivations();
    this.getAccountings();
  }

  validateField(e, field, fields) {
    e.preventDefault()

    field.value = ''
    if (field.name === 'confirm_password') {
      field.rules.equal.value = this.state.password
    }
    let result = approve.value(e.target.value, field.rules)
    field.errors = []
    if (result.errors.length > 0) {
      field.errors = Array.from(result.errors)
      field.value = ''
    } else {
      field.value = e.target.value
    }
    fields = fields.map(item => {
      if (item.name === field.name) {
        return field
      } else {
        return item
      }
    })
    return fields
  }

  validate(e, field) {
    let fields = this.validateField(e, field, this.state.fields)
    let errors = [];
    let valid = false;

    fields.map(field => {
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        errors.push(field.name);
      }

      return errors;
    })

    if (errors.length > 0) {
      valid = false
    } else {
      valid = true
    }

    this.setState({
      fields: fields,
      errors: errors,
      valid: valid
    })
    return false
  }

  submitForm = (e, fields) => {
    e.preventDefault()
    fields = fields.map(field => {
      field.errors = []
      let result = approve.value(field.value, field.rules)

      if (result.errors.length > 0) {
        field.errors = Array.from(result.errors)
      }
      return field
    })
    return fields
  }

  submit(e) {
    e.preventDefault()
    const crop = this.state;

    let fields = this.submitForm(e, crop.fields);
    this.setState({ fields: fields });

    if (crop.valid && crop.errors.length === 0) {
      crop.id ? this.update() : this.save();
    }
    return false
  }

  getBudgets = () => {
    api.get('budgets').then(result => {
      const budgets = result.data
      const options = [{ label: '', value: null }];

      budgets.map(budget => {
        const p = {
          label: budget.name,
          value: budget.id
        }
        options.push(p)
        return null;
      })
      const { fields } = this.state;

      fields[0].options = options;
      this.setState({
        fields: fields
      })
    })
  }

  getCultivations = () => {
    api.get('cultivations').then(result => {
      const cultivations = result.data
      const options = [{ label: '', value: null }];

      cultivations.map(cultivation => {
        const p = {
          label: cultivation.name,
          value: cultivation.id
        }
        options.push(p)
        return null;
      })
      const { fields } = this.state;

      fields[1].options = options;
      this.setState({
        fields: fields
      })
    })
  }

  getAccountings = async () => {
    let accountings = []
    const { fields } = this.state

    await api.get(`accountings?status_type=ATIVO&accounting_type=DESPESA`)
      .then(result => {
        fields[9].options = []
        fields[10].options = []

        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i]
          const item = { value: element.id, label: `${element.number} ${element.name}`, disabled: true }
          accountings.push(item)

          if (element.accountings && element.accountings.length > 0) {
            for (let x = 0; x < element.accountings.length; x++) {
              const elem = element.accountings[x]
              const item = { value: elem.id, label: `${elem.number} ${elem.name}` }
              accountings.push(item)
            }
          }
        }

        fields[9].options = accountings
        fields[9].options.unshift({ id: null, label: '' })
        fields[10].options = accountings
        fields[10].options.unshift({ id: null, label: '' })

        this.setState({ fields: fields })
      })
      .catch(error => {
        toastError(handleError(error))
      })
  }

  getUnitSelect = unity => {
    const { fields } = this.state;

    for (let i = 0; i < fields[5].options.length; i++) {
      const element = fields[5].options[i];

      if (element.value === unity) {
        return element.label
      }
    }
    return;
  }

  getCrop = async id => {
    await api.get(`crops/${id}`)
      .then(result => {
        const crop = result.data;
        const fields = this.state.fields;

        fields[0].value = crop.budget_id;
        fields[1].value = crop.cultivation_id;
        fields[2].value = crop.initial_date;
        fields[3].value = crop.end_date;
        fields[4].value = crop.name;
        fields[5].value = crop.unit_type;
        fields[6].value = crop.goal;
        fields[7].value = crop.average_price;
        fields[8].value = crop.partner_pricing;
        fields[9].value = crop.antecipation_accounting_id;
        fields[10].value = crop.gratification_accounting_id;
        fields[11].value = crop.packaging_tare;

        this.setState({
          id: crop.id || '',
          budget_id: crop.budget.id || '',
          cultivation_id: crop.cultivation_id || '',
          cultivation_name: crop.cultivation.name || '',
          initial_date: crop.initial_date ? stringToDate(crop.initial_date) : '',
          end_date: crop.end_date ? stringToDate(crop.end_date) : '',
          name: crop.name || '',
          unit_type: crop.unit_type || '',
          goal: crop.goal || '',
          average_price: crop.average_price || 0,
          partner_pricing: crop.partner_pricing || 0,
          area_in_ha: crop.area_in_ha || 0,
          thumbnail: crop.thumbnail || '',
          tillages: crop.tillages,
          total_of_plants: crop.total_of_plants || 0,
          antecipation_accounting_id: crop.antecipation_accounting_id || '',
          gratification_accounting_id: crop.gratification_accounting_id || '',
          packaging_tare: crop.packaging_tare ? formatWeight(crop.packaging_tare) : '',
          fields: fields
        })

        this.props.dispatch({
          type: 'SET_BREADCRUMB',
          items: [
            { page: 'Home', url: '/' },
            { page: 'Safras', url: '/' },
            { page: `${crop.cultivation.name}`, url: '#' },
            { page: 'Editar', url: '#' },
          ]
        })
      }).catch(error => {
        if (error.response && error.response.status === 500) this.props.history.push('/crops')
        toastError(handleError(error))
      });
  }

  handleChange(event) {
    const name = event.target.name;
    const crop = this.state;
    const fields = this.state.fields;

    fields[0].value = crop.budget_id;
    fields[1].value = crop.cultivation_id;
    fields[2].value = crop.initial_date;
    fields[3].value = crop.end_date;
    fields[4].value = crop.name;
    fields[5].value = crop.unit_type;
    fields[6].value = crop.goal;
    fields[7].value = crop.average_price;
    fields[8].value = crop.partner_pricing;
    fields[9].value = crop.antecipation_accounting_id;
    fields[10].value = crop.gratification_accounting_id;
    fields[11].value = crop.packaging_tare;

    this.setState({
      [name]: event.target.value,
      fields: fields
    })
  }

  save = async () => {
    const crop = this.state;
    crop.average_price = currencyToString(crop.average_price);
    crop.partner_pricing = currencyToString(crop.partner_pricing);
    crop.packaging_tare = wheigthToFloat(crop.packaging_tare);

    await api.post('crops', crop)
      .then(result => {
        toastSuccess('Novo registro incluído!');
        this.props.history.push(`/crops/${result.data.id}`)
        this.getCrop(result.data.id)
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  update = async () => {
    const crop = this.state;
    crop.average_price = currencyToString(crop.average_price);
    crop.partner_pricing = currencyToString(crop.partner_pricing);
    crop.packaging_tare = wheigthToFloat(crop.packaging_tare);

    await api.put(`crops/${crop.id}`, crop)
      .then(result => {
        toastSuccess(`Registro de ${crop.name} atualizado!`);
        this.setState({ packaging_tare: formatWeight(crop.packaging_tare) })
      }).catch(error => {
        toastError(handleError(error));
      })
  }

  onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const imageUrl = await this.readFile(e.target.files[0])

      this.setState({ imageUrl: imageUrl })
    }
  }

  readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  render() {
    const crop = this.state;

    return (
      <Widget title={crop.id ? `Editar ${crop.cultivation_name}` : 'Nova Safra'}>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-10 col-sm">
              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Orçamento"
                    value={crop.budget_id}
                    options={crop.fields[0].options}
                    onChange={this.handleChange}
                    field={crop.fields[0]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Cultivo"
                    value={crop.cultivation_id}
                    options={crop.fields[1].options}
                    onChange={this.handleChange}
                    field={crop.fields[1]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[1])}
                  />
                </div>

                <div className="col-sm">
                  <DateTime
                    label="* Início da safra"
                    value={crop.initial_date}
                    onChange={date => this.setState({ initial_date: date })}
                    field={crop.fields[2]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[2])}
                  />
                </div>

                <div className="col-sm">
                  <DateTime
                    label="* Final da safra"
                    value={crop.end_date}
                    onChange={date => this.setState({ end_date: date })}
                    field={crop.fields[3]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[3])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-6">
                  <InputText
                    label="* Nome"
                    value={crop.name}
                    onChange={this.handleChange}
                    field={crop.fields[4]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[4])}
                  />
                </div>

                <div className="col-sm">
                  <Select
                    label="* Unidade Comercial"
                    value={crop.unit_type}
                    options={crop.fields[5].options}
                    onChange={this.handleChange}
                    field={crop.fields[5]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[5])}
                  />
                </div>

                <div className="col-sm">
                  <InputText
                    label={`* Peso tara(kg) / ${crop.unit_type}`}
                    value={crop.packaging_tare}
                    onChange={this.handleChange}
                    field={crop.fields[11]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[11])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <InputText
                    label="* Meta de Produção"
                    value={crop.goal}
                    onChange={this.handleChange}
                    field={crop.fields[6]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[6])}
                  />
                </div>

                <div className="col-sm">
                  <InputCurrency
                    label={`* Preço Venda / ${this.getUnitSelect(crop.unit_type)}`}
                    value={crop.average_price}
                    onChange={this.handleChange}
                    field={crop.fields[7]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[7])}
                  />
                </div>

                <div className="col-sm">
                  <InputCurrency
                    label={`* Preço / ${this.getUnitSelect(crop.unit_type)} / Colheita`}
                    value={crop.partner_pricing}
                    onChange={this.handleChange}
                    field={crop.fields[8]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[8])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm col-md-6">
                  <Select
                    label="* Conta adiantamento salário colheita"
                    value={crop.antecipation_accounting_id}
                    options={crop.fields[9].options}
                    onChange={this.handleChange}
                    field={crop.fields[9]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[9])}
                  />
                </div>

                <div className="col-sm col-md-6">
                  <Select
                    label="* Conta gratificações colheita"
                    value={crop.gratification_accounting_id}
                    options={crop.fields[10].options}
                    onChange={this.handleChange}
                    field={crop.fields[10]}
                    validate={(e, field) =>
                      this.validate(e, crop.fields[10])}
                  />
                </div>
              </div>
            </div>

            {crop.id &&
              <div className="col-md-2  mt-3">
                <div className="card shadow">
                  <div className="row">
                    <div className="col">
                      <img width="100%" src={fetchImage(crop.thumbnail, 'crops') || '../assets/images/crop.png'} alt="Foto" />
                    </div>
                  </div>
                </div>

                <div className=" mt-2">
                  <Link to={`/crops/${crop.id}/photo`}>
                    <button
                      className="btn btn-success btn-block text-white">
                      Alterar Imagem
                  </button>
                  </Link>
                </div>
              </div>
            }
          </div>

          {crop.id &&
            <>
              <div className="d-flex mt-4">
                <h6 className="form-control-h6 mr-2 mt-1"><b>Lavouras</b></h6>
                <Link to={`/crops/${crop.id}/cultivations/${crop.cultivation_id}/tillages`}>
                  <i className="material-icons text-success">add_box</i>
                </Link>
              </div>

              <div className="row">
                {crop.tillages.map((tillage, i) => (
                  <div key={i} className="col-sm  col-md-4 mt-2">
                    <Link to={`/cultivations/${crop.cultivation_id}/tillages/${tillage.id}`}>
                      <div className="col card-tillage bg-success">
                        <p className="m-0 pt-1">{tillage.name}</p>

                        <div className="row col p-0 m-0">
                          <div className="col text-center">
                            <span>{tillage.number_of_plants || 0}</span>
                          </div>

                          <div className="col-3 p-1 mb-2 card-tillage-ha text-center">
                            <span>{tillage.area_in_ha || 0}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="row mt-4">
                <div className="col-sm mt-1">
                  <div className="col-sm card-total text-center">
                    <p>Total de plantas</p>
                    <span>{crop.total_of_plants}</span>
                  </div>
                </div>

                <div className="col-sm mt-1">
                  <div className="col-sm card-total text-center">
                    <p>Total área plantada (HA)</p>
                    <span>{crop.area_in_ha}</span>
                  </div>
                </div>
              </div>
            </>
          }

          <div className="row justify-content-end mt-5">
            <div className="col-sm col-md-3 mt-1">
              <Link to="#">
                <button
                  className="btn btn-success btn-block"
                  disabled={!crop.valid}
                  onClick={this.submit}>
                  {this.state.id ? 'Atualizar' : 'Salvar'}
                </button>
              </Link>
            </div>

            <div className="col-sm col-md-2 mt-1">
              <Link to="/">
                <button
                  className="btn btn-success btn-block ">
                  Voltar
									</button>
              </Link>
            </div>
          </div>
        </form>
      </Widget >
    )
  }
}

export default Index;