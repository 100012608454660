export function breadcrumb(
  state = [
    { page: 'Home', url: '#' },
  ],
  action
) {
  switch (action.type) {
    case 'SET_BREADCRUMB':
      return action.items
    default:
      return state
  }
}
