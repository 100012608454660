import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Widget from '../../../../components/widget';
import Loading from '../../../../components/loading';

import { handleError } from '../../../../actions/handle-error';
import { toastError } from '../../../../actions/toast';
import { floatToTimeList } from '../../../../actions/util';
import api from '../../../../actions/api';

class Index extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      header_table: [],
      data: [],
    };
  }

  componentDidMount() {
    this.getMachineTimesMachines();
  }

  getMachineTimesMachines = async () => {
    const params = await this.getParams();

    await api
      .get(`dashboard/machine-times/machines`, params)
      .then(result => {
        const data_ = result.data;

        if (data_.length > 0) {
          this.getHeaderTable(data_[0]);
        }

        this.setState({ loading: false, data: data_ });
      })
      .catch(error => {
        toastError(handleError(error));
      });
  };

  getParams = async () => {
    const budget_id = this.props.selected_budget.id;
    let params_ = {};

    if (!budget_id) this.props.history.push('/');

    if (budget_id) params_.budget_id = budget_id;

    return { params: params_ };
  };

  getHeaderTable = data => {
    let header_table_ = [{ label: 'Atividade' }];

    for (let i = 0; i < data.machines.length; i++) {
      let item = data.machines[i];
      header_table_.push({ label: item.name });
    }

    header_table_.push({ label: 'Total' });

    this.setState({ header_table: header_table_ });
  };

  rowTotalStyle = data => {
    const style = {
      backgroundColor: '#98c884',
      fontWeight: 'bold',
    };
    if (data.name === 'Total') return style;

    return null;
  };

  render() {
    const irrigation = this.state;

    return (
      <Widget title="Horas Máquina" printer={true} line={false}>
        <div className="row">
          <div className="col">
            {irrigation.loading && (
              <div className="row justify-content-center mt-5">
                <Loading />
              </div>
            )}

            {!irrigation.loading && (
              <div className="row mt-2">
                <div className="table-responsive">
                  <table className="table table-sm table-striped table-bordered table-hover">
                    <thead className="thead-report">
                      <tr>
                        {irrigation.header_table.map((item, i) => (
                          <th scope="col" key={i} className="text-center">
                            <span className="ref-month">{item.label}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {irrigation.data.map((data, y) => (
                        <tr key={y} style={this.rowTotalStyle(data)}>
                          <td>{data.name}</td>
                          {data.machines.map((crop, x) => (
                            <td key={x}>{floatToTimeList(crop.value)}</td>
                          ))}
                          <td>{floatToTimeList(data.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <form onSubmit={this.submit}>
              <div className="row no-gutters justify-content-end">
                <div className="col col-md-2 mt-3">
                  <Link to="/">
                    <button className="btn btn-success btn-block">
                      Voltar
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected_budget: state.selectedBudget,
  };
};
export default connect(mapStateToProps)(Index);
