import jwtDecode from 'jwt-decode'
import { getToken, isAuthenticated } from '../../actions/auth'
import { toastError, toastSuccess } from '../../actions/toast'
import { handleError } from '../../actions/handle-error'
import api from '../api'

export const getUserLogged= async (props) => {
  const tokenDecode = jwtDecode(getToken());
  const id = tokenDecode.uid;

  if(id && isAuthenticated()) {
    await api.get(`users/${id}`)
    .then(result => {
      const userLogged = result.data;
      
      props.dispatch({
        type: 'SET_USER',
        userLogged
      })
    })
    .catch(error => {
      toastError(handleError(error));
    })
  }
}

export const forgotPasswordApi = async (data) => {
  const result = new Promise((resolve) => {
    api.post('forgot', data)
      .then(result => {
        toastSuccess('E-mail enviado, verifique sua caixa de entrada!')
        resolve(result.data)
      })
      .catch(async error => {
        const response = await handleError(error);
        resolve(response);
      });
  })

  return result
}

export const resetPasswordApi = async (data) => {
  const result = new Promise((resolve) => {
    api.post('reset', data)
      .then(result => {
        toastSuccess('Senha Atualizada!')
        resolve(result.data)
      })
      .catch(async error => {
        const response = await handleError(error);
        resolve(response);
      });
  })

  return result
}


export default {getUserLogged, forgotPasswordApi}