
import { connect } from 'react-redux'
import { compose, branch, renderComponent, renderNothing, lifecycle } from 'recompose'
import CargoPackingsResume from './CargoPackingsResume'

const Component = compose(

  connect(state => {
    return {
      items: state.breadcrumb
    }
  }),
  lifecycle({
    componentDidMount() {

      this.props.dispatch({
        type: 'SET_BREADCRUMB',
        items: [
          { page: 'Home', url: '/' },
          { page: 'Dashboard', url: '/' },
          { page: 'Romaneio de Cargas', url: '#' },
        ]
      })
    }
  }),
  branch(
    () => {
      return true

    },
    renderComponent(CargoPackingsResume),
    renderNothing
  )
)(CargoPackingsResume)

export default Component