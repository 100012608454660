export const formatAddressType = type => {
  let formatter = '';
  if (type === 'CASA') formatter = 'Casa';
  if (type === 'TRABALHO') formatter = 'Trabalho';
  if (type === 'OUTRO') formatter = 'Outro';

  return formatter;
}

export const formatPhoneType = type => {
  let formatter = '';
  if (type === 'CASA') formatter = 'Casa';
  if (type === 'TRABALHO') formatter = 'Trabalho';
  if (type === 'CELULAR') formatter = 'Celular';
  if (type === 'OUTRO') formatter = 'Outro';

  return formatter;
}

export const formatPersonType = type => {
  let formatter = '';
  if (type === 'FISICA') formatter = 'Física';
  if (type === 'JURIDICA') formatter = 'Jurídica';

  return formatter;
}

export const formatPerfilType = type => {
  let formatter = '';
  if (type === 'ADMIN') formatter = 'administrador';
  if (type === 'USER') formatter = 'usuário';

  return formatter;
}

export const formatGenderType = type => {
  let formatter = '';
  if (type === 'MASCULINO') formatter = 'Masculino';
  if (type === 'FEMININO') formatter = 'Feminino';

  return formatter;
}

export default { formatAddressType, formatPhoneType, formatPerfilType, formatPersonType, formatGenderType };